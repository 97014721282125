import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const RocaContext = createContext();
export const RocaProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateRoca, setStateRoca] = useState({
        rocas:[],
        rocasF:[],
        project_id:null,
        project_name:"",
        loading:false,
        filter:10,
       
    });

    useEffect(async () =>{
        
        
        setStateRoca({...stateRoca, loading:true});
         

            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
                var rocasRes = await recordsServiceId("GET","rock_indices", id);
                
          
                if(rocasRes.status){ 

                    setStateRoca({
                        rocas:rocasRes.data,
                        rocasF:rocasRes.data,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter:10
                   
                    });
                }else{
                    if(rocasRes.code === 401 || rocasRes.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])
    
    return(
        <RocaContext.Provider value={{
            stateRoca,
            setStateRoca
        }}>
            {children}
        </RocaContext.Provider>
    )
}











