import React, { createContext, useState,useEffect, useContext } from "react"
import {dependentService, dependentServiceId, recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const ListasAsistenciaContext = createContext();
export const ListasAsistenciaProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateListas, setStateListas] = useState({
        listas:[],
        listasF:[],
        usuarios:[],
        project_id:null,
        project_name:"",
        loading:false,
        filter:"Todos",
        selectedUsers:[]
       
    });

    useEffect(async () =>{
        
        
        setStateListas({...stateListas, loading:true});
         

            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
                var listasRes = await recordsService("GET","assists");
                var usuRes = await dependentServiceId("GET","usersProyect", id);
                
          
                if(listasRes.status){ 

                    setStateListas({
                        listas:listasRes.data,
                        listasF:listasRes.data,
                        usuarios:usuRes.data,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter:"Todos",
                        selectedUsers:[]
                        
                   
                    });
                }else{
                    if(listasRes.code === 401 || listasRes.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])
    
    return(
        <ListasAsistenciaContext.Provider value={{
            stateListas,
            setStateListas
        }}>
            {children}
        </ListasAsistenciaContext.Provider>
    )
}











