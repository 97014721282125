
import React, { useState,useContext,createRef,useEffect } from 'react';
import {Journals, XCircleFill } from 'react-bootstrap-icons';

import DepartamentosRecords from '../components/records/departamentos_records'
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {showLoader, hideLoader,replaceObj, selectFromObjbyId, today} from '../utils/utils';


import { Form, Row, Col, Button } from 'react-bootstrap';
import { UserContext } from '../context/user_context';
import { DepartamentosContext } from '../context/departamentos_context';
import {Multiselect} from 'multiselect-react-dropdown'
import {services} from '../services/api'
import ViewDataLoader from '../components/ui/view_data_loader';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';

var projectsSelected = [];
var editing = false;
var editingId;
function DepartamentosView(){
   
      const [validated, setValidated] = useState(false);
      const [title, setTitle] = useState(true);
      const {user, setUser} = useContext(UserContext)
      const {state, setState} = useContext(DepartamentosContext)
      const {alert, showAlert, closeAlert} = useContext(AlertContext)

      const proyectosMultiSelect = createRef();

    //   useEffect(() => {
    //       console.log("AA");
    //       if(projectsSelected.length === 0){
    //         proyectosMultiSelect.current.resetSelectedValues()
    //       }
    //   }, [projectsSelected])

      const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
        var projects = []
        projectsSelected.forEach((p) =>{
            projects.push({'project_id':p.id})
        });
        body.projects = JSON.stringify(projects);
     
        if (form.checkValidity() === true) {
            
            var res;
            showLoader();
            if(!editing){
                res = await services("POST","departments",body);
            }else{
                res = await services("PUT","departments",body,editingId);
            }

            if(res.status){

                if(!editing){
                    
                    var depto_projects = []
                    projectsSelected.forEach((p) =>{
                        depto_projects.push({project:p})
                    })
                    var departament = res.data;
                    departament.depto_projects = depto_projects;
                    departament.deleted_at = null


                    var departments = state.departments;
                    var departmentsF = state.departmentsF;
                    departments.unshift(departament);
                    departmentsF.unshift(departament);
               
                    setState({...state,departments, departmentsF});
                    
                    showAlert("success", "¡Listo! Registro agregado exitosamente.");
                   

                }else{

                    var depto_projects = []
                    projectsSelected.forEach((p) =>{
                        depto_projects.push({project:p})
                    })
                    var departament = res.data;
                    departament.depto_projects = depto_projects;


                    var departments = replaceObj(state.departments,departament);
                    var departmentsF = replaceObj(state.departmentsF,departament);

                    setState({...state,departments, departmentsF});
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                    
                }
                
                hideLoader();
                hideForm();
                //clearForm();
            }
            
        }else{
            
            setValidated(true);
        }
    };

    const showForm = (data=undefined) =>{
        proyectosMultiSelect.current.resetSelectedValues()
        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');

        let f = document.getElementById("main_form");
        
        if(data !== undefined){
            reason_edition.classList.remove('d-none');
            setTitle(false);
            fillForm(data)
        }
        f.classList.add("d-block")
        f.classList.remove("d-none");
      }
    const hideForm = () =>{
        clearForm();
        let form = document.getElementById("main_form");
        form.classList.add("d-none");
        form.classList.remove("d-block");
    }
    const onChangeFilter = (evt) =>{
       
        setState({...state, filter:parseInt(evt.currentTarget.value)})
    }
    const onSearchChange = (val) => {
        var search = val.currentTarget.value
        setState({...state, search});
    }
    const clearForm = () => {
        
        let form = document.getElementById('departamentos_form');
        let {elements} = form
        elements.namedItem("reason_edition").disabled = true;
        editing = false;
        editingId = undefined;
        projectsSelected = []
        form.reset();
        setTitle(true)
        setValidated(false);
        setState({...state, projectsSelected:[]})

        
    }
    const fillForm = (data) => {
   
        let { elements } = document.getElementById('departamentos_form');;
        elements.namedItem("reason_edition").disabled = false;
        for (const [ key, value ] of Object.entries(data) ) {
            const field = elements.namedItem(key)
            if(key !== "reason_edition"){
                field && (field.value = value)
            }
            
        }
        var projects = [];
        data.depto_projects.forEach((p) =>{
            projects.push(p.project);
        });
        projectsSelected = projects;
        setState({...state, projectsSelected:projects})
        editing = true;
        editingId = data.id
    }



    if(state.loading){
     
        return (
            ViewDataLoader()
        )
    }else{
        
    return(
        <main role="main" className="">
               
            <div className="container-fluid position-relative min-h py-1 ">
            <h2><Journals size="25" color="#0a5cbe"></Journals> Catalogo: DEPARTAMENTOS</h2>  
            <div className="d-flex">
                <div className="col-2 py-2 px-0">
                    
                    <Form.Label>Estatus:</Form.Label>
                    <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                        <option value ="10">Todos</option>
                        <option value ="1">Activo</option>
                        <option value ="0">Desactivado</option>
                    </Form.Control>
                </div>
                <div className="col-3 py-2 px-0 mx-2">
                    
                    <Form.Label>BUSCAR:</Form.Label>
                    <Form.Control  type="text" name="buscar" onChange={onSearchChange} />
                </div>
                <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                    <Button className="primary px-4" onClick={()=>showForm()} >
                        Nuevo
                    </Button>
                </div>
            </div>
            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
            <DepartamentosRecords  onEdit={(data)=>{
                
                showForm(data);
            }}></DepartamentosRecords>

            <div className="catalogo_form_modal h-100 d-none" id="main_form">
                <Form noValidate validated={validated} onSubmit={handleSubmit} id="departamentos_form">
                    <div className="container-fluid w-75 container-md position-relative bg-white rounded pt-4">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="col">
                                <h5>{title === true ? "NUEVO" : "EDITAR"} DEPARTAMENTO</h5>
                            </div>
                            <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                <div className="form-control disabled">{today()}</div>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>RESPONSABLE</Form.Label>
                                <div className="form-control disabled">{user.name}</div>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>DEPARTAMENTO</Form.Label>
                                <Form.Control type="text" name="name"  required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>PROYECTO</Form.Label>
                                {/* <Form.Control type="text" name="project_id"  required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback> */}
                                <Multiselect
                                    ref={proyectosMultiSelect}
                                    options={state.projects}
                                    selectedValues={state.projectsSelected}
                                    displayValue="name"
                                    placeholder="Seleccionar"
                                    closeIcon="cancel"
                                    
                                    onSelect={(p)=>{
                                        projectsSelected = p;
                                        setState({...state, projectsSelected})
                                    }}
                                    onRemove={(p)=>{
                                        projectsSelected = p;
                                        setState({...state, projectsSelected})
                                    }}
                                    
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>DESCRIPCIÓN</Form.Label>
                                <Form.Control as="textarea" rows={3} name="description" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div id="reason_edition">
                                <Form.Group as={Col}>
                                    <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="reason_edition" disabled required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <Form.Group as={Col}>
                                <Button type="submit">GUARDAR</Button>
                            </Form.Group>

                            </div>
                            
                        </div>
                        <div className="cta position-absolute top right p-2" onClick={hideForm}>
                            <XCircleFill size="45"></XCircleFill>
                        </div>
                    </div>
                </Form>
            </div>
        

            </div>

        
        
        </main>
    )}

}


export default DepartamentosView;