import React, { createContext, useState,useEffect, useContext } from "react"

import { recordsService, dependentService} from '../services/api' 


import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";


export const UsuariosContext  = createContext();


const token = window.localStorage.getItem('token');
const uid = window.localStorage.getItem('uid');


export const UsuariosProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)


    const [state, setState] = useState({
          
        usuarios:[],
        usuariosF:[],
        departmets:[],
        projects:[],
        locations:[],
        categories:[],
        roles:[],
        selected:[],
        projectsSelected:[],
        filter: 10,
        loading:false,
        search:""
        
    });

    useEffect(async () =>{
   
        if(state.usuarios.length === 0 || state.departmets.length === 0 || state.projects.length === 0 || state.categories.length === 0 || state.locations.length === 0 || state.roles.length === 0 ){
            setState({...state, loading:true});
            var useRes = await recordsService("GET","users");
            console.log(useRes);
            
            var depRes = await dependentService("GET","departments");
            var proRes = await dependentService("GET","projects");
            var catRes = await dependentService("GET","categories");
            var locRes = await dependentService("GET","locations");
            var rolRes = await dependentService("GET","roles");

            if(useRes.status && depRes.status && proRes.status && catRes.status && locRes.status && rolRes){
                useRes.data.shift()
                setState({
                    ...state, 
                    loading:false, 
                    usuarios:useRes.data, 
                    usuariosF:useRes.data,
                    departmets:depRes.data, 
                    projects:proRes.data, 
                    categories:catRes.data,
                    locations:locRes.data,
                    roles:rolRes.data});
            }else{
                if(useRes.code == 401 || useRes.code == 500){
                    window.localStorage.clear();
                    setUser({});
                    auth.setMsg(true)
                    auth.signout();
                }
            }
        }
       
    },[])

    return(
        <UsuariosContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </UsuariosContext.Provider>

    )
}






