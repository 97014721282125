import React,{useState, useEffect} from 'react'
import  {services,} from "../../services/api"
import {PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import DataListInput from 'react-datalist-input';
import {message, handleObraRowsAutofill} from "../capturaUtils"
import { StatusContent,  SectoresContent, RmrContent, BarsContent, ZonasContent, ObrasContent} from "../capturaUtils/CapturaInputs"

function VoladurasOperartionRow({f, context, showAlert, machinerieValue}) {
    const [editing, setEditing] = useState(false)
    const [ item, setItem ] = useState(f)
    const [ machineCondition, setMachineCondition] = useState(machinerieValue)
    //const [ machineCondition, setMachineCondition] = useState(false)

    useEffect(()=>{
        setItem(f) 
    },[f])

    useEffect(()=>{
        if(typeof item.id == 'string'){
            setEditing(true)
        }
    },[])

    //en caso de que se agregue una maquina polvorera se deshabilitan algunos elementos

    const handleOperationItemChange = ((e)=>{
        const value = e.target.value
        console.log("change")
        setItem({
            ...item,
            [e.target.name]: value
          });
    })

    const handlePutReq =  (async(itemId)=>{
        setEditing(false)
        const body ={
            conectores: item.conectores,
            cordon_deto: item.cordon_deto,
            estatus: item.estatus,
            folio: item.folio,
            hora_final: item.hora_final,
            hora_inicial: item.hora_inicial,
            id: item.id,
            mecha: item.mecha,
            mexamon: item.mexamon,
            o_per_three_nine: item.o_per_three_nine,
            obra: item.obra,
            observations: item.observations,
            one_eight: item.one_eight,
            project_id: item.project_id,
            real_advance: item.real_advance,
            rmr: item.rmr,
            seccion_ancho: `${item.seccion_ancho}`,
            seccion_largo: `${item.seccion_largo}`,
            sector: item.sector,
            t_one: item.t_one,
            tipo_vol: item.tipo_vol,
            zona: item.zona,
        }
        const res = await services("PUT" , "blasting_type_form" ,  body, itemId)
        if(res.status == true){
            showAlert("success",`Listo, datos guardados correctamente`)
            setItem(res.data)
        }else{
            console.log(res)
            showAlert("error",`Error al guardar sus datos.Compruebe sus datos e intente de nuevo.`)
            setEditing(true)
        }
    })
    
    const handleEditing = (()=>{
        setEditing(true)
    })

  return (
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
            <select type="text" className="form-control" name="obra_disparada" value={item.obra} required onChange={(e)=>{
                    handleOperationItemChange(e,item.id)
                    handleObraRowsAutofill(e, item, setItem, context)
            }} >
                <ObrasContent obras={context.generalObras.data}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="sector" value={item.sector} required>
                <SectoresContent sectores={context.sectores}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="zona" value={item.zona} required>
                <ZonasContent zonas={context.zonasListado}/>
            </select>
        </td>
        <td >
            <input 
            type="text" 
            className="form-control" 
            onChange={(e)=>handleOperationItemChange(e,item.id)} 
            name="seccion_largo" 
            value={machineCondition ? item.seccion_largo : null} 
            readOnly={!editing } 
            disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="seccion_ancho" 
                value={item.seccion_ancho} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <select 
             className="form-control" 
             onChange={(e)=>handleOperationItemChange(e,item.id)} 
             name="rmr" 
             value={item.rmr} 
             //required 
             readOnly={!editing}
             disabled={machineCondition}
             type ="hidden"
             >
                <RmrContent rmr={context.rmr}/>
            </select>
        </td>
        <td>
            <select 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="tipo_vol" 
                value={item.tipo_vol} 
                readOnly={!editing}
                disabled={machineCondition}
             >
                <option value="Avance">Avance</option>
                <option value="despate">despate</option>
                <option value="desborde">desborde</option>
                <option value="encapille">encapille</option>
                <option value="descabece">descabece</option>
            </select>
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="real_advance" 
                value={item.real_advance} 
                readOnly={!editing}
                disabled={machineCondition}
             />
        </td>
        <td>
            <input 
                type="number" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="one_eight" 
                value={item.one_eight} 
                readOnly={!editing}
                disabled={machineCondition}
             />
        </td>
        <td>
            <input 
                type="number" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="t_one" 
                value={item.t_one} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="o_per_three_nine" 
                value={item.o_per_three_nine} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="number" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="mexamon" 
                value={item.mexamon} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="number" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="cordon_deto" 
                value={item.cordon_deto} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="number" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="mecha" 
                value={item.mecha} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="number" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="conectores" 
                value={item.conectores} 
                required 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="hora_inicial" 
                value={item.hora_inicial} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="hora_final" 
                value={item.hora_final} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            <select 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="estatus" 
                value={item.estatus} 
                readOnly={!editing}
                disabled={machineCondition}
            >
                <StatusContent/>
            </select>
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="observations" 
                value={item.observations} 
                readOnly={!editing}
                disabled={machineCondition}
            />
        </td>
        <td>
            {editing ? 
            <button className='btn btn-primary mx-2 text-nowrap' onClick={() => {if(window.confirm(message)){handlePutReq(item.id)}}}>Guardar Cambio</button> :
            <PencilSquare size="25" color="#606060" className='cursor-pointer mx-2' onClick={handleEditing}/>
            }
        </td>
    </tr>
  )
}

export default VoladurasOperartionRow