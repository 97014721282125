import React,{useContext, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import TablePagination from '@material-ui/core/TablePagination';
import { DepartamentosContext } from '../../context/departamentos_context';
import { PencilSquare } from 'react-bootstrap-icons';
import Switchy from '../triggers/switchy';
import { services} from '../../services/api';
import { selectFromObjbyId } from '../../utils/utils';




const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {

  const {state,setState} = useContext(DepartamentosContext)

  var row  = props.row;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const cellStyle = {
  
    paddingBottom:5,
    paddingTop:5,
    paddingLeft:5,
    paddingRight:10,
    lineHeight:1.2
  }

  const handleSwitch = async (data,id, val)=>{
    var res;
    if(!val){
      res = await services("DELETE","departments",undefined,id);
    
    }else{
      res = await services("GET","departments",undefined,id);
    }
    if(res.status){
    
      var departments = data
      var dep = selectFromObjbyId(data, id);
      dep.deleted_at = res.data.deleted_at;
      setState({...state, departments })
    }  
  }

  let active = row.deleted_at === null ? 1 : 0;
  let switchActive = row.deleted_at === null ? true : false;

  //if(state.filter === 10 || active === state.filter){  
    

  return (
    <React.Fragment>


      <TableRow className={classes.root} style={{padding:0}}>
      {/* <TableCell align="left" style={cellStyle}>{row.id}</TableCell> */}
  
        <TableCell align="left" style={cellStyle}>{row.name}</TableCell>
        <TableCell align="left" style={cellStyle}>{row.description}</TableCell>
        
        <TableCell  style={cellStyle}>
        {open ? "Ocultar" : "Abrir"}
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left"  style={cellStyle}>

          <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
            <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
          </IconButton>

        </TableCell>
        <TableCell align="left"  style={cellStyle}>
        <Switchy value={switchActive} onChange={(val)=>{
                              handleSwitch(state.departments, row.id, val)
                            }} /> 
        </TableCell>
      </TableRow>


      <TableRow className="bg-lightblue">
        <TableCell style={{ padding: 0, paddingBottom:0}} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              <h6 className="p-2 top-bordered bg-blue txt-white ">PROYECTOS</h6>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Proyecto</TableCell>
                    <TableCell>Description</TableCell>
               
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.depto_projects.map((proj, i) => (
                    <TableRow key={i}>

                      <TableCell>{proj.project.name}</TableCell>
                      <TableCell>{proj.project.description}</TableCell>
                  

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
// }else{
//     return null;
//   }
}

function DepartamentosRecords(props) {

    const {state,setState} = useContext(DepartamentosContext)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);
    
    
  

    useEffect(() => {
     
      var data = state.departments.filter((item)=>{
        var active = item.deleted_at === null ? 1 : 0;

        if(state.search === "" && state.filter === 10){
          return true;
        }

        if(state.search !== "" && state.filter === 10){
       
          return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase())
        }
       
        return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase()) && active === state.filter;
       

      })
      setPage(0);
      setState({...state, departmentsF:data});

    }, [state.search, state.filter])


   



    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  
    };


  const cellStyle = {
 
    paddingBottom:10,
    paddingTop:10,
    paddingLeft:5,
    paddingRight:10,
    lineHeight:1.2
  }
  return (

    <Paper>
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead className="bg-light">
          <TableRow>
         

            <TableCell style={{...cellStyle, minWidth:150}} align="left">Departamento</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Descripción</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Proyectos</TableCell>
            <TableCell style={{...cellStyle, width:100}} align="left">Editar</TableCell>
            <TableCell style={{...cellStyle, width:100}} align="left">Activar</TableCell>
        
          </TableRow>
        </TableHead>
        <TableBody>

          {
          
          state.departmentsF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
                return(
                  <Row key={i} row={row} open={open} onEdit={props.onEdit} />
                );
            })
                    
          }

            
         



          {/* {props.data.map((row,i) => (
            <Row key={i} row={row} />
          ))} */}


        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.departmentsF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
    
  );
}

export default DepartamentosRecords;
// import React,{useContext} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
// import { PencilSquare } from 'react-bootstrap-icons';
// import Switchy from '../triggers/switchy';
// import { DepartamentosContext } from '../../context/departamentos_context';

// const columns = [
//   { id:'name', label:'Departamento', minWidth: 170 },
//   { id:'description', label:'Descripcion', minWidth: 200 },
//   { id:'project_id', label:'Proyecto', minWidth: 50 },
//   { id:'editar', label:'Editar', minWidth: 50 },
//   { id:'activar', label:'Activar', minWidth: 50 },
// ];

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
 
// });

// function DepartamentosRecords(props) {

//   const {state,setState} = useContext(DepartamentosContext)
  

    
//     var data = state.departments.filter((item)=>{
//         if(state.search == ""){
//             return true;
//         }
      
//         return item.name.includes(state.search);
//     });
 

//     const classes = useStyles();
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);



//     const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//     };



//   return (
//     <Paper className={classes.root}>
//       <TableContainer  className={classes.container}>
//         <Table  stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column.id}
//                   align={column.align}
//                   style={{ 
//                     minWidth: column.minWidth,
//                     paddingLeft:0,
//                     paddingBottom:10,
//                     paddingTop:10,
//                     paddingLeft:5,
//                     paddingRight:10,
//                     lineHeight:1.2
//                    }}
//                 >
//                   {column.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

        

//               if(props.filter === 10 || row.activar === props.filter){               
//                 return (
//                   <TableRow hover role="checkbox" tabIndex={-1} key={i}>

//                     {columns.map((column) => {
//                       var value = row[column.id];
//                       return (
//                         <TableCell key={column.id} align={column.align} style={{ 
//                           paddingRight:10,
//                           paddingLeft:5,
//                           paddingTop:5,
//                           paddingBottom:0,

//                          }}>
//                           {
//                             column.id === "editar" ? 
//                             <PencilSquare size={20} className="cursor-pointer" onClick={()=>{
//                               props.editar(row)
//                             }} color="tomato"/> 
//                             : column.id === "activar" ?  
//                               value === 1 ?    
//                                 <Switchy value={true} onChange={(val)=>{}} /> 
//                               : 
//                                 <Switchy value={false} onChange={(val)=>{}} /> 
//                               : value
//                           }
//                         </TableCell>
                  
//                       );
//                     })}
                  
                    
//                   </TableRow>
//                 );
//               }


//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         animation="false"
//         rowsPerPageOptions={[10, 25, 100]}
//         component="div"
//         count={state.departments.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onChangePage={handleChangePage}
//         onChangeRowsPerPage={handleChangeRowsPerPage}
//         //labelRowsPerPage={"Ver"}
//         //abelDisplayedRows={"a"}
        
//       />
//     </Paper>
//   );
// }

// export default DepartamentosRecords;