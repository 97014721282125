import React,{useState, useEffect} from 'react'
import  {services,} from "../../services/api"
import {PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import DataListInput from 'react-datalist-input';
import {message, handleObraRowsAutofill} from "../capturaUtils"
import { StatusContent,  SectoresContent, RmrContent, BarsContent, ZonasContent, ObrasContent} from "../capturaUtils/CapturaInputs"


function LanzadoConcretoRow({f, context, showAlert, lanzado, acarreo}) {
    const [editing, setEditing] = useState(false)
    const [ item, setItem ] = useState(f)

    useEffect(()=>{
        setItem(f)
    },[f])

    useEffect(()=>{
        if(typeof item.id == 'string'){
            setEditing(true)
        }
    },[])

    const handleOperationItemChange = ((e)=>{
        const value = e.target.value
        console.log("change")
        setItem({
            ...item,
            [e.target.name]: value
          });
    })

    const handlePutReq =  (async(itemId)=>{
        setEditing(false)
        const res = await services("PUT" , "concrete_released_forms" ,  item, itemId)
        if(res.status == true){
            showAlert("success",`Listo, datos guardados correctamente`)
            setItem(res.data)
        }else{
            console.log(res)
            showAlert("error",`Error al guardar sus datos.Compruebe sus datos e intente de nuevo.`)
            setEditing(true)
        }
    })
    
    const handleEditing = (()=>{
        setEditing(true)
    })

  return (
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
            <select type="text" className="form-control" readOnly={!editing} name="obra_disparada" value={item.obra} required onChange={(e)=>{
                    handleOperationItemChange(e,item.id)
                    handleObraRowsAutofill(e, item, setItem, context)
            }} >
                <ObrasContent obras={context.generalObras.data}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="sector" value={item.sector} required>
                <SectoresContent sectores={context.sectores}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="zona" value={item.zona} required>
                <ZonasContent zonas={context.zonasListado}/>
            </select>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_largo" value={item.seccion_largo} required/>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_ancho" value={item.seccion_ancho} required/>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_real_largo" value={item.seccion_real_largo} required/>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_real_ancho" value={item.seccion_real_ancho} required/>
        </td>
        <td>
            <select
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="origen" 
                value={item.origen} 
                disabled={!acarreo}
            >
                <option value="">Seleccionar</option>
                {context.origenes.map((item,i)=>{
                    return (<option key={i} value={item.id}>{item.name}</option>)
                })}
            </select>
        </td>
        <td>
            <select
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="destino" 
                value={item.destino} 
                disabled={!acarreo}
            >
                <option value="">Seleccionar</option>
                {context.destinos.map((item,i)=>{
                    return (<option key={i} value={item.id}>{item.name}</option>)
                })}
            </select>
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="m3" 
                value={item.m3} 
                disabled={!acarreo}
            />
        </td>
        <td>
            <select 
             className="form-control" 
             onChange={(e)=>handleOperationItemChange(e,item.id)} 
             name="rmr" 
             value={item.rmr} 
             readOnly={!editing}
             type ="hidden"
             disabled={!lanzado}
             >
                <RmrContent rmr={context.rmr}/>
            </select>
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="area_colocacion" 
                value={item.area_colocacion} 
                disabled={!lanzado}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="espeso_cm_3" 
                value={item.espeso_cm_3} 
                disabled={!lanzado}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="volumen_m_3" 
                value={item.volumen_m_3} 
                disabled={!lanzado}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="fibra" 
                value={item.fibra} 
                disabled={!lanzado}
            />

        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="acelerante" 
                value={item.acelerante} 
                disabled={!lanzado}
            />
        </td>
        <td>
            <input 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                readOnly={!editing} 
                name="cant_testigos" 
                value={item.cant_testigos} 
                disabled={!lanzado}
            />
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="hora_inicial" value={item.hora_inicial} required/>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="hora_final" value={item.hora_final} required/>
        </td>
        <td>
            <select 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="estatus" 
                value={item.estatus} 
                readOnly={!editing}
            >
                <StatusContent/>
            </select>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="observaciones" value={item.observaciones} required/>
        </td>
        <td>
        {editing ? 
            <button className='btn btn-primary mx-2 text-nowrap' onClick={() => {if(window.confirm(message)){handlePutReq(item.id)}}}>Guardar Cambio</button> :
            <PencilSquare size="25" color="#606060" className='cursor-pointer mx-2' onClick={handleEditing}/>
        }
        </td>
    </tr>
  )
}

export default LanzadoConcretoRow