
import React, { useState,useContext,useEffect } from 'react';
import {ArrowLeftSquareFill, Journals, XCircleFill } from 'react-bootstrap-icons';

import OrigenesDestinosRecords from '../components/records/origenes_destinos_records'
import ViewDataLoader from '../components/ui/view_data_loader'
import {showLoader, hideLoader, replaceObj,removeFromObjbyId, today, selectFromObjbyId} from '../utils/utils';


import { Form, Col, Button } from 'react-bootstrap';
import { OrigenesDestinosContext } from '../context/origenes_destinos_context';
import { services } from '../services/api';
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import EspeciBarrenacionRecords from '../components/records/especi_barrenacion_records';
import { EspeciBarrenacionContext } from '../context/especi_barrenacion_context';
import { UserContext } from '../context/user_context';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';
import PresupuestoObrasRecords from '../components/records/presupuesto_obras_records';
import { PresupuestoObrasContext } from '../context/presupuesto_obras_context';




let dataEditing;
let editing = false;
let editingId;

function PresupuestoObrasView(){

    let history = useHistory();
   
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        title:true,
        editing:false,
        form:false,
        plantilla:undefined,
        tipo:undefined,
    });
    
    const {statePresupuesto, setStatePresupuesto} = useContext(PresupuestoObrasContext)
    const {user} = useContext(UserContext)
    const {alert, showAlert, closeAlert} = useContext(AlertContext)


    const handleSubmitPlantilla = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
        
        body.project_id = statePresupuesto.project_id;

        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","budgets", body);
                
            }else{
                res = await services("PUT","budgets",body,editingId);
                
            }

            if(res.status){

            if(!editing){
                let presupuestos = statePresupuesto.presupuestos;
                let presupuestosF = statePresupuesto.presupuestosF;

                let presupuesto = res.data
                presupuesto.deleted_at = null;
                let work = {}
                work.id =  res.data.work_id
                work.name = body.obra
                work.item = body.item
                let type_drilling =  selectFromObjbyId(statePresupuesto.conceptos, parseInt(res.data.type_drilling_id))
                let rock_indice =  selectFromObjbyId(statePresupuesto.rocas, parseInt(res.data.rock_id))
                let zone =  selectFromObjbyId(statePresupuesto.zonas, parseInt(res.data.zone_id))

                presupuesto.work = work;
                presupuesto.type_drilling = type_drilling;
                presupuesto.rock_indice = rock_indice;
                presupuesto.zone = zone;

                // work.id
                // presupuesto.work = selectFromObjbyId(state.bars, parseInt(machinary.bar_id))
                // presupuesto.work = statePresupuesto.plantilla.work;
                // presupuesto.type_drilling = statePresupuesto.plantilla.type_drilling;
                // presupuesto.rock_indice = statePresupuesto.rock_indice;
                // presupuesto.zone = statePresupuesto.zone;

                presupuestos.unshift(presupuesto);
                presupuestosF.unshift(presupuesto);
                setStatePresupuesto({...statePresupuesto,presupuestos, presupuestosF, historial:presupuestos, historialF:presupuestosF})

                showAlert("success", "¡Listo! Registro agregado exitosamente.")
            }else{

                let presupuesto = res.data
                presupuesto.deleted_at = null;
                let work = {}
                work.id =  res.data.work_id
                work.name = body.obra
                work.item = body.item
                let type_drilling =  selectFromObjbyId(statePresupuesto.conceptos, parseInt(res.data.type_drilling_id))
                let rock_indice =  selectFromObjbyId(statePresupuesto.rocas, parseInt(res.data.rock_id))
                let zone =  selectFromObjbyId(statePresupuesto.zonas, parseInt(res.data.zone_id))

                presupuesto.work = work;
                presupuesto.type_drilling = type_drilling;
                presupuesto.rock_indice = rock_indice;
                presupuesto.zone = zone;


         
                let presupuestos = replaceObj(statePresupuesto.presupuestos,presupuesto);
                var presupuestosF = replaceObj(statePresupuesto.presupuestosF,presupuesto);
                setStatePresupuesto({...statePresupuesto,presupuestos, presupuestosF, historial:presupuestos, historialF:presupuestosF})
                showAlert("success", "¡Listo! Registro editado exitosamente.")

            }
                hideLoader();
                hideForm();
            }else{
                hideLoader();
                var message = "";
                Object.keys(res.errors).map(function(key, index) {
                message = message + " " +res.errors[key][0]
                });
                showAlert("error", message);
            }

        }else{
            setValidated(true);
        }

    };

    // const handleSubmitTipo = async (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     const form = event.currentTarget;
    //     var formData = new FormData(form)

    //     var body = {};
    //     formData.forEach((value, key) => body[key] = value);
    //     
    //     body.project_id = statePresupuesto.project_id;

    //     if (form.checkValidity() === true) {

    //         var res;
    //         showLoader();
    //         if(!editing){
    //             res = await services("POST","type_drillings", body);
  
    //         }else{
    //             res = await services("PUT","type_drillings",body,editingId);
    //         }

    //         if(res.status){

    //         if(!editing){
    //             let tipos_barrenacion = statePresupuesto.tipos_barrenacion;
    //             let tipos_barrenacionF = statePresupuesto.tipos_barrenacionF;

    //             let tipo_barrenacion = res.data
    //             tipo_barrenacion.deleted_at = null;

    //             tipos_barrenacion.unshift(tipo_barrenacion);
    //             tipos_barrenacionF.unshift(tipo_barrenacion);
    //             setStatePresupuesto({...statePresupuesto,tipos_barrenacion,tipos_barrenacionF})
    //             showAlert("success", "¡Listo! Registro agregado exitosamente.")
    //         }else{

         
    //             let tipos_barrenacion = replaceObj(statePresupuesto.tipos_barrenacion,res.data);
    //             let tipos_barrenacionF = replaceObj(statePresupuesto.tipos_barrenacionF,res.data);
    //             setStatePresupuesto({...statePresupuesto,tipos_barrenacion, tipos_barrenacionF})
    //             showAlert("success", "¡Listo! Registro editado exitosamente.")

    //         }
    //             hideLoader();
    //             hideForm();
    //         }else{
    //             hideLoader();
    //             var message = "";
    //             Object.keys(res.errors).map(function(key, index) {
    //             message = message + " " +res.errors[key][0]
    //             });
    //             showAlert("error", message);
    //         }

    //     }else{
    //         setValidated(true);
    //     }

    // };


    

    const showForm = (data = undefined, form) =>{    

        

        if(data !== undefined){
            dataEditing = data;
            editing = true;
            editingId = data.id
        }
       
        if(form){
            if(data !== undefined){
                
                setState({editing:true, plantilla:data,form:true, title:false})
                return;
            }
            setState({...state,form:true})
            return;
        }


    }

    const hideForm = () =>{
        setState({title:true, editing:false, form:"", plantilla:undefined, tipo:undefined});
        setValidated(false);
        editing = false;
        editingId = null;
        dataEditing = null
    }

    if(statePresupuesto.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main" className={!alert.open ? "menu_collapsed": ""}>

            
            
                
            <div className="container-fluid position-relative py-1 ">
                 <div className="d-flex align-items-end py-2">
               
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                            history.goBack();
                        }}/>
                        <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
          
                    <div>
                        <h4 className="pl-1"> Catálogo: Presupuesto de Obras</h4>  
                        <h4 className="line-height-xs pl-1">PROYECTO: {statePresupuesto.project_name} </h4>
                    </div>

                    
                </div>
              
                
                 
                
                <div className="d-flex">
                    <div className="col-2 py-2 px-0">                 
                        {/* <Form.Label>Estatus:</Form.Label>
                        <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                            <option value ="10">Todos</option>
                            <option value ="1">Activo</option>
                            <option value ="0">Desactivado</option>
                        </Form.Control> */}
                    </div>
                    <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                        
                    </div>
                </div>

                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

                <PresupuestoObrasRecords 
                onEdit={(data, form)=>{
                    showForm(data, form);
                }}
                onAdd={(form)=>{

                    showForm(undefined,form)
                    
                }}
                >

                </PresupuestoObrasRecords>
                
                {state.form === true ? 
                <div className="catalogo_form_modal  py-3" id="">
                    <Form noValidate validated={validated} onSubmit={handleSubmitPlantilla} id="">
                        <div className="container position-relative bg-white rounded py-3">
                            <div className="col">
                                <h5>{state.title === true ? "NUEVO" : "EDITAR"} Presupuesto de Obras</h5>
                                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                            </div>
                        
                            <div className="row">
                                <div className="col-md-6">

                                

                                <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                    <div className="form-control disabled">{today()}</div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>RESPONSABLE</Form.Label>
                                    <div className="form-control disabled"  >{user.name}</div>
                                </Form.Group>

                                <Form.Group as={Col}>
                                <Form.Label>MES:</Form.Label>
                                <Form.Control defaultValue={state.plantilla !== undefined ? state.plantilla.month : ""} as="select" name="month"  required>
                                    <option value ="">Seleccionar</option>
                                    <option value ="1">1</option>
                                    <option value ="2">2</option>
                                    <option value ="3">3</option>
                                    <option value ="4">4</option>
                                    <option value ="5">5</option>
                                    <option value ="6">6</option>
                                    <option value ="7">7</option>
                                    <option value ="8">8</option>
                                    <option value ="9">9</option>
                                    <option value ="10">10</option>
                                    <option value ="11">11</option>
                                    <option value ="12">12</option>
                                </Form.Control>
                            </Form.Group>


                    


                                <Form.Group as={Col}>
                                    <Form.Label>AÑO</Form.Label>
                                    <Form.Control defaultValue={state.plantilla !== undefined ? state.plantilla.year : ""} type="number" min="2020" name="year" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio. Mimino 2020
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                <Form.Group as={Col}>
                                    <Form.Label>ITEM</Form.Label>
                                    <Form.Control className="text-uppercase" defaultValue={state.plantilla !== undefined ? state.plantilla.work.item : ""} type="text" name="item" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>OBRA</Form.Label>
                                    <Form.Control className="text-uppercase" defaultValue={state.plantilla !== undefined ? state.plantilla.work.name : ""} type="text" name="obra" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>RMR:</Form.Label>
                                    <Form.Control as="select"  name="rock_id" defaultValue={state.plantilla !== undefined ? state.plantilla.rock_id : ""}  required>
                                        <option value ="">Seleccionar</option>
                                        {statePresupuesto.rocas.map((roca,i)=>{
                                            
                                            return (<option key={i} value ={roca.id}>{roca.quality}</option>)
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                
                             
                                </div>
                                <div className="col-md-6">
                                    <Form.Group as={Col}>
                                        <Form.Label>ZONA:</Form.Label>
                                        <Form.Control as="select"  name="zone_id" defaultValue={state.plantilla !== undefined ? state.plantilla.zone_id : ""}  required>
                                            <option value ="">Seleccionar</option>
                                            {statePresupuesto.zonas.map((zona,i)=>{
                                                
                                                return (<option key={i} value ={zona.id}>{zona.name}</option>)
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>DESARROLLO (ml):</Form.Label>
                                        <Form.Control defaultValue={state.plantilla !== undefined ? state.plantilla.develop : ""} type="number" min="0" step="any" name="develop" required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>TUMBE (m3):</Form.Label>
                                        <Form.Control defaultValue={state.plantilla !== undefined ? state.plantilla.tumbe : ""} type="number" min="0" step="any" name="tumbe" required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>NO. CONCEPTO</Form.Label>
                                        <Form.Control className="text-uppercase" defaultValue={state.plantilla !== undefined ? state.plantilla.number_concept : ""} type="text" min="0" name="number_concept" required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>CONCEPTO:</Form.Label>
                                        <Form.Control as="select"  name="type_drilling_id" defaultValue={state.plantilla !== undefined ? state.plantilla.type_drilling_id : ""}  required>
                                            <option value ="">Seleccionar</option>
                                            {statePresupuesto.conceptos.map((concepto,i)=>{
                                                
                                                return (<option key={i} value ={concepto.id}>{concepto.name}</option>)
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    {/* <div id="reason_edition"> */}
                                    {state.editing === true ? 
                                    <Form.Group as={Col}>
                                        <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="reason_edition" required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    : null}
                                    {/* </div> */}
                                    <Form.Group as={Col}>
                                        <Button type="submit">GUARDAR</Button>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="cta position-absolute top right p-2" onClick={hideForm}>
                                <XCircleFill size="40"  ></XCircleFill>
                            </div>
                        </div>
                    </Form>
                </div>
                : null}

            
            
            </div>
           
        </main>
    )}

}

export default PresupuestoObrasView;

