
import React, { useState,useContext,useEffect } from 'react';
import {Journals, XCircleFill } from 'react-bootstrap-icons';

import OrigenesDestinosRecords from '../components/records/origenes_destinos_records'
import ViewDataLoader from '../components/ui/view_data_loader'
import {showLoader, hideLoader, replaceObj,removeFromObjbyId} from '../utils/utils';


import { Form, Col, Button } from 'react-bootstrap';
import { OrigenesDestinosContext } from '../context/origenes_destinos_context';
import { services } from '../services/api';
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import { MultiSelect } from 'react-multi-select-component';


let dataEditing;
let editing = false;
let editingId;

function OrigenesDestinosView(){
   
    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState(true);
    const [asignar, setAsignar] = useState({
        show:false,
        data:null
    });
    const {state,setState} = useContext(OrigenesDestinosContext)

    const {alert, showAlert, closeAlert} = useContext(AlertContext)


      
      const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
        

        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","origin_destination", body);
            }else{
                res = await services("PUT","origin_destination",body, editingId);
            }

            if(res.status){
                
                if(!editing){
                    
                    
                    if(res.data.data_type == "Origen"){
                        var origenes = state.origenes;
                        origenes.unshift(res.data);
                        setState({...state,origenes});

                    }
                    if(res.data.data_type == "Destino"){
                        var destinos = state.destinos;
                        destinos.unshift(res.data);
                        setState({...state,destinos});   
                    }
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")
                }else{
                   


                    if(res.data.data_type === "Origen"){

                    

                        if(dataEditing.data_type === "Origen"){
                            //EDITO ORGIGEN
                            var origenes = replaceObj(state.origenes,res.data);
                            setState({...state,origenes});
                            
                        }else{
                            
                            //EDITO DESTINO -> ORIGEN
                            var origenes = state.origenes;
                            origenes.unshift(res.data);
                            var destinos = removeFromObjbyId(state.destinos,res.data.id)
                            setState({...state,origenes, destinos});
                        }
                        
                    }

                    if(res.data.data_type == "Destino"){

                      

                        if(dataEditing.data_type === "Destino"){
                            //EDITO DESTINO
                            var destinos = replaceObj(state.destinos,res.data);
                            setState({...state,destinos});
                        }else{
                            //EDITO ORIGEN -> DESTINO
                            var destinos = state.destinos;
                            destinos.unshift(res.data);
                            var origenes = removeFromObjbyId(state.origenes,res.data.id)
                            setState({...state,destinos, origenes});
                        }

                    }
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                    
                }
                hideLoader();
                hideForm();
            }else{
                hideLoader();
                var message = "";
                Object.keys(res.errors).map(function(key, index) {
                    message = message + " " +res.errors[key][0]
                    
                });
                showAlert("error", message);
            }
            
        }else{
            
            setValidated(true);
        }

        };

        const showForm = (data = undefined) =>{

            let reason_edition = document.getElementById('reason_edition');
            reason_edition.classList.add('d-none');

            let f = document.getElementById("main_form");
            if(data !== undefined){
                reason_edition.classList.remove('d-none');
                setTitle(false);
                fillForm(data)
            }
            
            f.classList.add("d-block");
            f.classList.remove("d-none");
        }

        const hideForm = () =>{
        clearForm();
        let f = document.getElementById("main_form");
        f.classList.add("d-none");
        f.classList.remove("d-block");
        }

        const fillForm = (data) => {
            

        let { elements } = document.getElementById('origenesdestinos_form');
        elements.namedItem("reason_edition").disabled = false;

        for (const [ key, value ] of Object.entries(data) ) {
            const field = elements.namedItem(key)
            field && (field.value = value)
        }
        dataEditing = data;
        editing = true;
        editingId = data.id
        }
        const clearForm = () => {
        let f = document.getElementById('origenesdestinos_form');
        let {elements} = f
        elements.namedItem("reason_edition").disabled = true;

        editing = false;
        editingId = null;
        dataEditing = null
        f.reset();
        setValidated(false);
        setTitle(true);
        }


    if(state.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main">

            
            
                
            <div className="container-fluid position-relative py-1 ">
                <h2><Journals size="25" color="#0a5cbe"></Journals>  Catalogo: Origenes y Destinos</h2>  
                
                <div className="d-flex">
                    <div className="col-2 py-2 px-0">                 
                        {/* <Form.Label>Estatus:</Form.Label>
                        <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                            <option value ="10">Todos</option>
                            <option value ="1">Activo</option>
                            <option value ="0">Desactivado</option>
                        </Form.Control> */}
                    </div>
                    <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                        <Button variant="danger" className="primary px-4" onClick={()=>{showForm()}} >
                            Nuevo
                        </Button>
                    </div>
                </div>

                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

                <OrigenesDestinosRecords 
                    onEdit={(data)=>{
                        showForm(data);
                    }}
                    onAsignar={(data)=>{
                       
                        setAsignar({
                            show:true,
                            data,
                        })

                    }}
                >

                </OrigenesDestinosRecords>
                
                {
                    asignar.show ===  true ?
                    <div className="catalogo_form_modal h-100">
                    <div className="container w-50 asignar position-relative bg-white rounded pt-3 pb-5">
                        <div className="d-flex mb-2">
                            <div className="col p-0 pt-2">
                                <h5>ASIGNAR Origen/Destino</h5>
                            </div>
                            <div className="cta" onClick={()=>{
                                setAsignar({
                                    show:false,
                                    data:null
                                })
                            }}>
                                <XCircleFill size="40"></XCircleFill>
                            </div>
                        </div>

                        <OperacionesSelect data={asignar.data}></OperacionesSelect>


                    </div>
                </div>:null
                }
                

                <div className="catalogo_form_modal h-100 d-none py-3" id="main_form">
                    <Form noValidate validated={validated} onSubmit={handleSubmit} id="origenesdestinos_form">
                        <div className="container w-50 position-relative bg-white rounded pt-3">
                        
                            <div className="row">
                                <div className="col-md-12">

                                <div className="col">
                                    <h5>{title === true ? "NUEVO" : "EDITAR"} Origen/Destino</h5>
                                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                                </div>

                                <Form.Group as={Col}>
                                    <Form.Label>Catalogo</Form.Label>
                                    <Form.Control as="select"  name="data_type" required>
                                        <option value ="">Seleccionar</option>
                                        <option value ="Origen">Origen</option>
                                        <option value ="Destino">Destino</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group as={Col}>
                                    <Form.Label>ITEM</Form.Label>
                                    <Form.Control className="text-uppercase" type="text" name="item" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" name="name" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <div id="reason_edition">
                                    <Form.Group as={Col}>
                                        <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="reason_edition" disabled required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <Form.Group as={Col}>
                                    <Button type="submit">GUARDAR</Button>
                                </Form.Group>


                                </div>
                            </div>
                            <div className="cta position-absolute top right p-2" onClick={hideForm}>
                                <XCircleFill size="40"></XCircleFill>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
           
        </main>
    )}

}

function OperacionesSelect(props){
 
    let operations_selected = [];
    if(props.data.operations !== null){
        let operations_selected_aux = JSON.parse(props.data.operations)
    
        operations_selected_aux.map((item)=>{

            operations_selected.push(
                {
                    label: item.name, value: item.name, id:item.id
                }
            )
        })
        console.log(operations_selected)
    }

    





    const [selected, setSelected] = useState(operations_selected);


    const options = [
        { label: "ACARREO DE CONCRETO", value: "ACARREO DE CONCRETO", id:1},
        { label: "ACCARREO", value: "ACCARREO",  id:2 },
        { label: "AMACICE Y LIMPIEZA DE OBRA", value: "AMACICE Y LIMPIEZA DE OBRA",  id:3 },
        { label: "BARRENACIÓN CON JUMBO", value: "BARRENACIÓN CON JUMBO",  id:4 },
        { label: "LANZADO DE CONCRETO", value: "LANZADO DE CONCRETO",  id:5 },
        { label: "REZAGADO", value: "REZAGADO",  id:6 },
        { label: "SERVICIOS GENERALES", value: "SERVICIOS GENERALES",  id:7 },
        { label: "SOPORTE DE OBRA", value: "SOPORTE DE OBRA",  id:8 },
        { label: "TRANSPORTE DE PERSONAL Y MATERIAL", value: "TRANSPORTE DE PERSONAL Y MATERIAL", id:9 },
        { label: "VOLADURAS", value: "VOLADURAS",  id:10 },
    ];

   

    return(
        <div>
            <MultiSelect
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Seleccionar"
               overrideStrings={
                {
                    "allItemsAreSelected": "TODOS",
                    "clearSearch": "",
                    "noOptions": "SIN OPCIONES",
                    "search": "BUSCAR",
                    "selectAll": "SELECCIONAR TODOS",
                    "selectAllFiltered": "SELECCIONAR TODOS (FILTRADOS)",
                    "selectSomeItems": "SELECCIONAR"
                  }
               }
            />
            <div className="d-flex">
                <div className="col"></div>
                <div className="mt-3">
                    <Button className="" onClick={async ()=>{ 

                        let operations = []
                        selected.map((item) =>{

                            let op = {}
                            op.id = item.id;
                            op.name = item.value;
                            operations.push(op)
                        })
        
                        let body = {
                            origin_destinations_id:props.data.id,
                            operations:operations
                        }
                        console.log(body)
                        var res = await services("POST","origin_destination/add_operation", body);
            
                        console.log(res)
                        
                    }}>GUARDAR</Button>
                </div>
               

            </div>
            <br></br>
            <div>
                <table className="table table-striped">
                    <thead>
                        <td>ID</td>
                        <td>OPERACIÓN</td>
                    </thead>
                    <tbody>
                    {
                        selected.map((item,i)=>{

                            return(
                                
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.value}</td>
                                </tr>
                            )


                        })
                    }
                        
                    </tbody>
                </table>
                   
                </div>
        </div>
    )

}


export default OrigenesDestinosView;

