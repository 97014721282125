import React,{useContext,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { EyeFill, PencilSquare } from 'react-bootstrap-icons';
import Switchy from '../triggers/switchy';
import { BarrasContext } from '../../context/barras_context';
import {services} from '../../services/api';
import { selectFromObjbyId } from '../../utils/utils';
import { IconButton } from '@material-ui/core';
import { RocaContext } from '../../context/roca_context';
import { ListasAsistenciaContext } from '../../context/listas_asistencia_context';
import { Link, useRouteMatch } from 'react-router-dom';

const columns = [

  { id:'id', label:'ID', minWidth: 50 },
  { id:'week', label:'Semana', minWidth: 100 },
  { id:'mounth', label:'Mes', minWidth: 100 },
  { id:'year', label:'Año', minWidth: 100 },
  { id:'turn', label:'Turno', minWidth: 100 },
  { id:'date_start', label:'Fecha Inicio', minWidth: 120 },
  { id:'date_end', label:'Fecha Fin', minWidth: 120 },
  { id:'hour_start', label:'Hora Inicio', minWidth: 120 },
  { id:'hour_end', label:'Hora Fin', minWidth: 120 },
//   { id:'project_id', label:'Proyecto', minWidth: 120 },

  { id:'lista', label:'Lista', minWidth: 100 },
  { id:'editar', label:'Editar', minWidth: 100 },
  // { id:'activar', label:'Activar', minWidth: 100 },
  
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});




function ListaAsistenciaRecords(props) {

  const {stateListas, setStateListas} = useContext(ListasAsistenciaContext)
  

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { path, url } = useRouteMatch();

  useEffect(() => {
      
    var data = stateListas.listas.filter((item)=>{
      var turno = item.turn;

      if(stateListas.filter === "Todos"){
        return true;
      }
      return  turno.toLowerCase() === stateListas.filter.toLowerCase();
    })
    setPage(0);
    setStateListas({...stateListas, listasF:data});

  }, [stateListas.filter])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSwitch = async (data,id, val)=>{
    var res;
    if(!val){
      res = await services("DELETE","assists",undefined,id);
    }else{
      res = await services("GET","assists",undefined,id);
    }
    if(res.status){
      var listas = data
      var lista = selectFromObjbyId(data, id);
      lista.deleted_at = res.data.deleted_at;
      setStateListas({...stateListas, listas })
    }  
  }

  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
                  
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                   }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stateListas.listasF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter(lista => (lista.project_id === stateListas.project_id)).map((row,i) => {

            let active = row.deleted_at === null ? 1 : 0;
            let switchActive = row.deleted_at === null ? true : false;
            let date_end = new Date(Date.parse(row.date_end))
            let limit_date = date_end.setDate(date_end.getDate() + 7)
            let today = new Date();
            let editable = today > limit_date ? false : true;

         


           
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                          paddingRight:10,
                          paddingLeft:5,
                          paddingTop:5,
                          paddingBottom:0,

                         }}>
                          {

                            column.id === "lista" ?  
                            <Link to={`/home/proyectos/listas/asistencia/${row.id}`}>
                                <IconButton disabled={!switchActive}>
                                    <EyeFill size={20} className="cursor-pointer" color={switchActive ? "#0a5cbe" : "grey"}/>
                                </IconButton>
                            </Link>
                            : 
                            column.id === "editar" ? 
                            <IconButton disabled={!editable} onClick={()=>{props.onEdit(row)}}>
                              <PencilSquare size={20} className="cursor-pointer" color={editable ? "tomato" : "grey"}/>
                            </IconButton>
                            : value
                            // column.id === "activar" ?  

                            // <Switchy value={switchActive} onChange={(val)=>{
                            //   handleSwitch(stateListas.listas, row.id, val)
                            // }} /> 
                            // : value
                          }
                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
              
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={stateListas.listasF.filter(lista => (lista.project_id === stateListas.project_id)).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default ListaAsistenciaRecords;