import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const ZonasContext = createContext();
export const ZonasProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateZonas, setStateZonas] = useState({
        zonas:[],
        zonasF:[],
        project_id:null,
        project_name:"",
        loading:false,
        filter:10,
       
    });

    useEffect(async () =>{
        
        
        setStateZonas({...stateZonas, loading:true});
         

            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
                var zonasRes = await recordsServiceId("GET","zones", id);
                
          
                if(zonasRes.status){ 

                    setStateZonas({
                        zonas:zonasRes.data,
                        zonasF:zonasRes.data,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter:10
                   
                    });
                }else{
                    if(zonasRes.code === 401 || zonasRes.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])
    
    return(
        <ZonasContext.Provider value={{
            stateZonas,
            setStateZonas
        }}>
            {children}
        </ZonasContext.Provider>
    )
}











