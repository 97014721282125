import { ButtonGroup, IconButton } from '@material-ui/core'
import React, {createContext, useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import { ArrowLeftSquareFill, Back, Person, Trash, BlockquoteLeft, Search, X } from 'react-bootstrap-icons'

import { UserContext } from '../context/user_context'
import { Form, Col, Button } from 'react-bootstrap';

import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { ProyectosContext } from '../context/proyectos_context';
import { hideLoader, removeFromObjbyId, replaceObj, selectFromObjbyId, showLoader } from '../utils/utils';
import ViewDataLoader from '../components/ui/view_data_loader';
import { Doughnut } from 'react-chartjs-2';
import { dependentService, recordsService, recordsServiceId, services, singleService } from '../services/api';
import FormularioGeneral from '../components/formulario_general';
import DataListInput from 'react-datalist-input';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';

import { FormularioGeneralContext } from '../context/formulario_general_context';

const Context = createContext();
function VoladurasDetalleView() {

    

    let { project, id } = useParams();
    const { user } = useContext(UserContext);        
    const [proyecto, setProyecto] = useState(undefined);
    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)
    const {state,setState} = useContext(ProyectosContext);
    const [local, setLocal] = useState({
        forms:[], 
        form_id:null, 
        general_form:selectFromObjbyId(stateGenerales.generales,parseInt(id)),
        operation:{id:10, name:"VOLADURAS"},})
    const {alert, showAlert, closeAlert} = useContext(AlertContext)



    let history = useHistory();
    let location = useLocation();
    const { path, url } = useRouteMatch();

    useEffect(async()=>{

        var forms = await dependentService("GET", "blasting_type_form")
        
        setLocal({...local,forms:forms.data.filter((el) => el.folio === parseInt(id)), form_id:id });

    },[])

    useEffect(()=>{
        
        let proyecto = selectFromObjbyId(state.projects,parseInt(project))
        if(proyecto === undefined){
          
            history.replace(`/home/proyectos` );
        }else{
            setProyecto(proyecto);
        }
        
    },[project]);


    // useEffect(()=>{

    //     setLocal({...local,form_id:id})

    // },[id])


  

    const onRemoveForm = (id = "nuevo") => {

        let forms;

        if(id == "nuevo"){
            forms = local.forms;
            forms.pop();
        }else{
            forms = removeFromObjbyId(local.forms, id);
        }
        setLocal({...local, forms})
        showAlert("success", "¡Listo! formulario eliminado exitosamente.")
    }





    if(stateGenerales.loading){
        return (
            ViewDataLoader()
        )
    }else{
        
        return (
            <Context.Provider value={{
                local,
                setLocal,
                showLoader,
                hideLoader,
                onRemoveForm
            }}>
            <main role="main" className="">

                <div className="d-flex pl-1 border-bottom sticky-left">
                    <IconButton size="medium" disabled={false} onClick={()=>{
                        history.goBack();
                    }}>
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe"/>
                    </IconButton> 

               
                    {local.form_id === "nuevo" ? 
                    <Button type="submit" className="cta-title cta--disabled" >NUEVO FORMULARIO</Button>
                    :
                    <Button type="submit" className="cta-title" onClick={()=>{
                        var f = local.forms;
                        if(f.length < 15){
                            f.push("nuevo");
                            setLocal({...local,forms:f});
                        }
                    

                    }}>NUEVO FORMULARIO</Button>
                    }
             
                </div>

                <div className="sticky-left w-100">
                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                </div>
                
             
    
                <div className="general_forms container-fluid p-0">

          
                    <FormularioGeneral 
                        data={local.general_form} 

                        operation={{id:10, name:"VOLADURAS"}}

                        onCreate={(id)=>{
                            setLocal({...local,form_id:id});
                        }} 
                    
                        showLoader={()=>{
                            showLoader()
                        }}/> 

                            
                    {/* <VoladurasForm  data={undefined}/> */}

                    {
                    local.forms.map((el, i)=>{
                            if(el === "nuevo"){
                                return(<VoladurasForm key={i} data={undefined} general_form={selectFromObjbyId(stateGenerales.generales,parseInt(id))}/>) 
                            }else{
                                return(<VoladurasForm key={i} data={el} general_form={selectFromObjbyId(stateGenerales.generales,parseInt(id))} />)
                            }
                        
                    })
                    }


                    
                   
                </div>

           
            </main>
           
            </Context.Provider>
        )
    }
}


function VoladurasForm(props) {
  



    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)
    const {local, setLocal, showLoader, onRemoveForm} = useContext(Context)  
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({obra:null, zona:null, barras:null, mtsEficientes:null, avance:null, rmr:"", item:"", id:null})
    const {alert, showAlert, closeAlert} = useContext(AlertContext)


    useLayoutEffect(() => {
        var view = document.querySelector("main")
        view.scrollTo({left: view.scrollWidth, behavior: 'smooth'});
    }, [])

    useEffect(()=>{

        setState({
            ...state, 
            obra:props.data.obra, 
            zona:props.data.zona,
            rmr:props.data.rmr,
            barras:props.data.number_holes,
            mtsEficientes:props.data.efficient_length,
            type:props.data.concept,
            avance:props.data.per_avance,
            item:props.data.item,
            id:props.data.id !== null ? props.data.id : null
            })


    },[])


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        
        formData.forEach((value, key) => body[key] = value);
        body.project_id = stateGenerales.project_id;
        body.generalform_id = local.form_id;
        body.obra = state.obra;
        body.item = state.item;
        body.zona = state.zona;
        // body.number_holes = state.barras;
        // body.efficient_length = state.mtsEficientes;
        // body.concept = state.type;
        // body.per_avance = state.avance;
        body.folio = local.form_id;
  
        body.hora_final = body.hora_final + ":00";
        body.hora_inicial = body.hora_inicial + ":00";
    
       
        
        
 

        if (form.checkValidity() === true) {

            showLoader();
            
            var response;

            if(state.id === null){

               
                response = await services("POST","blasting_type_form",body);
                
                if(response.status){
                    
                    setState({...state, id:response.data.id})
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")

                }else{
                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }else{

         
                response = await services("PUT","blasting_type_form",body, state.id);
               

                if(response.status){
                 
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }else{

                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                    var view = document.querySelector("main")
                    view.scrollTop = 0;

                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }
         
        }

        setValidated(true);
    };

  

   let obras_by_month = [];
    if(props.general_form !== undefined){
        let created_month = new Date(Date.parse(props.general_form.date)).getMonth() + 1
        obras_by_month = stateGenerales.obras.filter((item) => item.month == created_month)
    }else{
        obras_by_month = stateGenerales.obras.filter((item) => item.month == (new Date().getMonth() + 1 ))
    }    
    
    const obras = useMemo(
        () =>
        obras_by_month.map((oneItem) => ({
            label: oneItem.work.item,
            key: oneItem.id,
            ...oneItem,
          })),
        [stateGenerales.obras]
    );

      let hora_inicial = null;
      let hora_final = null;

      if(props.data.hora_inicial !== null && props.data.hora_final !== null){
        hora_inicial = props.data.hora_inicial.split(":")[0] +":"+ props.data.hora_inicial.split(":")[1]
        hora_final = props.data.hora_final.split(":")[0] + ":"+props.data.hora_final.split(":")[1]
      }
       


    return (
        <div className="form_frente form_frente_v pb-5 mx-2">
            <div className="bg-blue text-white d-flex justify-content-center align-items-center p-2 mb-2 font-weight-bold">
                Formulario Voladuras {props.data.id}
                    <div className="cta position-absolute  right  pr-3" onClick={async ()=> {

                    
                        if(props.data.id !== null){
                            showLoader()
                            let response = await services("DELETE","blasting_type_form",undefined,props.data.id);
                            if(response.status){
                                onRemoveForm(props.data.id)
                            }
                            hideLoader();
                        }else{
                            onRemoveForm()
                        }
                    
                        
                        

                    }}>
                        <X size="30"></X>
                    </div>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Col}>
                    <Form.Label>SECTOR</Form.Label>
                    <Form.Control as="select" name="sector" defaultValue={props.data.sector} required>
                     <option value="">Seleccionar</option>
                        {stateGenerales.sectores.map((sector,i)=>{
                            return (<option key={i} value={sector.id}>{sector.sector}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>OBRA</Form.Label>
                    <div className="form-control disabled">{state.obra}</div>
                </Form.Group>


                <Form.Group as={Col} >
                    <Form.Label>ITEM</Form.Label><br></br>
                    <DataListInput
                        inputClassName="form-control-n"
                        placeholder=""      
                        value={state.item}
                        items={obras}
                        onInput = {(a) => {
                            //setLocal({...local,});
                        }}
                        onSelect={(el)=>{
                           
                            setState({...state, item:el.work.item, obra:el.work.name, zona:el.zone.name, type:el.type_drilling.name, rmr:el.rock_indice.id});
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                {
                    obras.length === 0 ? 
                    <div className="form-message form-message--red">
                        No hay obras dentro del mes.
                    </div> : null
                }

              

                <Form.Group as={Col}>
                    <Form.Label>ZONA</Form.Label>
                    <div className="form-control disabled">{state.zona}</div>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>SECCIÓN</Form.Label>
                    <Form.Control as="select" name="seccion" defaultValue={props.data.seccion} required onChange={(evt)=>{

                        let barras = selectFromObjbyId(stateGenerales.barrenacion, parseInt(evt.currentTarget.value))
                   
                        setState({...state, barras: barras !== undefined ? barras.number_bars : ""})

                    }}>
                        <option value="">Seleccionar</option>
                        {stateGenerales.barrenacion.map((barre,i)=>{
                            return (<option key={i} value={barre.id}>{barre.section}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} >
                    <Form.Label>BARRENOS CARGADOS</Form.Label>
                    <Form.Control type="number" step="any" min="0" defaultValue={props.data.barrenos_cargados} name="barrenos_cargados" required />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>RMR</Form.Label>
                    <Form.Control as="select" name="rmr" value={state.rmr} required onChange={(evt)=>{
                        setState({...state, rmr:evt.currentTarget.value});
                    }}>
                     <option value="">Seleccionar</option>
                        {stateGenerales.rmr.map((rmr,i)=>{
                            return (<option key={i} value={rmr.id}>{rmr.quality}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="d-flex">
                    <div className="col p-0">
                        <Form.Group as={Col}  className="">
                            <Form.Label>HORA INICIO</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={hora_inicial}
                                name="hora_inicial"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0">
                        <Form.Group as={Col}  className="">
                            <Form.Label>HORA FIN</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={hora_final}
                                name="hora_final"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <br></br><div className="text-center"><strong>EXPLOSIVOS Y ARTIFICIOS</strong></div><br></br>

              
                <div className="d-flex">
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>1x8 (PZAS)</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.o_per_eight} name="o_per_eight" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>T-1 (PZAS)</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.t_one}   name="t_one" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>1x39 (PZAS)</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.o_per_three_nine}   name="o_per_three_nine" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>MEXAMÓN (KG)</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.mexamon}  name="mexamon" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col p-0 m-1">

                        <Form.Group >
                            <Form.Label>NONEL 16 FT</Form.Label>
                            <Form.Control as="select" name="nonel_ft" defaultValue={props.data.nonel_ft}   required>
                            <option value="">Selec...</option>
                            <option value="MS">MS</option>
                            <option value="LP">LP</option>
                            
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                      
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#1</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_one}  name="one_one" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#2</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_two}  name="one_two" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#3</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_three} name="one_three" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#4</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_four}  name="one_four" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#5</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_five}  name="one_five" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#6</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_six}  name="one_six" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#7</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_seven}   name="one_seven" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="d-flex">

                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#8</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_eight}   name="one_eight" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#9</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_nine} name="one_nine" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#10</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_ten} name="one_ten" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#11</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_eleven} name="one_eleven" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                </div>
                <div className="d-flex">

                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#12</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_twelve} name="one_twelve" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#13</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_thirteen} name="one_thirteen" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#14</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_fourteen} name="one_fourteen" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0 m-1">
                        <Form.Group >
                            <Form.Label>#15</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.one_fifteen} name="one_fifteen" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>


                </div>
                <div className="d-flex">
                    <div className="col p-0">
                        <Form.Group as={Col} >
                            <Form.Label>CORDON DETONANTE</Form.Label>
                            <Form.Control type="text" name="cordon_deto" defaultValue={props.data.cordon_deto}  required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0">
                        <Form.Group as={Col} >
                            <Form.Label>IKON (LONG)</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.ikon_long}   name="ikon_long" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col p-0">
                        <Form.Group as={Col} >
                            <Form.Label>IKON (PZAS)</Form.Label>
                            <Form.Control type="number" step="any" defaultValue={props.data.ikon_pzas}  name="ikon_pzas" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0">
                        <Form.Group as={Col} >
                            <Form.Label>CAÑUELA (MTS)</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.canuela}   name="canuela" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="col p-0">
                        <Form.Group as={Col} >
                            <Form.Label>CONECTORES (PZAS)</Form.Label>
                            <Form.Control type="number" step="any" defaultValue={props.data.conectores}  name="conectores" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0">
                        <Form.Group as={Col} >
                            <Form.Label>FULMINANTES (PZAS)</Form.Label>
                            <Form.Control type="number" step="any" min="0" defaultValue={props.data.fulminante}  name="fulminante" required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <Form.Group as={Col} >
                    <Form.Label>CABLE DE VOLADURA (PZAS)</Form.Label>
                    <Form.Control type="number" step="any" name="cab_vol" defaultValue={props.data.cab_vol}  required />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>OBSERVACIONES</Form.Label>
                    <Form.Control as="textarea" defaultValue={props.data.observations} rows={3} name="observations" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>Estatus</Form.Label>
                    <Form.Control as="select" name="estatus" defaultValue={props.data.estatus !== undefined ? props.data.estatus.charAt(0).toUpperCase() + props.data.estatus.slice(1):null} required>
                        <option value ="">Seleccionar</option>
                        <option value ="Pendiente">Pendiente</option>
                        <option value ="Terminado">Terminado</option>
             
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Button type="submit">GUARDAR</Button>
                </Form.Group>


            </Form> 
        </div>
    )
}

VoladurasForm.defaultProps = {
    data:{
        id:null,
      
        hora_final: null,
        hota_inicial:null,
        generalform_id: null,
        holes_given: "",
        item: "",
        number_holes: null,
        obra: "",
        observation: "",
   
        project_id: null,
        rmr: "",
        seccion: "",
        sector: "",
        start_time: "",
        status: "",
        zona: ""
    
    } 
}

export default VoladurasDetalleView
