import {useContext, useState} from 'react'
import Cell from './Cell'
import { PencilSquare, CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { IconButton } from '@material-ui/core';
import { filteredPlanning, services } from '../../../services/api';
import { PlaneacionContext } from '../../../context/planeacion_context';

export default function SingleRow({row, dynamicColumns}){
    let {state,setState} = useContext(PlaneacionContext)
    let [editingRow,setEditingRow] = useState(false)
    let [newDays,setNewDays] = useState(row.days)

    const onEdit = () =>{
        setEditingRow(true)
    }

    const handleChange = (id,event)=>{
        let value = parseFloat(event.target.value)
        let name = event.target.name
        let newArr = [...newDays]
        newArr.map(day=>{
            if(day.id===id){
                day[name]=value
            }
        })
        setNewDays(newArr)
    }

    const onSave = async () =>{
        console.log({...row,days:newDays})
        let res = await services('PUT','planning_ml_m3',{...row,days:newDays},row.id)
        if(res.status){
            setEditingRow(false)
            getFilteredData()
        }
    }

    const onCancel = () =>{
        console.log(row.days,'main row')
        console.log(newDays,'newDays')
        getFilteredData()
        setEditingRow(false)
    }

    const getFilteredData = async () =>{
        setState({...state,loading:true})
        filteredPlanning(state.project_id,state.fechaStart,state.fechaEnd,'ml_m3').then(res=>{
            setState({...state,metrosF:res.data})
            setState({...state,loading:false})
        })
    }

    return(
        <>
            <div className='d-flex row justify-content-around py-2'>
                {dynamicColumns.map(column=>{
                    if(column.id==='obra'){
                        return(
                            <div className='border col'>
                                {row.obra}
                            </div>
                        )
                    }else if(column.id ==='editar'){
                        return(
                            <>
                                {
                                    editingRow
                                    ?
                                    <div className='col'>
                                        <CheckCircleFill size={20} color='green' onClick={()=>onSave()} className='cursor-pointer mx-2'></CheckCircleFill>
                                        <XCircleFill size={20} color='tomato' onClick={()=>onCancel()} className='cursor-pointer mx-2'></XCircleFill>
                                    </div>
                                    :
                                    <div className='col'>
                                        <IconButton onClick={()=>{onEdit(row)}}>
                                            <PencilSquare size={20} className='cursor-pointer' color='tomato'></PencilSquare>
                                        </IconButton>
                                    </div>
                                }
                            </>
                        )
                    }else{
                        let element = newDays.find(el => el.date === column.label)
                        if(element){
                            return(
                                <Cell cell={element} editing={editingRow} handleChange={handleChange}/>
                            )
                        }else{
                            return(
                                <div className='d-flex col justify-content-around border w-auto'>
                                    <div className='w-50'>
                                        N/A
                                    </div>
                                    <div className='w-50'>
                                        N/A
                                    </div>
                                </div>
                            )
                        }
                    }
                })}
            </div>
        </>
    )
}
