export default function Cell({cell, editing, handleChange}){
    console.log(cell)
    return(
        <div className={`d-flex col justify-content-between border w-auto`}>
            <div className={`w-auto`}>
                {editing ? <input type="number" name="ml" value={cell.ml} className='w-50 float-none' onChange={e=>handleChange(cell.id,e)}/> : <p className="w-100">{cell.ml}</p>}
            </div>
            <div className={`w-auto`}>
                {editing ? <input type="number" name='m3' value={cell.m3} className='w-50 float-none' onChange={e=>handleChange(cell.id,e)}/> : <p className="w-100">{cell.m3}</p>}
            </div>
        </div>
    )
}