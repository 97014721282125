import React,{useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { HistorialEdicionesContext } from '../../context/historial_edicion_context';

const columns = [
  { id:'id', label:'id', minWidth: 60 },
  { id:'catalog', label:'Catalogo', minWidth: 170 },
  { id:'project', label:'Proyecto', minWidth: 170 },
  { id:'input', label:'Campo', minWidth: 170 },
  { id:'data_old', label:'Anterior', minWidth:300 },
  { id:'data_new', label:'Nuevo', minWidth: 300 },
  { id:'reason', label:'Motivo', minWidth: 300 },
  { id:'responsable', label:'responsable', minWidth: 300 },
  { id:'created_at', label:'Fecha/Hora', minWidth: 300 },
 
  
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});

function HitorialEdicionesRecords(props) {


  const {state, setState, getMore} = useContext(HistorialEdicionesContext)


    // var data = state.anclas.filter((item)=>{
    //     if(state.search == ""){
    //         return true;
    //     }
    //     return item.name.includes(state.search);
    // })


  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

    let newPages = (Math.ceil(state.historial.length / event.target.value)) - 1;  
    setState({...state, pages:newPages})

  };



  useEffect(async ()=>{
 

    if(page > state.pages){
     
      let more = await getMore(rowsPerPage, page);   
      let auxHistorial = state.historial.concat(more.data);
      let auxHistorialF = state.historialFiltered.concat(more.data);     
      let newPages = (Math.ceil(auxHistorial.length / rowsPerPage)) - 1; 
      setState({...state, pages:newPages, historial:auxHistorial, historialFiltered:auxHistorialF})
      
    }
  },[page])

 

  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
                    paddingLeft:0,
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                   }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.historialFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
            let active = row.deleted_at === null ? 1 : 0;
            let switchActive = row.deleted_at === null ? true : false;
  
              if(state.filter === 10 || active === state.filter){               
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                          
                          paddingRight:10,
                          paddingLeft:5,
                          paddingTop:10,
                          paddingBottom:10,

                         }}>
                          {
                            column.id === "project" ? "N/A" : 
                            column.id === "responsable" ? row.responsable.name : value
                          }
                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination

        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.countF}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage=""
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default HitorialEdicionesRecords;