import React, { createContext, useState,useEffect } from "react"

import {getUser} from '../services/api' 
import { useAuth } from "../services/auth";


export const UserContext  = createContext();

//MAQUINARIA VIEW -> 2,3,4,5,6,7,8,9,10,11,12,13,14,15


const token = () =>{
    return window.localStorage.getItem('token');
}
const uid = () =>{
    return window.localStorage.getItem('uid');
}

export const UserProvider = ({children}) => {
    let auth = useAuth();

    const [user, setUser] = useState({})

    useEffect( async () =>{

        if(Object.entries(user).length === 0 && token() !== null && uid() !== null ){
            var response = await getUser();

            if(response.status){
               
                setUser(response.data.data) 
                
            }else{
                window.localStorage.clear();
                setUser({});
                auth.setMsg(true)
                auth.signout();
            }  
        }
       
    },[])

    return(
        <UserContext.Provider value={{
            user,
            setUser
        }}>
            {children}
        </UserContext.Provider>

    )
}
// const refreshUser = async() => {
   
//     const config = {
//         headers: { Authorization: `Bearer ${token}` }
//     };
//     var res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${uid}`,config)
//     return res;
// }





