
import React, { useContext, useEffect, useState } from 'react';
import {Journals, XCircleFill } from 'react-bootstrap-icons';

import CategoriasRecords from '../components/records/categorias_records'

import {showLoader, hideLoader, replaceObj, today} from '../utils/utils';


import { Form, Col, Button } from 'react-bootstrap';
import { CategoriasContext } from '../context/categorias_context';
import { UserContext } from '../context/user_context';
import { services} from '../services/api';
import ViewDataLoader from '../components/ui/view_data_loader';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';
let editing = false;
let editingId;

function CategoriasView(){
   
      const [validated, setValidated] = useState(false);
      const [title, setTitle] = useState(true);
      const {state,setState} = useContext(CategoriasContext)
      const {user} = useContext(UserContext)
      const {alert, showAlert, closeAlert} = useContext(AlertContext)
      
    
    
      

      const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
     

        if (form.checkValidity() === true) {

            var resCat;
            showLoader();
            if(!editing){
                resCat = await services("POST","categories",body);
            }else{
                resCat = await services("PUT","categories",body,editingId);
            }

            if(resCat.status){

                if(!editing){
                    var category = resCat.data;
                    category.deleted_at = null;
                    var categories = state.categories;
                    var categoriesF = state.categoriesF;
                    categories.unshift(category);
                    categoriesF.unshift(categoriesF);


                    setState({...state,categories:categories});
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")
                }else{
                    var categories = replaceObj(state.categories,resCat.data);
                    var categoriesF = replaceObj(state.categoriesF,resCat.data);
                    setState({...state,categories:categories, categoriesF});
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }
                hideLoader();
                hideForm();
            }
            
        }else{
           
            setValidated(true);
        }
      };

      const showForm = (data=undefined) =>{
        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');

        let f = document.getElementById("main_form");
        
        if(data !== undefined){
            reason_edition.classList.remove('d-none');
            setTitle(false);
            fillForm(data)
        }
        f.classList.add("d-block")
        f.classList.remove("d-none");
      }
      const hideForm = () =>{
        let f = document.getElementById("main_form");
        clearForm();
        f.classList.add("d-none");
        f.classList.remove("d-block");
      }
      const onChangeFilter = (evt) =>{
        setState({...state, filter:parseInt(evt.currentTarget.value)})
      }
      const onSearchChange = (val) => {
        var search = val.currentTarget.value
        setState({...state, search});
      }
      const fillForm = (data) => {
        let { elements } = document.getElementById('categories_form');
        elements.namedItem("reason_edition").disabled = false;
        for (const [ key, value ] of Object.entries(data) ) {
          const field = elements.namedItem(key)
          if(key !== "reason_edition"){
            field && (field.value = value)
            }
          
        }
        editing = true;
        editingId = data.id
      }
      const clearForm = () => {
        let form = document.getElementById('categories_form');
        let {elements} = form
        elements.namedItem("reason_edition").disabled = true;
        editing = false;
        editingId = undefined;
        form.reset();
        setTitle(true)
        setValidated(false);
      }
      

    if(state.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        
        <main role="main" className="">
               
            <div className="container-fluid position-relative min-h py-1 ">
            <h2><Journals size="25" color="#0a5cbe"></Journals> Catalogo: CATEGORIAS</h2>  
            <div className="d-flex">
                <div className="col-2 py-2 px-0">
                    
                    <Form.Label>Estatus:</Form.Label>
                    <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                        <option value ="10">Todos</option>
                        <option value ="1">Activo</option>
                        <option value ="0">Desactivado</option>
                    </Form.Control>
                </div>
                <div className="col-3 py-2 px-0 mx-2">
                    
                    <Form.Label>BUSCAR:</Form.Label>
                    <Form.Control  type="text" name="buscar" onChange={onSearchChange} />
                </div>
                <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                    <Button className="primary px-4" onClick={()=>{showForm()}} >
                        Nuevo
                    </Button>
                </div>
            </div>
            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
            <CategoriasRecords  onEdit={(data)=>{
             
               
                showForm(data);
            }}></CategoriasRecords>
            <div className="catalogo_form_modal h-100 d-none py-3" id="main_form">
                <Form noValidate validated={validated} onSubmit={handleSubmit} id="categories_form">
                    <div className="container w-50  position-relative bg-white rounded pt-4">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="col">
                                <h5>{title === true ? "NUEVA" : "EDITAR"} CATEGORIA</h5>
                            </div>
                            <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                <div className="form-control disabled">{today()}</div>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>RESPONSABLE</Form.Label>
                                <div className="form-control disabled">{user.name}</div>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>CATEGORIA</Form.Label>
                                <Form.Control type="text" name="name"  required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>DESCRIPCIÓN</Form.Label>
                                <Form.Control as="textarea" rows={3} name="description" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>DEPARTAMENTO</Form.Label>
                                <Form.Control as="select"  name="department_id" required>
                                    <option value ="">Seleccionar</option>
                                    {state.departments.map((dep,i)=>{
                                        return (<option key={i} value ={dep.id}>{dep.name}</option>)
                                    })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div id="reason_edition">
                                <Form.Group as={Col}>
                                    <Form.Label>Motivo de edicion</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="reason_edition" disabled required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <Form.Group as={Col}>
                                <Button type="submit">GUARDAR</Button>
                            </Form.Group>

                            </div>
                            
                        </div>
                        <div className="cta position-absolute top right p-2" onClick={hideForm}>
                            <XCircleFill size="45"></XCircleFill>
                        </div>
                    </div>
                </Form>
            </div>
        
            </div>

           
        </main>
    )}

}


export default CategoriasView;