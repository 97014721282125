import React, { createContext, useState,useEffect, useContext } from "react"
import {dependentService, recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId, todayC } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const BarrenacionJumboContext = createContext();
export const BarrenacionJumboProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateBarrenacionJumbo, setStateBarrenacionJumbo] = useState({
      
        barrenacion:[],
        loading:false,
      
       
    });

    useEffect(async () =>{

        setStateBarrenacionJumbo({...stateBarrenacionJumbo, loading:true});
         
            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
              
                var barrenacion = await recordsServiceId("GET","template_drillings", id);
          
              
                if(barrenacion.status){ 

                    setStateBarrenacionJumbo({
  
                        barrenacion:barrenacion.data.filter((el)=>el.deleted_at == null),
                        loading:false
                  
                   
                    });
                }else{
                    if(barrenacion.code === 401 || barrenacion.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])
    
    return(
        <BarrenacionJumboContext.Provider value={{
            stateBarrenacionJumbo,
            setStateBarrenacionJumbo
        }}>
            {children}
        </BarrenacionJumboContext.Provider>
    )
}











