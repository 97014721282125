import React, {useContext,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Tabs,Tab, Form} from 'react-bootstrap'


import {services} from '../../services/api' 
import {selectFromObjbyId} from '../../utils/utils';

import { ChevronCompactLeft, PencilSquare, Trash } from 'react-bootstrap-icons';
import { IconButton } from '@material-ui/core';
import Switchy from '../triggers/switchy';
import { PresupuestoObrasContext } from '../../context/presupuesto_obras_context';
import { Col,Button } from 'react-bootstrap';
import { AlertContext } from '../../context/alert_context';
import { PresupuestoSoporteObrasContext } from '../../context/presupuesto_soporte_obras_context';


function PresupuestoSoporteObrasRecords(props) {


//   const [key, setKey] = React.useState('Presupuestos')
//   const {statePresupuestoSoporte,setStatePresupuestoSoporte} = useContext(PresupuestoSoporteObrasContext)

//   return (
//     <Tabs
//         id="controlled-tab-example"
//         activeKey={key}
//         onSelect={(k) => setKey(k)}
//         variant="pills"
//     >
//         <Tab eventKey="Presupuestos"  title="Presupuestos">
//             <div className="container-fluid px-0 py-3">
//                 <PresupuestoObras 
//                     //data={statePresupuesto.presupuestos} 
//                     onEdit={(data, val)=>{
//                         props.onEdit(data, val)
//                     }} 
//                     onAdd={(val)=>{
//                         props.onAdd(val)
//                     }}>
//                 </PresupuestoObras>
//             </div>
//         </Tab>
//         <Tab eventKey="Historial" title="Historial">
//             <div className="container-fluid px-0 py-3">
//                 <PresupuestoHistorial 
//                     //data={statePresupuesto.tipos_barrenacion} 
//                     onEdit={(data, val)=>{
//                         props.onEdit(data, val)
//                     }}
//                     onAdd={(val)=>{
//                         props.onAdd(val)
//                     }}>
//                 </PresupuestoHistorial>
//             </div>
//         </Tab>
//     </Tabs>
  
//   );
const {statePresupuestoSoporte, setStatePresupuestoSoporte} = useContext(PresupuestoSoporteObrasContext)
const {alert, showAlert, closeAlert} = useContext(AlertContext)

const columns = [
    // { id:'id', label:'No. Folio', minWidth: 100},
    { id:'month', label:'MES', minWidth: 100 },
    { id:'year', label:'AÑO', minWidth: 100 },
    { id:'item', label:'ITEM', minWidth: 100 },
    { id:'obra', label:'OBRA', minWidth: 130 },
    { id:'rmr', label:'RMR', minWidth: 100 },
    { id:'zona', label:'ZONA', minWidth: 100 },

    { id:'esp_ancla_u', label:'ESP: ANCLAS 1', minWidth: 100 },
    { id:'esp_ancla_d', label:'ESP: ANCLAS 2', minWidth: 100 },
    { id:'esp_ancla_t', label:'ESP: ANCLAS 3', minWidth: 100 },
    { id:'esp_ancla_f', label:'ESP: ANCLAS 4', minWidth: 100 },
    { id:'esp_ancla_fi', label:'ESP: ANCLAS 5', minWidth: 100 },

    { id:'cable', label:'CABLE', minWidth: 100 },
    { id:'marcos', label:'MARCOS', minWidth: 100 },
    { id:'mallas', label:'MALLAS', minWidth: 100 },
    { id:'zarpeo', label:'ZARPEO', minWidth: 100 },
    { id:'factor_zarpeo', label:'FACTOR ZARPEO', minWidth: 100 },




  
 

 
    {id:'editar', label:"Editar", width: 100},
    {id:'activar', label:"Activar", width: 100}
  
  ];
  

const useStyles = makeStyles({
root: {
    width: '100%',
},

});

const classes = useStyles();
const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(10);

useEffect(() => {
    
    var data = statePresupuestoSoporte.presupuestos.filter((item)=>{
      var active = item.deleted_at === null ? 1 : 0;

      if(statePresupuestoSoporte.filter_plantilla === 10){
        return true;
      } 
      return active === statePresupuestoSoporte.filter_plantilla;
     
    })
   
    setPage(0);
    setStatePresupuestoSoporte({...statePresupuestoSoporte, presupuestosF:data});
  }, [statePresupuestoSoporte.filter_plantilla])



const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(+event.target.value);
  setPage(0);
};

const onChangeFilter = (evt) =>{  
    setStatePresupuestoSoporte({...statePresupuestoSoporte, filter_plantilla:parseInt(evt.currentTarget.value)})
}

const handleSwitch = async (data,id, val)=>{
    var res;
    if(!val){
      res = await services("DELETE","budgets",undefined,id);
    }else{
      res = await services("GET","budgets",undefined,id);
    }

 
    if(res.status){
      var presupuestos = data
      var presupuesto = selectFromObjbyId(data, id);
      presupuesto.deleted_at = res.data.deleted_at;
      setStatePresupuestoSoporte({...statePresupuestoSoporte, presupuestos })
    }  
}

const handleFilter2 = (event) =>{  
 
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    var formData = new FormData(form)

    var body = {};
    formData.forEach((value, key) => body[key] = value);



    var desde = new Date(Date.parse(body.desde))
    var hasta = new Date(Date.parse(body.hasta))
    //hasta.setDate(hasta.getDate() + 1);

    if(desde < hasta){
        closeAlert();
        var data = statePresupuestoSoporte.presupuestos.filter((item)=>{
            return item.year >= desde.getFullYear() && item.year <= hasta.getFullYear() && item.month >= (desde.getMonth() + 1) && item.month <= (hasta.getMonth()+1)
            //return new Date(Date.parse(item.created_at)) >= desde &&  new Date(Date.parse(item.created_at)) <= hasta;
        })
        setStatePresupuestoSoporte({...statePresupuestoSoporte, presupuestosF:data});
        
                    
    }else{
        showAlert("error", "¡Error! Fecha: Desde no puede ser mayor a Fecha: Hasta")
       
    }


  
    
    
  
}

return(
    <>
    <div className="d-flex align-items-center py-2">
        <div className="col-2 p-0">
            <Form.Label>ESTATUS:</Form.Label>
            <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                <option value ="10">Todos</option>
                <option value ="1">Activo</option>
                <option value ="0">Desactivado</option>
            </Form.Control>
        </div>
        <div className="col-8 pl-2">
        <Form  onSubmit={handleFilter2} id="">
            <div className="d-flex align-items-center py-2">
                <div className="col-3 p-0 m-0">
                
                        <Form.Label>DESDE</Form.Label>
                        <Form.Control
                            required
                            type="date"
                            name="desde"
                            value={statePresupuestoSoporte.desdeP}
                            onChange={(val)=>{
                                
                                setStatePresupuestoSoporte({...statePresupuestoSoporte, desdeP:val.currentTarget.value})
                                
                            }}
                        />
            
                </div>
                <div className="col-3 p-0 ml-2">
                
                        <Form.Label>HASTA</Form.Label>
                        <Form.Control
                            
                            required
                            type="date"
                            name="hasta"
                            value={statePresupuestoSoporte.hastaP}
                            onChange={(val)=>{
                                
                                setStatePresupuestoSoporte({...statePresupuestoSoporte, hastaP:val.currentTarget.value})
                                
                            }}
                            
                        />
                    
                
                </div>
                <Button type="submit" className="align-self-end ml-2">BUSCAR</Button>
                <IconButton size="small" className="align-self-end" onClick={()=>{
                        setStatePresupuestoSoporte({...statePresupuestoSoporte, hastaP:"", desdeP:"", presupuestosF:statePresupuestoSoporte.presupuestos})
                    }}>
                    <Trash size={26} className="cursor-pointer" color="tomato"/>
                </IconButton>
            </div>
        </Form>
        </div>  
        <div className="col"></div>
        
        {/* <div className="col-1 h-100"> */}
            <div className="btn btn-primary align-self-end my-2" onClick={()=>{
                props.onAdd(true);
            }}>NUEVO</div>
        {/* </div> */}
    </div>
    <Paper className={classes.root}>
    <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
            {columns.map((column) => (
                <TableCell
                key={column.id}
                align={column.align}
                style={{ 
                    minWidth: column.minWidth,
                    paddingLeft:0,
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                }}
                >
                {column.label}
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
        <TableBody>
            {statePresupuestoSoporte.presupuestosF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
                   let switchActive = row.deleted_at === null ? true : false;
                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                    var value = row[column.id];
                    return (
                        <TableCell key={column.id} align={column.align} style={{ 
                            paddingRight:10,
                            paddingLeft:5,
                            paddingTop:5,
                            paddingBottom:5,
                    
                            }}>
                        {
                            

                     

                        

                            column.id == "esp_ancla_u" ?   
                            selectFromObjbyId(statePresupuestoSoporte.anclas,parseInt(row.esp_ancla_u)).name  
                            :

                            column.id == "esp_ancla_d" ?   
                            selectFromObjbyId(statePresupuestoSoporte.anclas,parseInt(row.esp_ancla_d)).name  
                            :

                            column.id == "esp_ancla_t" ?   
                            selectFromObjbyId(statePresupuestoSoporte.anclas,parseInt(row.esp_ancla_t)).name  
                            :

                            column.id == "esp_ancla_f" ?   
                            selectFromObjbyId(statePresupuestoSoporte.anclas,parseInt(row.esp_ancla_f)).name  
                            :

                            column.id == "esp_ancla_fi" ?   
                            selectFromObjbyId(statePresupuestoSoporte.anclas,parseInt(row.esp_ancla_fi)).name  
                            :


                           
                            column.id === "editar" ? 
                            <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row, true)}}>
                            <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                            </IconButton>
                            : column.id === "activar" ?  

                            <Switchy value={switchActive} onChange={(val)=>{
                            handleSwitch(statePresupuestoSoporte.presupuestos, row.id, val)
                            }} /> 
                            : value
                        }
                        </TableCell>
                    );
                    })}
                </TableRow>
                );
            
            })}
        </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={statePresupuestoSoporte.presupuestosF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
    />
    </Paper>
    </>
);
}

// function PresupuestoHistorial(props){

//     const {alert, showAlert, closeAlert} = useContext(AlertContext)
   
   
//     const columns = [
//         { id:'id', label:'No. Folio', minWidth: 100},
//         { id:'month', label:'Mes', minWidth: 100 },
//         { id:'year', label:'Año', minWidth: 100 },
//         { id:'item', label:'Item', minWidth: 100 },
//         { id:'obra', label:'Obra', minWidth: 130 },
//         { id:'rmr', label:'No. RMR', minWidth: 100 },
//         { id:'zone', label:'Zona', minWidth: 100 },
//         { id:'develop', label:'Desarrollo (ml)', minWidth: 150 },
//         { id:'tumbe', label:'Tumbe (m3)', minWidth: 120 },
//         { id:'number_concept', label:'No. Concepto', minWidth: 150 },
//         { id:'concepto', label:'Concepto', minWidth: 170 },

      
//       ];
//       const useStyles = makeStyles({
//         root: {
//           width: '100%',
//         },
//       });
//     const classes = useStyles();
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);
//     const {statePresupuestoSoporte, setStatePresupuestoSoporte} = useContext(PresupuestoSoporteObrasContext)


//     const handleChangePage = (event, newPage) => {
//       setPage(newPage);
//     };
  
//     const handleChangeRowsPerPage = (event) => {
//       setRowsPerPage(+event.target.value);
//       setPage(0);
//     };

//     const handleFilter = (event) =>{  
     
//         event.preventDefault();
//         event.stopPropagation();
//         const form = event.currentTarget;
//         var formData = new FormData(form)

//         var body = {};
//         formData.forEach((value, key) => body[key] = value);
//         

   
//         var desde = new Date(Date.parse(body.desde))
//         var hasta = new Date(Date.parse(body.hasta))

//         if(desde < hasta){
//             closeAlert();
//             var data = statePresupuestoSoporte.historial.filter((item)=>{
           
//                 return item.year >= desde.getFullYear() && item.year <= hasta.getFullYear() && item.month >= (desde.getMonth() + 1) && item.month <= (hasta.getMonth()+1)
//                 //return new Date(Date.parse(item.created_at)) >= desde &&  new Date(Date.parse(item.created_at)) <= hasta;
//             })
//             setStatePresupuestoSoporte({...statePresupuestoSoporte, historialF:data});
                        
//         }else{
//             showAlert("error", "¡Error! Fecha: Desde no puede ser mayor a Fecha: Hasta")
           
//         }
        
        
      
//     }
//     const onChangeFilterMes = (evt) =>{  
//         setStatePresupuestoSoporte({...statePresupuestoSoporte, filter_mes:evt.currentTarget.value})
//     }

//     return(
//         <>
//         {/* noValidate validated={validated} */}
//         <Form  onSubmit={handleFilter} id="">
//         <div className="d-flex align-items-center py-2">
//             <div className="col-2 p-0">
            
//                     <Form.Label>DESDE</Form.Label>
//                     <Form.Control
//                         required
//                         type="date"
//                         name="desde"
//                         value={statePresupuestoSoporte.desde}
//                         onChange={(val)=>{
                            
//                             setStatePresupuestoSoporte({...statePresupuestoSoporte, desde:val.currentTarget.value})
                            
//                         }}
//                     />
        
//             </div>
//             <div className="col-2 p-0 ml-2">
            
//                     <Form.Label>HASTA</Form.Label>
//                     <Form.Control
                        
//                         required
//                         type="date"
//                         name="hasta"
//                         value={statePresupuestoSoporte.hasta}
//                         onChange={(val)=>{
                            
//                             setStatePresupuestoSoporte({...statePresupuestoSoporte, hasta:val.currentTarget.value})
                            
//                         }}
                        
//                     />
                
            
//             </div>
//             <Button type="submit" className="align-self-end ml-2">BUSCAR</Button>
//             <IconButton size="small" className="align-self-end" onClick={()=>{
//                     setStatePresupuestoSoporte({...statePresupuestoSoporte, hasta:"", desde:"", historialF:statePresupuestoSoporte.historial})
//                 }}>
//                 <Trash size={26} className="cursor-pointer" color="tomato"/>
//             </IconButton>
//         </div>
//         </Form>
//         <Paper className={classes.root}>
//         <TableContainer  className={classes.container}>
//             <Table  stickyHeader aria-label="sticky table">
//             <TableHead>
//                 <TableRow>
//                 {columns.map((column) => (
//                     <TableCell
//                     key={column.id}
//                     align={column.align}
//                     style={{ 
//                         minWidth: column.minWidth,
//                         paddingLeft:0,
//                         paddingBottom:10,
//                         paddingTop:10,
//                         paddingLeft:5,
//                         paddingRight:10,
//                         lineHeight:1.2
//                     }}
//                     >
//                     {column.label}
//                     </TableCell>
//                 ))}
//                 </TableRow>
//             </TableHead>
//             <TableBody>
//                 {statePresupuestoSoporte.historialF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

//                     let switchActive = row.deleted_at === null ? true : false;
//                     return (
//                     <TableRow hover role="checkbox" tabIndex={-1} key={i}>

//                         {columns.map((column) => {
//                         var value = row[column.id];
//                         return (
//                             <TableCell key={column.id} align={column.align} style={{ 
//                                 paddingRight:10,
//                                 paddingLeft:5,
//                                 paddingTop:15,
//                                 paddingBottom:15,
                        
//                                 }}>
//                             {
//                             //    column.id === "editar" ? 
//                             //    <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row, false)}}>
//                             //      <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
//                             //    </IconButton>
//                             //    : 
//                             //    column.id === "activar" ?  
   
//                             //    <Switchy value={switchActive} onChange={(val)=>{
//                             //      handleSwitch(statePresupuesto.historial, row.id, val)
//                             //    }} /> 
//                             //    : 
//                             column.id == "item" ?     
//                             row.work.item :

//                             column.id == "obra" ?     
//                             row.work.name :

//                             column.id == "rmr" ?     
//                             row.rock_indice.quality :

//                             column.id == "zone" ?     
//                             row.zone.name :

//                             column.id == "concepto" ?     
//                             row.type_drilling.name :
//                                value
//                             }
//                             </TableCell>
//                         );
//                         })}
//                     </TableRow>
//                     );
                
//                 })}
//             </TableBody>
//             </Table>
//         </TableContainer>
//         <TablePagination
//             animation="false"
//             rowsPerPageOptions={[10, 25, 100]}
//             component="div"
//             count={statePresupuestoSoporte.historialF.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onChangePage={handleChangePage}
//             onChangeRowsPerPage={handleChangeRowsPerPage}
//             //labelRowsPerPage={"Ver"}
//             //abelDisplayedRows={"a"}
            
//         />
//         </Paper>
//         </>
//     );
// }


// function PresupuestoObras(props){
//     const {statePresupuestoSoporte, setStatePresupuestoSoporte} = useContext(PresupuestoSoporteObrasContext)
//     const {alert, showAlert, closeAlert} = useContext(AlertContext)

//     const columns = [
//         { id:'id', label:'No. Folio', minWidth: 100},
//         { id:'month', label:'Mes', minWidth: 100 },
//         { id:'year', label:'Año', minWidth: 100 },
//         { id:'item', label:'Item', minWidth: 100 },
//         { id:'obra', label:'Obra', minWidth: 130 },
//         { id:'rmr', label:'No. RMR', minWidth: 100 },
//         { id:'zone', label:'Zona', minWidth: 100 },
//         { id:'develop', label:'Desarrollo (ml)', minWidth: 150 },
//         { id:'tumbe', label:'Tumbe (m3)', minWidth: 120 },
//         { id:'number_concept', label:'No. Concepto', minWidth: 150 },
//         { id:'concepto', label:'Concepto', minWidth: 170 },
//         {id:'editar', label:"Editar", width: 100},
//         {id:'activar', label:"Activar", width: 100}
      
//       ];
      
    
//     const useStyles = makeStyles({
//     root: {
//         width: '100%',
//     },

//     });

//     const classes = useStyles();
//     const [page, setPage] = React.useState(0);
//     const [rowsPerPage, setRowsPerPage] = React.useState(10);

//     useEffect(() => {
        
//         var data = statePresupuestoSoporte.presupuestos.filter((item)=>{
//           var active = item.deleted_at === null ? 1 : 0;
    
//           if(statePresupuestoSoporte.filter_plantilla === 10){
//             return true;
//           } 
//           return active === statePresupuestoSoporte.filter_plantilla;
         
//         })
       
//         setPage(0);
//         setStatePresupuestoSoporte({...statePresupuestoSoporte, presupuestosF:data});
//       }, [statePresupuestoSoporte.filter_plantilla])
  

  
//     const handleChangePage = (event, newPage) => {
//       setPage(newPage);
//     };
  
//     const handleChangeRowsPerPage = (event) => {
//       setRowsPerPage(+event.target.value);
//       setPage(0);
//     };

//     const onChangeFilter = (evt) =>{  
//         setStatePresupuestoSoporte({...statePresupuestoSoporte, filter_plantilla:parseInt(evt.currentTarget.value)})
//     }

//     const handleSwitch = async (data,id, val)=>{
//         var res;
//         if(!val){
//           res = await services("DELETE","budgets",undefined,id);
//         }else{
//           res = await services("GET","budgets",undefined,id);
//         }


//         if(res.status){
//           var presupuestos = data
//           var presupuesto = selectFromObjbyId(data, id);
//           presupuesto.deleted_at = res.data.deleted_at;
//           setStatePresupuestoSoporte({...statePresupuestoSoporte, presupuestos })
//         }  
//     }

//     const handleFilter2 = (event) =>{  
     
//         event.preventDefault();
//         event.stopPropagation();
//         const form = event.currentTarget;
//         var formData = new FormData(form)

//         var body = {};
//         formData.forEach((value, key) => body[key] = value);
//         

   
//         var desde = new Date(Date.parse(body.desde))
//         var hasta = new Date(Date.parse(body.hasta))
//         //hasta.setDate(hasta.getDate() + 1);

//         if(desde < hasta){
//             closeAlert();
//             var data = statePresupuestoSoporte.presupuestos.filter((item)=>{
//                 return item.year >= desde.getFullYear() && item.year <= hasta.getFullYear() && item.month >= (desde.getMonth() + 1) && item.month <= (hasta.getMonth()+1)
//                 //return new Date(Date.parse(item.created_at)) >= desde &&  new Date(Date.parse(item.created_at)) <= hasta;
//             })
//             setStatePresupuestoSoporte({...statePresupuestoSoporte, presupuestosF:data});
            
                        
//         }else{
//             showAlert("error", "¡Error! Fecha: Desde no puede ser mayor a Fecha: Hasta")
           
//         }


      
        
        
      
//     }

//     return(
//         <>
//         <div className="d-flex align-items-center py-2">
//             <div className="col-2 p-0">
//                 <Form.Label>ESTATUS:</Form.Label>
//                 <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
//                     <option value ="10">Todos</option>
//                     <option value ="1">Activo</option>
//                     <option value ="0">Desactivado</option>
//                 </Form.Control>
//             </div>
//             <div className="col-8 pl-2">
//             <Form  onSubmit={handleFilter2} id="">
//                 <div className="d-flex align-items-center py-2">
//                     <div className="col-3 p-0 m-0">
                    
//                             <Form.Label>DESDE</Form.Label>
//                             <Form.Control
//                                 required
//                                 type="date"
//                                 name="desde"
//                                 value={statePresupuestoSoporte.desdeP}
//                                 onChange={(val)=>{
                                    
//                                     setStatePresupuestoSoporte({...statePresupuestoSoporte, desdeP:val.currentTarget.value})
                                    
//                                 }}
//                             />
                
//                     </div>
//                     <div className="col-3 p-0 ml-2">
                    
//                             <Form.Label>HASTA</Form.Label>
//                             <Form.Control
                                
//                                 required
//                                 type="date"
//                                 name="hasta"
//                                 value={statePresupuestoSoporte.hastaP}
//                                 onChange={(val)=>{
                                    
//                                     setStatePresupuestoSoporte({...statePresupuestoSoporte, hastaP:val.currentTarget.value})
                                    
//                                 }}
                                
//                             />
                        
                    
//                     </div>
//                     <Button type="submit" className="align-self-end ml-2">BUSCAR</Button>
//                     <IconButton size="small" className="align-self-end" onClick={()=>{
//                             setStatePresupuestoSoporte({...statePresupuestoSoporte, hastaP:"", desdeP:"", presupuestosF:statePresupuestoSoporte.presupuestos})
//                         }}>
//                         <Trash size={26} className="cursor-pointer" color="tomato"/>
//                     </IconButton>
//                 </div>
//             </Form>
//             </div>  
//             <div className="col"></div>
            
//             {/* <div className="col-1 h-100"> */}
//                 <div className="btn btn-primary align-self-end my-2" onClick={()=>{
//                     props.onAdd(true);
//                 }}>NUEVO</div>
//             {/* </div> */}
//         </div>
//         <Paper className={classes.root}>
//         <TableContainer  className={classes.container}>
//             <Table  stickyHeader aria-label="sticky table">
//             <TableHead>
//                 <TableRow>
//                 {columns.map((column) => (
//                     <TableCell
//                     key={column.id}
//                     align={column.align}
//                     style={{ 
//                         minWidth: column.minWidth,
//                         paddingLeft:0,
//                         paddingBottom:10,
//                         paddingTop:10,
//                         paddingLeft:5,
//                         paddingRight:10,
//                         lineHeight:1.2
//                     }}
//                     >
//                     {column.label}
//                     </TableCell>
//                 ))}
//                 </TableRow>
//             </TableHead>
//             <TableBody>
//                 {statePresupuestoSoporte.presupuestosF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
//                        let switchActive = row.deleted_at === null ? true : false;
//                     return (
//                     <TableRow hover role="checkbox" tabIndex={-1} key={i}>

//                         {columns.map((column) => {
//                         var value = row[column.id];
//                         return (
//                             <TableCell key={column.id} align={column.align} style={{ 
//                                 paddingRight:10,
//                                 paddingLeft:5,
//                                 paddingTop:5,
//                                 paddingBottom:5,
                        
//                                 }}>
//                             {
//                                 column.id == "item" ?     
//                                 row.work.item :

//                                 column.id == "obra" ?     
//                                 row.work.name :

//                                 column.id == "rmr" ?     
//                                 row.rock_indice.quality :

//                                 column.id == "zone" ?     
//                                 row.zone.name :

//                                 column.id == "concepto" ?     
//                                 row.type_drilling.name :

//                                 column.id === "editar" ? 
//                                 <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row, true)}}>
//                                 <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
//                                 </IconButton>
//                                 : column.id === "activar" ?  

//                                 <Switchy value={switchActive} onChange={(val)=>{
//                                 handleSwitch(statePresupuestoSoporte.presupuestos, row.id, val)
//                                 }} /> 
//                                 : value
//                             }
//                             </TableCell>
//                         );
//                         })}
//                     </TableRow>
//                     );
                
//                 })}
//             </TableBody>
//             </Table>
//         </TableContainer>
//         <TablePagination
//             animation="false"
//             rowsPerPageOptions={[10, 25, 100]}
//             component="div"
//             count={statePresupuestoSoporte.presupuestosF.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onChangePage={handleChangePage}
//             onChangeRowsPerPage={handleChangeRowsPerPage}
//             //labelRowsPerPage={"Ver"}
//             //abelDisplayedRows={"a"}
            
//         />
//         </Paper>
//         </>
//     );
// }

export default PresupuestoSoporteObrasRecords;