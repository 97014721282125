
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  
} from "react-router-dom";
import { useAuth, ProvideAuth } from "./services/auth";

import LoginView from './views/login_view';
import HomeView from './views/home_view';
import {UserProvider} from  './context/user_context';
import { AlertContextProvider } from './context/alert_context';


function App() {
  return (
    <ProvideAuth>
      <Router>
        <UserProvider>
          <Switch>
            <Route exact path="/">
              <LoginView/>
            </Route>
            <PrivateRoute path="/home">
                <AlertContextProvider>
                  <HomeView />
                </AlertContextProvider>
              
            </PrivateRoute>
          </Switch>
        </UserProvider>
      </Router>
    </ProvideAuth>
  );
}


function PrivateRoute({ children, ...rest }) {
  var token = window.localStorage.getItem('token');
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.login || token != undefined ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


export default App;
