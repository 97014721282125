
import React, { useState, useContext } from 'react';
import {Journals, XCircleFill } from 'react-bootstrap-icons';

import UbicacionesRecords from '../components/records/ubicaciones_records'
import { UbicacionesContext } from '../context/ubicaciones_context';
import { UserContext } from '../context/user_context';
import ViewDataLoader from '../components/ui/view_data_loader';

import {showLoader, hideLoader, today,replaceObj} from '../utils/utils';
import {services} from '../services/api';


import { Form, Col, Button } from 'react-bootstrap';
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
let editing = false;
let editingId;
function UbicacionesView(){
   
    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState(true);
    const {state,setState} = useContext(UbicacionesContext)
    const {user} = useContext(UserContext)
    const {alert, showAlert, closeAlert} = useContext(AlertContext)

  

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
       
        

        if (form.checkValidity() === true) {

            var resUbi;
            showLoader();
            if(!editing){
                resUbi = await services("POST","locations",body);
            }else{
                resUbi = await services("PUT","locations",body, editingId);
        
            }

            if(resUbi.status){

                if(!editing){
                    var ubicacion = resUbi.data;
                    ubicacion.deleted_at = null
                    var ubicaciones = state.ubicaciones;
                    var ubicacionesF = state.ubicacionesF;


                    ubicaciones.unshift(ubicacion);
                    ubicacionesF.unshift(ubicacion);


                    setState({...state,ubicaciones, ubicacionesF});
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")
                }else{
                    var ubicaciones = replaceObj(state.ubicaciones,resUbi.data);
                    var ubicacionesF = replaceObj(state.ubicacionesF,resUbi.data);
                    
                    setState({...state,ubicaciones,ubicacionesF});
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }
                hideLoader();
                hideForm();
                clearForm();
            }
            
        }else{
            
            setValidated(true);
        }
    };

    const showForm = (data=undefined) =>{
        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');

        let f = document.getElementById("main_form");
        
        if(data !== undefined){
            reason_edition.classList.remove('d-none');
            setTitle(false);
            fillForm(data)
        }
        f.classList.add("d-block")
        f.classList.remove("d-none");
      }
    const hideForm = () =>{
        let f = document.getElementById("main_form");
        clearForm();
        f.classList.add("d-none");
        f.classList.remove("d-block");
    }
    
    const onChangeFilter = (evt) =>{
        setState({...state, filter:parseInt(evt.currentTarget.value)})
    }
    const onSearchChange = (val) => {
        var search = val.currentTarget.value
        setState({...state, search});
    }
    const fillForm = (data) => {
       
        let { elements } = document.getElementById('ubicaciones_form');;
        elements.namedItem("reason_edition").disabled = false;
        for (const [ key, value ] of Object.entries(data) ) {
          const field = elements.namedItem(key)

          if(key !== "reason_edition"){
            field && (field.value = value)
            }
         
        }
        editing = true;
        editingId = data.id
      }
    const clearForm = () => {
        let form = document.getElementById('ubicaciones_form');
        let {elements} = form
        elements.namedItem("reason_edition").disabled = true;
        editing = false;
        editingId = undefined;
        form.reset();
        setTitle(true);
        setValidated(false);
    }


    if(state.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main" className="">
              
            <div className="container-fluid position-relative min-h py-1 ">
            <h2><Journals size="25" color="#0a5cbe"></Journals> Catalogo: UBICACIONES</h2>  
            <div className="d-flex">
                <div className="col-2 py-2 px-0">
                    
                    <Form.Label>Estatus:</Form.Label>
                    <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                        <option value ="10">Todos</option>
                        <option value ="1">Activo</option>
                        <option value ="0">Desactivado</option>
                    </Form.Control>
                </div>
                <div className="col-3 py-2 px-0 mx-2">
                    
                    <Form.Label>BUSCAR:</Form.Label>
                    <Form.Control  type="text" name="buscar" onChange={onSearchChange} />
                </div>
                <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                    <Button className="primary px-4" onClick={()=>{showForm()}} >
                        Nuevo
                    </Button>
                </div>
            </div>

            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

            <UbicacionesRecords onEdit={(data)=>{
    
                showForm(data);
            }}></UbicacionesRecords>

            <div className="catalogo_form_modal h-100 d-none py-3" id="main_form">
                <Form noValidate validated={validated} onSubmit={handleSubmit} id="ubicaciones_form">
                    <div className="container w-50 position-relative bg-white rounded pt-4">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="col">
                                <h5>{title === true ? "NUEVA" : "EDITAR"} UBICACIÓN</h5>
                            </div>
                            <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                <div className="form-control disabled">{today()}</div>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>RESPONSABLE</Form.Label>
                                <div className="form-control disabled">{user.name}</div>
                            </Form.Group>


                            <Form.Group as={Col}>
                                <Form.Label>Ubicacion.</Form.Label>
                                <Form.Control type="text" name="name" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Descripcion</Form.Label>
                                <Form.Control as="textarea" rows={3} name="description" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>


                            <div id="reason_edition">
                                <Form.Group as={Col}>
                                    <Form.Label>Motivo de edicion</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="reason_edition" disabled required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <Form.Group as={Col}>
                                <Button type="submit">GUARDAR</Button>
                            </Form.Group>

                            </div>
                            
                        </div>
                        <div className="cta position-absolute top right p-2" onClick={hideForm}>
                            <XCircleFill size="45"></XCircleFill>
                        </div>
                    </div>
                </Form>
            </div>
       

            </div>

           
       </main>
    )}

}


export default UbicacionesView;