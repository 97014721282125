import React, { useContext, createContext, useState } from "react";

import * as axios from "axios";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {

  const [login, setLogin] = useState(null);
  const [msg, setMsg] = useState(false);
  const [cred, setCred] = useState(false);

  const signin = async (email, pass) => {
    var body = {email:email, password:pass}
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, body)
  
      if(response.status === 200){
        if(response.data.status !== "Error"){
            
            setCred("")
            window.localStorage.setItem('token', response.data.token);
            window.localStorage.setItem('uid', response.data.data.id);
            setLogin(true)
            return response.data.data     
        }else{
      
            setCred("Credenciales invalidas")
            return false;
        }
    }else{
       
        return false;
    }
  }

  const signout = () => {
    window.localStorage.clear();
    setLogin(false)
  };

  return {
    cred,
    msg,
    setMsg,
    login,
    signin,
    signout
  };
}
