import React from "react"
import { Link, useParams } from "react-router-dom"
import { IconButton } from '@material-ui/core'
import { ArrowLeftSquareFill } from 'react-bootstrap-icons';

const BackToProjectsButton = ({project}) =>{
    let { id } = useParams();
    return(
        <>
        <div className="d-flex py-5 align-items-center">
                <Link to={`/home/proyectos/${id}`}>
                    <IconButton size="medium" disabled={false}>
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe"/>
                    </IconButton> 
                </Link>
                <h2>PROYECTO: { project}</h2>
            </div>
        </>
    )
}

export default BackToProjectsButton