import React, { createContext, useState,useEffect, useContext } from "react"
import {dependentService, recordsService, recordsServiceId, services, getObras, getUsersList} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { replaceObj, selectFromObjbyId, todayC } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";
import { AlertContext } from "./alert_context";


export const FormularioGeneralContext = createContext();
export const FormularioGeneralProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);
    const {alert, showAlert, closeAlert} = useContext(AlertContext)


    const [stateGenerales, setStateGenerales] = useState({
        generales:[],
        generalesF:[],
        users:[],
        maquinarias:[],
        equipos:[],
        obras:[],
        barrenacion:[],
        rmr:[],
        sectores:[],
        bars:[],
        listasAll:[],
        listas:[],
        origenes:[],
        destinos:[],
        materiales:[],
        anclas:[],
        operation:{},
        project_id:null,
        project_name:"",
        loading:false,
        filter:10,
        search: "",
        fecha:"",
        source:""
    });

    useEffect(async () =>{

        setStateGenerales({...stateGenerales, loading:true});
         
            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd ;
            
            if(proyecto !== undefined){
                //SE TIENE QUE OPTIMIZAR
                var generales = await recordsServiceId("GET","general_forms", id);
                var users = await dependentService("GET","users");
                var maquinarias = await dependentService("GET","machinaries");
                var equipos = await dependentService("GET","equipments");
                var bars = await dependentService("GET","bars")
                var obras = await recordsServiceId("GET","budgets", id);
                var barrenacion = await recordsServiceId("GET","template_drillings", id);
                var rmr = await recordsServiceId("GET","rock_indices", id);
                var sectores = await recordsServiceId("GET","sectors", id);
                var listas = await recordsService("GET","assists");
                var origenesdestinos = await recordsService("GET","origin_destination");
                var materiales = await recordsServiceId("GET","materials", id);
                var anclas = await recordsServiceId("GET","anchorTypes", id);
                /*  */
                var barrenacionTipos = await recordsServiceId("GET",'type_drillings',id)
                var zonasListado = await recordsServiceId("GET","zones",id)
                var generalObras = await getObras(id)
                var usersToday = await getUsersList(id, today)
                //var usersToday = await getUsersList(id, '2022-03-01')
                
                if(generales.status){ 
                   
                    
                    setStateGenerales({
                        usersToday:usersToday.data.data,
                        generalObras:generalObras.data,
                        barrenacionTipos:barrenacionTipos.data,
                        zonasListado:zonasListado.data,
                        // obrasListado:obrasListado.data,
                        //usuariosAsistencias:usuariosAsistencias.data,      
                        /*  */
                        generales:generales.data,
                        generalesF:generales.data,
                        users:users.data,
                        maquinarias:maquinarias.data,
                        equipos:equipos.data,
                        obras:obras.data.filter((el)=>el.deleted_at == null),
                        
                        barrenacion:barrenacion.data.filter((el)=>el.deleted_at == null),
                        rmr:rmr.data.filter((el)=>el.deleted_at == null),
                        sectores:sectores.data.filter((el)=>el.deleted_at == null),
                        bars:bars.data,
                        listasAll:listas.data,
                        listas:listas.data.filter((el) =>{
                            
                            var date = todayC()
                            var startDate = Date.parse(el.date_start);
                            var endDate = Date.parse(el.date_end);
                            var today = Date.parse(date);
                            
                            if(today >= startDate && today <= endDate && el.project_id === parseInt(id)){
                                return true
                            }
                            return false;
                         
                        }),
                        origenes:origenesdestinos.data.filter((item)=>item.data_type=="Origen"),
                        destinos:origenesdestinos.data.filter((item)=>item.data_type=="Destino"),
                        materiales:materiales.data,
                        anclas:anclas.data,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter:10,
                        search:"",
                        fecha:"",
                        source:""
                        
                   
                    });
                }else{
                    if(generales.code === 401 || generales.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])


    const _onCreateForm = async (body, operation) => {

        let response = await services("POST","general_forms",body);
        console.log(response);
        console.log(body)
        if(response.status){

            let data = response.data
            data.equipment = selectFromObjbyId(stateGenerales.equipos, parseInt(data.equipment_id))
            data.operation = operation;
            data.supervisor = selectFromObjbyId(stateGenerales.users, parseInt(data.user_id) )
            data.assistant = selectFromObjbyId(stateGenerales.users, parseInt(body.assistant))
            data.operator = selectFromObjbyId(stateGenerales.users, parseInt(response.data.operator) )
            data.oficial = selectFromObjbyId(stateGenerales.users, parseInt(response.data.oficial) )
            data.polvorero = selectFromObjbyId(stateGenerales.users, parseInt(body.polvorero))
      
            data.deleted_at = null;

            var generales = stateGenerales.generales;
            var generalesF = stateGenerales.generalesF;
            generales.unshift(data)
            generalesF.unshift(data)
            
            setStateGenerales({...stateGenerales,generales, generalesF})
            showAlert("success", "¡Listo! Registro agregado exitosamente.")
            return data;

        }else{
            var message = "";
            Object.keys(response.errors).map(function(key, index) {
                message = message + " " +response.errors[key]
            });
            showAlert("error", message);
        }
    }

    const _onEditForm = async (body, id, operation) => {

     
        let response = await services("PUT","general_forms",body, id);
      
        if(response.status){

            let data = response.data
            data.equipment = selectFromObjbyId(stateGenerales.equipos, parseInt(data.equipment_id))
            data.operation = operation;
            data.supervisor = selectFromObjbyId(stateGenerales.users, parseInt(data.user_id) )
            data.assistant = selectFromObjbyId(stateGenerales.users, parseInt(response.data.assistant) )
            data.operator = selectFromObjbyId(stateGenerales.users, parseInt(response.data.operator) )
            var generales = replaceObj(stateGenerales.generales,data);
            var generalesF = replaceObj(stateGenerales.generalesF,data);

            //setLocal({...local, form_id: response.data.id});
            setStateGenerales({...stateGenerales,generales, generalesF});
            showAlert("success", "¡Listo! Registro editado exitosamente.")



            return data;

        }else{
            var message = "";
            Object.keys(response.errors).map(function(key, index) {
                message = message + " " +response.errors[key]
            });
            showAlert("error", message);
        }


    }


    return(
        <FormularioGeneralContext.Provider value={{
            stateGenerales,
            setStateGenerales,
            _onCreateForm,
            _onEditForm
        }}>
            {children}
        </FormularioGeneralContext.Provider>
    )
}