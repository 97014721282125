const showLoader = () => {
    let loader = document.getElementById("loader")
    loader.classList.add("d-flex");
    loader.classList.remove("d-none")
}
const hideLoader = () => {
    let loader = document.getElementById("loader")
    loader.classList.add("d-none");
    loader.classList.remove("d-flex")
}
const replaceObj = (data, obj) => {
    var arr = data;
    const index = arr.findIndex((el) => el.id === obj.id);
    arr[index] = obj
    return arr;
}
const today = () => {
    return new Date().getDate() + "/" + (new Date().getMonth() + 1).toString() + "/" + new Date().getFullYear()
}

const todayY = () =>{
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    return year + "-" + (month < 10 ? "0"+month.toString() : month) + "-" + (day <10 ? "0"+day.toString() : day)
}

const todayC = () => {

    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    return year + "-" + (month < 10 ? "0"+month.toString() : month) + "-" + (day <10 ? "0"+day.toString() : day)
}

const formatDate = date => {

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year + "-" + (month < 10 ? "0"+month.toString() : month) + "-" + (day <10 ? "0"+day.toString() : day)
}

const removeFromObjbyId = (data,id) => {  
    return data.filter(e => e.id !== id);
}
const removeFromObjbyUserId = (data,id) => {  
    return data.filter(e => e.user_id !== id);
}
const selectFromObjbyId = (data,id) => {  
    return data.filter(e => e.id === id)[0];
}

const selectFromObjbyItem = (data,item) => {  
    return data.filter(e => e.item === item)[0];
}

const departamentsAllow = {
    "maquinaria":[2]
}

const actions = {
    "edit":[1,2,3,12,14,15,16,17,18,19,20,21,22],
    "add":[1,2,3,12,14,15,16,17,18,19,20,21,22],
    "delete":[1,16,17],
    "activate":[1,2,3,12,14,15,16,17,18,19,20,21,22],
}
const permmisions = {
    "maquinaria":[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
    "origenesdestinos":[1,16,17],
    "barras":[1,15,19,20],
    "ubicaciones":[1,17,20,21,22,],
    "categorias":[1,17,20,21,22],
    "departamentos":[1,17,20,21,22],
    "usuarios":[1,17,20,21,22,],
    "equipos":[1,2,3,4,5,6,7,8,10,11,12,13,14,15,17,20,24,25],
    "tipoanclas":[1,18,20],
    "ediciones":[1,3,7,8,9,10,11,15,19,20,21,22,26,27],
    "proyectos":[],
    "barrenacion":[],
    "sector":[],
    "zonas":[],
    "material":[],
    "roca":[],
    "presupuesto":[]
}
const allowAux = false;
const allowActions = (user,action) => {

    if(Object.entries(user).length !== 0 ){
        let allow = allowAux;
        for (var i = 0; i < user.user_projects.length; i++) {
            var some = actions[action].some((e) =>{
                return e === user.user_projects[i].categorie.id;
            })
            var dep = departamentsAllow["maquinaria"].some((e) =>{
                return e === user.user_projects[i].department_id;
            })
            if (some || dep) { 
                allow = true;
                break; 
            }
        }
        return allow;
    }
    return false;
}


const allow = (user, catalogo) => {

    if(Object.entries(user).length !== 0 ){
        let allow = allowAux;
        for (var i = 0; i < user.user_projects.length; i++) {

            var some = permmisions[catalogo].some((e) =>{
                return e === user.user_projects[i].categorie.id;
            })
            var dep = departamentsAllow["maquinaria"].some((e) =>{
                return e === user.user_projects[i].department_id;
            })
            if (some || dep) { 
                allow = true;
                break; 
            }
        }
        return allow;
    }
    return false;


}


const getStringDay = (day) => {
    switch(day){
        case 0:
            return 'sunday'
            break;
        case 1:
            return 'monday'
            break;
        case 2:
            return 'tuesday'
            break;
        case 3:
            return 'wednesday'
            break;
        case 4:
            return 'thursday'
            break;
        case 5:
            return 'friday'
            break;
        case 6:
            return 'saturday'
            break;
        default:
            break;
    }
}

module.exports = {
    "showLoader": showLoader,
    "hideLoader": hideLoader,
    "replaceObj": replaceObj,
    "today": today,
    "todayY":todayY,
    "todayC":todayC,
    "removeFromObjbyId":removeFromObjbyId,
    "selectFromObjbyId":selectFromObjbyId,
    "selectFromObjbyItem":selectFromObjbyItem,
    "allow":allow,
    "allowActions":allowActions,
    "removeFromObjbyUserId":removeFromObjbyUserId,
    "getStringDay":getStringDay,
    'formatDate':formatDate
};