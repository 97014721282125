import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ActionAlert(props) {
  const classes = useStyles();
  const [show, setShow] = useState(props.show)


  useEffect(() => {
    setShow(props.show)

  }, [props.show])

  return (
    <div className="py-2">
        {
        show ?
        <Alert severity={props.type} onClose={() => {
            props.onClose()
        }}>{props.msg}</Alert> : null
        }

    </div>
  );
}