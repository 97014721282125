import { ButtonGroup, IconButton } from '@material-ui/core'
import React, {useContext, useEffect, useLayoutEffect, useState} from 'react'
import { ArrowLeftSquareFill, Back, Person, Trash, BlockquoteLeft, Search } from 'react-bootstrap-icons'

import { UserContext } from '../context/user_context'
import { Form, Col, Button } from 'react-bootstrap';

import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { ProyectosContext } from '../context/proyectos_context';
import { selectFromObjbyId } from '../utils/utils';
import ViewDataLoader from '../components/ui/view_data_loader';
import { Doughnut } from 'react-chartjs-2';
import { recordsServiceId, services, singleService } from '../services/api';
import { AlertContext } from '../context/alert_context';

function ProyectoDetalle() {

    const { user } = useContext(UserContext);    
    const { alert, main } = useContext(AlertContext);        
    const [proyecto, setProyecto] = useState(undefined);
    const [chart, setChart] = useState({
        users:10,
        days:147,
        absences:24,
        indicator:10,
        loading:true,
        startDate:"",
        endDate:"",

    });
    const {state,setState} = useContext(ProyectosContext);

    let { id } = useParams();
    let history = useHistory();
    let location = useLocation();
    const { path, url } = useRouteMatch();

    useEffect(async ()=>{
        
        let proyecto = selectFromObjbyId(state.projects,parseInt(id))
        if(proyecto === undefined){
    
            history.replace(`/home/proyectos` );
        }else{
            setProyecto(proyecto);
            var chartres = await singleService("GET","assists/graph/project", id );
            setChart({...chart, users:chartres.data.users, days:chartres.data.assists,absences:chartres.data.delay, indicator:chartres.data.users, loading:false })

        }
        
    },[id]);


    


    if(proyecto === undefined || chart.loading === true){
        return (
            ViewDataLoader()
        )
    }else{
        
        return (
            <main role="main" className={!alert.open ? "d-flex flex-column menu_collapsed": "d-flex flex-column"}>

                <div className="d-flex py-5 align-items-center">
                <IconButton size="medium" disabled={false} onClick={()=>{
                    history.goBack();
                }}>
                    <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe"/>
                </IconButton> 
                <h2>PROYECTO: {proyecto.name} </h2>
                </div>


                
                

                <div className="container-fluid d-non">
                    <strong>ENLACES:</strong>
                    <hr></hr>

                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <div className="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            Catálogo de especificaciones
                            </button>
                            <ul className="dropdown-menu w-100" aria-labelledby="btnGroupDrop1">
                                <li>
                                    <Link className="dropdown-item" to={`/home/proyectos/barrenacion/${id}`}>
                                        Esp. de barrenación
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to={`/home/proyectos/sectores/${id}`}>
                                        Sectores
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to={`/home/proyectos/zonas/${id}`}>
                                        Zonas
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to={`/home/proyectos/materiales/${id}`}>
                                        Materiales
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to={`/home/proyectos/roca/${id}`}>
                                        Indice de calidad de Roca
                                    </Link>
                                </li>

                                
                            </ul>
                        </div>
                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <Link className="btn btn-primary" to={`/home/proyectos/barrenacion_jumbo/${id}`}>
                                Ir a captura de operaciones
                            </Link>
                            <Link className="btn btn-primary" to={`/home/proyectos/presupuestos/${id}`}>
                                Presupuesto de obras
                            </Link>
                            <Link className="btn btn-primary" to={`/home/proyectos/presupuestos_soporte/${id}`}>
                                Presupuestos de soporte de obra
                            </Link>
                            <Link className='btn btn-primary' to={`/home/proyectos/tipo_obras/${id}`}>
                                Tipo de Obras
                            </Link>

                            <Link className='btn btn-primary' to={`/home/proyectos/obras/${id}`}>
                                Obras
                            </Link>

                            <Link className='btn btn-primary' to={`/home/proyectos/planeacion/${id}`}>
                                Planeacion
                            </Link>
                            
                        </div>
                
                    </div>
                    <br></br><br></br>

                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">

                        <Link className="btn btn-primary" to={`/home/proyectos/listas/${id}`}>
                            Ir a Listas de asistencia
                        </Link>

                        
                    </div>

                
               

                    <hr></hr>
                </div>
             

            </main>
        )
    }
}

export default ProyectoDetalle


