import React, { createContext, useState,useEffect, useContext, useCallback } from "react"

import { recordsService, services} from '../services/api' 
import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";

export const BarrasContext  = createContext();

export const BarrasProvider = ({children}) => {
    let auth = useAuth();
    const {setUser}= useContext(UserContext)

    const [state, setState] = useState({          
        bars:[],
        barsF:[],
        filter: 10,
        loading:false
    });
 
    useEffect(async () => {
    
            
            //if(state.bars.length === 0){
                setState(state => ({ ...state, loading:true}));
                var bars = await recordsService("GET","bars")
    
                if(bars.status){
                    setState(state => ({ ...state, loading:false, bars:bars.data, barsF:bars.data}));
                    
                }else{
                    if(bars.code === 401 || bars.code === 500 ){
                        window.localStorage.clear();
                        setUser({})
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
            //}

    
    }, []);

   


    // useEffect(async () =>{
        
    // },[])
    
    return(
        <BarrasContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </BarrasContext.Provider>

    )
}






