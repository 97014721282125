import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import '../App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useLocation,
  Redirect
} from "react-router-dom";

import MaquinariaView from '../views/maquinaria_view'
import OrigenesDestinosView from '../views/origenes_destinos_view'
import BarraMtsEficientesView from '../views/barra_mts_eficientes_view'
import UbicacionesView from '../views/ubicaciones_view'
import CategoriasView from '../views/categorias_view';
import DepartamentosView from '../views/departamentos_view';
import UsuariosView from '../views/usuarios_view';
import ProfilleView from '../views/profile_view'
import { DoorOpenFill } from 'react-bootstrap-icons';
import { useAuth } from "./../services/auth";
import { UserContext } from '../context/user_context';
import { MaquinariaProvider } from '../context/maquinaria_context';
import { CategoriasProvider } from '../context/categorias_context';
import { UbicacionesProvider } from '../context/ubicaciones_context';
import { OrigenesDestinosProvider } from '../context/origenes_destinos_context';
import { BarrasProvider } from '../context/barras_context';
import { DepartamentosProvider } from '../context/departamentos_context';
import { UsuariosProvider } from '../context/usuarios_context';
import TipoAnclasView from './tipo_anclas_view';
import { TipoAnclasProvider } from '../context/tipo_anclas_context';
import { EquiposProvider } from '../context/equipos_context';
import EquiposView from './equipos_view';
import HistorialEdicionView from './historial_edicion_view';
import { HistorialEdicionesProvider } from '../context/historial_edicion_context';
import { AlertContext } from '../context/alert_context';
import { Journals, BlockquoteLeft, Person } from 'react-bootstrap-icons';
import { Menu, OpenInBrowserTwoTone } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { allow } from '../utils/utils';
import { EspeciBarrenacionProvider } from '../context/especi_barrenacion_context';
import EspeciBarrenacionView from './especi_barrenacion_view';
import { SectorProvider } from '../context/sector_context';
import SectorView from './sector_view';
import { ZonasProvider } from '../context/zonas_context';
import ZonasView from './zonas_view';
import { MaterialContext, MaterialProvider } from '../context/material_context';
import MaterialView from './material_view';
import { RocaProvider } from '../context/roca_context';
import RocaView from './roca_view';
import { ProyectosProvider } from '../context/proyectos_context';
import ProyectosView from './proyectos_view';
import ProyectoDetalle from './proyecto_detalle_view';
import { PresupuestoObrasProvider } from '../context/presupuesto_obras_context';
import PresupuestoObrasView from './presupuesto_obras_view';
import { ListasAsistenciaProvider } from '../context/listas_asistencia_context';
import ListasAsistenciaView from './listas_asistencia_view';
import ListaDetalle from './listas_asistencia_detalle_view';
import { ListaProvider } from '../context/lista_context';
import ListasAsistenciaGraficos from './listas_asistencia_graficos';
import { BarrenacionJumboProvider } from '../context/barrenacion_jumbo_context';
import BarrenacionJumboView from './barrenacion_jumbo_view';
import BarrenacionJumboDetalleView from './barrenacion_jumbo_detalle_view';

import VoladurasView from './voladuras_view';
import { VoladurasProvider } from '../context/voladuras_context';
import VoladurasDetalleView from './voladuras_detalle_view';
import { FormularioGeneralProvider } from '../context/formulario_general_context';
import RezagadoView from './rezagado_view';
import RezagadoDetalleView from './rezagado_detalle_view';
import AcarreoView from './acarreo_view';
import AcarreoDetalleView from './acarreo_detalle_view';
import AcarreoConcretoView from './acarre_concreto_view';
import AcarreoConcretoDetalleView from './acarreo_concreto_detalle_view';
import TransportePersonalMaterialView from './transporte_personal_material_view';
import TransportePersonalMaterialDetalleView from './transporte_personal_material_detalle_view';
import AmaciceLimpiezaView from './amacice_limpieza_view';
import AmaciceLimpiezaDetalleView from './amacice_limpieza_detalle_view';
import ServiciosGeneralesView from './servicios_generales_view';
import ServiciosGeneralesDetalleView from './servicios_generales_detalle_view';
import LanzadoConcretoView from './lanzado_concreto_view';
import LanzadoConcretoDetalleView from './lanzado_concreto_detalle_view';
import PresupuestoSoporteObrasView from './presupuesto_soporte_obras_view';
import { PresupuestoSoporteObrasContext, PresupuestoSoporteObrasProvider } from '../context/presupuesto_soporte_obras_context';
import SoporteObraView from './soporte_obra_view';
import SoporteObraDetalleView from './soporte_obra_detalle_view';
import { TipoObrasProvider } from '../context/tipo_obras_context';
import TipoObrasView from './tipo_obras_views';
import { ObrasProvider } from '../context/obras_context';
import ObrasView from './obras_view';
import { PlaneacionProvider } from '../context/planeacion_context';
import PlaneacionView from './planeacion_view';
import ServiciosAdministrativosView from "./servicios_administrativos_view"

//Transporte  Get 404
//general_formid en el getter de Amacice y limpieza de obra
// Error en el put de Amacice y liempieza de obra





function HomeView(){

    const auth = useAuth();
    // const [open, setOpen] = useState(true)
    const {user, setUser} = useContext(UserContext)
    const {alert,closeAlert, setAlert} = useContext(AlertContext)
    const { path, url } = useRouteMatch();
    let location = useLocation();



    

    useEffect(() => {
      
        if(alert.show){
          closeAlert();
        }
         
    },[location.pathname])

  

    useEffect(() => {
 
     if(Object.entries(user).length !== 0 ){
   
      
     }
   

    },[user])

  
    const handleMenu = () => {
      var menu = document.getElementById("sidebarMenu")
      var main = document.querySelector("main")
      var header =  document.querySelector(".header")

      if(alert.open){
     
        menu.classList.add("w-0", "overflow-hidden");
        main.classList.add("menu_collapsed");
        header.classList.add("menu_collapsed");
     
              
       
      }else{
        
        menu.classList.remove("w-0", "overflow-hidden");
        main.classList.remove("menu_collapsed")
        header.classList.remove("menu_collapsed")
       
      }
      setAlert({...alert, open:!alert.open});
     
    }

  

    return (
        
        <>
          <div className="header bg-blue d-flex">
              <div id="nav" className="d-flex w-100 flex-row p-0">


                <div className="col pl-2">
                  <IconButton color="inherit" size="medium" onClick={() => {
                    handleMenu();
                  }}>
                      {alert.open ? <Menu style={{ color: "white" }}/> : <Menu style={{ color: "white" }} />}
                  </IconButton>              
                </div>

                <button className="btn btn--exit pt-1 px-3 m-0 right" onClick={()=>{     
                    window.localStorage.clear();
                    setUser({});
                    auth.signout();
                  }}>  
                  
                  <span>SALIR</span> 
                       
                  <span><DoorOpenFill size={25}/> </span> 
                </button>

              </div>
          </div>
    
        <div className="container-fluid p-0">
          <div className="row m-0">

            <nav id="sidebarMenu" className=" bg-light sidebar">

            <div id="brand" className="header_brand bg-white">
               <img src="assets/logo.svg" width="120" height="48" className="d-block mx-auto" />
            </div>
        
              <div className="sidebar-sticky pt-3">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}`}><Person size="20" color="#0a5cbe"></Person> HOME</Link>
                  </li>
                  
                </ul>
    
                <div className="sidebar-heading d-flex justify-content-between align-items-center text-muted">
                  <span>CATALOGOS</span>
                  <a className="d-flex align-items-center text-muted" href="#" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                  </a>
                </div>
                <ul className="nav flex-column mb-2">

                 
                  { allow(user, "maquinaria") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/maquinaria`}><Journals size="15" color="#0a5cbe"></Journals> MAQUINARIA</Link>
                  </li>: null}

                  { allow(user, "origenesdestinos") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/origenesdestinos`}><Journals size="15" color="#0a5cbe"></Journals> ORIGENES/DESTINOS</Link>
                  </li>: null}

                  { allow(user, "barras") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/barras`}><Journals size="15" color="#0a5cbe"></Journals> BARRA MTS. EFICIENTES</Link>
                  </li>: null}

                  { allow(user, "ubicaciones") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/ubicaciones`}><Journals size="15" color="#0a5cbe"></Journals> UBICACIONES</Link>
                  </li>: null}

                  { allow(user, "categorias") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/categorias`}><Journals size="15" color="#0a5cbe"></Journals> CATEGORIAS</Link>
                  </li>: null}

                  { allow(user, "departamentos") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/departamentos`}><Journals size="15" color="#0a5cbe"></Journals> DEPARTAMENTOS</Link>
                  </li>: null}

                  { allow(user, "usuarios") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/usuarios`}><Journals size="15" color="#0a5cbe"></Journals> USUARIOS</Link>
                  </li>: null}

                  { allow(user, "equipos") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/equipos`}><Journals size="15" color="#0a5cbe"></Journals> EQUIPOS</Link>
                  </li>: null}

                  { allow(user, "tipoanclas") === true ? 
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/tipoanclas`}><Journals size="15" color="#0a5cbe"></Journals> TIPO DE ANCLAS</Link>
                  </li>: null}

                  {/* { allow(user, "proyectos") === true ?  */}
                  <li className="nav-item">
                    <Link className="nav-link" to={`${url}/proyectos`}><Journals size="15" color="#0a5cbe"></Journals> PROYECTOS</Link>
                  </li>
                  {/* : null} */}

                

                </ul>
                <div className="sidebar-heading d-flex justify-content-between align-items-center text-muted">
                  <span>HISTORIAL</span>
                  <a className="d-flex align-items-center text-muted" href="#" aria-label="Add a new report">
                    <span data-feather="plus-circle"></span>
                  </a>
                </div>
                <ul className="nav flex-column mb-2">
                <li className="nav-item">
                    <Link className="nav-link" to={`${url}/ediciones`}><BlockquoteLeft size="20" color="#0a5cbe"></BlockquoteLeft> HISTORIAL DE EDICIÓN</Link>
                  </li>
                </ul>
              </div>
            </nav>
    
    
            <Switch>
              <Route exact path={`${path}`}>
                <ProfilleView/>
              </Route>

              <AllowRoute path={`${path}/maquinaria`} catalogo="maquinaria">  
                  <MaquinariaProvider>
                    <MaquinariaView />
                  </MaquinariaProvider>
              </AllowRoute>


              <AllowRoute path={`${path}/origenesdestinos`} catalogo="origenesdestinos">
                  <OrigenesDestinosProvider>
                    <OrigenesDestinosView />
                  </OrigenesDestinosProvider>
              </AllowRoute>


              <AllowRoute path={`${path}/barras`} catalogo="barras">
                <BarrasProvider>
                  <BarraMtsEficientesView />
                </BarrasProvider>
              </AllowRoute>


              <AllowRoute path={`${path}/ubicaciones`} catalogo="ubicaciones">
                <UbicacionesProvider>
                  <UbicacionesView />
                </UbicacionesProvider>
              </AllowRoute>

              <AllowRoute path={`${path}/categorias`} catalogo="categorias">
                <CategoriasProvider>
                  <CategoriasView />
                </CategoriasProvider>           
              </AllowRoute>


              <AllowRoute path={`${path}/departamentos`} catalogo="departamentos">
                <DepartamentosProvider>
                  <DepartamentosView />
                </DepartamentosProvider>
              </AllowRoute>

              <AllowRoute path={`${path}/usuarios`} catalogo="usuarios">
                <UsuariosProvider>
                  <UsuariosView />
                </UsuariosProvider>
              </AllowRoute>

              <AllowRoute path={`${path}/equipos`} catalogo="equipos">
                <EquiposProvider>
                  <EquiposView />
                </EquiposProvider>
              </AllowRoute>

              
              <AllowRoute path={`${path}/tipoanclas`} catalogo="tipoanclas">
                <TipoAnclasProvider>
                  <TipoAnclasView />
                </TipoAnclasProvider>
              </AllowRoute>

              

              {/* <AllowRoute path={`${path}/proyectos`} catalogo="proyectos"> */}
                <Route path={`${path}/proyectos`} >
                  <ProyectosProvider>
                    <Switch>
                        <Route exact path={`${path}/proyectos`} >
                          <ProyectosView />
                        </Route>

                        <Route exact path={`${path}/proyectos/:id`} >
                          <ProyectoDetalle />
                        </Route>

                        <Route exact path={`${path}/proyectos/barrenacion/:id`} >
                          <EspeciBarrenacionProvider>
                            <EspeciBarrenacionView />
                          </EspeciBarrenacionProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/sectores/:id`} >
                          <SectorProvider>
                            <SectorView />
                          </SectorProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/zonas/:id`} >
                          <ZonasProvider>
                            <ZonasView />
                          </ZonasProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/materiales/:id`} >
                          <MaterialProvider>
                            <MaterialView />
                          </MaterialProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/roca/:id`} >
                          <RocaProvider>
                            <RocaView />
                          </RocaProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/listas/:id`} >
                          <ListasAsistenciaProvider>
                            <ListasAsistenciaView />
                          </ListasAsistenciaProvider>
                        </Route>

                       
                        <Route exact path={`${path}/proyectos/presupuestos/:id`} >
                          <PresupuestoObrasProvider>
                            <PresupuestoObrasView />
                          </PresupuestoObrasProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/presupuestos_soporte/:id`} >
                          <PresupuestoSoporteObrasProvider>
                            <PresupuestoSoporteObrasView />
                          </PresupuestoSoporteObrasProvider>
                        </Route>
                        


                        <Route exact path={`${path}/proyectos/listas/graficos/:id`} >
                          <ListasAsistenciaProvider>
                            <ListasAsistenciaGraficos />
                          </ListasAsistenciaProvider>
                        </Route>


                        <Route exact path={`${path}/proyectos/listas/asistencia/:id`} >
                          <ListasAsistenciaProvider>
                            <ListaProvider>
                              <ListaDetalle />
                            </ListaProvider>
                          </ListasAsistenciaProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/barrenacion_jumbo/:id`} >
                          <FormularioGeneralProvider>
                            <BarrenacionJumboProvider>
                              <BarrenacionJumboView />
                            </BarrenacionJumboProvider>
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/barrenacion_jumbo/:project/:id`} >
                          <FormularioGeneralProvider>
                            <BarrenacionJumboProvider>
                              <BarrenacionJumboDetalleView />
                            </BarrenacionJumboProvider>
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/voladuras/:id`} >
                          <FormularioGeneralProvider>
                            <VoladurasView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/voladuras/:project/:id`} >
                          <FormularioGeneralProvider>
                            <VoladurasDetalleView />
                          </FormularioGeneralProvider>
                        </Route>


                        <Route exact path={`${path}/proyectos/rezagado/:id`} >
                          <FormularioGeneralProvider>
                            <RezagadoView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/rezagado/:project/:id`} >
                          <FormularioGeneralProvider>
                            <RezagadoDetalleView />
                          </FormularioGeneralProvider>
                        </Route>


                        <Route exact path={`${path}/proyectos/acarreo/:id`} >
                          <FormularioGeneralProvider>
                            <AcarreoView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/acarreo/:project/:id`} >
                          <FormularioGeneralProvider>
                            <AcarreoDetalleView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/acarreo_concreto/:id`} >
                          <FormularioGeneralProvider>
                            <AcarreoConcretoView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/acarreo_concreto/:project/:id`} >
                          <FormularioGeneralProvider>
                            <AcarreoConcretoDetalleView />
                          </FormularioGeneralProvider>
                        </Route>


                        <Route exact path={`${path}/proyectos/transporte_personal_material/:id`} >
                          <FormularioGeneralProvider>
                            <TransportePersonalMaterialView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/transporte_personal_material/:project/:id`} >
                          <FormularioGeneralProvider>
                            <TransportePersonalMaterialDetalleView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/amacice_limpieza/:id`} >
                          <FormularioGeneralProvider>
                            <AmaciceLimpiezaView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/amacice_limpieza/:project/:id`} >
                          <FormularioGeneralProvider>
                            <AmaciceLimpiezaDetalleView />
                          </FormularioGeneralProvider>
                        </Route>


                        <Route exact path={`${path}/proyectos/servicios_generales/:id`} >
                          <FormularioGeneralProvider>
                            <ServiciosGeneralesView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/servicios_generales/:project/:id`} >
                          <FormularioGeneralProvider>
                            <ServiciosGeneralesDetalleView />
                          </FormularioGeneralProvider>
                        </Route>


                        <Route exact path={`${path}/proyectos/lanzado_concreto/:id`} >
                          <FormularioGeneralProvider>
                            <LanzadoConcretoView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/lanzado_concreto/:project/:id`} >
                          <FormularioGeneralProvider>
                            <LanzadoConcretoDetalleView />
                          </FormularioGeneralProvider>
                        </Route>


                        <Route exact path={`${path}/proyectos/soporte_obra/:id`} >
                          <FormularioGeneralProvider>
                            <SoporteObraView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/soporte_obra/:project/:id`} >
                          <FormularioGeneralProvider>
                            <SoporteObraDetalleView />
                          </FormularioGeneralProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/tipo_obras/:id`}>
                          <TipoObrasProvider>
                            <TipoObrasView/>
                          </TipoObrasProvider>
                        </Route>
                        
                        <Route exact path={`${path}/proyectos/obras/:id`}>
                          <ObrasProvider>
                            <ObrasView/>
                          </ObrasProvider>
                        </Route>

                        <Route exact path={`${path}/proyectos/planeacion/:id`}>
                          <PlaneacionProvider>
                            <PlaneacionView/>
                          </PlaneacionProvider>
                        </Route>
                        <Route exact path={`${path}/proyectos/servicios_administrativos/:id`} >
                          <FormularioGeneralProvider>
                            <ServiciosAdministrativosView />
                           </FormularioGeneralProvider>
                        </Route>


                        

                        

                    
                    </Switch>
                  </ProyectosProvider>
                </Route>
              {/* </AllowRoute> */}



              





              {/* <AllowRoute path={`${path}/barrenacion`} catalogo="barrenacion"> */}
                {/* <Route exact path={`${path}/barrenacion/:id`} >
                  <EspeciBarrenacionProvider>
                    <EspeciBarrenacionView />
                  </EspeciBarrenacionProvider>
                </Route> */}
              {/* </AllowRoute> */}





               {/* <AllowRoute path={`${path}/sector`} catalogo="sector"> */}
               {/* <Route path={`${path}/sector`} >
                  <SectorProvider>
                    <SectorView />
                  </SectorProvider>
                </Route> */}
              {/* </AllowRoute> */}


              {/* <AllowRoute path={`${path}/zonas`} catalogo="zonas"> */}
                {/* <Route path={`${path}/zonas`} >
                  <ZonasProvider>
                    <ZonasView />
                  </ZonasProvider>
                </Route> */}
              {/* </AllowRoute> */}

               {/* <AllowRoute path={`${path}/material`} catalogo="material"> */}
               {/* <Route path={`${path}/material`} >
                  <MaterialProvider>
                    <MaterialView />
                  </MaterialProvider>
                </Route> */}
              {/* </AllowRoute> */}

              {/* <AllowRoute path={`${path}/roca`} catalogo="roca"> */}
              {/* <Route path={`${path}/roca`} >
                  <RocaProvider>
                    <RocaView />
                  </RocaProvider>
                </Route> */}
              {/* </AllowRoute> */}

              {/* <AllowRoute path={`${path}/presupuesto`} catalogo="presupuesto"> */}
              {/* <Route path={`${path}/presupuesto`} >
                  <PresupuestoObrasProvider>
                    <PresupuestoObrasView />
                  </PresupuestoObrasProvider>
                </Route> */}
              {/* </AllowRoute> */}

              <AllowRoute path={`${path}/ediciones`} catalogo="ediciones">
                <HistorialEdicionesProvider>
                  <HistorialEdicionView />
                </HistorialEdicionesProvider>
              </AllowRoute>
              
    
            </Switch>
    
    
    
          </div>
        </div>
        </>
      
      ); 
}

function AllowRoute({ children, catalogo, ...rest }) {
  const {user, setUser} = useContext(UserContext)
  return (
    <Route   {...rest} render={({ location }) => {

      if(allow(user, catalogo)){
        return children
      }else{
        return <Redirect to={{ 
          pathname: "/home", 
          state: { from: location }
        }} />
      }
    }
      }
    />
  );
}


export default HomeView;