import {createContext, useContext, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { dependentService, recordsServiceId } from '../services/api'
import { useAuth } from '../services/auth'
import { UserContext } from './user_context'


export const TipoObrasContext = createContext()
export const TipoObrasProvider = ({children}) =>{

    let auth = useAuth()
    let {id} = useParams()
    const {setUser} = useContext(UserContext)

    const [state,setState] = useState({
        tipos:[],
        tiposF:[],
        filter:10,
        projects:[],
        search:'',
        project_id:id,
        loading:false
    })

    useEffect(()=>{
        const fetchData = async () =>{
            if(state.tipos.length === 0 ){
                setState({...state,loading:true})
                var res = await recordsServiceId('GET','work_types',id)
                var proj = await dependentService('GET','projects')

                if(res.status && proj.status){
                    setState({...state,loading:false,tipos:res.data,tiposF:res.data,projects:proj.data})
                }else{
                    if(res.code===401){
                        window.localStorage.clear()
                        setUser({})
                        auth.setMsg(true)
                        auth.signout()
                    }
                }
            }
        }
        fetchData()
    },[])

    return(
        <TipoObrasContext.Provider value={{
            state,setState
        }}>
            {children}
        </TipoObrasContext.Provider>
    )
}