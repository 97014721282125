import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const SectorContext  = createContext();
export const SectorProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateSector, setStateSector] = useState({
        sectores:[],
        sectoresF:[],
        project_id:null,
        project_name:"",
        loading:false,
        filter:10,
       
    });

    useEffect(async () =>{
        
        
        setStateSector({...state, loading:true});
         

            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
                var sectoresRes = await recordsServiceId("GET","sectors", id);
              
                if(sectoresRes.status){ 

                    setStateSector({
                        sectores:sectoresRes.data,
                        sectoresF:sectoresRes.data,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter:10
                   
                    });
                }else{
                    if(sectoresRes.code === 401 || sectoresRes.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])

   

    
    return(
        <SectorContext.Provider value={{
            stateSector,
            setStateSector
        }}>
            {children}
        </SectorContext.Provider>
    )
}






