
import React, { useState, useContext,useMemo } from 'react';
import {PencilFill, XCircleFill, Trash, BlockquoteLeft, Search } from 'react-bootstrap-icons';

import HistorialEdicionesRecords from '../components/records/historial_edicion_records'

import { UserContext } from '../context/user_context';
import ViewDataLoader from '../components/ui/view_data_loader';

import {showLoader, hideLoader, today,replaceObj, selectFromObjbyId} from '../utils/utils';
import {services} from '../services/api';


import { Form, Col } from 'react-bootstrap';

import { HistorialEdicionesContext } from '../context/historial_edicion_context';
import DataListInput from 'react-datalist-input';
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import { IconButton } from '@material-ui/core';
let editing = false;
let editingId;

var catalogFilter = "";
var startDateFilter = null;
var endDateFilter = null;
var userFilter = null;
function HistorialEdicionView(){
   
    const [validated, setValidated] = useState(false);

    const {state,setState, getFilter} = useContext(HistorialEdicionesContext)
    const {alert, showAlert, closeAlert} = useContext(AlertContext)
    const {user} = useContext(UserContext)


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
       
        

        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","anchorTypes",body);
            }else{
                res = await services("POST","anchorTypes",body, editingId);
        
            }

            // if(res.status){
               
            //     if(!editing){


            //         var anclas = state.anclas;
            //         var ancla = res.data;
            //         var project = selectFromObjbyId(state.projects, parseInt(ancla.project_id));
            //         ancla.project = project
            //         ancla.deleted_at = null;

            //         anclas.unshift(ancla);
            //         setState({...state,anclas});


            //     }else{

            //         var ancla = res.data;
            //         var project = selectFromObjbyId(state.projects, parseInt(ancla.project_id));
            //         ancla.project = project
                   
            //         var anclas = replaceObj(state.anclas,ancla);
            //         setState({...state,anclas});
            //     }
            //     hideLoader();
            //     hideForm();
            //     clearForm();
            // }
            
        }else{
            
            setValidated(true);
        }
    };


    // const hideForm = () =>{
    //     let f = document.getElementById("main_form");
    //     clearForm();
    //     f.classList.add("d-none");
    //     f.classList.remove("d-block");
    // }
    
    // const onChangeFilter = (evt) =>{
    //     setState({...state, filter:parseInt(evt.currentTarget.value)})
    // }

    

    // const onCatalogChange = (evt) => {
    //     catalogFilter = evt.currentTarget.value
    // }

    // const onSearchChange = (val) => {
    //     var search = val.currentTarget.value
    //     setState({...state, search});
    // }
    // const fillForm = (data) => {
       
    //     let { elements } = document.getElementById('anclas_form');;
    //     elements.namedItem("reason_edition").disabled = false;
    //     for (const [ key, value ] of Object.entries(data) ) {
    //       const field = elements.namedItem(key)
    //       field && (field.value = value)
    //     }
    //     editing = true;
    //     editingId = data.id
    //   }
    // const clearForm = () => {
    //     let form = document.getElementById('anclas_form');
    //     editing = false;
    //     editingId = undefined;
    //     form.reset();
    //     setValidated(false);
    // }
    
    const catalogos = [
        {name:"Maquinaria", id:1},
        {name:"Categorias", id:2},
        {name:"Colaboradores", id:3},
        {name:"Tipos de anclas", id:4},
        {name:"Departamentos", id:5},
        {name:"Barras", id:6},
        {name:"Ubicaciones", id:7},
        {name:"Usuarios", id:8},
        {name:"Origenes y Destinos", id:9}
    ]

    const catalogosItems = useMemo(
        () =>
        catalogos.map((oneItem) => ({
   
            label: oneItem.name,
     
            key: oneItem.id,
        
            ...oneItem,
          })),
        [catalogos]
      );

    

    const items = useMemo(
        () =>
          state.usuarios.map((oneItem) => ({
   
            label: oneItem.name,
     
            key: oneItem.id,
        
            ...oneItem,
          })),
        [state.usuarios]
      );


    if(state.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main" className="">
            <div className="col">
                <h2><BlockquoteLeft size="32" color="#0a5cbe"></BlockquoteLeft> HISTORIAL DE EDICIONES - CATALOGOS</h2>  
            </div>    
           
            <div className="container-fluid position-relative py-1 mt-4 ">
            <Form id="filtro_form">
            <div className="d-flex">
            
                <div className="col-2 p-0">
                    <Form.Group as={Col} controlId="" className="p-0">
                        <Form.Label>DESDE</Form.Label>
                        <Form.Control
                            required
                            type="date"
                            
                            value={state.startDate}
                            onChange={(evt)=>{
                           
                                setState({...state, startDate:evt.currentTarget.value}) 
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Este campo es obligatorio.
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className="col-2">
                    <Form.Group as={Col} controlId="" className="p-0">
                        <Form.Label>HASTA</Form.Label>
                        <Form.Control
                          
                            type="date"
                            
                            value={state.endDate}
                            onChange={(evt)=>{
                            
                                setState({...state, endDate:evt.currentTarget.value}) 

                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Este campo es obligatorio.
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className="col-2 p-0">
                    <Form.Group as={Col}  className="p-0">
                        <Form.Label>USUARIO</Form.Label>
                        <DataListInput
                            inputClassName="form-control-n"
                            placeholder=""      
                                       
                            items={items}
                            onInput = {(a) => {
                                setState({...state, userFilter:""});
                            }}
                            onSelect={(el)=>{
                                setState({...state, userFilter:el.id});
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Este campo es obligatorio.
                        </Form.Control.Feedback>
                    </Form.Group>


                    {/* <Form.Group as={Col} controlId="" className="p-0 m-0">
                        <Form.Label>USUARIO:</Form.Label>
                        <Form.Control  type="text" value={state.userFilter} onChange={(evt)=>{
                            setState({...state, userFilter:evt.currentTarget.value}) 
                        }} />
                    </Form.Group> */}


                </div>
                <div className="col-2">

                    <Form.Group as={Col}  className="p-0">
                        <Form.Label>CATALOGO</Form.Label>
                        <DataListInput
                            inputClassName="form-control-n"
                            placeholder=""                     
                            items={catalogosItems}
                            onInput = {(a) => {
                                setState({...state, catalogFilter:""});
                            }}
                            onSelect={(el)=>{
                                setState({...state, catalogFilter:el.name});
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Este campo es obligatorio.
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group as={Col} controlId="" className="p-0 m-0">
                        <Form.Label>CATALOGO:</Form.Label>
                        <Form.Control  type="text" value={state.catalogFilter} onChange={(evt)=>{
                            setState({...state, catalogFilter:evt.currentTarget.value}) 
                        }} />
                    </Form.Group> */}
                </div>


                <div className="col d-flex align-items-end  py-2 px-0">
                    
                

                <IconButton onClick={()=>{

                    if(state.startDate === "" && state.endDate === "" && state.userFilter === "" && state.catalogFilter === ""){
                        showAlert("error", "Debes seleccionar por lo menos un filtro")
                    }else{
                        if(Date.parse(state.startDate) > Date.parse(state.endDate)){ 
                            setState({...state, historialFiltered:state.historial, userFilter:"",catalogFilter:"",startDate:"", endDate:""})
                            showAlert("error", "Fecha Hasta no pude ser menor a Desde ")
                        
                        }else{
                            closeAlert();
                            getFilter();
                        }
                    }
                        
                 
                }}>
                    <Search size={28} className="cursor-pointer" color="#0a5cbe"/>
                </IconButton>

                <IconButton onClick={()=>{

                    setState({...state,  
                    historialFiltered:state.historial,
                    countF:state.count,
                    userFilter:"",
                    catalogFilter:"",
                    startDate:"",
                    endDate:""})
                    }}>
                <Trash size={28} className="cursor-pointer" color="tomato"/>
                </IconButton>

                </div>
               
            </div>
            </Form>
            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
              

            <HistorialEdicionesRecords></HistorialEdicionesRecords>

            </div>

           
        </main>
    )}

}


export default HistorialEdicionView;