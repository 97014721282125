import React, { createContext, useState,useEffect,useContext } from "react"
import {dependentService, recordsService} from '../services/api' 
import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";


export const TipoAnclasContext  = createContext();
export const TipoAnclasProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)
    const [state, setState] = useState({
        anclas:[],
        anclasF:[],
        projects:[],
        filter:10,
        search: "",
        loading:false
    });

    useEffect(async () =>{
        if(state.anclas.length === 0 || state.projects.length === 0){
           
                setState({...state, loading:true});
                var res = await recordsService("GET","anchorTypes")
                var proj = await dependentService("GET","projects");
              

                if(res.status && proj.status){
                    setState({...state, loading:false,anclas:res.data,projects:proj.data, anclasF:res.data});
                }else{
                    if(res.code == 401){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
            
        }
    },[])
    
    return(
        <TipoAnclasContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </TipoAnclasContext.Provider>

    )
}






