import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService} from '../services/api' 


import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";



export const UbicacionesContext  = createContext();
export const UbicacionesProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)


    const [state, setState] = useState({
        ubicaciones:[],
        ubicacionesF:[],
        filter:10,
        search: "",
        loading:false
    });

    useEffect(async () =>{
        if(state.ubicaciones.length === 0){
            setState({...state, loading:true});
            var ubiRes = await recordsService("GET","locations");

            if(ubiRes.status){
                setState({...state, loading:false,ubicaciones:ubiRes.data, ubicacionesF:ubiRes.data});
            }else{
                if(ubiRes.code == 401 || ubiRes.code == 500){
                    window.localStorage.clear();
                    setUser({});
                    auth.setMsg(true)
                    auth.signout();
                }
            }
        }
    },[])
    
    return(
        <UbicacionesContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </UbicacionesContext.Provider>

    )
}






