import React, {useState, useEffect} from 'react';
import Switch from '@material-ui/core/Switch';


import { withStyles } from '@material-ui/core/styles';
import { purple, red, green, grey } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const PurpleSwitch = withStyles({
    switchBase: {
      color: grey[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

function Switchy(props){

    const [checked, setChecked] = useState(props.value);
    // const {user, setUser} = useContext(UserContext)

    useEffect(() => {
      setChecked(props.value)
    }, [props.value])

    return <PurpleSwitch disabled={props.disabled} checked={checked} onChange={(event)=>{
  
        setChecked(event.target.checked)
        props.onChange(event.target.checked)

    }}  color="default" /> 

}

export default Switchy;