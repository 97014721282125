import React,{useState, useEffect, useContext} from 'react'
import {PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import  {services, getUsersList} from "../../services/api"
import {message} from "../capturaUtils"
import { TurnContent, EquiposContent, UsersTodayContent} from "../capturaUtils/CapturaInputs"
import { useParams } from 'react-router-dom'

function GeneralRow({generalData, handleNewOperation,showAlert, context}) {
    const [editing, setEditing] = useState(false)
    const [ item, setItem ] = useState(generalData)
    const [usersToday, setUsersToday] = useState([])
    let { id } = useParams();

    useEffect(()=>{
        setItem(generalData)
    },[generalData])

    useEffect(()=>{
        if(typeof item.id == 'string'){
            setEditing(true)
        }
    })

    useEffect(async()=>{
        var usersToday = await getUsersList(id, item.date)
        setUsersToday(usersToday.data.data)
    },[])
    
    const handleGeneralItemChange = ((e)=>{
        const value = e.target.value
        setItem({
            ...item,
            [e.target.name]: value
          });
    })

    const handlePutReq =  (async(itemId)=>{
        setEditing(false)
        const res = await services("PUT" , "general_forms" ,  item, itemId)
        if(res.status == true){
            setItem(res.data)
            showAlert("success",`Listo, datos guardados correctamente`)
        }else{
            console.log(res)
            showAlert("error",`Error al guardar sus datos.Compruebe sus datos e intente de nuevo.`)
            setEditing(true)
        }
    })

    const handleEditing = (async()=>{
        setEditing(true)
    })

  return (
    <tr>
        <td>
            <select  id="" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="machinarie_id" value={item.machinarie_id} required >
                <EquiposContent equipos={context.equipos}/>
            </select>
        </td>
        <td>
            <select  id="" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="turn" value={item.turn} required >
                <TurnContent/>
            </select>
        </td>
        <td>
            <input type="text" className="form-control large-input" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="folio" value={item.folio} required />
        </td>
        <td>
            <input type="date" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="date" value={item.date} required />
        </td>
        <td>
            <select  id="" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="operator" value={item.operator} required >
                <UsersTodayContent usersToday={usersToday}/>
            </select>
        </td>
        <td>
            <select  id="" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="auxiliar" value={item.auxiliar} required>
                <UsersTodayContent usersToday={usersToday}/>
            </select>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="hour_start" value={item.hour_start} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="hour_end" value={item.hour_end} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="diesel_hr_i" value={item.diesel_hr_i} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="diesel_hr_f" value={item.diesel_hr_f} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="electronic_hr_i" value={item.electronic_hr_i} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="electronic_hr_f" value={item.electronic_hr_f} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="percussion_hr_i" value={item.percussion_hr_i} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="percussion_hr_f" value={item.percussion_hr_f} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="reparation_hours" value={item.reparation_hours} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="satellite_hours" value={item.satellite_hours} required/>
        </td>
        <td>
            <input type="number" className="form-control" onChange={(e) => handleGeneralItemChange(e, item.id)} name="worked_hours" value={(item.electronic_hr_f - item.electronic_hr_i) + (item.diesel_hr_f - item.diesel_hr_i) } required readOnly/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="effective_hours" value={item.effective_hours} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="dead_hours" value={item.dead_hours} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="charge_fuel" value={item.charge_fuel} required/>
        </td>
        <td>
            <input type="number" className="form-control" readOnly={!editing} onChange={(e) => handleGeneralItemChange(e, item.id)} name="hr_charge_fuel" value={item.hr_charge_fuel} required/>
        </td>
        <td className='text-center d-flex'>
            <span className={ typeof item.id == 'string' ? "d-none" : "d-flex"}>
                {editing ? <button className='btn btn-primary mx-2 text-nowrap' onClick={() => {if(window.confirm(message)){handlePutReq(item.id)}}}>Guardar Cambio</button> : <PencilSquare size="25" color="#606060" className='cursor-pointer mx-2' onClick={handleEditing} />}
                <PlusCircleFill size="25" color="#0a5cbe" className='cursor-pointer mx-2' onClick={()=>handleNewOperation(item.id)}/>
            </span>
        </td>
    </tr>
  )
}

export default GeneralRow