import React, {useState, useEffect, useContext} from 'react'
import  {recordsServiceId, dependentService, dependentServiceId, recordsService, services,} from "../services/api"//
import { Form, Col, Button } from 'react-bootstrap';
import {PlusCircleFill, ArrowLeftSquareFill } from 'react-bootstrap-icons';
import { useHistory, useParams,useRouteMatch, Link } from 'react-router-dom';
import { ButtonGroup, IconButton } from '@material-ui/core'
import GeneralTableDirectory from "./GeneralTableDirectory"
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import context from 'react-bootstrap/esm/AccordionContext';
import GeneralRow from "./capturaUtils/GeneralRow"
import SoporteObraRow from './capturaUtils/SoporteObraRow'
import {handleNewRow, handleInputChange, handleDateFilter, handlePost, handleDeleteUnsaved, handleObraRecordsAutofill } from "./capturaUtils"
import BackToProjectsButton from './capturaUtils/BackToProjectsButton';
import { TurnContent, StatusContent, EquiposContent , SectoresContent, RmrContent, BarsContent, ZonasContent, UsersTodayContent, ObrasContent} from "./capturaUtils/CapturaInputs"

function SoporteObraRecords({generalContent, operationContent, context}) {
    
    
    let { id } = useParams();
    let history = useHistory();
    const [validated, setValidated] = useState(false);
    const [generalItems, setGeneralItems] = useState(generalContent)
    const [operationItems, setOperationItems] = useState(operationContent)
    const [dateRange, setDateRange] = useState(['0001-01-01','9999-01-01'])
    const {alert, showAlert, closeAlert} = useContext(AlertContext)
    const [generalPost, setGeneralPost] = useState([])
    const [operationPost, setOperationPost] = useState([])

    useEffect(()=>{
        setGeneralItems(generalContent)
        setOperationItems(operationContent)
        console.log(context)
    },[generalContent, operationContent])

    const handleNewOperation = (itemId) =>{
        const operationFormCount = operationPost.filter((item)=>{
            return item.folio == itemId
        })

        if(operationFormCount.length >=15){
            showAlert("error", "Número máximo de formularios de operación alcanzados")
        }else{
            const body ={
                id:`newId${Math.floor(Math.random() * (100000 - 0 + 1))}`,
                project_id: id,
                barr_servi: 0,
                estatus: "Pendiente",
                folio: itemId,
                hora_final: "00:00:00",
                hora_inicial: "00:00:00",
                obra: "obra 1",
                observaciones: "",
                rmr: "RMR 1",
                seccion_ancho: `${0}`,
                seccion_largo: `${0}`,
                seccion_real_ancho: `${0}`,
                seccion_real_largo: `${0}`,
                zona: "zona 1",
                anclas:[
                    {
                        "ancla": "ancla",
                        "cantidad": "0"
                    }
                ]
            }
    
            setOperationPost([...operationPost,...[body]])
        }
    }

    
    const postGenerals =()=>{
        handlePost(generalPost, "general_forms").then((data) => {
            console.log(data)
            const errorData = data.filter((item)=>{
                return item.status == false
            })
    
            if(errorData.length > 0){
                showAlert("error",`Error al guardar algunas filas.Compruebe sus datos e intente de nuevo.`)
            }else{
                showAlert("success",`Listo! , Registros guardados exitosamente`)
            }
    
            const successData = data.filter((item)=>{
                return item.status == true
            }).map((i)=>{
                return i.data
            })
    
            const itemsToRemove = successData.map((item)=>{
                return item.folio
            })
    
            const notSavedItems = generalPost.filter(e => !itemsToRemove.includes(e.folio))
            setGeneralPost(notSavedItems)
            setGeneralItems([...successData,...generalItems])
        })  
    } 

    

    const postOperations = () =>{
        handlePost(operationPost, "work_support_forms").then((data) => {
            console.log(data)
            const errorData = data.filter((item)=>{
                return item.status == false
            })

            if(errorData.length > 0){
                showAlert("error",`Error al guardar algunas filas.Compruebe sus datos e intente de nuevo.`)
            }else{
                showAlert("success",`Listo! , Registros guardados exitosamente`)
            }

            const successData = data.filter((item)=>{
                return item.status == true
            }).map((i)=>{
                return i.data
            })

            const itemsToRemove = successData.map((item)=>{
                return item.folio
            })

            const notSavedItems = operationPost.filter(e => !itemsToRemove.includes(e.folio))
            setOperationPost(notSavedItems)
            setOperationItems([...operationItems,...successData])
        })
    }

    const handleSubmit = ((e)=>{
        e.preventDefault()
        if(generalPost.length > 0){
            postGenerals()
        }
        if(operationPost.length > 0){
            postOperations()
        }
    })

    
  return (
    <>
            <BackToProjectsButton project={context.project_name}/>
            <Form onSubmit={(e)=> handleDateFilter(e,generalContent, setGeneralItems,setDateRange)}>
                <div className="row align-items-end mb-2">
                    <div className="col-12 col-md-3">
                        <div className="form-group d-flex align-items-center">
                            <label className='mr-3 mb-0' htmlFor="">Desde</label>
                            <input type="date" className="form-control" id="date-start-input" required/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                    <div className="form-group d-flex align-items-center">
                            <label className="mr-3 mb-0" htmlFor="">Hasta</label>
                            <input type="date" className="form-control" id="date-end-input" required/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <Button className='primary mb-3 mr-2' type="submit">Aplicar  filtro</Button>
                        <Button variant="light" className='border mb-3' onClick={()=> setGeneralItems(generalContent)}>Deshacer filtro</Button>
                    </div>
                </div>
            </Form>
            <div className="d-flex justify-content-between mx-3 my-5">
                <GeneralTableDirectory/>
                <div className='d-flex'>
                    <button className="text-nowrap btn btn-primary mr-2 ml-5" type="submit" form="general-form" value="Update"> Guardar Nuevos Registros</button>
                    <button className="text-nowrap btn btn-light border" onClick={()=>handleNewRow(id, generalPost, setGeneralPost, 8)}>+ Añadir nuevo registro</button>
                </div>
            </div>
            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
            <div className='w-100 overflow-auto general-form-container flex-column flex-1'>
            <Form className="" noValidate validated={validated} id="general-form" onSubmit={(e)=>handleSubmit(e)}>
                <table className='proyect-table'>
                    <tr>
                        <th>Equipos</th>
                        <th>Turno</th>
                        <th>Folio</th>
                        <th>Fecha</th>
                        <th>Operador</th>
                        <th>Ayudante</th>
                        <th>Hora Inicio jornada</th>
                        <th>Hora fin jornada</th>
                        <th>Diesel HR.i</th>
                        <th>Diesel HR.F</th>
                        <th>Eléctrico HR.i</th>
                        <th>Eléctrico HR.f</th>
                        <th>Percusión HR.i</th>
                        <th>Percusión HR.f</th>
                        <th>Horas Reparación</th>
                        <th>Horas satelitales</th>
                        <th>Horas trabajadas</th>
                        <th>Horas efectivas</th>
                        <th>Horas muertas</th>
                        <th>Carga de combustibles</th>
                        <th>Horómetro de carga</th>
                        <th>Acciones</th>
                        {/*  */}
                        <th>Obra</th>
                        <th>Sector</th>
                        <th>Zona</th>
                        <th>Seccción largo</th>
                        <th>Seccción ancho</th>
                        <th>Seccción largo real</th>
                        <th>Seccción ancho real</th>
                        <th>RMR</th>
                        <th>Anclas</th>
                        <th>Barrenos de servicio</th>
                        <th>Hora inicial</th>
                        <th>Hora final</th>
                        <th>Estatus</th>
                        <th>Observaciones</th>
                        <th>Acciones</th>
                    </tr>
                        {generalPost.map((item)=>(
                            <tr>
                                <td>
                                    <select  id="" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost )} name="machinarie_id" value={item.machinarie_id} required >
                                        <EquiposContent equipos={context.equipos}/>
                                    </select>
                                </td>
                                <td>
                                    <select  id="" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost )} name="turn" value={item.turn} required >
                                        <TurnContent/>
                                    </select>
                                </td>
                                <td>
                                    <input type="text" className="form-control large-input"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="folio" value={item.folio} required />
                                </td>
                                <td>
                                    <input type="date" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="date" value={item.date} required />
                                </td>
                                <td>
                                    <select  id="" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="operator" value={item.operator} required >
                                        <UsersTodayContent usersToday={context.usersToday}/>
                                    </select>
                                </td>
                                <td>
                                    <select  id="" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="auxiliar" value={item.auxiliar} required>
                                        <UsersTodayContent usersToday={context.usersToday}/>
                                    </select>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="hour_start" value={item.hour_start} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="hour_end" value={item.hour_end} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="diesel_hr_i" value={item.diesel_hr_i} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="diesel_hr_f" value={item.diesel_hr_f} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="electronic_hr_i" value={item.electronic_hr_i} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="electronic_hr_f" value={item.electronic_hr_f} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="percussions_hr_i" value={item.percussion_hr_i} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="percussion_hr_f" value={item.percussion_hr_f} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="reparation_hours" value={item.reparation_hours} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="satellite_hours" value={item.satellite_hours} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="worked_hours" value={(item.electronic_hr_f - item.electronic_hr_i) + (item.diesel_hr_f - item.diesel_hr_i) } required readOnly/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="effective_hours" value={item.effective_hours} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="dead_hours" value={item.dead_hours} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="charge_fuel" value={item.charge_fuel} required/>
                                </td>
                                <td>
                                    <input type="number" className="form-control"  onChange={(e) => handleInputChange(e, item.id, generalPost, setGeneralPost)} name="hr_charge_fuel" value={item.hr_charge_fuel} required/>
                                </td>
                                <td>
                                    <div className="btn btn-danger" onClick={()=>handleDeleteUnsaved(item.id, generalPost, setGeneralPost)}>Borrar</div>
                                </td>
                            </tr>
                        ))}
                        {generalItems.map((item)=>(
                            <>
                            <GeneralRow generalData={item} handleNewOperation={handleNewOperation} context={context} showAlert={showAlert}/>
                                {operationPost.filter(operationItem =>operationItem.folio == item.id ).map(f => (
                                    <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <select id=""  className="form-control" name="obra" value={f.obra} required onChange={(e)=>{
                                                handleInputChange(e, f.id, operationPost, setOperationPost)
                                                handleObraRecordsAutofill(e, f.id, context, operationPost, setOperationPost)
                                            }} >
                                            <ObrasContent obras={context.generalObras.data}/>
                                        </select>
                                    </td>
                                    <td>
                                        <select type="text" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="sector" value={f.sector} required>
                                            <SectoresContent sectores={context.sectores}/>
                                        </select>
                                    </td>
                                    <td>
                                        <select type="text" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="zona" value={f.zona} required>
                                            <ZonasContent zonas={context.zonasListado}/>
                                        </select>
                                    </td>
                                    <td >
                                        <input type="number" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="seccion_largo" value={f.seccion_largo} required/>
                                    </td>
                                    <td >
                                        <input type="number" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="seccion_ancho" value={f.seccion_ancho} required/>
                                    </td>
                                    <td >
                                        <input type="number" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="seccion_real_largo" value={f.seccion_real_largo} required/>
                                    </td>
                                    <td >
                                        <input type="number" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="seccion_real_ancho" value={f.seccion_real_ancho} required/>
                                    </td>
                                    <td>
                                        <select type="text" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="rmr" value={f.rmr} required>
                                            <RmrContent rmr={context.rmr}/>
                                        </select>
                                    </td>
                                    <td className="d-flex">
                                        {f.anclas ? f.anclas.map((anclasItem)=>(
                                            <span>
                                                <label>{anclasItem.ancla}</label>
                                                <input type="text" className='mr-2 form-control' onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)} name="anclas" value={anclasItem.cantidad} disabled/>
                                            </span>
                                        )) : ""}
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="barr_servi" value={f.barr_servi} required/>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="hora_inicial" value={f.hora_inicial} required/>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="hora_final" value={f.hora_final} required/>
                                    </td>
                                    <td>
                                        <select type="text" className="form-control" onChange={(e)=>handleInputChange(e, f.id, operationPost, setOperationPost)} name="estatus" value={f.estatus} required>
                                            <StatusContent/>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" onChange={(e) => handleInputChange(e, f.id, operationPost, setOperationPost)}  name="observaciones" value={f.observaciones} required/>
                                    </td>
                                    <td>
                                    <div className="btn btn-danger" onClick={()=>handleDeleteUnsaved(f.id, operationPost, setOperationPost)}>Borrar</div>
                                    </td>
                                </tr>
                                ))} 
                                {operationItems.filter(operationItem =>operationItem.folio == item.id ).map(f => (
                                    <SoporteObraRow f={f} context={context} showAlert={showAlert}/>
                                ))} 
                            </>
                        ))}
                </table>
            </Form>
        </div>
        </>
  )
}

export default SoporteObraRecords