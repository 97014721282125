import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService} from '../services/api' 


import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";



export const ProyectosContext  = createContext();
export const ProyectosProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)


    const [state, setState] = useState({
        projects:[],
        projectsF:[],
        filter:10,
        search: "",
        loading:false
    });

    useEffect(async () =>{
        
        if(state.projects.length === 0){
            setState({...state, loading:true});
            var res = await recordsService("GET","projects");

            if(res.status){
                setState({...state, loading:false,projects:res.data, projectsF:res.data});
            }else{
                if(res.code == 401 || res.code == 500){
                    window.localStorage.clear();
                    setUser({});
                    auth.setMsg(true)
                    auth.signout();
                }
            }
        }
    },[])
    
    return(
        <ProyectosContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </ProyectosContext.Provider>

    )
}






