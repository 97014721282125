import { makeStyles } from '@material-ui/core/styles'
import { useContext, useEffect, useState } from 'react'
import { PlaneacionContext } from '../../context/planeacion_context'
import {formatDate} from '../../utils/utils'
import SingleRow from './fragments/SingleRow'

const columns = [
    {id:'obra',label:'Nombre de la Obra',minWidth:170},
    {id:'editar', label:'Acciones', minWidth:170}
]

const useStyles = makeStyles({
    root : {
        width: '100%'
    }
})

export default function PlaneacionMetrosRecords(props) {
    const [dynamicColumns,setDynamicColumns] = useState(columns)
    const {state,setState} = useContext(PlaneacionContext)
    const classes = useStyles()
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)

    const handleChangePage = (event, newPage) =>{
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) =>{
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    useEffect(()=>{
        let newCols = [...columns]
        if(state.fechaStart!=='' && state.fechaEnd!==''){
            let inicio = new Date(state.fechaStart)
            inicio.setHours(inicio.getHours()+6)
            let final = new Date(state.fechaEnd)
            final.setHours(final.getHours()+6)
            let loop = new Date(final)

            while(loop>=inicio){
                let date = formatDate(loop)
                newCols.splice(1,0,{id:'date'+date,label:date,minWidth:170})
                let newDate = loop.setDate(loop.getDate()-1)
                loop = new Date(newDate)
            }
        }else{
            // setState({...state,metrosF:[]})
        }
        setDynamicColumns(newCols)
    },[state.metrosF,state.update])

    return(
        <div className='overflow-auto w-auto'>
            <div className='bg-primary d-flex container justify-content-around w-auto'>
                {dynamicColumns.map(column=>{
                    return(
                        <div className='row' style={{width:'auto'}}>
                            <h6 className='w-100'>{column.label}</h6>
                            <h6>{column.id.includes('date')? 'ML - M3' : ''}</h6>
                        </div>
                    )
                })}
            </div>
            <div className='bg-light container justify-content-around'>
                {state.metrosF.map(row=>{
                    return <SingleRow row={row} dynamicColumns={dynamicColumns}/>
                })}
                
            </div>
        </div>
    )
}

