import React, { createContext, useState,useEffect, useLayoutEffect, createRef } from 'react'
import { useLocation } from 'react-router';

export const AlertContext = createContext();


export const AlertContextProvider = ({children}) => {

    const [alert, setAlert] = useState({
        message:"",
        type:"success",
        show:false,
        open:true
    });

    let location = useLocation();
 



    const closeAlert = () => {
        setAlert({
            ...alert,
            message:"",
            type:"success",
            show:false
        })
    }
    const showAlert = (type, message) => {
        setAlert({
            ...alert,
            message,
            type,
            show:true
        })
        setTimeout(()=>{
       /*      if(alert.show == true){ */
                closeAlert()
            
               
        }, 4000)
    }

    useLayoutEffect(() => {
     
            setLayout();
        
        
    }, [location.pathname])

    const setLayout = () => {

        var menu = document.getElementById("sidebarMenu")
        var main = document.querySelector("main")
        var header =  document.querySelector(".header")
     

        if(main != null){
            if(!alert.open){
                menu.classList.add("w-0", "overflow-hidden");
                main.classList.add("menu_collapsed");
                header.classList.add("menu_collapsed"); 
            }else{
                menu.classList.remove("w-0", "overflow-hidden");
                main.classList.remove("menu_collapsed")
                header.classList.remove("menu_collapsed")
            }
        }


}




    return(
        <AlertContext.Provider value={{
            alert,
            showAlert,
            closeAlert,
            setAlert,
            setLayout,
          

        }}>
            {children}
        </AlertContext.Provider>
    )

    
}



