import React,{useContext,useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Link, useRouteMatch } from 'react-router-dom';
import { ListaContext } from '../../context/lista_context';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { services } from '../../services/api';
import { replaceObj } from '../../utils/utils';

const columns = [

  { id:'user_id', label:'No.', minWidth: 50 },
  { id:'name', label:'Nombre', minWidth: 150 },
  { id:'category', label:'Categoria', minWidth: 200 },
  { id:'monday', label:'Lunes', minWidth: 120 },
  { id:'tuesday', label:'Martes', minWidth: 120 },
  { id:'wednesday', label:'Miercoles', minWidth: 120 },
  { id:'thursday', label:'Jueves', minWidth: 120 },
  { id:'friday', label:'Viernes', minWidth: 120 },
  { id:'saturday', label:'Sabado', minWidth: 120 },
  { id:'sunday', label:'Domingo', minWidth: 120 },
  { id:'A', label:'Asistencia', minWidth: 150 },
  { id:'F', label:'Falta', minWidth: 150 },
  { id:'D', label:'Descanso', minWidth: 150 },
  { id:'I', label:'Incapacidad', minWidth: 150 },
  { id:'V', label:'Vacaciones', minWidth: 150 },
  { id:'BS', label:'Baja Sustitución', minWidth: 150 },
  { id:'BT', label:'Baja Pro. Terminado', minWidth: 150 },
  { id:'DIF', label:'Diferencia', minWidth: 150 },
   
];

const columnsTotals = [

 
  { id:'monday', label:'Lunes', minWidth: 120 },
  { id:'tuesday', label:'Martes', minWidth: 120 },
  { id:'wednesday', label:'Miercoles', minWidth: 120 },
  { id:'thursday', label:'Jueves', minWidth: 120 },
  { id:'friday', label:'Viernes', minWidth: 120 },
  { id:'saturday', label:'Sabado', minWidth: 120 },
  { id:'sunday', label:'Domingo', minWidth: 120 },
  { id:'A', label:'Asistencia', minWidth: 150 },
  { id:'F', label:'Falta', minWidth: 150 },
  { id:'D', label:'Descanso', minWidth: 150 },
  { id:'I', label:'Incapacidad', minWidth: 150 },
  { id:'V', label:'Vacaciones', minWidth: 150 },
  { id:'BS', label:'Baja Sustitución', minWidth: 150 },
  { id:'BT', label:'Baja Pro. Terminado', minWidth: 150 },
  { id:'DIF', label:'Diferencia', minWidth: 150 },
   
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});




function ListaDetalleRecords(props) {

  const {stateLista, setStateLista} = useContext(ListaContext)
  const [stateRecords, setStateRecord] = React.useState({});

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { path, url } = useRouteMatch();

  useEffect(() => {
      
   //let totals = dayTotals(stateLista.usersF)
  

  }, [stateLista.usersF])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

//   const handleSwitch = async (data,id, val)=>{
//     var res;
//     if(!val){
//       res = await services("DELETE","rock_indices",undefined,id);
//     }else{
//       res = await services("GET","rock_indices",undefined,id);
//     }
//     if(res.status){
//       var listas = data
//       var lista = selectFromObjbyId(data, id);
//       lista.deleted_at = res.data.deleted_at;
//       setStateListas({...stateListas, listas })
//     }  
//   }




  const assistTotals = (user) => {


    let weekAssit = {}
    weekAssit.a = 0;
    weekAssit.f = 0;
    weekAssit.d = 0;
    weekAssit.i = 0;
    weekAssit.v = 0;
    weekAssit.bs = 0;
    weekAssit.bt = 0;
    weekAssit.dif = 7;

    var assistDays = ["sunday","monday","tuesday","wednesday","thursday","friday", "saturday"]
    for (const key in user) {

      if(assistDays.includes(key)){

        switch (user[key]) {
          case "Asistencia":
            weekAssit.a = weekAssit.a + 1;
            break;
          case "Falta":
            weekAssit.f = weekAssit.f + 1;
            weekAssit.dif = weekAssit.dif - 1;
            break;
          case "Descanso":
            weekAssit.d = weekAssit.d + 1;
            break;
          case "Incapacidad":
            weekAssit.i = weekAssit.i + 1;
            break;
          case "Vacaciones":
            weekAssit.v = weekAssit.v + 1;
            break;
          case "Baja sustitucion":
            weekAssit.bs = weekAssit.bs + 1;
            break;
          case "Baja Proy. Terminado":
            weekAssit.bt = weekAssit.bt + 1;
            break;
  
          default:
            break;
        }

      }

    }
    return weekAssit
  }



  const resume = (day,value) =>{
    
    let total = 0;
    stateLista.users.forEach(user => {
      for (const key in user) {
        if(key === day && user[key] === value){
          total = total + 1;
        }
      }
    });

    let percent = (total / stateLista.users.length) * 100
    
    if(stateLista.users.length == 0){
      return (
        <div>
          0
          <br></br>
          0% 
        </div>
      )
    }
    return (
      <div>
        {total}
        <br></br>
        {percent !== NaN ? percent : 0}% 
      </div>
    )
 
  }

  const resumeWeek = (value) =>{

    let total = 0;
    stateLista.users.forEach(user => {
      for (const key in user) {
        if(user[key] === value){
          total = total + 1;
        }
      }
    });




    let percent = (total / (stateLista.users.length * 7) ) * 100
    
    if(stateLista.users.length == 0){
      return (
        <div>
          0
          <br></br>
          0% 
        </div>
      )
    }
    return (
      <div>
        {total}
        <br></br>
        {percent !== NaN ? percent.toFixed(2) : 0}% 
      </div>
    )
  }
  const weekDif = () =>{

    let total = 0;
    stateLista.users.forEach(user => {
      for (const key in user) {
        if(user[key] === "Falta"){
          total = total + 1;
        }
      }
    });




    let percent = (((stateLista.users.length * 7) - total) / (stateLista.users.length * 7) ) * 100
    
    if(stateLista.users.length == 0){
      return (
        <div>
          0
          <br></br>
          0% 
        </div>
      )
    }
    return (
      <div>
        {(stateLista.users.length * 7) - total}
        <br></br>
        {percent !== NaN ? percent.toFixed(2) : 0}% 
      </div>
    )
    
  }





  const styles = { 
    paddingBottom:10,
    paddingTop:10,
    paddingLeft:5,
    paddingRight:10,
    lineHeight:1.2,
    textAlign:"center"
   }

  return (
    <>
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {

                let bgColor;
                let color;
                let assistValues = ["A","F","D","I","V","BS","BT","DIF"]
                column.id === "A" ? bgColor = "#6B99D0" :
                column.id === "F" ? bgColor = "#6BD06B" :
                column.id === "D" ? bgColor = "#6BD0A4" :
                column.id === "I" ? bgColor = "#D8D05D" :
                column.id === "V" ? bgColor = "#A0B1C0" :
                column.id === "BS" ? bgColor = "#F57A45" :
                column.id === "BT" ? bgColor = "#E25757" :
                column.id === "DIF" ? bgColor = "#000000"
                : bgColor= "";

                column.id === "A" ? color = "#FFFFFF" : color= "";
                return (
                  <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2,
                    minWidth: column.minWidth,
                    backgroundColor:bgColor,
                    color: assistValues.includes(column.id) ? "white": "",
                    textAlign: column.id !== "user_id" && column.id !== "name" && column.id !== "category"  ? "center" : "left"
                    
                  }}

                >
                  {column.label}
                </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {stateLista.usersF.map((row,i) => {

            let active = row.deleted_at === null ? 1 : 0;
            let switchActive = row.deleted_at === null ? true : false;
            let assistValues = ["A","F","D","I","V","BS","BT","DIF"]
         
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                        
                          padding:5,
                        
                          textAlign:assistValues.includes(column.id) ? "center" : ""

                         }}>
                          {
                            
                            column.id === "sunday" ?
                            <AsistenciaDrop value={value} user={row} day="sunday" />
                            :
                            column.id === "monday" ?
                            <AsistenciaDrop value={value} user={row} day="monday" />
                            :
                            column.id === "tuesday" ?
                            <AsistenciaDrop value={value} user={row} day="tuesday" />
                            :
                            column.id === "wednesday" ?
                            <AsistenciaDrop value={value} user={row} day="wednesday" />
                            :
                            column.id === "thursday" ?
                            <AsistenciaDrop value={value} user={row} day="thursday" />
                            :
                            column.id === "friday" ?
                            <AsistenciaDrop value={value} user={row} day="friday" />
                            :
                            column.id === "saturday" ?
                            <AsistenciaDrop value={value} user={row} day="saturday" />
                            :
                            column.id === "A" ?
                            assistTotals(row).a
                            :
                            column.id === "F" ?
                            assistTotals(row).f
                            :
                            column.id === "D" ?
                            assistTotals(row).d
                            :
                            column.id === "I" ?
                            assistTotals(row).i
                            :
                            column.id === "V" ?
                            assistTotals(row).v
                            :
                            column.id === "BS" ?
                            assistTotals(row).bs
                            :
                            column.id === "BT" ?
                            assistTotals(row).bt
                            :
                            column.id === "DIF" ?
                            assistTotals(row).dif
                            :
                            value
                          }
                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
              
            })}
          </TableBody>
         
          
          <TableHead>
            <tr className="h-xs">
              
            </tr>
            <tr className="">
              <td></td>
              <td></td>
              <td></td>
              <td colSpan="7"  align="center"  width="100%" className="border font-weight-bold">
                DIARIO
              </td>
              <td colSpan="8" align="center" width="100%" className="border font-weight-bold">
                SEMANA
              </td>
            </tr>

            <TableRow>
              <td></td>
              <td></td>
              <td></td>
              {columnsTotals.map((column, i) => {

                let bgColor;
                let color;
                let assistValues = ["A","F","D","I","V","BS","BT","DIF"]
                column.id === "A" ? bgColor = "#6B99D0" :
                column.id === "F" ? bgColor = "#6BD06B" :
                column.id === "D" ? bgColor = "#6BD0A4" :
                column.id === "I" ? bgColor = "#D8D05D" :
                column.id === "V" ? bgColor = "#A0B1C0" :
                column.id === "BS" ? bgColor = "#F57A45" :
                column.id === "BT" ? bgColor = "#E25757" :
                column.id === "DIF" ? bgColor = "#000000":
                column.id === "" ? bgColor = "#FFFFFF" 
           
                : bgColor= "black";

                // column.id === "A" ? color = "#FFFFFF" : color= "";
                return (
                  <TableCell
                  key={i}
                  align={column.align}
                  style={{
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2,
                    minWidth: column.minWidth,
                    backgroundColor:bgColor,
                    color: column.id !== "" ? "white" : "",
                    textAlign:"center"
                    
                  }}

                >
                  {column.label}
                </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>


            <TableRow>
              <td></td>
              <td></td>
              <TableCell style={{backgroundColor:"#6B99D0", color:"white"}}>Asistencia</TableCell>
              <TableCell style={styles}>{resume("sunday","Asistencia")}</TableCell>
              <TableCell style={styles}>{resume("monday","Asistencia")}</TableCell>
              <TableCell style={styles}>{resume("tuesday","Asistencia")}</TableCell>
              <TableCell style={styles}>{resume("wednesday","Asistencia")}</TableCell>
              <TableCell style={styles}>{resume("thursday","Asistencia")}</TableCell>
              <TableCell style={styles}>{resume("friday","Asistencia")}</TableCell>
              <TableCell style={styles}>{resume("saturday","Asistencia")}</TableCell>
              <TableCell style={styles}>{resumeWeek("Asistencia")}</TableCell>
              <TableCell style={styles}>{resumeWeek("Falta")}</TableCell>
              <TableCell style={styles}>{resumeWeek("Descanso")}</TableCell>
              <TableCell style={styles}>{resumeWeek("Incapacidad")}</TableCell>
              <TableCell style={styles}>{resumeWeek("Vacaciones")}</TableCell>
              <TableCell style={styles}>{resumeWeek("Baja Sustitucion")}</TableCell>
              <TableCell style={styles}>{resumeWeek("Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}>{weekDif()}</TableCell>
            </TableRow>

            <TableRow>
              <td></td>
              <td></td>
              <TableCell style={{backgroundColor:"#6BD06B", color:"white"}}>Falta</TableCell>
              <TableCell style={styles}>{resume("sunday","Falta")}</TableCell>
              <TableCell style={styles}>{resume("monday","Falta")}</TableCell>
              <TableCell style={styles}>{resume("tuesday","Falta")}</TableCell>
              <TableCell style={styles}>{resume("wednesday","Falta")}</TableCell>
              <TableCell style={styles}>{resume("thursday","Falta")}</TableCell>
              <TableCell style={styles}>{resume("friday","Falta")}</TableCell>
              <TableCell style={styles}>{resume("saturday","Falta")}</TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
            </TableRow>

            <TableRow>
              <td></td>
              <td></td>
              <TableCell style={{backgroundColor:"#6BD0A4", color:"white"}}>Descanso</TableCell>
              <TableCell style={styles}>{resume("sunday","Descanso")}</TableCell>
              <TableCell style={styles}>{resume("monday","Descanso")}</TableCell>
              <TableCell style={styles}>{resume("tuesday","Descanso")}</TableCell>
              <TableCell style={styles}>{resume("wednesday","Descanso")}</TableCell>
              <TableCell style={styles}>{resume("thursday","Descanso")}</TableCell>
              <TableCell style={styles}>{resume("friday","Descanso")}</TableCell>
              <TableCell style={styles}>{resume("saturday","Descanso")}</TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
            </TableRow>

            <TableRow>
              <td></td>
              <td></td>
              <TableCell style={{backgroundColor:"#D8D05D", color:"white"}}>Incapacidad</TableCell>
              <TableCell style={styles}>{resume("sunday","Incapacidad")}</TableCell>
              <TableCell style={styles}>{resume("monday","Incapacidad")}</TableCell>
              <TableCell style={styles}>{resume("tuesday","Incapacidad")}</TableCell>
              <TableCell style={styles}>{resume("wednesday","Incapacidad")}</TableCell>
              <TableCell style={styles}>{resume("thursday","Incapacidad")}</TableCell>
              <TableCell style={styles}>{resume("friday","Incapacidad")}</TableCell>
              <TableCell style={styles}>{resume("saturday","Incapacidad")}</TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
            </TableRow>

            <TableRow>
              <td></td>
              <td></td>
              <TableCell style={{backgroundColor:"#A0B1C0", color:"white"}}>Vacaciones</TableCell>
              <TableCell style={styles}>{resume("sunday","Vacaciones")}</TableCell>
              <TableCell style={styles}>{resume("monday","Vacaciones")}</TableCell>
              <TableCell style={styles}>{resume("tuesday","Vacaciones")}</TableCell>
              <TableCell style={styles}>{resume("wednesday","Vacaciones")}</TableCell>
              <TableCell style={styles}>{resume("thursday","Vacaciones")}</TableCell>
              <TableCell style={styles}>{resume("friday","Vacaciones")}</TableCell>
              <TableCell style={styles}>{resume("saturday","Vacaciones")}</TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
            </TableRow>

        
            <TableRow>
              <td></td>
              <td></td>
              <TableCell style={{backgroundColor:"#F57A45", color:"white"}}>Baja Sustitucion</TableCell>
              <TableCell style={styles}>{resume("sunday","Baja Sustitucion")}</TableCell>
              <TableCell style={styles}>{resume("monday","Baja Sustitucion")}</TableCell>
              <TableCell style={styles}>{resume("tuesday","Baja Sustitucion")}</TableCell>
              <TableCell style={styles}>{resume("wednesday","Baja Sustitucion")}</TableCell>
              <TableCell style={styles}>{resume("thursday","Baja Sustitucion")}</TableCell>
              <TableCell style={styles}>{resume("friday","Baja Sustitucion")}</TableCell>
              <TableCell style={styles}>{resume("saturday","Baja Sustitucion")}</TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
            </TableRow>

            <TableRow>
              <td></td>
              <td></td>
              <TableCell style={{backgroundColor:"#E25757", color:"white"}}>Baja Proy. Terminado</TableCell>
              <TableCell style={styles}>{resume("sunday","Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}>{resume("monday","Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}>{resume("tuesday","Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}>{resume("wednesday","Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}>{resume("thursday","Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}>{resume("friday","Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}>{resume("saturday","Baja Proy. Terminado")}</TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
              <TableCell style={styles}></TableCell>
            </TableRow>


          </TableBody>
        
        </Table>
      </TableContainer>
      {/* <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={stateLista.usersF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      /> */}
    </Paper>

    
    


    </>
  );
}


function AsistenciaDrop(props){

  
  const {stateLista, setStateLista} = useContext(ListaContext);
  const [state, setState] = useState({
    loading:false,
    color:"red"
  })

  const getColor = (value) => {

    let color = ""
    switch (value) {
    case "Asistencia":
      color = "#6B99D0"
      break;
    case "Falta":
      color = "#6BD06B"
      break;
    case "Descanso":
      color = "#6BD0A4"
      break;
    case "Incapacidad":
      color = "#D8D05D"
      break;
    case "Vacaciones":
      color = "#A0B1C0"
      break;
    case "Baja Sustitucion":
      color = "#F57A45"
      break;
    case "Baja Proy. Terminado":
      color = "#E25757"
      break;
    
      default:
        color = "#cccccc"
        break;
    }
    return color;
  }



    return(
        
        <div className="position-relative">
         
            
          {state.loading ? 
            <div className="d-block mx-auto spinner-border position-absolute right spinner-border--small" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            :null
          }
         
          <Form.Control as="select" className="font-weight-bold" style={{color:getColor(props.value)}} defaultValue={props.value} onChange={ async (evt)=>{
              setState({...state, loading:true});
              let body = props.user;

              body[props.day] = evt.currentTarget.value;


              let res = await services("PUT","assist_users",body,body.id);
              

              var users = replaceObj(stateLista.users,body);
              var usersF = replaceObj(stateLista.usersF,body);

              setState({...state, loading:false});
              setStateLista({...stateLista, users, usersF});
            }}>
            <option value ="">Seleccinar</option>
            <option value ="Asistencia">Asistencia</option>
            <option value ="Falta">Falta</option>
            <option value ="Descanso">Descanso</option>
            <option value ="Incapacidad">Incapacidad</option>
            <option value ="Vacaciones">Vacaciones</option>
            <option value ="Baja Sustitucion">Baja sustitucion</option>
            <option value ="Baja Proy. Terminado">Baja Proy. Terminado</option>
          </Form.Control>
        </div>
    );
}

export default ListaDetalleRecords;