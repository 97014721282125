import { ButtonGroup, IconButton } from '@material-ui/core'
import React, {createContext, useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import { ArrowLeftSquareFill, Back, Person, Trash, BlockquoteLeft, Search, X, TrashFill, EmojiNeutralFill } from 'react-bootstrap-icons'

import { UserContext } from '../context/user_context'
import { Form, Col, Button, Row } from 'react-bootstrap';

import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { ProyectosContext } from '../context/proyectos_context';
import { hideLoader, removeFromObjbyId, replaceObj, selectFromObjbyId, selectFromObjbyItem, showLoader } from '../utils/utils';
import ViewDataLoader from '../components/ui/view_data_loader';
import { Doughnut } from 'react-chartjs-2';
import { dependentService, recordsService, recordsServiceId, services, singleService } from '../services/api';
import FormularioGeneral from '../components/formulario_general';
import DataListInput from 'react-datalist-input';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';

import { FormularioGeneralContext } from '../context/formulario_general_context';

const Context = createContext();
function RezagadoDetalleView() {

    

    let { project, id } = useParams();
    const { user } = useContext(UserContext);        
    const [proyecto, setProyecto] = useState(undefined);
    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)
    const {state,setState} = useContext(ProyectosContext);
    const [local, setLocal] = useState({
        forms:[], 
        form_id:null, 
        general_form:selectFromObjbyId(stateGenerales.generales,parseInt(id)),
        operation:{id:6, name:"REZAGADO"},})
    const {alert, showAlert, closeAlert} = useContext(AlertContext)



    let history = useHistory();
    let location = useLocation();
    const { path, url } = useRouteMatch();

    useEffect(async()=>{

        var forms = await dependentService("GET", "laggards_type_form")
      
        setLocal({...local,forms:forms.data.filter((el) => el.folio === parseInt(id)), form_id:id });

    },[])

    useEffect(()=>{
        
        let proyecto = selectFromObjbyId(state.projects,parseInt(project))
        if(proyecto === undefined){
        
            history.replace(`/home/proyectos` );
        }else{
            setProyecto(proyecto);
        }
        
    },[project]);


    // useEffect(()=>{

    //     setLocal({...local,form_id:id})

    // },[id])


  

    const onRemoveForm = (id = "nuevo") => {

        let forms;

        if(id == "nuevo"){
            forms = local.forms;
            forms.pop();
        }else{
            forms = removeFromObjbyId(local.forms, id);
        }
        setLocal({...local, forms})
        showAlert("success", "¡Listo! formulario eliminado exitosamente.")
    }





    if(stateGenerales.loading){
        return (
            ViewDataLoader()
        )
    }else{
        
        return (
            <Context.Provider value={{
                local,
                setLocal,
                showLoader,
                hideLoader,
                onRemoveForm
            }}>
            <main role="main" className="">

                <div className="d-flex pl-1 border-bottom sticky-left">
                    <IconButton size="medium" disabled={false} onClick={()=>{
                        history.goBack();
                    }}>
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe"/>
                    </IconButton> 

               
                    {local.form_id === "nuevo" ? 
                    <Button type="submit" className="cta-title cta--disabled" >NUEVO FORMULARIO</Button>
                    :
                    <Button type="submit" className="cta-title" onClick={()=>{
                        var f = local.forms;
                        if(f.length < 15){
                            f.push("nuevo");
                            setLocal({...local,forms:f});
                        }
                    }}>NUEVO FORMULARIO</Button>
                    }
             
                </div>

                <div className="sticky-left w-100">
                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                </div>
                
             
    
                <div className="general_forms_r d-flex container-fluid pr-0">

          
                    <FormularioGeneral 
                        data={local.general_form} 

                        operation={{id:6, name:"REZAGADO"}}

                        onCreate={(id)=>{
                            setLocal({...local,form_id:id});
                        }} 
                    
                        showLoader={()=>{
                            showLoader()
                        }}
                    /> 

                    <div className="frente_forms d-flex flex-column">
                        {
                        local.forms.map((el, i)=>{
                                if(el === "nuevo"){
                                    return(<RezagadoForm key={i} data={undefined}/>) 
                                }else{
                                    return(<RezagadoForm key={i} data={el} />)
                                }
                        })
                        }
                    </div>

                     
            


                      

                            
                   


                    
                   
                </div>

           
            </main>
           
            </Context.Provider>
        )
    }
}


function RezagadoForm(props) {



    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)
    const {local, setLocal, showLoader, onRemoveForm} = useContext(Context)  
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        
        origen:{},
        destino:{},
        id:null
    })
    const {alert, showAlert, closeAlert} = useContext(AlertContext)

    useEffect(()=>{

        setState({
            ...state, 
            origen: selectFromObjbyItem(stateGenerales.origenes, props.data.item_origen),
            destino: selectFromObjbyItem(stateGenerales.destinos, props.data.item_destino),
            id:props.data.id !== null ? props.data.id : null

            })


    },[props.data])


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        
        formData.forEach((value, key) => body[key] = value);
        body.project_id = stateGenerales.project_id;
        body.generalform_id = local.form_id;
        body.item_origen = state.origen.item
        body.origen = state.origen.name
        body.item_destino = state.destino.item
        body.destino = state.destino.name
        // body.obra = state.obra;
        // body.item = state.item;
        // body.zona = state.zona;
        // body.number_holes = state.barras;
        // body.efficient_length = state.mtsEficientes;
        // body.concept = state.type;
        // body.per_avance = state.avance;
        body.folio = local.form_id;

        body.hora_final = body.hora_final + ":00";
        body.hora_inicial = body.hora_inicial + ":00";
    
       
        

 

        if (form.checkValidity() === true) {

            showLoader();
            
            var response;

            if(state.id === null){

             
                response = await services("POST","laggards_type_form",body);
              
                if(response.status){
             
                    setState({...state, id:response.data.id})
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")

                }else{
                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }else{

   
                response = await services("PUT","laggards_type_form",body, state.id);
                

                if(response.status){
                 
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }else{

                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                    var view = document.querySelector("main")
                    view.scrollTop = 0;

                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }
         
        }

        setValidated(true);
    };

    let origenes_assigned = [];
    let destinos_assigned = [];

    origenes_assigned = stateGenerales.origenes.filter((item)=>{
        let operations = [];
        if(item.operations !== null){
            operations = JSON.parse(item.operations).filter((item) => item.id === 6);
            console.log(operations);
        }
        if(operations.length > 0){
                return true;
        }
        return false;
    });

    destinos_assigned = stateGenerales.destinos.filter((item)=>{

        let operations = [];
        if(item.operations !== null){
            operations = JSON.parse(item.operations).filter((item) => item.id === 6);
            console.log(operations);
        }
        if(operations.length > 0){
                return true;
        }
        return false;
    });
    const origenes = useMemo(
        () =>
        origenes_assigned.map((oneItem) => ({
   
            label: oneItem.item,
     
            key: oneItem.id,
        
            ...oneItem,
          })),
        [stateGenerales.origenes]
      );

    const destinos = useMemo(
        () =>
        destinos_assigned.map((oneItem) => ({
   
            label: oneItem.item,
     
            key: oneItem.id,
        
            ...oneItem,
          })),
        [stateGenerales.destinos]
      );

      let hora_inicial = null;
      let hora_final = null;

      if(props.data.hora_inicial !== null && props.data.hora_final !== null){
        hora_inicial = props.data.hora_inicial.split(":")[0] +":"+ props.data.hora_inicial.split(":")[1]
        hora_final = props.data.hora_final.split(":")[0] + ":"+props.data.hora_final.split(":")[1]
      }
       


    return (
        <div className="border-bottom">
            {/* <div className="bg-blue text-white d-flex justify-content-center align-items-center p-2 mb-2 font-weight-bold">
                Formulario Voladuras {props.data.id}
                    <div className="cta position-absolute  right  pr-3" onClick={async ()=> {

                    
                        if(props.data.id !== null){
                            showLoader()
                            let response = await services("DELETE","laggards_type_form",undefined,props.data.id);
                            if(response.status){
                                onRemoveForm(props.data.id)
                            }
                            hideLoader();
                        }else{
                            onRemoveForm()
                        }
                    
                        
                        

                    }}>
                        <X size="30"></X>
                    </div>
            </div> */}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <div className="d-flex form_items">

                    <div>
                        <Form.Group >
                            <Form.Label>VIAJES</Form.Label>
                            <Form.Control type="number" name="viaje" defaultValue={props.data.viaje} required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>SECTOR</Form.Label>
                            <Form.Control as="select" name="sector" defaultValue={props.data.sector} required>
                            <option value="">Seleccionar</option>
                                {stateGenerales.sectores.map((sector,i)=>{
                                    return (<option key={i} value={sector.id}>{sector.sector}</option>)
                                })}
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div>
                        <Form.Group>
                            <Form.Label>HORA INICIO</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={hora_inicial}
                                name="hora_inicial"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>HORA FIN</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={hora_final}
                                name="hora_final"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group >
                            <Form.Label>ITEM</Form.Label><br></br>
                            <DataListInput
                                inputClassName="form-control-n"
                                placeholder=""      
                                value={state.origen !== undefined ? state.origen.item : ""}
                                items={origenes}
                                onInput = {(el) => {
                                    setState({...state, origen:el})
                                }}
                                onSelect={(el)=>{
                                   
                                   setState({...state, origen:el})
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>ORIGEN</Form.Label>
                            <Form.Control as="select" name="origen" value={state.origen !== undefined ? state.origen.id : ""} defaultValue={props.data.origen} onChange={(evt)=>{
                                let origen = selectFromObjbyId(origenes, parseInt(evt.currentTarget.value))
                                setState({...state, origen})
                            }} required>
                            <option value="">Seleccionar</option>
                                {origenes.map((item,i)=>{
                                    return (<option key={i} value={item.id}>{item.name}</option>)
                                })}
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group >
                            <Form.Label>ITEM</Form.Label><br></br>
                            <DataListInput
                                inputClassName="form-control-n"
                                placeholder=""      
                                value={state.destino !== undefined ? state.destino.item : ""}
                                items={destinos}
                                onInput = {(el) => {
                                    setState({...state, destino:el})
                                }}
                                onSelect={(el)=>{
                                 
                                   setState({...state, destino:el})
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>DESTINO</Form.Label>
                            <Form.Control as="select" name="destino" value={state.destino !== undefined ? state.destino.id : ""} defaultValue={props.data.destino} onChange={(evt)=>{
                                let destino = selectFromObjbyId(destinos, parseInt(evt.currentTarget.value))
                                setState({...state, destino})
                            }} required>
                            <option value="">Seleccionar</option>
                                {destinos.map((item,i)=>{
                                    return (<option key={i} value={item.id}>{item.name}</option>)
                                })}
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>MATERIAL</Form.Label>
                            <Form.Control as="select" name="material" defaultValue={props.data.material} required>
                            <option value="">Seleccionar</option>
                                {stateGenerales.materiales.map((item,i)=>{
                                    return (<option key={i} value={item.id}>{item.name}</option>)
                                })}
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div>
                        <Form.Group >
                            <Form.Label>CUCHARAONES</Form.Label>
                            <Form.Control type="number" name="cucharones" defaultValue={props.data.cucharones} required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group >
                            <Form.Label>OBSERVACIONES</Form.Label>
                            <Form.Control type="text" name="observaciones" defaultValue={props.data.observaciones} required />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    

                    
                
                   <div className="p-1 d-flex justify-content-center align-items-end">
                    

                        <Form.Group>
                            <Button type="submit">GUARDAR</Button>
                        
                        </Form.Group>
                     

                    <div className="pb-2 px-2">
                        <Form.Group className="m-0 d-flex justify-content-center">
                            <IconButton size="medium" onClick={async()=>{

                                    if(props.data.id !== null){
                                        showLoader()
                                        let response = await services("DELETE","laggards_type_form",undefined,props.data.id);
                                        if(response.status){
                                            onRemoveForm(props.data.id)
                                        }
                                        hideLoader();
                                    }else{
                                        onRemoveForm()
                                    }
                            }}>
                                <TrashFill size="22" color="tomato" /> 
                            </IconButton>
                        </Form.Group>
                    </div>

                   </div>



                </div>


              





             


            </Form> 
        </div>
    )
}

RezagadoForm.defaultProps = {
    data:{
        id:null,
        cucharones: null,
        hora_final: null,
        hora_inicial: null,
        item_destino: null,
        item_origen: null,
        material: null,
        observaciones: null,
    
        project_id: null,
        sector: null,
        viaje:null
    
    } 

    

}

export default RezagadoDetalleView
