import React,{useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TablePagination from '@material-ui/core/TablePagination';
import { UsuariosContext } from '../../context/usuarios_context';
import Switchy from '../triggers/switchy';
import { services } from '../../services/api';
import { PencilSquare, Square, CheckSquare } from 'react-bootstrap-icons';
import { selectFromObjbyId } from '../../utils/utils';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const {state, setState} = useContext(UsuariosContext)
  var row  = props.row;
  
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const cellStyle = {
    paddingLeft:0,
    paddingBottom:5,
    paddingTop:5,
    paddingLeft:5,
    paddingRight:10,
    lineHeight:1.2
  }
  const handleSwitch = async (data,id, val)=>{

        var usuarios = data
        var usuario = selectFromObjbyId(data, id);

    if(!val){
      var res = await services("DELETE","users",undefined,id);
      if(res.status){
     
        
        usuario.deleted_at = res.data.deleted_at;
        setState({...state, usuarios })
      } 
    
    }else{
      var res = await services("GET","users",undefined,id);
      if(res.status){
  
        usuario.deleted_at = null
        setState({...state, usuarios })
      } 
    }
     
  }
  let active = row.deleted_at === null ? 1 : 0;
  let switchActive = row.deleted_at === null ? true : false;

  //if(state.filter === 10 || active === state.filter){  
    
  
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
      {/* <TableCell align="left" style={cellStyle}>{row.id}</TableCell> */}
        <TableCell align="left" style={cellStyle}>{row.number}</TableCell>
        {/* <TableCell align="left" style={cellStyle}>{row.role[0] !== undefined ? row.role[0].display_name : ""}</TableCell> */}
        <TableCell align="left" style={cellStyle}>{row.name}</TableCell>
        <TableCell  style={cellStyle}>
          {open ? "Ocultar" : "Abrir"}
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" style={cellStyle}>{row.email}</TableCell>
        <TableCell align="left" style={cellStyle}>{row.phone}</TableCell>
        <TableCell align="left" style={cellStyle}>{row.rfc}</TableCell>
        <TableCell align="left" style={cellStyle}>{row.curp}</TableCell>
        <TableCell align="left" style={cellStyle}>{row.location !== undefined && row.location !== null ? row.location.name : "undefined"}</TableCell>
        <TableCell align="left" style={cellStyle}>{
          row.access == 0 ? <Square size={15}></Square> :  <CheckSquare size={15}></CheckSquare>
        }
        </TableCell>

        <TableCell align="left" style={cellStyle}>
          <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
            <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
          </IconButton>
        </TableCell>
        <TableCell align="left" style={cellStyle}>
          <IconButton disabled={!switchActive} onClick={()=>{props.onEditPermissions(row.id, row.name)}}>
            <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
          </IconButton>
        </TableCell>
        <TableCell align="left"  style={cellStyle}>
          <Switchy value={switchActive} onChange={(val)=>{
            handleSwitch(state.usuarios, row.id, val)
          }} />
        </TableCell>
      </TableRow>


      <TableRow className="bg-lightblue">
        <TableCell style={{ padding: 0, paddingBottom:0}} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
            <h6 className="p-2 top-bordered bg-blue txt-white ">PROYECTOS</h6>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Proyecto</TableCell>
                    <TableCell>Departamento</TableCell>
                    <TableCell>Categoria</TableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.user_projects.map((p, i) => (
                    <TableRow key={i}>

                      <TableCell>{p.project.name}</TableCell>
                      <TableCell>{p.department.name}</TableCell>
                      <TableCell>{p.categorie.name}</TableCell>
             
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
  // }else{
  //   return null;
  // }
}

function UsuariosRecords(props) {


    const {state, setState} = useContext(UsuariosContext)

    // var data = state.usuarios.filter((item)=>{
    //   if(state.search == ""){
    //       return true;
    //   }
    //   return item.name.includes(state.search);
    // })

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);


    useEffect(() => {
      
      var data = state.usuarios.filter((item)=>{
        var active = item.deleted_at === null ? 1 : 0;

        if(state.search === "" && state.filter === 10){
          return true;
        }

        if(state.search !== "" && state.filter === 10){
       
          return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase())
        }
       
        return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase()) && active === state.filter;
       
      })
      setPage(0);
      setState({...state, usuariosF:data});

    }, [state.search, state.filter])


    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  
    };

    const cellStyle = {
      paddingLeft:0,
      paddingBottom:10,
      paddingTop:10,
      paddingLeft:5,
      paddingRight:10,
      lineHeight:1.2
    }
  return (
    <Paper>
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead className="bg-light">
          <TableRow>
         

            <TableCell style={{...cellStyle, minWidth:150}} align="left">No. Empleado</TableCell>
            {/* <TableCell style={{...cellStyle, minWidth:150}} align="left">Puesto</TableCell> */}
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Nombre</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Proyectos</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Correo</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Telefono</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">RFC</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">CURP</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Ubicación</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Acceder</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Editar Usuario</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Editar Permisos</TableCell>
            <TableCell style={{...cellStyle, minWidth:150}} align="left">Activar</TableCell>
        
          </TableRow>
        </TableHead>
        <TableBody>

          {state.usuariosF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

            return(
              <Row key={i} row={row} open={open} onEdit={props.onEdit} onEditPermissions={props.onEditPermissions}/>
            );
          })}

            
         



          {/* {props.data.map((row,i) => (
            <Row key={i} row={row} />
          ))} */}


        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.usuariosF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
    
  );
}

export default UsuariosRecords;

