import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const MaterialContext = createContext();
export const MaterialProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateMaterial, setStateMaterial] = useState({
        materiales:[],
        materialesF:[],
        project_id:null,
        project_name:"",
        loading:false,
        filter:10,
       
    });

    useEffect(async () =>{
        
        
        setStateMaterial({...stateMaterial, loading:true});
         

            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
                var materialesRes = await recordsServiceId("GET","materials", id);
                
          
                if(materialesRes.status){ 

                    setStateMaterial({
                        materiales:materialesRes.data,
                        materialesF:materialesRes.data,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter:10
                   
                    });
                }else{
                    if(materialesRes.code === 401 || materialesRes.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])
    
    return(
        <MaterialContext.Provider value={{
            stateMaterial,
            setStateMaterial
        }}>
            {children}
        </MaterialContext.Provider>
    )
}











