
import React, { useState, useContext,useEffect } from 'react';
import {Journals, XCircleFill, ArrowLeftSquareFill } from 'react-bootstrap-icons';

import BarraMtsEficientesRecords from '../components/records/barra_mts_eficientes_records'
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {showLoader, hideLoader, today, replaceObj} from '../utils/utils';

import { Form, Row, Col, Button } from 'react-bootstrap';
import { BarrasContext } from '../context/barras_context';
import { UserContext } from '../context/user_context';
import { services } from '../services/api';
import ViewDataLoader from '../components/ui/view_data_loader';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';
import SectorRecords from '../components/records/sector_records';
import { SectorContext } from '../context/sector_context';
import { useHistory } from 'react-router-dom';
let editing = false;
let editingId;
function SectorView(){

        let history = useHistory();
   
        const [validated, setValidated] = useState(false);
        const [title, setTitle] = useState(true);
        const {stateSector, setStateSector} = useContext(SectorContext)
        const {user, setUser} = useContext(UserContext)
        const {alert, showAlert, closeAlert} = useContext(AlertContext)
        


      const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
        body.project_id = stateSector.project_id;
        let filt = document.getElementById("status_label")
        

        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","sectors",body);
            }else{
                res = await services("PUT","sectors",body, editingId);
            }
            if(res.status){
                if(!editing){
                    
                    var sectores = stateSector.sectores;
                    var sectoresF = stateSector.sectoresF;

                    var sector = res.data;
                    sector.deleted_at = null;

                    sectores.unshift(sector);
                    if(filt.value != 0) sectoresF.unshift(sector);

                    setStateSector({...stateSector,sectores, sectoresF});
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")

                }else{
                    var sectores = replaceObj(stateSector.sectores,res.data);
                    var sectoresF = replaceObj(stateSector.sectoresF,res.data);
                    setStateSector({...stateSector,sectores, sectoresF});
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }
                hideLoader();
                hideForm();
            }else{
                hideLoader();
                var message = "";
              
                Object.keys(res.errors).map(function(key, index) {
                    message = message + " " +res.errors[key]
                    
                });
                showAlert("error", message);
            }
        }else{
           
            setValidated(true);
        }
      };
      const showForm = (data=undefined) =>{
        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');

        let f = document.getElementById("main_form");
        
        if(data !== undefined){
            reason_edition.classList.remove('d-none');
            setTitle(false);
            fillForm(data)
        }
        f.classList.add("d-block")
        f.classList.remove("d-none");
      }
      const hideForm = () =>{
        let f = document.getElementById("main_form");
        clearForm();
        f.classList.add("d-none");
        f.classList.remove("d-block");
      }
      const onChangeFilter = (evt) =>{
        setStateSector({...stateSector, filter:parseInt(evt.currentTarget.value)})
      }
     
      const fillForm = (data) => {
        let { elements } = document.getElementById('sector_form');
        elements.namedItem("reason_edition").disabled = false;
        for (const [ key, value ] of Object.entries(data) ) {
          const field = elements.namedItem(key)
          if(key !== "reason_edition"){
            field && (field.value = value)
            }
         
        }
        editing = true;
        editingId = data.id
      }
      const clearForm = () => {
        let form = document.getElementById('sector_form');
        let {elements} = form
        elements.namedItem("reason_edition").disabled = true;
        editing = false;
        editingId = undefined;
        form.reset();
        setTitle(true);
        setValidated(false);
      }

  
    if(stateSector.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main" className={!alert.open ? "menu_collapsed": ""}>
             
            <div className="container-fluid position-relative py-1 ">
        
            <div className="d-flex align-items-end py-2">
                <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                history.goBack();
                }}/>
                <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
                <div>
                    <h4 className="pl-1"> Catálogo: Sector</h4>  
                    <h4 className="line-height-xs pl-1">PROYECTO: {stateSector.project_name} </h4>
                </div>
            </div>
               
            <div className="d-flex">
                <div className="col-2 py-2 px-0">
                    
                    <Form.Label>Estatus:</Form.Label>
                    <Form.Control as="select"  id="status_label" name="proyecto" onChange={onChangeFilter} required>
                        <option value ="10">Todos</option>
                        <option value ="1">Activo</option>
                        <option value ="0">Desactivado</option>
                    </Form.Control>
                </div>
                <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                    <Button className="primary px-4" onClick={()=>{showForm()}} >
                        Nuevo
                    </Button>
                </div>
            </div>

            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

            <SectorRecords onEdit={(data)=>{
                showForm(data);
            }}></SectorRecords>

            <div className="catalogo_form_modal h-100 d-none py-3" id="main_form">
                <Form noValidate validated={validated} onSubmit={handleSubmit} id="sector_form">
                    <div className="container w-50 position-relative bg-white rounded pt-4">
                    
                        <div className="row">
                            <div className="col-md-12">
                                
                            <div className="col">
                                <h5>{title === true ? "NUEVO" : "EDITAR"} SECTOR</h5>
                                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                            </div>
                            <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                <div className="form-control disabled">{today()}</div>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>RESPONSABLE</Form.Label>
                                <div className="form-control disabled"  >{user.name}</div>
                             
                            </Form.Group>


                            <Form.Group as={Col}>
                                <Form.Label>SECTOR.</Form.Label>
                                <Form.Control type="text" min="0" name="sector" required />
                                <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio. Numeros positivos.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>DESCRIPCIÓN</Form.Label>
                                <Form.Control as="textarea" rows={3} name="description" required/>
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div id="reason_edition">
                                <Form.Group as={Col}>
                                    <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="reason_edition" disabled required/>
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <Form.Group as={Col}>
                                <Button type="submit">GUARDAR</Button>
                            </Form.Group>

                            </div>
                            
                        </div>
                        <div className="cta position-absolute top right pt-1 pr-1" onClick={hideForm}>
                            <XCircleFill size="45"></XCircleFill>
                        </div>
                    </div>
                </Form>
            </div>
 

            </div>

       
        </main>
    )}
        
}


export default SectorView;