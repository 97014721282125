import { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { PencilSquare } from 'react-bootstrap-icons';
import { IconButton } from '@material-ui/core';
import Switchy from '../triggers/switchy';

import { TipoObrasContext } from '../../context/tipo_obras_context';
import { services } from '../../services/api';
import { selectFromObjbyId } from '../../utils/utils';


const columns = [
    { id: 'code', label: 'Codigo', minWidth: 170},
    { id: 'type', label: 'Tipo de Obra', minWidth: 170},
    { id: 'description', label: 'Descripción', minWidth: 170},
    { id: 'editar', label: 'Editar', minWidth: 170},
    { id: 'activar', label: 'Activar', minWidth: 170}
]

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function TipoObrasRecords(props) {

    const {state,setState} = useContext(TipoObrasContext)
    const classes = useStyles()
    const [page,setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(()=>{
        let data = state.tipos.filter(item=>{
            let active = item.deleted_at === null ? 1 : 0
            if(state.search === '' && state.filter === 10){
                return true
            }

            if(state.search !== '' && state.filter === 10){
                return item.type.toLocaleUpperCase().includes(state.search.toLocaleUpperCase())
            }

            return item.type.toLocaleUpperCase().includes(state.search.toLocaleUpperCase()) && active === state.filter
        })

        setPage(0)
        setState({...state,tiposF:data})
    },[state.search,state.filter])
    
    const handleChangePage = (event, newPage) =>{
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) =>{
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const handleSwitch = async (data,id,val) =>{
        if(!val){
            var res = await services('DELETE','work_types',undefined,id)
        }else{
            var res = await services('GET', 'work_types',undefined,id)
        }

        if(res.status){
            var tipos = data
            var tipo = selectFromObjbyId(data,id)
            tipo.deleted_at = res.data.deleted_at
            setState({...state,tipos})
        }
    }

    return(
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column=>{
                                return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ 
                                            minWidth: column.minWidth,
                                            paddingBottom:10,
                                            paddingTop:10,
                                            paddingLeft:5,
                                            paddingRight:10,
                                            lineHeight:1.2
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.tiposF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i)=>{
                            let switchActive = row.deleted_at === null ? 1 : 0
                            return (
                                <TableRow hover role='checkbox' tabIndex={-1} key={i}>
                                    {columns.map(column=>{
                                        var value = row[column.id]
                                        return(
                                            <TableCell>
                                                {column.id === 'editar' ? 
                                                <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
                                                    <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                                                </IconButton>
                                                : column.id === 'activar' ?
                                                <Switchy value={switchActive} onChange={(val)=>{
                                                    handleSwitch(state.tipos, row.id, val)
                                                }}/>
                                                :
                                                value
                                                }
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                animation={false}
                rowsPerPageOptions={[10,25,100]}
                component='div'
                count={state.tiposF.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
}