import React, {useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Tabs,Tab, Button} from 'react-bootstrap'
import { OrigenesDestinosContext } from '../../context/origenes_destinos_context';
import {deleteOrigenesDestinos, services} from '../../services/api' 
import {showLoader, hideLoader, removeFromObjbyId} from '../../utils/utils';
import { MultiSelect } from 'react-multi-select-component';


function OrigenesDestinosRecords(props) {


  const [key, setKey] = React.useState('orignes')
  const {state,setState} = useContext(OrigenesDestinosContext)

  return (
    <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        variant="pills"
    >
        <Tab eventKey="orignes" title="ORIGENES">
            <div className="container-fluid px-0 py-3">
                <OrigenesRecords 
                data={state.origenes} 
                onClick={(data)=>{
                    props.onEdit(data)
                }}
                onAsignar={(data)=>{

                    props.onAsignar(data)
                }}
                >

                </OrigenesRecords>
            </div>
        </Tab>
        <Tab eventKey="destinos" title="DESTINOS">
            <div className="container-fluid px-0 py-3">
                <DestinosRecords data={state.destinos} onClick={(data)=>{
                    props.onEdit(data)
                }}
                 onAsignar={(data)=>{

                    props.onAsignar(data)
                }}></DestinosRecords>
            </div>
        </Tab>
        
    </Tabs>
  
  );
}

function OrigenesRecords(props){
   

    const columns = [
        { id:'item', label:'Item', minWidth: 170 },
        { id:'name', label:'Origen', minWidth: 170 },
        { id:'operaciones', label:'Operaciones', maxWidth: 200 },
      ];
      const useStyles = makeStyles({
        root: {
          width: '100%',
        },
      });
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {state,setState} = useContext(OrigenesDestinosContext)

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return(
        <Paper className={classes.root}>
        <TableContainer  className={classes.container}>
            <Table  stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ 
                        minWidth: column.minWidth,
                        paddingLeft:0,
                        paddingBottom:10,
                        paddingTop:10,
                        paddingLeft:5,
                        paddingRight:10,
                        lineHeight:1.2
                    }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

          


                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        {columns.map((column) => {
                        var value = row[column.id];
                        return (
                            <TableCell key={column.id} align={column.align} style={{ 
                                paddingRight:10,
                                paddingLeft:5,
                                paddingTop:5,
                                paddingBottom:5,
                        
                                }}>
                            {
                                column.id === "operaciones" ? 
                                
                                <div className="d-flex op">
                                    <Button variant="success" className="mx-1" onClick={()=>{
                                        props.onClick(row)
                                    }}>EDITAR</Button>

                                    
                                    <Button className="mx-1" onClick={()=>{
                                        props.onAsignar(row);
                                    }}>ASIGNAR</Button>
                     
                                    

                                    <Button variant="danger" className="mx-1" onClick={ async()=>{
                                        showLoader();
                                        await services("DELETE","origin_destination", undefined, row.id);
                                        var origenes = removeFromObjbyId(state.origenes,row.id);
                                        setState({...state, origenes})
                                        hideLoader();

                                    }}>ELIMINAR</Button>
                                </div>
                                
                                : value
                            }
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            animation="false"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            //labelRowsPerPage={"Ver"}
            //abelDisplayedRows={"a"}
            
        />
        </Paper>
    );
}

function DestinosRecords(props){
    const {state,setState} = useContext(OrigenesDestinosContext)

    const columns = [

        { id:'item', label:'Item', minWidth: 170 },
        { id:'name', label:'Destino', minWidth: 170 },
        { id:'operaciones', label:'Operaciones', minWidth: 300 },
      
      ];
      
    
      const useStyles = makeStyles({
        root: {
          width: '100%',
        },
       
      });



    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  

  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return(
        <Paper className={classes.root}>
        <TableContainer  className={classes.container}>
            <Table  stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ 
                        minWidth: column.minWidth,
                        paddingLeft:0,
                        paddingBottom:10,
                        paddingTop:10,
                        paddingLeft:5,
                        paddingRight:10,
                        lineHeight:1.2
                    }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        {columns.map((column) => {
                        var value = row[column.id];
                        return (
                            <TableCell key={column.id} align={column.align} style={{ 
                                paddingRight:10,
                                paddingLeft:5,
                                paddingTop:5,
                                paddingBottom:5,
                        
                                }}>
                            {
                                column.id === "operaciones" ? 
                                <div>
                                    <Button variant="success" className="mx-1" onClick={()=>{
                                        props.onClick(row)
                                    }}>EDITAR</Button>
                                    <Button className="mx-1" onClick={()=>{
                                        props.onAsignar(row);
                                    }}>ASIGNAR</Button>
                                    <Button variant="danger" className="mx-1" onClick={ async()=>{
                                        showLoader();
                                        await services("DELETE","origin_destination", undefined, row.id);
                                        var destinos = removeFromObjbyId(state.destinos,row.id);
                                        setState({...state, destinos})
                                        hideLoader();

                                    }}>ELIMINAR</Button>
                                </div>
                                : value
                            }
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            animation="false"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            //labelRowsPerPage={"Ver"}
            //abelDisplayedRows={"a"}
            
        />
        </Paper>
    );
}



export default OrigenesDestinosRecords;