
import React, { useState,useContext,useEffect } from 'react';
import {ArrowLeftSquareFill, Journals, XCircleFill } from 'react-bootstrap-icons';

import OrigenesDestinosRecords from '../components/records/origenes_destinos_records'
import ViewDataLoader from '../components/ui/view_data_loader'
import {showLoader, hideLoader, replaceObj,removeFromObjbyId, today} from '../utils/utils';


import { Form, Col, Button } from 'react-bootstrap';
import { OrigenesDestinosContext } from '../context/origenes_destinos_context';
import { services } from '../services/api';
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import EspeciBarrenacionRecords from '../components/records/especi_barrenacion_records';
import { EspeciBarrenacionContext } from '../context/especi_barrenacion_context';
import { UserContext } from '../context/user_context';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';




let dataEditing;
let editing = false;
let editingId;

function EspeciBarrenacionView(){

    let history = useHistory();
   
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        title:true,
        editing:false,
        form:"",
        plantilla:undefined,
        tipo:undefined,
    });
    
    const {stateBarrenacion, setStateBarrenacion} = useContext(EspeciBarrenacionContext)
    const {user} = useContext(UserContext)
    const {alert, showAlert, closeAlert} = useContext(AlertContext)


    const handleSubmitPlantilla = async (event) => {
    
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
      
        body.project_id = stateBarrenacion.project_id;
        console.log(body)
        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","template_drillings", body);
                console.log(res)
            }else{
                res = await services("PUT","template_drillings",body,editingId);
            }

            if(res.status){

            if(!editing){
                let a = stateBarrenacion.plantillas_barrenacion;
                let b = stateBarrenacion.plantillas_barrenacionF;

                let plantilla = res.data
                plantilla.deleted_at = null;

                
               
                a.unshift(plantilla);
                if(stateBarrenacion.filter_plantilla!=0) b.unshift(plantilla);
              

                setStateBarrenacion({...stateBarrenacion,plantillas_barrenacion:a, plantillas_barrenacionF:b})

                showAlert("success", "¡Listo! Registro agregado exitosamente.")
            }else{
                

           
                let plantillas_barrenacion = replaceObj(stateBarrenacion.plantillas_barrenacion,res.data);
                var plantillas_barrenacionF = replaceObj(stateBarrenacion.plantillas_barrenacionF,res.data);
                setStateBarrenacion({...stateBarrenacion,plantillas_barrenacion, plantillas_barrenacionF})
                showAlert("success", "¡Listo! Registro editado exitosamente.")

            }
                hideLoader();
                hideForm();
            }else{
                hideLoader();
                var message = "";
                Object.keys(res.errors).map(function(key, index) {
                message = message + " " +res.errors[key][0]
                });
                showAlert("error", message);
            }

        }else{
            setValidated(true);
        }

    };

    const handleSubmitTipo = async (event) => {
      
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
      
        body.project_id = stateBarrenacion.project_id;
        console.log(body)
        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","type_drillings", body);
                console.log(res)
            }else{
                res = await services("PUT","type_drillings",body,editingId);
            }

            if(res.status){

            if(!editing){
                let tipos_barrenacion = stateBarrenacion.tipos_barrenacion;
                let tipos_barrenacionF = stateBarrenacion.tipos_barrenacionF;

                let tipo_barrenacion = res.data
                tipo_barrenacion.deleted_at = null;

                tipos_barrenacion.unshift(tipo_barrenacion);
                if(stateBarrenacion.filter_tipo!=0) tipos_barrenacionF.unshift(tipo_barrenacion);

                setStateBarrenacion({...stateBarrenacion,tipos_barrenacion,tipos_barrenacionF})
                showAlert("success", "¡Listo! Registro agregado exitosamente.")
            }else{

         
                let tipos_barrenacion = replaceObj(stateBarrenacion.tipos_barrenacion,res.data);
                let tipos_barrenacionF = replaceObj(stateBarrenacion.tipos_barrenacionF,res.data);
                setStateBarrenacion({...stateBarrenacion,tipos_barrenacion, tipos_barrenacionF})
                showAlert("success", "¡Listo! Registro editado exitosamente.")

            }
                hideLoader();
                hideForm();
            }else{
                hideLoader();
                var message = "";
                Object.keys(res.errors).map(function(key, index) {
                message = message + " " +res.errors[key][0]
                });
                showAlert("error", message);
                console.log(message)
            }

        }else{
            setValidated(true);
        }

    };


    

    const showForm = (data = undefined, form) =>{    

        if(data !== undefined){
            dataEditing = data;
            editing = true;
            editingId = data.id
        }
       
        if(form){
            if(data !== undefined){
                
                setState({editing:true, plantilla:data,form:"plantilla", title:false})
                return;
            }
            setState({...state,form:"plantilla"})
            return;
        }

        if(data !== undefined){
            setState({editing:true, tipo:data,form:"tipo", title:false})
            return;
        }
        setState({...state,form:"tipo"})
   
    }

    const hideForm = () =>{
        setState({title:true, editing:false, form:"", plantilla:undefined, tipo:undefined});
        setValidated(false);
        editing = false;
        editingId = null;
        dataEditing = null
    }
    console.log(stateBarrenacion.loading)
    if(stateBarrenacion.loading){
        
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main">

            
            
                
            <div className="container-fluid position-relative py-1 ">
                 <div className="d-flex align-items-end py-2">
               
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                            history.goBack();
                        }}/>
                        <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
          
                    <div>
                        <h4 className="pl-1"> Catálogo: Especificaciones de Barrenación</h4>  
                        <h4 className="line-height-xs pl-1">PROYECTO: {stateBarrenacion.project_name} </h4>
                    </div>

                    
                </div>
              
                
                 
                
                <div className="d-flex">
                    <div className="col-2 py-2 px-0">                 
                        {/* <Form.Label>Estatus:</Form.Label>
                        <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                            <option value ="10">Todos</option>
                            <option value ="1">Activo</option>
                            <option value ="0">Desactivado</option>
                        </Form.Control> */}
                    </div>
                    <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                        
                    </div>
                </div>

                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

                <EspeciBarrenacionRecords 
                onEdit={(data, form)=>{
                    showForm(data, form);
                }}
                onAdd={(form)=>{

                    showForm(undefined,form)
                    
                }}
                >

                </EspeciBarrenacionRecords>
                
                {state.form === "plantilla" ? 
                <div className="catalogo_form_modal h-100 py-3" id="">
                    <Form noValidate validated={validated} onSubmit={handleSubmitPlantilla} id="">
                        <div className="container w-50 position-relative bg-white rounded pt-3">
                        
                            <div className="row">
                                <div className="col-md-12">

                                <div className="col">
                                    <h5>{state.title === true ? "NUEVO" : "EDITAR"} Plantilla de barrenación</h5>
                                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                                </div>

                                <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                    <div className="form-control disabled">{today()}</div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>RESPONSABLE</Form.Label>
                                    <div className="form-control disabled"  >{user.name}</div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>LARGO SECCIÓN</Form.Label>
                                    <Form.Control className="text-uppercase" defaultValue={state.plantilla !== undefined ? state.plantilla.section_length : ""} type="text" name="section_length" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>ANCHO SECCIÓN</Form.Label>
                                    <Form.Control className="text-uppercase" defaultValue={state.plantilla !== undefined ? state.plantilla.section_width : ""} type="text" name="section_width" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>NO. DE BARRENOS</Form.Label>
                                    <Form.Control type="number" name="number_bars" defaultValue={state.plantilla !== undefined ? state.plantilla.number_bars : ""} min="0" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>LONGITUD (metros lineales)</Form.Label>
                                    <Form.Control type="number" name="length_linear_meters" defaultValue={state.tipo !== undefined ? state.tipo.length_linear_meters : ""} min="0" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* <div id="reason_edition"> */}
                                {state.editing === true ? 
                                <Form.Group as={Col}>
                                    <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="reason_edition" required/>
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                : null}
                                {/* </div> */}
                                <Form.Group as={Col}>
                                    <Button type="submit">GUARDAR</Button>
                                </Form.Group>
                                </div>
                            </div>
                            <div className="cta position-absolute top right p-2" onClick={hideForm}>
                                <XCircleFill size="40"  ></XCircleFill>
                            </div>
                        </div>
                    </Form>
                </div>
                : null}

                {state.form === "tipo" ? 
                <div className="catalogo_form_modal h-100 py-3" id="">
                    <Form noValidate validated={validated} onSubmit={handleSubmitTipo} id="">
                        <div className="container w-50 position-relative bg-white rounded pt-3">
                        
                            <div className="row">
                                <div className="col-md-12">

                                <div className="col">
                                    <h5>{state.title === true ? "NUEVO" : "EDITAR"} Tipo de barrenación</h5>
                                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                                </div>

                                <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                    <div className="form-control disabled">{today()}</div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>RESPONSABLE</Form.Label>
                                    <div className="form-control disabled"  >{user.name}</div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>NOMBRE</Form.Label>
                                    <Form.Control type="text" name="name" defaultValue={state.tipo !== undefined ? state.tipo.name : ""} required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>DESCRIPCIÓN</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="description" defaultValue={state.tipo !== undefined ? state.tipo.description : ""} required/>
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* <div id="reason_edition"> */}
                                {state.editing === true ? 
                                <Form.Group as={Col}>
                                    <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="reason_edition" required/>
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                : null }
                                {/* </div> */}
                                <Form.Group as={Col}>
                                    <Button type="submit">GUARDAR</Button>
                                </Form.Group>
                                </div>
                            </div>
                            <div className="cta position-absolute top right p-2" onClick={hideForm}>
                                <XCircleFill size="40"  ></XCircleFill>
                            </div>
                        </div>
                    </Form>
                </div>
                : null }
            
            
            </div>
           
        </main>
    )}

}

export default EspeciBarrenacionView;

