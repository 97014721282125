import React from 'react'

export function TurnContent() {
  return (
      <>
        <option value="">Seleccionar</option>
        <option value="Primero">Primero</option>
        <option value="Segundo">Segundo</option>
      </>
  )
}


export function StatusContent() {
    return (
        <>
          <option value="">Seleccionar</option>
          <option value="Pendiente">Pendiente</option>
          <option value="Terminado">Terminado</option>
        </>
    )
}


export function EquiposContent({equipos}) {
    return (
        <>
            <option value="">Seleccionar</option>
            {equipos.map((item,i)=>(
                <option value={item.id}>{item.name}</option>
            ))}
        </>
    )
}


export function ZonasContent({zonas}) {
    return (
        <>
            <option value="">Seleccionar</option>
            {zonas.map((item,i)=>(
                <option value={item.id}>{item.name}</option>
            ))}
        </>
    )
}

export function SectoresContent({sectores}) {
    return (
        <>
            <option value="">Seleccionar</option>
            {sectores.map((item,i)=>(
                <option value={item.id}>{item.sector}</option>
            ))}
        </>
    )
}

export function RmrContent({rmr}) {
    return (
        <>
            <option value="">Seleccionar</option>
            {rmr.map((item,i)=>(
                <option value={item.id}>{item.quality}</option>
            ))}
        </>
    )
}

export function BarsContent({bars}) {
    return (
        <>
            <option value="">Seleccionar</option>
            {bars.map((item,i)=>(
                <option value={item.id}>{item.length}</option>
            ))}
        </>
    )
}

export function UsersTodayContent({usersToday}) {
    return (
        <>
            <option value="">Seleccionar</option>
            {usersToday.map((item,i)=>(
                <option value={item.id}>{item.usuario}</option>
            ))}
        </>
    )
}

export function ObrasContent({obras}) {
    return (
        <>
            <option value="">Seleccionar</option>
            {obras.map((item,i)=>(
                <option value={item.id}>{item.name}</option>
            ))}
        </>
    )
}
