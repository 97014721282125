
import React, {useContext, useEffect, useState} from 'react'
import { ArrowLeftSquareFill, Journals } from 'react-bootstrap-icons'

import { UserContext } from '../context/user_context'


import {

    useHistory,
    useLocation,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { ProyectosContext } from '../context/proyectos_context';
import { selectFromObjbyId } from '../utils/utils';
import ViewDataLoader from '../components/ui/view_data_loader';
import { Doughnut } from 'react-chartjs-2';
import { graficosFiltro, singleService } from '../services/api';
import FilterGraficos from '../components/graficos/filtro_graficos';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';



function ListasAsistenciaGraficos() {

    const { user } = useContext(UserContext);        
    const {alert, showAlert, closeAlert} = useContext(AlertContext)
    const [proyecto, setProyecto] = useState(undefined);
    const [chart, setChart] = useState({
        users:10,
        days:147,
        absences:24,
        indicator:10,

        usersF:10,
        daysF:147,
        absencesF:24,
    


        loading:true,


    });
    const {state,setState} = useContext(ProyectosContext);

    let { id } = useParams();
    let history = useHistory();
    let location = useLocation();
    const { path, url } = useRouteMatch();

    useEffect(async ()=>{
        
        let proyecto = selectFromObjbyId(state.projects,parseInt(id))
        if(proyecto === undefined){
          
            history.replace(`/home/proyectos` );
        }else{
            setProyecto(proyecto);
            var chartres = await singleService("GET","assists/graph/project", id );
            
            setChart({...chart, 
                users:chartres.data.users, 
                days:chartres.data.assists,
                absences:chartres.data.delay, 

                usersF:chartres.data.users, 
                daysF:chartres.data.assists,
                absencesF:chartres.data.delay, 

                indicator:chartres.data.users, 
                loading:false })

            
        }
        
    },[id]);


    const plantillaData = {
        labels: ['Autorizada periodo 1', 'Actual'],
        datasets: [
          {
            label: '# of Votes',
            data: [chart.indicator - chart.usersF, chart.usersF],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)'
            ],
            borderWidth: 1,
          },
        ],
      };

      const ausentismoData = {
        labels: ['Ausentimo', 'Dias laborales'],
        datasets: [
          {
            label: '# of Votes',
            data: [chart.absencesF, chart.daysF],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)'
              
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)'
              
            ],
            borderWidth: 1,
          },
        ],
      };





    if(proyecto === undefined || chart.loading === true){
        return (
            ViewDataLoader()
        )
    }else{
        
        return (
            <main role="main" className={!alert.open ? "menu_collapsed": ""}>


                <div className="container-fluid mt-1">
                    <div className="d-flex align-items-end py-2">
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                        history.goBack();
                        }}/>
                        <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
                        <div>
                            <h4 className="pl-1"> Catálogo: Listas de asistencia</h4>  
                            <h4 className="line-height-xs pl-1">PROYECTO: {proyecto.name} </h4>
                        </div>
                    </div>
                </div>


                
               
                <div className="container-fluid mt-3">
                  
                <strong>GRAFICOS:</strong>
                <hr></hr>
                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                    
                    <FilterGraficos 
                    onSearch={async (start, end)=>{


                        var startDate = Date.parse(start)
                        var endDate = Date.parse(end)

                        if(startDate < endDate){
                        
                            closeAlert();
                            let filter = await graficosFiltro(start, end, id);
                            setChart({...chart, 
                                usersF:filter.data.users, 
                                daysF:filter.data.assists,
                                absencesF:filter.data.delay, 
                            })
                        }else{
                            showAlert("error", "¡Error! Fecha: Desde no puede ser mayor a Fecha: Hasta")
                        }


                        
                        
                        
                    }}
                    onClean={()=>{

                        setChart({...chart, 
                            usersF:chart.users, 
                            daysF:chart.days,
                            absencesF:chart.absences, 
                          })
                        
                    }}/>
                    
                    <div className="d-flex">
                        <div className="col-md-4 p-0">
                            <table className="table-striped"> 
                                <thead>
                                    <tr>
                                        <td><strong>Plantilla</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{height:"35.5px"}}>
                                        <td style={{width:"60%"}}>Autorizada periodo 1</td>
                                        <td style={{width:"20%"}}>{((1 - (chart.usersF / chart.indicator)) * 100).toFixed(0)}%</td>
                                        <td style={{width:"20%"}}><input type="number" value={chart.indicator} onChange={(evt)=>{
                                            setChart({...chart, indicator:parseInt(evt.currentTarget.value)})
                                        }} min="0" className="form-control"></input></td>
                                    </tr>
                                    <tr style={{height:"35.5px"}}>
                                        <td>Actual</td>
                                        <td>{((chart.usersF / chart.indicator) * 100).toFixed(0)}%</td>
                                        <td>{chart.usersF}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <Doughnut data={plantillaData} />
                        </div>
                        <div className="col-md-4 p-0 ml-3">
                        <table className="table-striped w-100"> 
                                <thead>
                                    <tr>
                                        <td><strong>Ausentimo</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{height:"35.5px"}}>
                                        <td style={{width:"60%"}}>Ausentismos</td>
                                        <td style={{width:"20%"}}>{((chart.absencesF  / chart.daysF) * 100).toFixed(0)}%</td>
                                        <td style={{width:"20%"}}>{chart.absencesF}</td>
                                    </tr>
                                    <tr style={{height:"35.5px"}}>
                                        <td>Dias laborales</td>
                                        <td>{((1-(chart.absencesF  / chart.daysF)) * 100).toFixed(0)}%</td>
                                        <td>{chart.daysF}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <Doughnut data={ausentismoData} />
                          
                        </div>
                    </div>

                </div>

            </main>
        )
    }
}

export default ListasAsistenciaGraficos
