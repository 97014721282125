import React,{useEffect, useState} from 'react'
import {Link, useParams, useLocation} from "react-router-dom"

function General_Table_Headers() {
  let { id } = useParams();
  const location = useLocation()
  const path = location.pathname

  return (
    <ul className='list-group flex-row'>
        <Link className={'remove-link-styles p-0'} to={`/home/proyectos/barrenacion_jumbo/${id}`}>
          <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("barrenacion_jumbo") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Barrenacion
          </li>
        </Link>
        <Link className='remove-link-styles p-0' to={`/home/proyectos/voladuras/${id}`}>
          <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("voladuras") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Voladuras
          </li>
        </Link>
        <Link className='remove-link-styles p-0' to={`/home/proyectos/soporte_obra/${id}`}>
        <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("soporte_obra") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Soporte de obra
        </li>
        </Link>
        <Link className='remove-link-styles p-0' to={`/home/proyectos/lanzado_concreto/${id}`}>
          <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("lanzado_concreto") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Lanzado de concreto
          </li>
        </Link>
          <Link className='remove-link-styles p-0' to={`/home/proyectos/acarreo/${id}`}>
            <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("acarreo") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Acarreo
            </li>
        </Link>
        <Link className='remove-link-styles p-0' to={`/home/proyectos/rezagado/${id}`}>
          <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("rezagado") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Rezagado
          </li>
        </Link>
        <Link className='remove-link-styles p-0' to={`/home/proyectos/servicios_generales/${id}`}>
          <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("servicios_generales") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Servicios
          </li>
        </Link>
        <Link className='remove-link-styles p-0' to={`/home/proyectos/amacice_limpieza/${id}`}>
          <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("amacice_limpieza") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Amacice
          </li>
        </Link>
        <Link className='remove-link-styles p-0' to={`/home/proyectos/servicios_administrativos/${id}`}>
          <li className={"list-group-item text-nowrap border cursor-pointer " + (path.includes("servicios_administrativos") ? "blue-bg-text" : "")} style={{background:"Inherit"}}>
              Servicios administrativos
          </li>
        </Link>
    </ul>
  )
}

export default General_Table_Headers