import React, { createContext, useState,useEffect, useContext } from "react"

import { dependentService, recordsService} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";


export const MaquinariaContext  = createContext();


const token = window.localStorage.getItem('token');
const uid = window.localStorage.getItem('uid');


export const MaquinariaProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)


    const [state, setState] = useState({
          
        data:[],
        dataF:[],
        equipments:[],
        bars:[],
        proyects:[],
        project_selected:"",
        project_selected_id:null,
        mtsE:"",
        filter: 10,
        loading:false,
        projectsSelected:[],
        title:"NUEVO"
    
    });

    useEffect(async () =>{
      
        setState({...state, loading:true});
        var machiRes = await recordsService("GET","machinaries")
        var barsRes = await dependentService("GET","bars")
        var equiRes = await dependentService("GET","equipments")
        var proyRes = await dependentService("GET","projects")

        if(machiRes.status && barsRes.status && equiRes.status && proyRes.status){
            setState({...state, loading:false, data:machiRes.data, dataF:machiRes.data, bars:barsRes.data, equipments:equiRes.data, proyects:proyRes.data});
        }else{
            if(machiRes.code == 401){
                window.localStorage.clear();
                setUser({});
                auth.setMsg(true)
                auth.signout();
            }
        }
 
    },[])

    return(
        <MaquinariaContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </MaquinariaContext.Provider>

    )
}






