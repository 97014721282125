import { IconButton } from '@material-ui/core'
import React, {useState} from 'react'
import {  Trash, Search,  } from 'react-bootstrap-icons'
import { Form, Col } from 'react-bootstrap';




export default function FilterGraficos(props) {

    const [state, setState] = useState({
        startDate:"",
        endDate:""
    })
    return (
        <div className="d-flex">
            <div className="col-2 p-0">
                <Form.Group as={Col} controlId="" className="p-0">
                    <Form.Label>DESDE</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        
                        value={state.startDate}
                        onChange={(evt)=>{
                        
                            setState({...state, startDate:evt.currentTarget.value}) 
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>
            </div>
            <div className="col-2 p-0 ml-2">
                <Form.Group as={Col} controlId="" className="p-0">
                    <Form.Label>HASTA</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        
                        value={state.endDate}
                        onChange={(evt)=>{
                            
                            setState({...state, endDate:evt.currentTarget.value}) 
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>
            </div>
            <div className="col d-flex align-items-end ml-2 py-2 px-0">
                <IconButton onClick={()=>{

                    props.onSearch(state.startDate, state.endDate)
                    

                }}>
                <Search size={28} className="cursor-pointer" color="#0a5cbe"/>
                </IconButton>

                <IconButton onClick={()=>{

                    setState({...state, endDate:"", startDate:""})
                    props.onClean();

                }}
                >
                <Trash size={28} className="cursor-pointer" color="tomato"/>
                </IconButton>
            </div>
            
        </div>
    )
}
