
import React, { useState, useContext,useEffect } from 'react';
import {Journals, XCircleFill,ArrowLeftSquareFill, Trash } from 'react-bootstrap-icons';

import {showLoader, hideLoader, today, replaceObj} from '../utils/utils';

import { Form, Col, Button } from 'react-bootstrap';

import { UserContext } from '../context/user_context';

import ViewDataLoader from '../components/ui/view_data_loader';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';
import { useHistory, useParams,useRouteMatch, Link } from 'react-router-dom';

import { FormularioGeneralContext } from '../context/formulario_general_context';
import FormulariosGeneralesRecords from '../components/records/formularios_generales_records';
import { IconButton } from '@material-ui/core';


function TransportePersonalMaterialView(){

        let history = useHistory();

        const [validated, setValidated] = useState(false);
        const [title, setTitle] = useState(true);
     
        const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)  
        const {user, setUser} = useContext(UserContext)
        const {alert, showAlert, closeAlert} = useContext(AlertContext)
        const { path, url } = useRouteMatch();

        const onSearchChange = (val) => {
            var search = val.currentTarget.value
            setStateGenerales({...stateGenerales, search});
        }
        const onDateChange = (val) => {
            var fecha = val.currentTarget.value
            setStateGenerales({...stateGenerales, fecha});
        }


    if(stateGenerales.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main" className={!alert.open ? "menu_collapsed": ""}>
             
            <div className="container-fluid position-relative py-1 ">
        
            <div className="d-flex align-items-end py-2">
                <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                history.goBack();
                }}/>
                <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
                <div>
                    <h4 className="pl-1"> CATALOGO: Transporte de Personal y Material</h4>  
                    <h4 className="line-height-xs pl-1">PROYECTO: {stateGenerales.project_name} </h4>
                </div>
            </div>
               
            <div className="d-flex">
                <div className="col-2 pt-3 px-0">
                    <Form.Label>FOLIO:</Form.Label>
                    <Form.Control  type="text" name="buscar" 
                        value={stateGenerales.search}
                        onChange={(evt)=>{
                            onSearchChange(evt)
                        }} 
                    />
                </div>
                <div className="col-2 pt-3 px-0 ml-1">
                    <Form.Group className="p-0 ">
                        <Form.Label>FECHA</Form.Label>
                        <Form.Control
                            required
                            type="date"
                            value={stateGenerales.fecha}
                            onChange={(evt)=>{onDateChange(evt)}}
                        />
                    </Form.Group>
                </div>
                <div className="d-flex align-items-end justify-content-end py-1 px-2">
                    <IconButton onClick={()=>{
                        setStateGenerales({...stateGenerales, fecha:"", search:""});
                     }}>
                        <Trash size={28} className="cursor-pointer" color="tomato"/>
                    </IconButton>
                </div>
                <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                    <Link to={`${url}/nuevo`}>
                        <Button className="primary px-4" >
                            NUEVO 
                        </Button>
                    </Link>
                </div>
            </div>

            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

            <FormulariosGeneralesRecords operation={9}/>

           
 

            </div>

       
        </main>
    )}
        
}


export default TransportePersonalMaterialView;