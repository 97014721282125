import  {recordsServiceId, dependentService, dependentServiceId, recordsService, services,} from "../services/api"//

export const handleNewRow = (projectId, state, setState, operationId) =>{
    //fecha del dia de hoy
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd ;

    //id de nuevo item
    const newId = `newId${Math.floor(Math.random() * (100000 - 0 + 1))}`

    const body ={
        id:newId,
        project_id:projectId,
        operation_id:operationId,
        user_id: 1,
        machinarie_id:1,
        turn:"Primero",
        folio:"",
        date:today,
        operator:1,
        assistant:1,
        auxiliar:1,
        hour_start:0,
        hour_end:0,
        diesel_hr_i:0,
        diesel_hr_f:0,
        electronic_hr_i:0,
        electronic_hr_f:0,
        percussion_hr_i:0,
        percussion_hr_f:0,
        reparation_hours:0,
        satellite_hours:0,
        worked_hours:0,
        effective_hours:0,
        dead_hours:0,
        charge_fuel:0,
        hr_charge_fuel:0,
        machinarie_id:1,
    }
    setState([body,...state])
}

export const handleNewSplitRow = (projectId, state, setState, operationId) =>{
    //fecha del dia de hoy
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd ;

    //id de nuevo item
    const newId = `newId${Math.floor(Math.random() * (100000 - 0 + 1))}`

    const body ={
        id:newId,
        project_id:projectId,
        operation_id:operationId,
        user_id: 1,
        turn:"Primero",
        folio:"",
        date:today,
        operator:1,
        assistant:1,
        auxiliar:1,
        hour_start:0,
        hour_end:0,
        diesel_hr_i:0,
        diesel_hr_f:0,
        reparation_hours:0,
        satellite_hours:0,
        worked_hours:0,
        effective_hours:0,
        dead_hours:0,
        charge_fuel:0,
        hr_charge_fuel:0,
        machinarie_id:1,
    }
    setState([body,...state])
}

export const handleInputChange = ((e, itemId, state, setState)=>{
    const objIndex = state.findIndex((obj) => obj.id == itemId);
    const obj = state[objIndex];
    obj[e.target.name] = e.target.value;
    setState(
    state.map((obj) => [state[objIndex]].find((o) => o.id === obj.id) || obj)
    );
    //console.log(state)
})

export const handleDateFilter = (e,state, setState, dateRange) =>{
    e.preventDefault()
    const dateStart = document.querySelector('#date-start-input').value;
    const dateEnd = document.querySelector("#date-end-input").value
    const dateStartValue = new Date(dateStart).getTime()
    const dateEndValue = new Date(dateEnd).getTime()
    dateRange([dateStart, dateEnd])

    const filteredItems = state.filter((item)=>{
        return new Date(item.date).getTime() >= dateStartValue &&
        new Date(item.date).getTime() <= dateEndValue
    })
    setState(filteredItems)
}

export async function handlePost(array, endpoint) {
    try {
      const itemsToPost = array.map((item)=>{
          //delete item.id
          const {id, ...itemToPost} = item;
          return services("POST" , endpoint , itemToPost)
      })
      const results = await Promise.all(itemsToPost);
      
      const dataPromises = results.map(result => result);
      
      const data = await Promise.all(dataPromises);
      console.log(data)
      return data;

    } catch (err) {
      throw new Error(err);
  }
}

export const handleDeleteUnsaved = ((id, state, setState)=>{
    const newArray = state.filter((item)=>{
        return item.id !== id
    })
    setState(newArray)        
})

export const handleObraRowsAutofill = ((e, item, setItem, context)=>{
    const obraValue = e.target.value
    if(obraValue !== ''){
        const obraData = context.generalObras.data.filter((item)=>{
            return item.id == obraValue
        })
        const zone= `${obraData[0].zone_id}`
        const sector = `${obraData[0].sector_id}`
        if(obraData[0].rmr){
            const rmr = obraData[0].rmr
            setItem({...item,...{obra:obraValue,zona:zone,sector:sector,rmr:rmr}})
            console.log(item)
        }else{
            setItem({...item,...{obra:obraValue,zona:zone,sector:sector}})
            console.log(item)
        }
    }
})

export const handleObraRecordsAutofill = (e, id, context, operationPost, setOperationPost) =>{

    const obraValue = e.target.value

    if(obraValue !== ""){
        const obraData = context.generalObras.data.filter((item)=>{
            return item.id == obraValue
        })

        const zone= `${obraData[0].zone_id}`
        const sector = `${obraData[0].sector_id}`
        const rmr = obraData[0].rmr

        const obj = operationPost.filter((item)=>{
            return item.id == id
        })
        console.log(sector)
        const newObj = [{...obj[0],...{zona:zone,sector:sector,rmr:rmr}}]
        console.log(newObj)

        const newOperationPostArray = operationPost.map(obj => newObj.find(o => o.id === obj.id) || obj);

        setOperationPost(newOperationPostArray)
    }
}

export const message = "ATENCIÓN: las ediciones de algunos campos se ven reflejados en los formularios de frente de voladuras"