import { useContext, useState } from "react"
import ObrasRecords from "../components/records/obras_records"
import ViewDataLoader from "../components/ui/view_data_loader"
import { AlertContext } from "../context/alert_context"
import { ObrasContext } from "../context/obras_context"
import { UserContext } from "../context/user_context"
import { Form, Button, Col} from 'react-bootstrap'
import {Journals, XCircleFill, FileSpreadsheetFill, ArrowLeftSquareFill} from 'react-bootstrap-icons'
import ActionAlert from "../components/ui/alert"
import {hideLoader, replaceObj, selectFromObjbyId, showLoader, today} from '../utils/utils'
import { services } from "../services/api"
import {useHistory} from 'react-router'

let editing = false
let editingId

export default function ObrasView(props) {
    let history = useHistory()
    const [validated,setValidated] = useState(false)
    const [title,setTitle] = useState(true)
    const {state,setState} = useContext(ObrasContext)
    const {user} = useContext(UserContext)
    const {alert,showAlert,closeAlert} = useContext(AlertContext)

    const showForm = (data=undefined) =>{
        let reason_edition = document.getElementById('reason_edition')
        reason_edition.classList.add('d-none')

        let form = document.getElementById('main_form')
        
        if(data !== undefined){
            reason_edition.classList.remove('d-none')
            setTitle(false)
            fillForm(data)
        }
        form.classList.add('d-block')
        form.classList.remove('d-none')
    }

    const hideForm = () =>{
        let form = document.getElementById('main_form')
        clearForm()
        form.classList.add('d-none')
        form.classList.remove('d-block')
    }

    const onChangeFilter = (evt) =>{
        setState({...state,filter:parseInt(evt.currentTarget.value)})
    }

    const onTypeChange = (evt) =>{
        let value = evt.currentTarget.value === '' ? '' : parseInt(evt.currentTarget.value)
        setState({...state,tipoF:value})
    }

    const onStartDateChange = (evt) =>{
        setState({...state,fechaStart:evt.currentTarget.value})
    }

    const onEndDateChange = (evt) =>{
        setState({...state,fechaEnd:evt.currentTarget.value})
    }

    const onFaseChange = (evt) =>{
        setState({...state,faseF:evt.currentTarget.value})
    }

    const onStateChange = (evt) =>{
        setState({...state,estadoF:evt.currentTarget.value})
    }

    const fillForm  = (data) =>{
        let {elements} = document.getElementById('obras_form')
        elements.namedItem('reason_edition').disabled = false
        for(const [key,value] of Object.entries(data)){
            const field = elements.namedItem(key)
            if(key !== 'reason_edition'){
                field && (field.value = value)
            }
        }
        editing = true
        editingId = data.id
    }

    const clearForm = () =>{
        let form = document.getElementById('obras_form')
        editing = false
        editingId = undefined
        form.reset()
        setTitle(true)
        setValidated(false)
    }

    const handleSubmit =async (event) =>{
        event.preventDefault()
        event.stopPropagation()
        const form = event.currentTarget
        var formData = new FormData(form)
        
        var body = {}
        formData.forEach((value,key)=>body[key]=value)
        body.project_id = state.project_id

        if(form.checkValidity()){
            var res;
            showLoader()
            if(!editing){
                res = await services('POST','works',body)
            }else{
                res = await services('PUT', 'works', body, editingId)
            }

            if(res.status){
                if(!editing){
                    var obras = state.obras
                    var obrasF = state.obrasF

                    var obra = res.data
                    var project = selectFromObjbyId(state.projects, parseInt(obra.project_id))
                    obra.project = project
                    obra.deleted_at = null
                    
                    obras.unshift(obra)
                    obrasF.unshift(obra)
                    setState({...state,obras,obrasF})
                    showAlert('success', '¡Listo! Registro agregado exitosamente.')
                }else{
                    var obra = res.data
                    var project = selectFromObjbyId(state.projects,parseInt(obra.project_id))

                    obra.project = project

                    var obras = replaceObj(state.obras,obra)
                    var obrasF = replaceObj(state.obrasF,obra)

                    setState({...state,obras,obrasF})
                    showAlert('success', '¡Listo! Registro editado exitosamente.')
                }
            }else{
                var message = ''
                Object.keys(res.errors).map(key=>
                    message = message + ' ' + res.errors[key]
                )
                showAlert('error',message)
            }
            hideLoader()
            hideForm()
            clearForm()
        }
    }

    if(state.loading){
        return(
            ViewDataLoader()
        )
    }else{
        return(
            <main role="main" className="">
                <div className="container-fluid position-relative min-h py-1">
                    <div className="d-flex align-items-end py-2">
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                            history.goBack();
                        }}/>
                        <h2><Journals size={25} color='#0a4cbe'></Journals>Obras</h2>
                    </div>
                    <div className="d-flex">
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Desde: </Form.Label>
                            <Form.Control type="date" name="date_start" onChange={onStartDateChange}/>
                        </div>
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Hasta: </Form.Label>
                            <Form.Control type="date" name="date_end" onChange={onEndDateChange}/>
                        </div>
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Estatus: </Form.Label>
                            <Form.Control as='select' name='estatus' onChange={onChangeFilter}>
                                <option value="10">Todos</option>
                                <option value="1">Activo</option>
                                <option value="0">Desactivado</option>
                            </Form.Control>
                        </div>
                        
                    </div>
                    <div className="d-flex">
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Tipo de Obra: </Form.Label>
                            <Form.Control as="select" name="work_type_id" onChange={onTypeChange}>
                                <option value="">Selecciona</option>
                                {state.tipos.map(tipo=>{
                                    return <option value={tipo.id}>{tipo.type}</option>
                                })}
                            </Form.Control>
                        </div>
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Fase: </Form.Label>
                            <Form.Control as='select' name='fase' onChange={onFaseChange}>
                                <option value="">Selecciona</option>
                                <option value="Apex">Apex</option>
                                <option value="Opex">Opex</option>
                            </Form.Control>
                        </div>
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Estado: </Form.Label>
                            <Form.Control as='select' name='state' onChange={onStateChange}>
                                <option value="">Selecciona</option>
                                <option value="Pendiente">Pendiente</option>
                                <option value="Terminada">Terminada</option>
                            </Form.Control>
                        </div>
                        <div className="col-2 d-flex align-items-end justify-content-end py-2 px-0">
                            <Button className="primary px-4" onClick={()=>showForm()}>Nuevo</Button>
                        </div>
                        <div className="col-2 d-flex align-items-end justify-content-end py-2 px-0">
                            <Button className="btn success px-4" variant="success"><a className="text-light" href={`https://mineria.canteradigital.mx/api/works/excel/${state.project_id}?desde=${state.fechaStart}&hasta=${state.fechaEnd}&status=${state.filter===10?'Todos':state.filter===1?'Activos':'Inactivos'}&tipo=${state.tipoF}&fase=${state.faseF}&state=${state.estadoF}`}><FileSpreadsheetFill/> Descargar</a></Button>
                        </div>
                    </div>
                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert}/>
                    <ObrasRecords onEdit={(data)=>showForm(data)}/>
                    <div className="catalogo_form_modal h-100 d-none py-3" id='main_form'>
                        <Form validated={validated} onSubmit={handleSubmit} id='obras_form'>
                            <div className="container w-50 position-relative bg-white rounded pt-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5>{title ? 'NUEVA' : 'EDITAR'} OBRA</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group as={Col}>
                                            <Form.Label>Fecha: </Form.Label>
                                            <div className="form-control disabled">{today()}</div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group as={Col}>
                                            <Form.Label>Responsable: </Form.Label>
                                            <div className="form-control disabled">{user.name}</div>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group as={Col}>
                                            <Form.Label>Nombre de la Obra</Form.Label>
                                            <Form.Control type="text" name='name' required/>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo de Obra</Form.Label>
                                            <Form.Control required as='select' type="select" name='work_type_id'>
                                                <option value=''>Selecciona</option>
                                                {state.tipos.map(tipo=>{
                                                    return <option key={tipo.id} value={tipo.id}>{tipo.type}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Fecha de Inicio</Form.Label>
                                            <Form.Control required type="date" name="start_date"/>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Zona</Form.Label>
                                            <Form.Control required as='select' type="select" name='zone_id'>
                                                <option value=''>Selecciona</option>
                                                {state.zonas.map(zona=>{
                                                    return <option key={zona.id} value={zona.id}>{zona.name}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>RMR</Form.Label>
                                            <Form.Control required as='select' type="select" name='rmr'>
                                                <option value=''>Selecciona</option>
                                                {state.rocas.map(roca=>{
                                                    return <option key={roca.id} value={roca.id}>{roca.quality}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>PEP</Form.Label>
                                            <Form.Control type="text" name='pep' required/>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Button type="submit">GUARDAR</Button>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">

                                        <Form.Group as={Col}>
                                            <Form.Label>Fase</Form.Label>
                                            <Form.Control required as='select' type="select" name='fase'>
                                                <option value=''>Selecciona</option>
                                                <option value="Apex">Apex</option>
                                                <option value="Opex">Opex</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Control required as='select' type="select" name='state'>
                                                <option value=''>Selecciona</option>
                                                <option value="Pendiente">Pendiente</option>
                                                <option value="Terminada">Terminada</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Sector</Form.Label>
                                            <Form.Control required as='select' type="select" name='sector_id'>
                                                <option value=''>Selecciona</option>
                                                {state.sectores.map(sector=>{
                                                    return <option key={sector.id} value={sector.id}>{sector.sector}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <div id="reason_edition">
                                            <Form.Group as={Col}>
                                                <Form.Label>Motivo de edición</Form.Label>
                                                <Form.Control as="textarea" rows={3} name='reason_edition' disabled required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Este campo es obligatorio
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="cta position-absolute top right p-2" onClick={hideForm}>
                                    <XCircleFill size='45'></XCircleFill>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </main>
        )
    }
}