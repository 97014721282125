import React,{useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { PencilSquare } from 'react-bootstrap-icons';
import Switchy from '../triggers/switchy';
import { MaquinariaContext } from '../../context/maquinaria_context';
import {services} from '../../services/api';
import { allowActions, selectFromObjbyId } from '../../utils/utils';
import { IconButton } from '@material-ui/core';
import { UserContext } from '../../context/user_context';



const columns = [
  // { id:'id', label:'id', minWidth: 170 },
  { id:'economic_number', label:'No. Económico', minWidth: 170 },
  { id:'machinary_projects', label:'Proyectos', minWidth: 200 },
  { id:'machinary_operations', label:'Operaciones', minWidth: 280 },
  { id:'descriptions', label:'Descripcion', minWidth: 200 },
  { id:'equipment', label:'Equipo', minWidth: 170 },
  { id:'brand', label:'Marca', minWidth: 170 },
  { id:'model', label:'Modelo', minWidth: 170 },
  { id:'year', label:'Año', minWidth: 170 },
  { id:'serie', label:'Serie', minWidth: 170 },
  { id:'capacity', label:'Capacidad', minWidth: 170 },
  { id:'engine_fuel', label:'Motor/Conbustible', minWidth: 170 },
  { id:'engine_brand', label:'Motor/Marca', minWidth: 170 },
  { id:'engien_model', label:'Motor/Modelo', minWidth: 170 },
  { id:'engien_serie', label:'Motor/Serie', minWidth: 170 },
  { id:'engien_capacity', label:'Capacidad Lts', minWidth: 170},
  { id:'projection_under', label:'Bajo Lts.', minWidth: 170 },
  { id:'projection_medium', label:'Medio Lts.', minWidth: 170 },
  { id:'proyection_high', label:'Alto Lts.', minWidth: 170 },
 
  { id:'bar', label:'Barreno/Long.', minWidth: 170 },
  { id:'bar_mts', label:'Barreno/Mts Ef.', minWidth: 170 },
  { id:'arrival_date', label:'Fecha de llegada', minWidth: 170 },
  { id:'editar', label:'Editar', minWidth: 100 },
  { id:'activar', label:'Activar', minWidth: 100 },
 
];


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});

function MaquinariaRecords(props) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {state, setState} = useContext(MaquinariaContext)
  const {user, setUser} = useContext(UserContext)


  useEffect(() => {
  
    var data = state.data.filter((item)=>{
      var active = item.deleted_at === null ? 1 : 0;

      if(state.filter === 10){
        return true;
      } 
      return active === state.filter;
     
    })
    setPage(0);
    setState({...state, dataF:data});

  }, [state.filter])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchOperations = (arr) => {

    let operation = ""
    arr.forEach((el,i) => {
      
      if(el.operation !== ""){
        if(i == arr.length - 1){
          operation = operation  + el.operation;
        }else{
          operation = operation  + el.operation + ", ";
        }
      }

    });
    return operation;

  }
  const fetchProyectos = (arr) => {


    let proyecto = ""
    arr.forEach((el, i) => {
  
      if(i == arr.length - 1){
        proyecto = proyecto  + el.projects.name;
      }else{
        proyecto = proyecto  + el.projects.name + ", ";
      }
    });
    return proyecto;

  }
  const matchProject  = (row) => {
    return row.project !== null ? row.project.name  : "";
  }

  const handleSwitch = async (data,id, val)=>{

   
      var res;
      if(!val){
        res = await services("DELETE","machinaries",undefined,id);
      }else{
        res = await services("GET","machinaries",undefined,id);
      }
      if(res.status){
        var data = data
        var maquinaria = selectFromObjbyId(data, id);
        maquinaria.deleted_at = res.data.deleted_at;
        setState({...state, data })
      }

   
  }

  const cellSpacing = {
    paddingRight:10,
    paddingLeft:5,
    paddingTop:5,
    paddingBottom:0,
  }

  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">


          <TableHead>
            <tr>
              <td  className="bg-blue text-white font-weight-bold" align="center"  colSpan="10">DATOS DEL EQUIPO</td>
              <td  className="bg-blue text-white font-weight-bold" align="center" colSpan="4">DATOS DEL MOTOR</td>
              <td  className="bg-blue text-white font-weight-bold" align="center" colSpan="3">PROYECCIÓN DE COMBUSTIBLE</td>
              <td  className="bg-blue text-white font-weight-bold" align="center" colSpan="2">ESPECIFICACIONES DE BARRENACIÓN</td>
            </tr>
          </TableHead>

          <TableHead>
            <TableRow>
              {columns.map((column) => {
              
                  return (<TableCell
                    className={
                      column.id==="engine_fuel"  || column.id === "projection_under" || column.id==="economic_number"  ? "section_left" : column.id === "proyection_high" || column.id === "bar_mts" ? "section_right" : ""
                    }
                    key={column.id}
                    align={column.align}
                    style={{ 
                      minWidth: column.minWidth,
                      
                      paddingBottom:10,
                      paddingTop:10,
                      paddingLeft:5,
                      paddingRight:10,
                      lineHeight:1.2
                     }}
                  >
                    {column.label}
                  </TableCell>)
                
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.dataF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

              let active = row.deleted_at === null ? 1 : 0;
              let switchActive = row.deleted_at === null ? true : false;
              
              let allowEdit = allowActions(user,"edit") === false ? true : !switchActive;
              let allowSwitch = allowActions(user,"activate");
              

      
        
              
             // if(state.filter === 10 || active === state.filter){               
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    

                    {columns.map((column) => {
                      var value = row[column.id];

                     
                      return (
                        <TableCell 
                        className={
                          column.id==="engine_fuel" || column.id === "projection_under" || column.id==="economic_number"  ? "section_left" : column.id === "proyection_high" || column.id === "bar_mts" ? "section_right" : ""
                        }
                        key={column.id} align={column.align} style={cellSpacing }>

                          {
                            column.id === "machinary_operations" ?  fetchOperations(value) :
                            // column.id === "proyect_id" ? matchProject(row) :
                            column.id === "machinary_projects" ? fetchProyectos(value) :
                            column.id === "equipment" ?  value.name :
                            column.id === "bar" ?  value.length :
                            column.id === "bar_mts" ?  row.bar.efficient_meters :
                            column.id === "editar" ? 
                            <IconButton disabled={allowEdit} onClick={()=>{props.onEdit(row)}}>
                              <PencilSquare size={20} className="cursor-pointer" color={switchActive && !allowEdit ? "tomato" : "grey"}/>
                            </IconButton>
                            : 
                            column.id === "activar" ?  
                            <Switchy disabled={!allowSwitch} value={switchActive} onChange={(val)=>{
                              handleSwitch(state.data, row.id, val)
                            }} /> 
                            : value
                          }

                          


                        </TableCell>
                  
                      )
                    })}
                  
                    
                  </TableRow>
                );
             // }
              //return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.dataF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default MaquinariaRecords;