import React,{useState, useEffect} from 'react'
import  {services,} from "../../services/api"
import {PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import DataListInput from 'react-datalist-input';
import {message} from "../capturaUtils"
import {ZonasContent, TurnContent} from "../capturaUtils/CapturaInputs"


function ServiciosAdministrativosRow({f, context, showAlert}) {
    const [editing, setEditing] = useState(false)
    const [ item, setItem ] = useState(f)

    useEffect(()=>{
        setItem(f)
    },[f])

    useEffect(()=>{
        if(typeof item.id == 'string'){
            setEditing(true)
        }
    },[])

    const handleOperationItemChange = ((e)=>{
        const value = e.target.value
        console.log("change")
        setItem({
            ...item,
            [e.target.name]: value
          });
    })

    const handlePutReq =  (async(itemId)=>{
        setEditing(false)
        const res = await services("PUT" , "administrative_services_form" ,  item, itemId)
        if(res.status == true){
            console.log(res)
            setItem(res.data)
            showAlert("success",`Listo, datos guardados correctamente`)
        }else{
            console.log(res)
            setEditing(true)
            showAlert("error",`Error al guardar sus datos.Compruebe sus datos e intente de nuevo.`)
        }
    })
    
    const handleEditing = (()=>{
        setEditing(true)
    })

  return (
    <tr>
        <td>
            <select type="text" className="form-control large-input" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="colaborador" value={item.colaborador} required>
                <option value="">Seleccionar</option>
                {context.users.map((item)=>(
                    <option value={item.id}>{item.name}</option>
                ))}
            </select>
        </td>
        <td>
            <select type="text" className="form-control large-input" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="turno" value={item.turno} required>
                <TurnContent/>
            </select>
        </td>
        <td>
            <input type="date" className="form-control large-input" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="fecha" value={item.fecha} required/>
        </td>
        <td>
            <select type="text" className="form-control large-input" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="zona" value={item.zona} required>
                <ZonasContent zonas={context.zonasListado}/>
            </select>
        </td>
        <td>
            <input type="text" className="form-control large-input" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="actividad" value={item.actividad} required/>
        </td>
        <td>
            {editing ? 
                <button className='btn btn-primary mx-2 text-nowrap' onClick={() => {if(window.confirm(message)){handlePutReq(item.id)}}}>Guardar Cambio</button> :
                <PencilSquare size="25" color="#606060" className='cursor-pointer mx-2' onClick={handleEditing}/>
            }
        </td>
    </tr>
  )
}

export default ServiciosAdministrativosRow