import { ButtonGroup, IconButton } from '@material-ui/core'
import React, {createContext, useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import { ArrowLeftSquareFill, Back, Person, Trash, BlockquoteLeft, Search, X } from 'react-bootstrap-icons'

import { UserContext } from '../context/user_context'
import { Form, Col, Button } from 'react-bootstrap';

import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { ProyectosContext } from '../context/proyectos_context';
import { hideLoader, removeFromObjbyId, replaceObj, selectFromObjbyId, showLoader } from '../utils/utils';
import ViewDataLoader from '../components/ui/view_data_loader';
import { Doughnut } from 'react-chartjs-2';
import { dependentService, recordsService, recordsServiceId, services, singleService } from '../services/api';
import FormularioGeneral from '../components/formulario_general';
import { BarrenacionJumboContext } from '../context/barrenacion_jumbo_context';
import DataListInput from 'react-datalist-input';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';
import { FormularioGeneralContext } from '../context/formulario_general_context';

const Context = createContext();
function AmaciceLimpiezaDetalleView() {

    

    const { user } = useContext(UserContext);        
    const [proyecto, setProyecto] = useState(undefined);

    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)  

    const {state,setState} = useContext(ProyectosContext);
    const [local, setLocal] = useState({forms:[], form_id:null, operation:{id:3, name:"AMACICE Y LIMPIEZA DE OBRA"},})
    const {alert, showAlert, closeAlert} = useContext(AlertContext)



    let { project, id } = useParams();
    let history = useHistory();
    let location = useLocation();
    const { path, url } = useRouteMatch();

    useEffect(async()=>{
        var forms = await dependentService("GET", "amacine_and_cleaning")
       
        setLocal({...local, forms:forms.data, form_id:id });
        setLocal({...local, forms:forms.data.filter((el) => el.folio === parseInt(id)), form_id:id });
    },[])

    useEffect(()=>{
        
        let proyecto = selectFromObjbyId(state.projects,parseInt(project))
        if(proyecto === undefined){
           
            history.replace(`/home/proyectos` );
        }else{
            setProyecto(proyecto);
        }
        
    },[project]);



    const onRemoveForm = (id = "nuevo") => {
        let forms;
        if(id == "nuevo"){
            forms = local.forms;
            forms.pop();
        }else{
            forms = removeFromObjbyId(local.forms, id);
        }
        setLocal({...local, forms})
        showAlert("success", "¡Listo! formulario eliminado exitosamente.")
    }


    if(stateGenerales.loading){
        return (
            ViewDataLoader()
        )
    }else{
        
        return (
            <Context.Provider value={{
                local,
                setLocal,
                showLoader,
                hideLoader,
                onRemoveForm
            }}>
            <main role="main" className="">

                <div className="d-flex pl-1 border-bottom sticky-left">

                    
                    <IconButton size="medium" disabled={false} onClick={()=>{
                        history.goBack();
                    }}>
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe"/>
                    </IconButton> 

               
                    {local.form_id === "nuevo" ? 
                    <Button type="submit" className="cta-title cta--disabled" >NUEVO FORMULARIO</Button>
                    :
                    <Button type="submit" className="cta-title" onClick={()=>{
                        var f = local.forms;
                       if(f.length < 15){
                            f.push("nuevo");
                            setLocal({...local,forms:f});
                        }
                        
                    }}>NUEVO FORMULARIO</Button>
                    }




          
                </div>

                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                



                

                <div className="general_forms container-fluid p-0">

          
                    <FormularioGeneral data={selectFromObjbyId(stateGenerales.generales,parseInt(id))} 
                        operation={local.operation}
                        onCreate={(id)=>{
                            setLocal({...local, form_id:id})
                        }} 
                        showLoader={()=>{
                            showLoader()
                        }}/> 


                    {
                    local.forms.map((el, i)=>{
                      
                            if(el === "nuevo"){
                                return(<AmaciceForm key={i} data={undefined} general_form={selectFromObjbyId(stateGenerales.generales,parseInt(id))}/>) 
                            }else{
                                return(<AmaciceForm key={i} data={el} general_form={selectFromObjbyId(stateGenerales.generales,parseInt(id))}/>)
                            }
                        
                    })
                    }


                    
                   
                </div>

           
            </main>
           
            </Context.Provider>
        )
    }
}


function AmaciceForm(props) {



    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)  
    const {local, setLocal, showLoader, onRemoveForm} = useContext(Context)  
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({obra:null, zona:null, item:"", id:null})
    const {alert, showAlert, closeAlert} = useContext(AlertContext)

    useLayoutEffect(() => {
        var view = document.querySelector("main")
        view.scrollTo({left: view.scrollWidth, behavior: 'smooth'});
    }, [])

    useEffect(()=>{

        setState({
            ...state, 
            obra:props.data.obra, 
            zona:props.data.zona,
            rmr:props.data.rmr,
            barras:props.data.number_holes,
            mtsEficientes:props.data.efficient_length,
            type:props.data.concept,
            avance:props.data.per_avance,
            item:props.data.item,
            id:props.data.id !== null ? props.data.id : null
            })


    },[])


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        
        formData.forEach((value, key) => body[key] = value);
        body.project_id = stateGenerales.project_id;
        body.generalform_id = local.form_id;
        body.obra = state.obra;
        body.item = state.item;
        body.zona = state.zona;
  

        body.hora_final = body.hora_final + ":00";
        body.hora_inicial = body.hora_inicial + ":00";
        body.folio = local.form_id;
       
        
   
 

        if (form.checkValidity() === true) {

            showLoader();
            
            var response;

            if(state.id === null){

   
                response = await services("POST","amacine_and_cleaning",body);
            
                if(response.status){
                   
                    setState({...state, id:response.data.id})
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")

                }else{
                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }else{

             
                response = await services("PUT","amacine_and_cleaning",body, state.id);
            

                if(response.status){
                 
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }else{

                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                    var view = document.querySelector("main")
                    view.scrollTop = 0;

                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }
         
        }

        setValidated(true);
    };

  

    let obras_by_month = [];
    if(props.general_form !== undefined){
        let created_month = new Date(Date.parse(props.general_form.date)).getMonth() + 1
        obras_by_month = stateGenerales.obras.filter((item) => item.month == created_month)
    }else{
        obras_by_month = stateGenerales.obras.filter((item) => item.month == (new Date().getMonth() + 1 ))
    }    
    
    const obras = useMemo(
        () =>
        obras_by_month.map((oneItem) => ({
            label: oneItem.work.item,
            key: oneItem.id,
            ...oneItem,
          })),
        [stateGenerales.obras]
    );



      let hora_inicial = null;
      let hora_final = null;

      if(props.data.hora_inicial !== null && props.data.hora_final !== null){
        hora_inicial = props.data.hora_inicial.split(":")[0] +":"+ props.data.hora_inicial.split(":")[1]
        hora_final = props.data.hora_final.split(":")[0] + ":"+props.data.hora_final.split(":")[1]
      }



    return (
        <div className="form_frente pb-5 mx-2">
            <div className="bg-blue text-white d-flex justify-content-center align-items-center p-2 mb-2 font-weight-bold">
                Formulario Amacice y Limpieza de Obra {props.data.id}
                    <div className="cta position-absolute  right  pr-3" onClick={async ()=> {

                    
                        if(props.data.id !== null){
                            showLoader()
                            let response = await services("DELETE","amacine_and_cleaning",undefined,props.data.id);
                            if(response.status){
                                onRemoveForm(props.data.id)
                            }
                            hideLoader();
                        }else{
                            onRemoveForm()
                        }
                    
                        
                        

                    }}>
                        <X size="30"></X>
                    </div>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Col}>
                    <Form.Label>SECTOR</Form.Label>
                    <Form.Control as="select" name="sector" defaultValue={props.data.sector} required>
                     <option value="">Seleccionar</option>
                        {stateGenerales.sectores.map((sector,i)=>{
                            return (<option key={i} value={sector.id}>{sector.sector}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>OBRA</Form.Label>
                    <div className="form-control disabled">{state.obra}</div>
                </Form.Group>


                <Form.Group as={Col} >
                    <Form.Label>ITEM</Form.Label><br></br>
                    <DataListInput
                        inputClassName="form-control-n"
                        placeholder=""      
                        value={state.item}
                        items={obras}
                        onInput = {(a) => {
                            //setLocal({...local,});
                        }}
                        onSelect={(el)=>{
                         
                            setState({...state, item:el.work.item, obra:el.work.name, zona:el.zone.name, type:el.type_drilling.name, rmr:el.rock_indice.id});
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                {
                    obras.length === 0 ? 
                    <div className="form-message form-message--red">
                        No hay obras dentro del mes.
                    </div> : null
                }

              

                <Form.Group as={Col}>
                    <Form.Label>ZONA</Form.Label>
                    <div className="form-control disabled">{state.zona}</div>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>SECCIÓN</Form.Label>
                    <Form.Control as="select" name="seccion" defaultValue={props.data.seccion} required onChange={(evt)=>{

                        let barras = selectFromObjbyId(stateGenerales.barrenacion, parseInt(evt.currentTarget.value))
                   
                        setState({...state, barras: barras !== undefined ? barras.number_bars : ""})

                    }}>
                        <option value="">Seleccionar</option>
                        {stateGenerales.barrenacion.map((barre,i)=>{
                            return (<option key={i} value={barre.id}>{barre.section}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Group as={Col}>
                    <Form.Label>RMR</Form.Label>
                    <Form.Control as="select" name="rmr" value={state.rmr} required onChange={(evt)=>{
                        setState({...state, rmr:evt.currentTarget.value});
                    }}>
                     <option value="">Seleccionar</option>
                        {stateGenerales.rmr.map((rmr,i)=>{
                            return (<option key={i} value={rmr.id}>{rmr.quality}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group> */}

                <div className="d-flex">
                    <div className="col p-0">
                        <Form.Group as={Col}  className="">
                            <Form.Label>HORA INICIO</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={hora_inicial}
                                name="hora_inicial"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0">
                        <Form.Group as={Col}  className="">
                            <Form.Label>HORA FIN</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={hora_final}
                                name="hora_final"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                {/* <Form.Group as={Col}>
                    <Form.Label>OBRA</Form.Label>
                    <div className="form-control disabled"></div>
                </Form.Group> */}

                {/* <Form.Group as={Col}>
                    <Form.Label>CANTIDAD DE BARRENOS</Form.Label>
                    <div className="form-control disabled">{state.barras}</div>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>BARRENO DADOS</Form.Label>
                    <Form.Control type="number" min="0"  name="holes_given" defaultValue={props.data.holes_given} required onChange={(evt)=>{

                        let avance = (parseInt(evt.currentTarget.value) * 1) / state.barras;
                        setState({...state, avance:avance});
                    }}/>
                    <Form.Control.Feedback type="invalid">
                    Este campo es obligatorio. Numeros positivos.
                    </Form.Control.Feedback>
                </Form.Group> */}


                {/* <Form.Group as={Col}>
                    <div className="row">
                        <div className="col-md-6 p-0">
                        <Form.Group as={Col}>
                            <Form.Label>Longitud de barra</Form.Label>
                            <Form.Control as="select"  name="bar_length" defaultValue={props.data.bar_length} onChange={(evt)=>{

                                    let longitudbarra = selectFromObjbyId(stateGenerales.bars, parseInt(evt.currentTarget.value))
                                    
                                    setState({...state, mtsEficientes:longitudbarra !== undefined ? longitudbarra.efficient_meters : ""})

                                    }}>
                            <option value ="">Seleccionar</option>
                            {stateGenerales.bars.map((bar, i)=>{
                                return (<option key={i} value ={bar.id}>{bar.length}</option>)
                            })}
                                
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                        </div>
                        <div className="col-md-6 p-0">
                        <Form.Group as={Col}>
                            <Form.Label>Metros eficientes</Form.Label>
                            
                            <div className="form-control disabled">{state.mtsEficientes}</div>
                    
                        </Form.Group>
                        </div>
                    </div>
                </Form.Group> */}

                {/* <Form.Group as={Col}>
                    <Form.Label>CONCEPTO</Form.Label>
                    <div className="form-control disabled">{state.type}</div>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>% DE AVANCE</Form.Label>
                    <div className="form-control disabled">{state.avance}</div>
                </Form.Group> */}

                <Form.Group as={Col}  className="">
                    <Form.Label>ACTIVIDAD EJECUTADA</Form.Label>
                    <Form.Control type="number"  name="actividad_ejec" defaultValue={props.data.actividad_ejec} required />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}  className="">
                    <Form.Label>DISTANCIA</Form.Label>
                    <Form.Control type="number"  name="distancia" defaultValue={props.data.distancia} required />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>OBSERVACIONES</Form.Label>
                    <Form.Control as="textarea" defaultValue={props.data.observaciones} rows={3} name="observaciones" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>
{/* 
                <Form.Group as={Col}>
                    <Form.Label>Estatus</Form.Label>
                    <Form.Control as="select" name="status" defaultValue={props.data.status} required>
                        <option value ="">Seleccionar</option>
                        <option value ="Pendiente">Pendiente</option>
                        <option value ="Terminado">Terminado</option>
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group> */}

                <Form.Group as={Col}>
                    <Button type="submit">GUARDAR</Button>
                </Form.Group>


            </Form> 
        </div>
    )
}

AmaciceForm.defaultProps = {
    data:{
        id:null,
      
        hora_final: "",
        generalform_id: null,

        item: "",
     
        obra: "",
        observation: "",
        actividad_ejec:"",
        project_id: null,
   
        seccion: "",
        sector: "",
        hora_inicial: "",

        zona: ""
    
    } 
}

export default AmaciceLimpiezaDetalleView
