import React,{useState, useEffect, useContext} from 'react'
import  {dependentServiceId, getOperationRecords} from "../services/api"//
import ViewDataLoader from '../components/ui/view_data_loader';
import { AlertContext } from '../context/alert_context';
import { useHistory, useParams,useRouteMatch, Link } from 'react-router-dom';
import { FormularioGeneralContext } from '../context/formulario_general_context';
import ServiciosGeneralesRecords from "./ServiciosGeneralesRecords"

function RezagadoView(){

    let { id } = useParams();
    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)  
    const {alert, showAlert, closeAlert} = useContext(AlertContext)
    const [generalItems, setGeneralItems] = useState([])
    const [operationItems, setOperationItems] = useState([])

    useEffect(async()=>{
        var generalData = await getOperationRecords(7)
        setGeneralItems(generalData.data.filter(item=>item.project_id == id).reverse())
        var operationData = await dependentServiceId("GET", "general_services_forms",1)  
        setOperationItems(operationData.data.filter(item=>item.project_id == id))
        console.log("general", generalData)
        console.log("operation", operationData)
    },[])

    if(stateGenerales.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main" className={!alert.open ? "d-flex flex-column menu_collapsed px-3": "d-flex flex-column px-3"}>
            <ServiciosGeneralesRecords generalContent={generalItems} operationContent={operationItems} context={stateGenerales}/>
        </main>
    )}        
}

export default RezagadoView;