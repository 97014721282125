import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow';
import { PencilSquare } from 'react-bootstrap-icons';
import { IconButton } from '@material-ui/core';
import { useContext, useState } from 'react'
import { PlaneacionContext } from '../../context/planeacion_context'


const columns = [
    {id:'obra',label:'Nombre de la Obra', minWidth:170},
    {id:'plan_mes',label:'Producción Plan Mes', minWidth:170},
    {id:'editar',label:'Acciones',minWidth:170}
]

const useStyles = makeStyles({
    root : {
        width: '100%'
    }
})

export default function PlaneacionProduccionRecords(props) {
    const {state,setState} = useContext(PlaneacionContext)
    const classes = useStyles()
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(10)

    const handleChangePage = (event, newPage) =>{
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) =>{
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    return(
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map(column=>{
                                return(
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth: column.minWidth,
                                            paddingBottom:10,
                                            paddingTop:10,
                                            paddingLeft:5,
                                            paddingRight:10,
                                            lineHeight:1.2
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.prodF.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage).map(row=>{
                            return(
                                <TableRow>
                                    {columns.map(column=>{
                                        let value = row[column.id]
                                        return(
                                            <TableCell>
                                                {column.id==='editar'?
                                                    <IconButton onClick={()=>{props.onEdit(row)}}>
                                                        <PencilSquare size={20} className='cursor-pointer' color='tomato'></PencilSquare>
                                                    </IconButton>
                                                    :
                                                    value
                                                }
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination 
                animation={false}
                rowsPerPageOptions={[10,25,100]}
                component='div'
                count={state.prodF.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
}