
import React, { useState,useContext,useEffect } from 'react';
import {ArrowLeftSquareFill, Journals, XCircleFill } from 'react-bootstrap-icons';

import OrigenesDestinosRecords from '../components/records/origenes_destinos_records'
import ViewDataLoader from '../components/ui/view_data_loader'
import {showLoader, hideLoader, replaceObj,removeFromObjbyId, today, selectFromObjbyId} from '../utils/utils';


import { Form, Col, Button } from 'react-bootstrap';
import { OrigenesDestinosContext } from '../context/origenes_destinos_context';
import { services } from '../services/api';
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import EspeciBarrenacionRecords from '../components/records/especi_barrenacion_records';
import { EspeciBarrenacionContext } from '../context/especi_barrenacion_context';
import { UserContext } from '../context/user_context';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';
import PresupuestoObrasRecords from '../components/records/presupuesto_obras_records';
import { PresupuestoObrasContext } from '../context/presupuesto_obras_context';
import PresupuestoSoporteObrasRecords from '../components/records/presupuesto_soporte_obras_records';
import { PresupuestoSoporteObrasContext } from '../context/presupuesto_soporte_obras_context';




let dataEditing;
let editing = false;
let editingId;

function PresupuestoSoporteObrasView(){

    let history = useHistory();
   
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        title:true,
        editing:false,
        form:false,
        plantilla:undefined,
        tipo:undefined,
    });
    
    const {statePresupuestoSoporte, setStatePresupuestoSoporte} = useContext(PresupuestoSoporteObrasContext)
    const {user} = useContext(UserContext)
    const {alert, showAlert, closeAlert} = useContext(AlertContext)


    const handleSubmitPresupuesto = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
    
        body.project_id = statePresupuestoSoporte.project_id;
        // body.folio = 10;
        // body.operaciones = "0";
        console.log(body)


        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","support_budget_works", body);
                console.log(res);
               
                
            }else{
                res = await services("PUT","support_budget_works",body,editingId);
            
            }

            if(res.status){

            if(!editing){
                let presupuestos = statePresupuestoSoporte.presupuestos;
                let presupuestosF = statePresupuestoSoporte.presupuestosF;

                let presupuesto = res.data
                presupuesto.deleted_at = null;
                let work = {}
                work.id =  res.data.work_id
                work.name = body.obra
                work.item = body.item
                let type_drilling =  selectFromObjbyId(statePresupuestoSoporte.conceptos, parseInt(res.data.type_drilling_id))
                let rock_indice =  selectFromObjbyId(statePresupuestoSoporte.rocas, parseInt(res.data.rock_id))
                let zone =  selectFromObjbyId(statePresupuestoSoporte.zonas, parseInt(res.data.zone_id))

                presupuesto.work = work;
                presupuesto.type_drilling = type_drilling;
                presupuesto.rock_indice = rock_indice;
                presupuesto.zone = zone;

                // work.id
                // presupuesto.work = selectFromObjbyId(state.bars, parseInt(machinary.bar_id))
                // presupuesto.work = statePresupuesto.plantilla.work;
                // presupuesto.type_drilling = statePresupuesto.plantilla.type_drilling;
                // presupuesto.rock_indice = statePresupuesto.rock_indice;
                // presupuesto.zone = statePresupuesto.zone;

                presupuestos.unshift(presupuesto);
                presupuestosF.unshift(presupuesto);
                setStatePresupuestoSoporte({...statePresupuestoSoporte,presupuestos, presupuestosF, historial:presupuestos, historialF:presupuestosF})

                showAlert("success", "¡Listo! Registro agregado exitosamente.")
            }else{

                let presupuesto = res.data
                presupuesto.deleted_at = null;
                let work = {}
                work.id =  res.data.work_id
                work.name = body.obra
                work.item = body.item
                let type_drilling =  selectFromObjbyId(statePresupuestoSoporte.conceptos, parseInt(res.data.type_drilling_id))
                let rock_indice =  selectFromObjbyId(statePresupuestoSoporte.rocas, parseInt(res.data.rock_id))
                let zone =  selectFromObjbyId(statePresupuestoSoporte.zonas, parseInt(res.data.zone_id))

                presupuesto.work = work;
                presupuesto.type_drilling = type_drilling;
                presupuesto.rock_indice = rock_indice;
                presupuesto.zone = zone;


         
                let presupuestos = replaceObj(statePresupuestoSoporte.presupuestos,presupuesto);
                var presupuestosF = replaceObj(statePresupuestoSoporte.presupuestosF,presupuesto);
                setStatePresupuestoSoporte({...statePresupuestoSoporte,presupuestos, presupuestosF, historial:presupuestos, historialF:presupuestosF})
                showAlert("success", "¡Listo! Registro editado exitosamente.")

            }
                hideLoader();
                hideForm();
            }else{
                hideLoader();
                var message = "";
                Object.keys(res.errors).map(function(key, index) {
                message = message + " " +res.errors[key][0]
                });
                showAlert("error", message);
            }

        }else{
            setValidated(true);
        }

    };

   
    

    const showForm = (data = undefined, form) =>{    



        

        if(data !== undefined){
            dataEditing = data;
            editing = true;
            editingId = data.id
        }
       
        if(form){
            if(data !== undefined){
                
                setState({editing:true, plantilla:data,form:true, title:false})
                return;
            }
            setState({...state,form:true})
            return;
        }


    }

    const hideForm = () =>{
        setState({title:true, editing:false, form:"", plantilla:undefined, tipo:undefined});
        setValidated(false);
        editing = false;
        editingId = null;
        dataEditing = null
    }

    if(statePresupuestoSoporte.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main" className={!alert.open ? "menu_collapsed": ""}>

            
            
                
            <div className="container-fluid position-relative py-1 ">
                 <div className="d-flex align-items-end py-2">
               
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                            history.goBack();
                        }}/>
                        <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
          
                    <div>
                        <h4 className="pl-1"> Catálogo: Presupuestos de Soporte de Obra</h4>  
                        <h4 className="line-height-xs pl-1">PROYECTO: {statePresupuestoSoporte.project_name} </h4>
                    </div>

                    
                </div>
              
                
                 
                
                <div className="d-flex">
                    <div className="col-2 py-2 px-0">                 
                        {/* <Form.Label>Estatus:</Form.Label>
                        <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                            <option value ="10">Todos</option>
                            <option value ="1">Activo</option>
                            <option value ="0">Desactivado</option>
                        </Form.Control> */}
                    </div>
                    <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                        
                    </div>
                </div>

                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

                <PresupuestoSoporteObrasRecords 
                onEdit={(data, form)=>{
                    showForm(data, form);
                }}
                onAdd={(form)=>{

                    showForm(undefined,form)
                    
                }}
                >

                </PresupuestoSoporteObrasRecords>
                
                {state.form === true ? 
                <div className="catalogo_form_modal  py-3" id="">
                    <Form noValidate validated={validated} onSubmit={handleSubmitPresupuesto} id="">
                        <div className="container position-relative bg-white rounded py-3">
                            <div className="col">
                                <h5>{state.title === true ? "NUEVO" : "EDITAR"} Presupuesto de Soporte de Obra</h5>
                                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                            </div>
                        
                            <div className="row">
                                <div className="col-md-6">

                                

                                <Form.Group as={Col}>
                                <Form.Label>FECHA</Form.Label>
                                    <div className="form-control disabled">{today()}</div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>RESPONSABLE</Form.Label>
                                    <div className="form-control disabled"  >{user.name}</div>
                                </Form.Group>

                                <Form.Group as={Col}>
                                <Form.Label>MES:</Form.Label>
                                <Form.Control defaultValue={state.plantilla !== undefined ? state.plantilla.month : ""} as="select" name="month"  required>
                                    <option value ="">Seleccionar</option>
                                    <option value ="1">1</option>
                                    <option value ="2">2</option>
                                    <option value ="3">3</option>
                                    <option value ="4">4</option>
                                    <option value ="5">5</option>
                                    <option value ="6">6</option>
                                    <option value ="7">7</option>
                                    <option value ="8">8</option>
                                    <option value ="9">9</option>
                                    <option value ="10">10</option>
                                    <option value ="11">11</option>
                                    <option value ="12">12</option>
                                </Form.Control>
                            </Form.Group>


                    


                                <Form.Group as={Col}>
                                    <Form.Label>AÑO</Form.Label>
                                    <Form.Control defaultValue={state.plantilla !== undefined ? state.plantilla.year : ""} type="number" min="2020" name="year" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio. Mimino 2020
                                    </Form.Control.Feedback>
                                </Form.Group>
                                
                                <Form.Group as={Col}>
                                    <Form.Label>ITEM</Form.Label>
                                    <Form.Control className="text-uppercase" defaultValue={state.plantilla !== undefined ? state.plantilla.item : ""} type="text" name="item" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>OBRA</Form.Label>
                                    <Form.Control className="text-uppercase" defaultValue={state.plantilla !== undefined ? state.plantilla.obra : ""} type="text" name="obra" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>RMR:</Form.Label>
                                    <Form.Control as="select"  name="rmr" defaultValue={state.plantilla !== undefined ? state.plantilla.rmr : ""}  required>
                                        <option value ="">Seleccionar</option>
                                        {statePresupuestoSoporte.rocas.map((roca,i)=>{
                        
                                            return (<option key={i} value ={roca.id}>{roca.quality}</option>)
                                        })}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>ZONA:</Form.Label>
                                    <Form.Control as="select"  name="zona" defaultValue={state.plantilla !== undefined ? state.plantilla.zona : ""}  required>
                                        <option value ="">Seleccionar</option>
                                        {statePresupuestoSoporte.zonas.map((zona,i)=>{
                                            
                                            return (<option key={i} value ={zona.id}>{zona.name}</option>)
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            
                             
                                </div>
                                <div className="col-md-6">
                                 

                                    <Form.Group as={Col}>
                                        <Form.Label>Especificación Anclas 1:</Form.Label>
                                        <Form.Control as="select" name="esp_ancla_u" defaultValue={state.plantilla !== undefined ? state.plantilla.esp_ancla_u : ""}  required>

                                            <option value ="">Seleccionar</option>
                                            {statePresupuestoSoporte.anclas.map((item,i)=>{
                                                
                                                return (<option key={i} value ={item.id}>{item.name}</option>)
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col}>
                                        <Form.Label>Especificación Anclas 2:</Form.Label>
                                        <Form.Control as="select" name="esp_ancla_d" defaultValue={state.plantilla !== undefined ? state.plantilla.esp_ancla_d : ""}  required>

                                        <option value ="">Seleccionar</option>
                                            {statePresupuestoSoporte.anclas.map((item,i)=>{
                                                
                                                return (<option key={i} value ={item.id}>{item.name}</option>)
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    
                                    <Form.Group as={Col}>
                                        <Form.Label>Especificación Anclas 3:</Form.Label>
                                        <Form.Control as="select" name="esp_ancla_t" defaultValue={state.plantilla !== undefined ? state.plantilla.esp_ancla_t : ""}  required>
                                      
                                        <option value ="">Seleccionar</option>
                                            {statePresupuestoSoporte.anclas.map((item,i)=>{
                                                
                                                return (<option key={i} value ={item.id}>{item.name}</option>)
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Especificación Anclas 4:</Form.Label>
                                        <Form.Control as="select" name="esp_ancla_f" defaultValue={state.plantilla !== undefined ? state.plantilla.esp_ancla_f : ""}  required>
                                  
                                        <option value ="">Seleccionar</option>
                                            {statePresupuestoSoporte.anclas.map((item,i)=>{
                                                
                                                return (<option key={i} value ={item.id}>{item.name}</option>)
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col}>
                                        <Form.Label>Especificación Anclas 5:</Form.Label>
                                        <Form.Control as="select" name="esp_ancla_fi" defaultValue={state.plantilla !== undefined ? state.plantilla.esp_ancla_fi : ""}  required>
                                       
                                        <option value ="">Seleccionar</option>
                                            {statePresupuestoSoporte.anclas.map((item,i)=>{
                                                
                                                return (<option key={i} value ={item.id}>{item.name}</option>)
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col}>
                                        <Form.Label>CABLE:</Form.Label>
                                        <Form.Control type="number" name="cable" defaultValue={state.plantilla !== undefined ? state.plantilla.cable : ""}  required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>MARCOS:</Form.Label>
                                        <Form.Control type="number" name="marcos" min="0" step="any" defaultValue={state.plantilla !== undefined ? state.plantilla.marcos : ""}  required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>MALLAS:</Form.Label>
                                        <Form.Control type="number" name="mallas" min="0" step="any" defaultValue={state.plantilla !== undefined ? state.plantilla.mallas : ""}  required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>ZARPEO:</Form.Label>
                                        <Form.Control type="number" name="zarpeo" min="0" step="any" defaultValue={state.plantilla !== undefined ? state.plantilla.zarpeo : ""}  required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>FACTOR ZARPEO:</Form.Label>
                                        <Form.Control type="number" name="factor_zarpeo" min="0" step="any" defaultValue={state.plantilla !== undefined ? state.plantilla.factor_zarpeo : ""}  required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>





                                
                                  
                              
                                 
                                    {/* {state.editing === true ? 
                                    <Form.Group as={Col}>
                                        <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="reason_edition" required/>
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    : null} */}
                                  
                                    <Form.Group as={Col}>
                                        <Button type="submit">GUARDAR</Button>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="cta position-absolute top right p-2" onClick={hideForm}>
                                <XCircleFill size="40"  ></XCircleFill>
                            </div>
                        </div>
                    </Form>
                </div>
                : null}

            
            
            </div>
           
        </main>
    )}

}

export default PresupuestoSoporteObrasView;

