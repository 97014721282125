import { ButtonGroup, IconButton } from '@material-ui/core'
import React, {createContext, useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import { ArrowLeftSquareFill, Back, Person, Trash, BlockquoteLeft, Search, X } from 'react-bootstrap-icons'

import { UserContext } from '../context/user_context'
import { Form, Col, Button } from 'react-bootstrap';

import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { ProyectosContext } from '../context/proyectos_context';
import { hideLoader, removeFromObjbyId, replaceObj, selectFromObjbyId, showLoader } from '../utils/utils';
import ViewDataLoader from '../components/ui/view_data_loader';
import { Doughnut } from 'react-chartjs-2';
import { dependentService, recordsService, recordsServiceId, services, singleService } from '../services/api';
import FormularioGeneral from '../components/formulario_general';
import { BarrenacionJumboContext } from '../context/barrenacion_jumbo_context';
import DataListInput from 'react-datalist-input';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';
import { FormularioGeneralContext } from '../context/formulario_general_context';
import { Add } from '@material-ui/icons';

const Context = createContext();
function SoporteObraDetalleView() {

    

    const { user } = useContext(UserContext);        
    const [proyecto, setProyecto] = useState(undefined);

    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)  

    const {state,setState} = useContext(ProyectosContext);
    const [local, setLocal] = useState({forms:[], form_id:null, operation:{id:8, name:"SOPORTE DE OBRA"},})
    const {alert, showAlert, closeAlert} = useContext(AlertContext)



    let { project, id } = useParams();
    let history = useHistory();
    let location = useLocation();
    const { path, url } = useRouteMatch();

    useEffect(async()=>{
        var forms = await dependentService("GET", "work_support_forms")  
       
        setLocal({...local, forms:forms.data.filter((el) => el.folio === parseInt(id)), form_id:id });
    },[])

    useEffect(()=>{
        
        let proyecto = selectFromObjbyId(state.projects,parseInt(project))
        if(proyecto === undefined){
         
            history.replace(`/home/proyectos` );
        }else{
            setProyecto(proyecto);
        }
        
    },[project]);



    const onRemoveForm = (id = "nuevo") => {
        let forms;
        if(id == "nuevo"){
            forms = local.forms;
            forms.pop();
        }else{
            forms = removeFromObjbyId(local.forms, id);
        }
        setLocal({...local, forms})
        showAlert("success", "¡Listo! formulario eliminado exitosamente.")
    }


    if(stateGenerales.loading){
        return (
            ViewDataLoader()
        )
    }else{
        
        return (
            <Context.Provider value={{
                local,
                setLocal,
                showLoader,
                hideLoader,
                onRemoveForm
            }}>
            <main role="main" className="">

                <div className="d-flex pl-1 border-bottom sticky-left">

                    
                    <IconButton size="medium" disabled={false} onClick={()=>{
                        history.goBack();
                    }}>
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe"/>
                    </IconButton> 

               
                    {local.form_id === "nuevo" ? 
                    <Button type="submit" className="cta-title cta--disabled" >NUEVO FORMULARIO</Button>
                    :
                    <Button type="submit" className="cta-title" onClick={()=>{
                        var f = local.forms;
                        if(f.length < 15){
                            f.push("nuevo");
                            setLocal({...local,forms:f});
                        }
                        
                    }}>NUEVO FORMULARIO</Button>
                    }




          
                </div>

                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                



                

                <div className="general_forms container-fluid p-0">

          
                    <FormularioGeneral data={selectFromObjbyId(stateGenerales.generales,parseInt(id))} 
                        operation={local.operation}
                        onCreate={(id)=>{
                            setLocal({...local, form_id:id})
                        }} 
                        showLoader={()=>{
                            showLoader()
                        }}/> 

                        {/* <SoporteObraForm data={undefined}/> */}



                    {
                    local.forms.map((el, i)=>{
                            if(el === "nuevo"){
                                return(<SoporteObraForm key={i} data={undefined}  general_form={selectFromObjbyId(stateGenerales.generales,parseInt(id))}/>) 
                            }else{
                                return(<SoporteObraForm key={i} data={el} general_form={selectFromObjbyId(stateGenerales.generales,parseInt(id))} />)
                            }
                        
                    })
                    }


                    
                   
                </div>

           
            </main>
           
            </Context.Provider>
        )
    }
}


function SoporteObraForm(props) {



    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)  
    const {local, setLocal, showLoader, onRemoveForm} = useContext(Context)  
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        obra:null, 
        zona:null, 
        barras:null, 
        mtsEficientes:null, 
        avance:null, 
        rmr:"", 
        item:"", 
        id:null, 
        anclas:[], 
        ancla:"",
        cantidad:0 
    })
    const {alert, showAlert, closeAlert} = useContext(AlertContext)

    useLayoutEffect(() => {
        var view = document.querySelector("main")
        view.scrollTo({left: view.scrollWidth, behavior: 'smooth'});
    }, [])

  
    useEffect(()=>{

        setState({
            ...state, 
            obra:props.data.obra, 
            zona:props.data.zona,
            rmr:props.data.rmr,
            barras:props.data.number_holes,
            mtsEficientes:props.data.efficient_length,
            type:props.data.concept,
            avance:props.data.per_avance,
            item:props.data.item,
            id:props.data.id !== null ? props.data.id : null,
            anclas:props.data.anclas
            })


    },[])


    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        
        
        formData.forEach((value, key) => body[key] = value);
        body.folio = local.form_id;
        body.project_id = stateGenerales.project_id;
        body.generalform_id = local.form_id;
        body.obra = state.obra;
        body.item = state.item;
        body.zona = state.zona;
        body.anclas = state.anclas;
        // body.number_holes = state.barras;
        // body.efficient_length = state.mtsEficientes;
        // body.concept = state.type;
        // body.per_avance = state.avance;
      
            
        // body.final_hour = body.final_hour + ":00";
        // body.start_time = body.start_time + ":00";
       
    

        if (form.checkValidity() === true) {

            showLoader();
            
            var response;

            if(state.id === null){

              
                response = await services("POST","work_support_forms",body);
       
                if(response.status){
                    
                    setState({...state, id:response.data.id})
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")

                }else{
                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }else{

         
                response = await services("PUT","work_support_forms",body, state.id);
            

                if(response.status){
                 
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }else{

                    var message = "";
                    Object.keys(response.errors).map(function(key, index) {
                        message = message + " " +response.errors[key]
                    });
                    showAlert("error", message);
                    var view = document.querySelector("main")
                    view.scrollTop = 0;

                }
                hideLoader();
                var view = document.querySelector("main")
                view.scrollTop = 0;
                
            }
         
        }

        setValidated(true);
    };

  

   let obras_by_month = [];
    if(props.general_form !== undefined){
        let created_month = new Date(Date.parse(props.general_form.date)).getMonth() + 1
        obras_by_month = stateGenerales.obras.filter((item) => item.month == created_month)
    }else{
        obras_by_month = stateGenerales.obras.filter((item) => item.month == (new Date().getMonth() + 1 ))
    }    
    
    const obras = useMemo(
        () =>
        obras_by_month.map((oneItem) => ({
            label: oneItem.work.item,
            key: oneItem.id,
            ...oneItem,
          })),
        [stateGenerales.obras]
    );


    //   let start_time = null;
    //   let final_hour = null;

    //   if(props.data.start_time !== null && props.data.final_hour !== null){
    //     start_time = props.data.start_time.split(":")[0] +":"+ props.data.start_time.split(":")[1]
    //     final_hour = props.data.final_hour.split(":")[0] + ":"+props.data.final_hour.split(":")[1]
    //   }
       



    return (
        <div className="form_frente pb-5 mx-2">
            <div className="bg-blue text-white d-flex justify-content-center align-items-center p-2 mb-2 font-weight-bold">
                Formulario Soporte de Obra {props.data.id}
                    <div className="cta position-absolute  right  pr-3" onClick={async ()=> {

                    
                        if(props.data.id !== null){
                            showLoader()
                            let response = await services("DELETE","work_support_forms",undefined,props.data.id);
                            if(response.status){
                                onRemoveForm(props.data.id)
                            }
                            hideLoader();
                        }else{
                            onRemoveForm()
                        }
                    
                        
                        

                    }}>
                        <X size="30"></X>
                    </div>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Col}>
                    <Form.Label>SECTOR</Form.Label>
                    <Form.Control as="select" name="sector" defaultValue={props.data.sector} required>
                     <option value="">Seleccionar</option>
                        {stateGenerales.sectores.map((sector,i)=>{
                            return (<option key={i} value={sector.id}>{sector.sector}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>OBRA</Form.Label>
                    <div className="form-control disabled">{state.obra}</div>
                </Form.Group>


                <Form.Group as={Col} >
                    <Form.Label>ITEM</Form.Label><br></br>
                    <DataListInput
                        inputClassName="form-control-n"
                        placeholder=""      
                        value={state.item}
                        items={obras}
                        onInput = {(a) => {
                            //setLocal({...local,});
                        }}
                        onSelect={(el)=>{
                          
                            setState({...state, item:el.work.item, obra:el.work.name, zona:el.zone.name, type:el.type_drilling.name, rmr:el.rock_indice.id});
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                {
                    obras.length === 0 ? 
                    <div className="form-message form-message--red">
                        No hay obras dentro del mes.
                    </div> : null
                }


              

                <Form.Group as={Col}>
                    <Form.Label>ZONA</Form.Label>
                    <div className="form-control disabled">{state.zona}</div>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>SECCIÓN</Form.Label>
                    <Form.Control as="select" name="seccion" defaultValue={props.data.seccion} required onChange={(evt)=>{

                        let barras = selectFromObjbyId(stateGenerales.barrenacion, parseInt(evt.currentTarget.value))
                   
                        setState({...state, barras: barras !== undefined ? barras.number_bars : ""})

                    }}>
                        <option value="">Seleccionar</option>
                        {stateGenerales.barrenacion.map((barre,i)=>{
                            return (<option key={i} value={barre.id}>{barre.section}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>RMR</Form.Label>
                    <Form.Control as="select" name="rmr" value={state.rmr} required onChange={(evt)=>{
                        setState({...state, rmr:evt.currentTarget.value});
                    }}>
                     <option value="">Seleccionar</option>
                        {stateGenerales.rmr.map((rmr,i)=>{
                            return (<option key={i} value={rmr.id}>{rmr.quality}</option>)
                        })}
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>


                <div className="d-flex pl-3">
                    <div className="col-5 p-0">

                    <Form.Group>
                        <Form.Label>ANCLAS</Form.Label>
                        <Form.Control as="select" name="" value={state.ancla} onChange={(evt)=>{

                            setState({...state, ancla:evt.currentTarget.value})
        
                        }}>
                            <option value="">Seleccionar</option>
                            
                            {stateGenerales.anclas.map((item,i)=>{
                                return (<option key={i} value={item.name}>{item.name}</option>)
                            })}
                        </Form.Control>

                        {/* <Form.Control.Feedback type="invalid">
                            Este campo es obligatorio.
                        </Form.Control.Feedback> */}
                    </Form.Group>

                    </div>
                    <div className="col-5 p-0 pl-1">
                        <Form.Group>
                            <Form.Label>CANTIDAD</Form.Label>
                            <Form.Control type="number" min="0" step="any" value={state.cantidad}  name="" onChange={(evt)=>{
                                setState({...state, cantidad:evt.currentTarget.value})
                            }}/>
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio. Numeros positivos
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-2 d-flex justify-content-center">
                     
                        <div className="d-table pt-4">
                            <IconButton onClick={()=>{

                                let anclas = state.anclas;
                                //let selectedAncla = selectFromObjbyId(stateGenerales.anclas, parseInt(state.ancla))
                                let ancla = {
                                    ancla:state.ancla,
                                    cantidad:state.cantidad
                                }
                                anclas.push(ancla);
                              
                                setState({...state, anclas, ancla:"", cantidad:0})

                            }}>
                                <Add size={22} className="cursor-pointer" color="tomato"/>
                            </IconButton>
                        </div>

                    </div>
                </div>
                <div className="pl-3 pb-2">
                    <table className="table-striped w-100">
                        <tbody>

                            {state.anclas.map((item,i)=>{
                                return(

                                    <tr key={i}>
                                        <td>{item.ancla}</td>
                                        <td>{item.cantidad}</td>
                                        <td width="50">
                                            <IconButton onClick={()=>{}}>
                                                <Trash size={20} className="cursor-pointer" color="tomato"/>
                                            </IconButton>
                                        </td>
                                    </tr>

                                )

                            })}
                            
                           
                            
                        </tbody>
                    </table>
                </div>


                <Form.Group as={Col}>
                    <Form.Label>MARCOS</Form.Label>
                    <Form.Control type="number" min="0" step="any" defaultValue={props.data.marcos} name="marcos" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio. Numeros positivos
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>MALLAS</Form.Label>
                    <Form.Control type="number" min="0" step="any" defaultValue={props.data.mallas} name="mallas" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio. Numeros positivos
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>SUPERFICIE CUBIERTA M2</Form.Label>
                    <Form.Control type="text" defaultValue={props.data.sup_cubierta} name="sup_cubierta" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio. 
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>CABLE</Form.Label>
                    <Form.Control type="number" min="0" step="any" defaultValue={props.data.cable} name="cable" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio. Numeros positivos
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>BARRENOS INYECTADOS</Form.Label>
                    <Form.Control type="number" min="0" step="any" defaultValue={props.data.barr_inyect} name="barr_inyect" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio. Numeros positivos
                    </Form.Control.Feedback>
                </Form.Group>


                <Form.Group as={Col}>
                    <Form.Label>BARRENOS DE SERVICIO</Form.Label>
                    <Form.Control type="number" min="0" step="any" defaultValue={props.data.barr_servi} name="barr_servi" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio. Numeros positivos
                    </Form.Control.Feedback>
                </Form.Group>

                {/* <div className="d-flex">
                    <div className="col p-0">
                        <Form.Group as={Col}  className="">
                            <Form.Label>HORA INICIO</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={start_time}
                                name="start_time"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col p-0">
                        <Form.Group as={Col}  className="">
                            <Form.Label>HORA FIN</Form.Label>
                            <Form.Control
                                required
                                type="time"
                                defaultValue={final_hour}
                                name="final_hour"
                            />
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div> */}

                {/* <Form.Group as={Col}>
                    <Form.Label>OBRA</Form.Label>
                    <div className="form-control disabled"></div>
                </Form.Group> */}

                {/* <Form.Group as={Col}>
                    <Form.Label>CANTIDAD DE BARRENOS</Form.Label>
                    <div className="form-control disabled">{state.barras}</div>
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>BARRENO DADOS</Form.Label>
                    <Form.Control type="number" min="0"  name="holes_given" defaultValue={props.data.holes_given} required onChange={(evt)=>{

                        let avance = (parseInt(evt.currentTarget.value) * 1) / state.barras;
                        setState({...state, avance:avance});
                    }}/>
                    <Form.Control.Feedback type="invalid">
                    Este campo es obligatorio. Numeros positivos.
                    </Form.Control.Feedback>
                </Form.Group> */}

{/* 
                <Form.Group as={Col}>
                    <div className="row">
                        <div className="col-md-6 p-0">
                        <Form.Group as={Col}>
                            <Form.Label>Longitud de barra</Form.Label>
                            <Form.Control as="select"  name="bar_length" defaultValue={props.data.bar_length} onChange={(evt)=>{

                                    let longitudbarra = selectFromObjbyId(stateGenerales.bars, parseInt(evt.currentTarget.value))
                                    
                                    setState({...state, mtsEficientes:longitudbarra !== undefined ? longitudbarra.efficient_meters : ""})

                                    }}>
                            <option value ="">Seleccionar</option>
                            {stateGenerales.bars.map((bar, i)=>{
                                return (<option key={i} value ={bar.id}>{bar.length}</option>)
                            })}
                                
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio.
                            </Form.Control.Feedback>
                        </Form.Group>
                        </div>
                        <div className="col-md-6 p-0">
                        <Form.Group as={Col}>
                            <Form.Label>Metros eficientes</Form.Label>
                            
                            <div className="form-control disabled">{state.mtsEficientes}</div>
                    
                        </Form.Group>
                        </div>
                    </div>
                </Form.Group> */}

                {/* <Form.Group as={Col}>
                    <Form.Label>CONCEPTO</Form.Label>
                    <div className="form-control disabled">{state.type}</div>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>% DE AVANCE</Form.Label>
                    <div className="form-control disabled">{state.avance}</div>
                </Form.Group> */}

                <Form.Group as={Col}>
                    <Form.Label>OBSERVACIONES</Form.Label>
                    <Form.Control as="textarea" defaultValue={props.data.observaciones} rows={3} name="observaciones" required/>
                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Group as={Col}>
                    <Form.Label>Estatus</Form.Label>
                    <Form.Control as="select" name="status" defaultValue={props.data.status} required>
                        <option value ="">Seleccionar</option>
                        <option value ="Pendiente">Pendiente</option>
                        <option value ="Terminado">Terminado</option>
                    </Form.Control>

                    <Form.Control.Feedback type="invalid">
                        Este campo es obligatorio.
                    </Form.Control.Feedback>
                </Form.Group> */}

                <Form.Group as={Col}>
                    <Button type="submit">GUARDAR</Button>
                </Form.Group>


            </Form> 
        </div>
    )
}

SoporteObraForm.defaultProps = {
    data:{
        id:null,
        anclas:[],
        bar_length: "",
     
        generalform_id: null,
     
        item: "",
    
        obra: "",
        observation: "",
    
        project_id: null,
        rmr: "",
        seccion: "",
        sector: "",
       
    
        zona: ""
    
    } 
}

export default SoporteObraDetalleView
