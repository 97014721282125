import React, { createContext, useState,useEffect, useContext} from "react"

import {dependentService, recordsService} from '../services/api' 


import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";


export const CategoriasContext  = createContext();


const token = window.localStorage.getItem('token');
const uid = window.localStorage.getItem('uid');

export const CategoriasProvider = ({children}) => {
    let auth = useAuth();
    const {setUser}= useContext(UserContext)


    const [state, setState] = useState({
          
        categories:[],
        categoriesF:[],
        departments:[],
        filter: 10,
        search: "",
        loading:false
    
    });

    useEffect(async () =>{
        //if(state.categories.length === 0 ||state.departments.length === 0){
            setState({...state, loading:true})
            var catRes = await recordsService("GET","categories");
            var depRes = await dependentService("GET","departments");
            
            if(catRes.status && depRes.status){
                setState({...state,loading:false, departments:depRes.data, categories:catRes.data, categoriesF:catRes.data});
            }else{
                if(catRes.code == 401 || catRes.code == 500 ){
                    window.localStorage.clear();
                    setUser({});
                    auth.setMsg(true)
                    auth.signout();
                }
            }
        //}
    },[])
    
    return(
        <CategoriasContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </CategoriasContext.Provider>

    )
}






