import React,{useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Eye, Eyedropper, EyeFill, PencilSquare } from 'react-bootstrap-icons';

import Switchy from '../triggers/switchy';
import { UbicacionesContext } from '../../context/ubicaciones_context';
import { services} from '../../services/api';
import { selectFromObjbyId } from '../../utils/utils';
import { IconButton } from '@material-ui/core';
import { ProyectosContext } from '../../context/proyectos_context';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    Redirect
  } from "react-router-dom";

const columns = [
  { id:'id', label:'id', minWidth: 60 },
  { id:'name', label:'Proyecto', minWidth: 170 },
  { id:'description', label:'Descripcion', minWidth: 300 },
  { id:'ver', label:'Ver', minWidth: 50 },
  { id:'editar', label:'Editar', minWidth: 50 },
  { id:'activar', label:'Activar', minWidth: 50 },
  
  
  
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});

function ProyectosRecords(props) {


  const {state, setState} = useContext(ProyectosContext)

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { path, url } = useRouteMatch();


  useEffect(() => {
    var data = state.projects.filter((item)=>{
      var active = item.deleted_at === null ? 1 : 0;
      if(state.search === "" && state.filter === 10){
        return true;
      }
      if(state.search !== "" && state.filter === 10){
     
        return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase())
      }
      return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase()) && active === state.filter;
     
    })
    setPage(0);
    setState({...state, projectsF:data});

  }, [state.search, state.filter])



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleSwitch = async (data,id, val)=>{
    if(!val){
      var res = await services("DELETE","projects",undefined,id);
    }else{
      var res = await services("GET","projects",undefined,id);
    }
    if(res.status){
      var ubicaciones = data
      var ubicacion = selectFromObjbyId(data, id);
      ubicacion.deleted_at = res.data.deleted_at;
      setState({...state, ubicaciones })
    }  
  }
  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
                    paddingLeft:0,
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                   }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.projectsF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
            let active = row.deleted_at === null ? 1 : 0;
            let switchActive = row.deleted_at === null ? true : false;
      
  
                         
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                          paddingRight:10,
                          paddingLeft:5,
                          paddingTop:5,
                          paddingBottom:0,

                         }}>
                          {
                            column.id === "ver" ?  
                            <Link to={`${url}/${row.id}`}>
                                <IconButton disabled={!switchActive}>
                                    <EyeFill size={20} className="cursor-pointer" color={switchActive ? "#0a5cbe" : "grey"}/>
                                </IconButton>
                            </Link>
                            : 

                            column.id === "editar" ? 
                            
                            <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
                              <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                            </IconButton> 
                            :
                    
                            column.id === "activar" ?  
                            <Switchy value={switchActive} onChange={(val)=>{
                              handleSwitch(state.projects, row.id, val)
                            }} />  
                            : value
                          }
                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
              
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.projectsF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default ProyectosRecords;