import React,{useState, useEffect} from 'react'
import  {services,} from "../../services/api"
import {PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import DataListInput from 'react-datalist-input';
import {message, handleObraRowsAutofill} from "../capturaUtils"
import { StatusContent,  SectoresContent, RmrContent, BarsContent, ZonasContent, ObrasContent} from "../capturaUtils/CapturaInputs"

function BarrenacionRow({f, showAlert, context}) {
    const [editing, setEditing] = useState(false)
    const [ item, setItem ] = useState(f)

    useEffect(()=>{
        setItem(f)
    },[f])

    useEffect(()=>{
        //console.log("item", item)
        //console.log("obra", obra)
        console.log("context",context)
        if(typeof item.id == 'string'){
            setEditing(true)
        }
    },[])

    const handleOperationItemChange = ((e)=>{
        const value = e.target.value
        setItem({
            ...item,
            [e.target.name]: value
          });
    })

    const handlePutReq =  (async(itemId)=>{
        setEditing(false)
        const res = await services("PUT" , "drilling_type_forms" ,  item, itemId)
        if(res.status == true){
            showAlert("success",`Listo, datos guardados correctamente`)
            setItem(res.data)
        }else{
            console.log(res)
            showAlert("error",`Error al guardar sus datos.Compruebe sus datos e intente de nuevo.`)
            setEditing(true)
        }
    })
    
    const handleEditing = (()=>{
        setEditing(true)
    })

  return (
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
            <select 
                type="text" 
                className="form-control" 
                readOnly={!editing} 
                name="obra" 
                value={item.obra} 
                required
                onChange={(e)=>{
                    handleOperationItemChange(e,item.id)
                    handleObraRowsAutofill(e, item, setItem, context)
                }} 
                >
                <ObrasContent obras={context.generalObras.data}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="sector" value={item.sector} required>
                <SectoresContent sectores={context.sectores}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="zona" value={item.zona} required>
                <ZonasContent zonas={context.zonasListado}/>
            </select>
        </td>
        <td >
            <input type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_largo" value={item.seccion_largo} required/>
        </td>
        <td >
            <input type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_ancho" value={item.seccion_ancho} required/>
        </td>
        <td>
        <select 
             className="form-control" 
             onChange={(e)=>handleOperationItemChange(e,item.id)} 
             name="rmr" 
             value={item.rmr} 
             readOnly={!editing}
             type ="hidden"
             >
                <RmrContent rmr={context.rmr}/>
        </select>
        </td>
        <td>
            <select type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="bar_length" value={item.bar_length} required>
                <BarsContent bars={context.bars}/>
            </select>
        </td>
        <td>
            <select  className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="number_holes" value={item.number_holes} required>
                <option value="">Seleccionar</option>
               {context.barrenacion.map((item)=>(
                    <option value={item.id}>{item.number_bars}</option>
                ))}
            </select>
        </td>
        <td>
            <select  className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="type_drilling" value={item.type_drilling} required>
                <option value="">Seleccionar</option>
                {context.barrenacionTipos.map((item)=>(
                    <option value={item.id}>{item.name}</option>
                ))}
            </select>
        </td>
        <td>
            <input type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="real_advance" value={item.real_advance} required/>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="observation" value={item.observation} required/>
        </td>
        <td>
        <td>
            <select 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="estatus" 
                value={item.estatus} 
                readOnly={!editing}
            >
                <StatusContent/>
            </select>
        </td>
        </td>
        <td>
        {editing ? 
            <button className='btn btn-primary mx-2 text-nowrap' onClick={() => {if(window.confirm(message)){handlePutReq(item.id)}}}>Guardar Cambio</button> :
            <PencilSquare size="25" color="#606060" className='cursor-pointer mx-2' onClick={handleEditing}/>
        }
        </td>
    </tr>
  )
}

export default BarrenacionRow