
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Journals, XCircleFill, ArrowLeftSquareFill, TrashFill } from 'react-bootstrap-icons';
import { showLoader, hideLoader, today, replaceObj, removeFromObjbyId, removeFromObjbyUserId, todayY } from '../utils/utils';

import { Form, Col, Button } from 'react-bootstrap';

import { UserContext } from '../context/user_context';
import { services, singleService } from '../services/api';
import ViewDataLoader from '../components/ui/view_data_loader';
import { AlertContext } from '../context/alert_context';
import ActionAlert from '../components/ui/alert';
import IconButton from '@material-ui/core/IconButton';
import { useHistory, Link, useParams } from 'react-router-dom';
import { ListasAsistenciaContext } from '../context/listas_asistencia_context';
import ListaAsistenciaRecords from '../components/records/listas_asistencia_records';
import DataListInput from 'react-datalist-input';

let editing = false;
let editingId;
function ListasAsistenciaView() {

    let history = useHistory();
    let { id } = useParams();

    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState(true);
    const { stateListas, setStateListas } = useContext(ListasAsistenciaContext)
    const { user, setUser } = useContext(UserContext)
    const { alert, showAlert, closeAlert } = useContext(AlertContext)




    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        console.log(body)
        formData.forEach((value, key) => body[key] = value);
        body.project_id = stateListas.project_id;
        body.users = JSON.stringify(stateListas.selectedUsers);



        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if (!editing) {
                res = await services("POST", "assists", body);

            } else {

                let _hour_end = body.hour_end.split(":");
                let _hour_start = body.hour_start.split(":");

                let hour_start = _hour_start[0] + ":" + _hour_start[1]
                let hour_end = _hour_end[0] + ":" + _hour_end[1]

                body.hour_end = hour_end;
                body.hour_start = hour_start;

                res = await services("PUT", "assists", body, editingId);
            }
            if (res.status) {
                if (!editing) {

                    var listas = stateListas.listas;
                    var listasF = stateListas.listasF;

                    var lista = res.data;
                    lista.deleted_at = null;

                    listas.unshift(lista);
                    listasF.unshift(lista);


                    setStateListas({ ...stateListas, listas, listasF });
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")

                } else {


                    var listas = replaceObj(stateListas.listas, res.data);
                    var listasF = replaceObj(stateListas.listasF, res.data);
                    setStateListas({ ...stateListas, listas, listasF });
                    showAlert("success", "¡Listo! Registro editado exitosamente.")
                }
                hideLoader();
                hideForm();
            } else {
                hideLoader();
                var message = "";

                Object.keys(res.errors).map(function (key, index) {
                    message = message + " " + res.errors[key]

                });
                showAlert("error", message);
            }
        } else {

            setValidated(true);
        }
    };
    const showForm = (data = undefined) => {
        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');

        let f = document.getElementById("main_form");

        if (data !== undefined) {
            reason_edition.classList.remove('d-none');
            setTitle(false);
            fillForm(data)
        }
        f.classList.add("d-block")
        f.classList.remove("d-none");
    }
    const hideForm = () => {
        let f = document.getElementById("main_form");
        clearForm();
        f.classList.add("d-none");
        f.classList.remove("d-block");
    }
    const onChangeFilter = (evt) => {
        setStateListas({ ...stateListas, filter: evt.currentTarget.value })
    }

    const fillForm = (data) => {
        let { elements } = document.getElementById('material_form');
        elements.namedItem("reason_edition").disabled = false;
        for (const [key, value] of Object.entries(data)) {
            const field = elements.namedItem(key)
            if (key !== "reason_edition") {
                field && (field.value = value)
            }

        }
        editing = true;
        editingId = data.id
    }
    const clearForm = () => {
        let form = document.getElementById('material_form');
        let { elements } = form
        elements.namedItem("reason_edition").disabled = true;
        editing = false;
        editingId = undefined;
        form.reset();
        setTitle(true);
        setValidated(false);
        setStateListas({ ...stateListas, selectedUsers: [] })
    }

    const changeDate = (evt) => {
        let month = document.getElementById("month")
        let year = document.getElementById("year")
        let yearV = new Date(evt.currentTarget.value)
        let endDate = document.getElementById("date_end")
        let startDate = document.getElementById("date_start")

        yearV.setDate(yearV.getDate() + 1)
        console.log(yearV.getDay())
        if(yearV.getDay()==1){
            month.value = yearV.getMonth() + 1
            year.value = yearV.getFullYear()
            let newDate = new Date()
            newDate.setDate(yearV.getDate() + 6)
            let newM = newDate.getMonth() + 1
            let newD = newDate.getDate()
            endDate.value = newDate.getFullYear() + "-" + (newM < 10 ? "0" + newM.toString() : newM) + "-" + (newD < 10 ? "0" + newD.toString() : newD)
        }else{
            showAlert("error","La Fecha de Inicio sólo puede ser Lunes")
            startDate.value = null
            month.value = null
            year.value = null
            endDate.value = null
        }
    }

    stateListas.usuarios.map((a) => {
        // console.log(a)
    })

    const usuarios = useMemo(
        () =>
            stateListas.usuarios.map((oneItem, i) => ({


                label: oneItem.user.name + " (" + oneItem.categorie.name + ")",

                key: i,

                ...oneItem,
            })),
        [stateListas.usuarios]
    );

    const userDeleteDisabled = (user) => {

        let disable = false;

        if (
            user["sunday"] != null ||
            user["monday"] != null ||
            user["tuesday"] != null ||
            user["wednesday"] != null ||
            user["thursday"] != null ||
            user["friday"] != null ||
            user["saturday"] != null
        ) {
            disable = true;
        }
        return disable;

    }

    if (stateListas.loading) {
        return (
            ViewDataLoader()
        )
    } else {
        var a = new Date().getFullYear();

        return (


            <main role="main" className={!alert.open ? "menu_collapsed" : ""}>

                <div className="container-fluid position-relative mh-100 py-1 ">

                    <div className="d-flex align-items-end py-2">
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={() => {
                            history.goBack();
                        }} />
                        <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
                        <div>
                            <h4 className="pl-1"> Catálogo: Listas de asistencia</h4>
                            <h4 className="line-height-xs pl-1">PROYECTO: {stateListas.project_name} </h4>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="col-2 py-2 px-0">

                            <Form.Label>Turno:</Form.Label>
                            <Form.Control as="select" name="proyecto" onChange={onChangeFilter} required>
                                <option value="Todos">Todos</option>
                                <option value="Primero">Primero</option>
                                <option value="Segundo">Segundo</option>
                            </Form.Control>
                        </div>
                        <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">

                                <Link className="btn btn-primary mr-2" to={`/home/proyectos/listas/graficos/${id}`}>
                                    Ir a graficos
                                </Link>


                            </div>
                            <Button className="primary px-4" onClick={() => { showForm() }} >
                                Nuevo
                            </Button>
                        </div>
                    </div>

                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

                    <ListaAsistenciaRecords onEdit={async (data) => {
                        showForm(data);
                        var listaRes = await singleService("GET", "assists", data.id);

                        setStateListas({ ...stateListas, selectedUsers: listaRes.data.users })
                    }}></ListaAsistenciaRecords>

                    <div className="catalogo_form_modal d-none" id="main_form">
                        <Form noValidate validated={validated} onSubmit={handleSubmit} id="material_form">
                            <div className="container position-relative bg-white rounded pt-4">
                                <div className="col">
                                    <h5>{title === true ? "NUEVA" : "EDITAR"} LISTA DE ASISTENCIA</h5>
                                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                                </div>

                                <div className="row">
                                    <div className="col-md-6">


                                        <Form.Group as={Col}>
                                            <Form.Label>FECHA</Form.Label>
                                            <div className="form-control disabled">{today()}</div>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>RESPONSABLE</Form.Label>
                                            <div className="form-control disabled"  >{user.name}</div>

                                        </Form.Group>

                                        <div className="d-flex">
                                            <div className="col p-0">
                                                <Form.Group as={Col} className="">
                                                    <Form.Label>FECHA INICIO</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="date"
                                                        name="date_start"
                                                        defaultValue=""
                                                        onChange={changeDate}
                                                        id="date_start"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Este campo es obligatorio.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col p-0">
                                                <Form.Group as={Col} className="">
                                                    <Form.Label>FECHA FIN</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        name="date_end"
                                                        id="date_end"
                                                        className="disabled"
                                                        readOnly
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Este campo es obligatorio.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <div className="col p-0">
                                                <Form.Group as={Col} className="">
                                                    <Form.Label>HORA INICIO</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="time"
                                                        name="hour_start"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Este campo es obligatorio.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col p-0">
                                                <Form.Group as={Col} className="">
                                                    <Form.Label>HORA FIN</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="time"
                                                        name="hour_end"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Este campo es obligatorio.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>


                                        <Form.Group as={Col}>
                                            <Form.Label>SEMANA</Form.Label>
                                            <Form.Control type="number" min="0" name="week" required />
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio. Numeros positivos.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>MES:</Form.Label>
                                            <Form.Control name="mounth" id="month" className="disabled" required readOnly>
                                            </Form.Control>
                                        </Form.Group>


                                        {/* <Form.Group as={Col}>
                                <Form.Label>MES</Form.Label>
                                <Form.Control type="number"  min="0"  name="mounth" required />
                                <Form.Control.Feedback type="invalid">
                                Este campo es obligatorio. Numeros positivos.
                                </Form.Control.Feedback>
                            </Form.Group> */}

                                        <Form.Group as={Col}>
                                            <Form.Label>AÑO</Form.Label>
                                            <Form.Control type="number" required className="disabled" min="0" id="year" name="year" min="2019" max={new Date().getFullYear().toString()} />
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio. Minimo 2019 - Maximo {new Date().getFullYear().toString()}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>TURNO:</Form.Label>
                                            <Form.Control as="select" name="turn" required>
                                                <option value="">Seleccionar</option>
                                                <option value="Primero">Primero</option>
                                                <option value="Segundo">Segundo</option>
                                            </Form.Control>
                                        </Form.Group>

                                        <div id="reason_edition">
                                            <Form.Group as={Col}>
                                                <Form.Label>MOTIVO DE EDICIÓN</Form.Label>
                                                <Form.Control as="textarea" rows={3} name="reason_edition" disabled required />
                                                <Form.Control.Feedback type="invalid">
                                                    Este campo es obligatorio.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <Form.Group as={Col}>
                                            <Button type="submit">GUARDAR</Button>
                                        </Form.Group>

                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group as={Col} className="p-0 mb-1">

                                            <Form.Label>USUARIOS {usuarios.length == 0 ? <span className="text-red">Ningun usuario asignado a este proyecto.</span> : null}</Form.Label>
                                            <DataListInput
                                                inputClassName="form-control-n"
                                                clearInputOnSelect={true}
                                                placeholder=""
                                                dropDownLength={15}
                                                items={usuarios}

                                                onInput={(evt) => {

                                                }}
                                                onSelect={(el) => {

                                                    var user_list = {}
                                                    user_list.user_id = el.user.id
                                                    user_list.name = el.user.name
                                                    user_list.project_id = stateListas.project_id;
                                                    user_list.sunday = null;
                                                    user_list.moday = null;
                                                    user_list.tusday = null;
                                                    user_list.wednesday = null;
                                                    user_list.thursday = null;
                                                    user_list.friday = null;
                                                    user_list.saturday = null;

                                                    let selectedUsers = stateListas.selectedUsers;

                                                    selectedUsers.unshift(user_list);

                                                    setStateListas({ ...stateListas, selectedUsers });



                                                    //setState({...state, userFilter:el.id});
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="btn btn--link p-0 m-0" onClick={() => {

                                            let selectedUsers = [];
                                            stateListas.usuarios.forEach((el) => {
                                                var user_list = {}
                                                user_list.user_id = el.user.id
                                                user_list.name = el.user.name
                                                user_list.project_id = stateListas.project_id;
                                                user_list.sunday = null;
                                                user_list.moday = null;
                                                user_list.tusday = null;
                                                user_list.wednesday = null;
                                                user_list.thursday = null;
                                                user_list.friday = null;
                                                user_list.saturday = null;
                                                selectedUsers.unshift(user_list);

                                            });

                                            setStateListas({ ...stateListas, selectedUsers })
                                        }}>
                                            SELECCIONAR TODOS
                                        </div>

                                        {/* <div className="selected_users"> */}
                                        <table className="table-striped">
                                            <thead></thead>
                                            <tbody>
                                                {stateListas.selectedUsers.map((user, i) => {

                                                    return (
                                                        <tr key={i}>
                                                            <td className="" style={{ paddingLeft: 5 }}>{stateListas.selectedUsers.length - i}.</td>
                                                            <td className="w-100" style={{ paddingLeft: 5 }}>{user.name}</td>
                                                            <td style={{ paddingLeft: 10 }}>
                                                                <Form.Group className="m-0 d-flex justify-content-center">


                                                                    <IconButton disabled={userDeleteDisabled(user)} className="p-2" size="small" onClick={async () => {

                                                                        var selectedUsers = removeFromObjbyUserId(stateListas.selectedUsers, user.user_id);
                                                                        setStateListas({ ...stateListas, selectedUsers });

                                                                        if (user.id !== undefined) {
                                                                            var _delete = await services("DELETE", "assist_users", undefined, user.id);

                                                                        }

                                                                    }}>

                                                                        <TrashFill size="18" color={userDeleteDisabled(user) == true ? "gray" : "tomato"} />
                                                                    </IconButton>
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="cta position-absolute top right pt-1 pr-1" onClick={hideForm}>
                                    <XCircleFill size="45"></XCircleFill>
                                </div>
                            </div>
                        </Form>
                    </div>


                </div>


            </main>
        )
    }

}


export default ListasAsistenciaView;