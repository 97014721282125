import React, {useContext, useState, useEffect} from 'react';
import { Form, Col, InputGroup, Spinner } from 'react-bootstrap';
import { PersonFill, LockFill } from 'react-bootstrap-icons';
import { useAuth } from "./../services/auth";
import { toUser } from "../models/user_model";

import {
    BrowserRouter as Router,
    useHistory,
    useLocation
  } from "react-router-dom";
import { UserContext } from '../context/user_context';
import { resetPassword } from '../services/api';

import ActionAlert from '../components/ui/alert';


function LoginView(){


    const [loading, setLoading] = useState(false);
    const [recovery, setRecovery] = useState(false);
    
    const {user, setUser} = useContext(UserContext)

    let history = useHistory();
    let location = useLocation();
    let auth = useAuth();

    useEffect(() => {
       var token = window.localStorage.getItem('token');
        if (token != undefined) {
            history.replace("/home");
        }
    }, [])


    let { from } = location.state || { from: { pathname: "/home" } };

    let signIn = async (event) => {
        event.preventDefault();
        var form = event.currentTarget;
        var formData = new FormData(form)
        setLoading(true)

        
        let user = await auth.signin(formData.get('usuario'), formData.get('password'))
        if(user != false){
            setUser(user);
            //console.log(user);
            
            history.replace(from.pathname);
        }else{
            setLoading(false)
        }
      };


    return (
        <div className="container-fluid d-flex justify-content-center align-items-center h-100 bg-light red">

            { recovery == false ? 
            <div className="loginview_sigin py-5">
              
                <ActionAlert type="error" show={auth.msg} msg="Tu sesión ha expirado" onClose={()=>{auth.setMsg(false)}} />
                <img src="assets/logo.svg" width="360" className="d-block mx-auto mb-5" />
                <p className="text-center">{auth.cred}</p>
                <Form onSubmit={signIn}>
                <Form.Group as={Col} md="12" controlId="usuario">
                    {/* <Form.Label>Usuario</Form.Label> */}
                    <InputGroup hasValidation>
                    <InputGroup.Prepend>
                        <InputGroup.Text><PersonFill /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        placeholder="Correo"

                        name="usuario"
                    />
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="contraseña">
                    {/* <Form.Label>Contraseña</Form.Label> */}
                    <InputGroup hasValidation>
                    <InputGroup.Prepend>
                        <InputGroup.Text><LockFill /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="password"
                        placeholder="Contraseña"

                        name="password"
                    />
                    </InputGroup>
                </Form.Group>
                <div className="px-3">
                    {!loading ? <button type="submit" className="cta cta--primary cta--big w-100">ENTRAR</button> :

                    <div className="wrap_cta">
                    
                        <Spinner className="d-table m-auto m-5" animation="border" variant="secondary" role="status">
                        <span className="sr-only">Loading...</span>
                        </Spinner>
                    
                    </div>

                    }
                </div>
                <div className="cta--text mt-2 d-table mx-auto" onClick={()=>{
                    setRecovery(true)
                }}>Recuperar contrasña</div>
                </Form>
               
            </div>
            :
            <RecoveryForm onBack={()=>{
                setRecovery(false);
            }}></RecoveryForm>

         
            }
        </div>
            

    )    
}
export default LoginView;


function RecoveryForm(props){

    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");

    let recuperar = async (event) => {
        event.preventDefault();
        var form = event.currentTarget;
        var formData = new FormData(form)
        setLoading(true)

        var res = await resetPassword(formData.get('usuario'))
        if(res.status){
            setMsg(res.data.data)
        }else{
            setMsg("Se envió un correo con la nueva contraseña, al correo electrónico ingresado.")
        }
        setLoading(false)
       
      };

    return (
            <div className="loginview_sigin py-5">
                <img src="assets/logo.svg" width="360" className="d-block mx-auto mb-5" />
                
                <Form onSubmit={recuperar}>
                <Form.Group as={Col} md="12" controlId="usuario">
                <div className="text-center pb-2">{msg}</div>
                    {/* <Form.Label>Usuario</Form.Label> */}
                    <InputGroup hasValidation>
                    <InputGroup.Prepend>
                        <InputGroup.Text><PersonFill /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        placeholder="Correo"

                        name="usuario"
                    />
                    </InputGroup>
                </Form.Group>
                
                <div className="px-3">
                    {!loading ? <button type="submit" className="cta cta--primary cta--big w-100">RECUPERAR</button> :

                    <div className="wrap_cta">                   
                        <Spinner className="d-table m-auto m-5" animation="border" variant="secondary" role="status">
                        <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    }
                </div>
                <div className="cta--text mt-2 d-table mx-auto" onClick={()=>{
                    
                    props.onBack()
                }}>Iniciar sesión</div>
                </Form>
               
            </div>
    );
}