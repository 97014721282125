import React,{useState, useEffect} from 'react'
import  {services,} from "../../services/api"
import {PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import DataListInput from 'react-datalist-input';
import {message, handleObraRowsAutofill} from "../capturaUtils"
import { StatusContent,  SectoresContent, RmrContent, BarsContent, ZonasContent, ObrasContent} from "../capturaUtils/CapturaInputs"


function AmaciceRow({f, context, showAlert}) {
    const [editing, setEditing] = useState(false)
    const [ item, setItem ] = useState(f)

    useEffect(()=>{
        setItem(f)
    },[f])

    useEffect(()=>{
        if(typeof item.id == 'string'){
            setEditing(true)
        }
    },[])

    const handleOperationItemChange = ((e)=>{
        const value = e.target.value
        console.log("change")
        setItem({
            ...item,
            [e.target.name]: value
          });
    })

    const handlePutReq =  (async(itemId)=>{
        setEditing(false)
        const res = await services("PUT" , "amacine_and_cleaning" ,  item, itemId)
        if(res.status == true){
            setItem(res.data)
            showAlert("success",`Listo, datos guardados correctamente`)
        }else{
            console.log(res)
            setEditing(true)
            showAlert("error",`Error al guardar sus datos.Compruebe sus datos e intente de nuevo.`)
        }
    })
    
    const handleEditing = (()=>{
        setEditing(true)
    })

  return (
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
            <select type="text" className="form-control" readOnly={!editing} name="obra_disparada" value={item.obra} required onChange={(e)=>{
                    handleOperationItemChange(e,item.id)
                    handleObraRowsAutofill(e, item, setItem, context)
            }} >
                <ObrasContent obras={context.generalObras.data}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="sector" value={item.sector} required>
                <SectoresContent sectores={context.sectores}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="zona" value={item.zona} required>
                <ZonasContent zonas={context.zonasListado}/>
            </select>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_largo" value={item.seccion_largo} required />
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_ancho" value={item.seccion_ancho} required />
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="descripcion" value={item.descripcion} required />
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="hora_inicial" value={item.hora_inicial} required />
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="hora_final" value={item.hora_final} required />
        </td>
        <td>
            <select     
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="estatus" 
                value={item.estatus} 
                readOnly={!editing}
            >
                <StatusContent/>
            </select>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="observaciones" value={item.observaciones} required />
        </td>
        <td>
            {editing ? 
                <button className='btn btn-primary mx-2 text-nowrap' onClick={() => {if(window.confirm(message)){handlePutReq(item.id)}}}>Guardar Cambio</button> :
                <PencilSquare size="25" color="#606060" className='cursor-pointer mx-2' onClick={handleEditing}/>
            }
        </td>
    </tr>
  )
}

export default AmaciceRow