import { createContext, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { recordsServiceId } from "../services/api"
import { useAuth } from "../services/auth"
import { UserContext } from "./user_context"


export const PlaneacionContext = createContext()
export const PlaneacionProvider = ({children}) => {

    let auth = useAuth()
    let {id} = useParams()
    const {setUser} = useContext(UserContext)

    const [state,setState] = useState({
        metros:[],
        metrosF:[],
        prod:[],
        prodF:[],
        extr:[],
        extrF:[],
        obras:[],
        filter:[],
        fechaStart:'',
        fechaEnd:'',
        project_id:id,
        loading:false,
        update:false
    })

    useEffect(()=>{
        const fetchData = async () =>{
            if(state.metros.length === 0 || state.prod.length === 0 || state.extr.length === 0){
                setState({...state,loading:true})
                var resMetros = await recordsServiceId('GET','planning_ml_m3',id)
                var resProd = await recordsServiceId('GET','planning_tons_production',id)
                var resExtr = await recordsServiceId('GET','planning_tons_extraction',id)
                var resObras = await recordsServiceId('GET','works',id)

                if(resMetros.status && resProd.status && resExtr.status){
                    setState({...state,metros:resMetros.data,prod:resProd.data,prodF:resProd.data,extr:resExtr.data,extrF:resExtr.data,obras:resObras.data,loading:false})
                }else{
                    if(resMetros.code===401){
                        window.localStorage.clear()
                        setUser({})
                        auth.setMsg(true)
                        auth.signout()
                    }
                }
            }
        }
        fetchData()
    },[])

    return(
        <PlaneacionContext.Provider value={{state,setState}}>
            {children}
        </PlaneacionContext.Provider>
    )
}