import React, { createContext, useState,useEffect, useContext } from "react"
import {dependentService,recordsService} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";

export const DepartamentosContext  = createContext();
export const DepartamentosProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)

    const [state, setState] = useState({
        departments:[],
        departmentsF:[],
        projects:[],
        projectsSelected:[],
        filter:10,
        search: "",
        loading:false
    });

    useEffect(async () =>{
        if(state.departments.length === 0){
            setState({...state, loading:true});
            var depRes = await recordsService("GET","departments");
            //var depRes = await getDepartments();
           
            var projRes = await dependentService("GET","projects");
            if(depRes.status){
                setState({...state, loading:false,departments:depRes.data, departmentsF:depRes.data, projects:projRes.data});
            }else{
                if(depRes.code == 401){
                    window.localStorage.clear();
                    setUser({});
                    auth.setMsg(true)
                    auth.signout();
                }
            }
        }
    },[])


    
    return(
        <DepartamentosContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </DepartamentosContext.Provider>

    )
}






