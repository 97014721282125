import React,{useContext,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { EyeFill, PencilSquare } from 'react-bootstrap-icons';
import Switchy from '../triggers/switchy';
import { BarrasContext } from '../../context/barras_context';
import {services} from '../../services/api';
import { selectFromObjbyId } from '../../utils/utils';
import { IconButton } from '@material-ui/core';

import { BarrenacionJumboContext } from '../../context/barrenacion_jumbo_context';
import { useRouteMatch, Link } from 'react-router-dom';
import { VoladurasContext } from '../../context/voladuras_context';
import { FormularioGeneralContext } from '../../context/formulario_general_context';

const columns = [

  
  { id:'folio', label:'No. Folio', minWidth: 170 },
  { id:'date', label:'Fecha', minWidth: 170 },
  { id:'ver', label:'Ver', minWidth: 100 },
  
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});




function FormulariosGeneralesRecords(props) {

  const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { path, url } = useRouteMatch();


  useEffect(() => {
    
      
    var data = stateGenerales.generales.filter((item)=>{
      var active = item.deleted_at === null ? 1 : 0;
   
  
      if(stateGenerales.search !== "" && stateGenerales.fecha !== "" ){
        return item.folio.toLocaleUpperCase().includes(stateGenerales.search.toLocaleUpperCase()) && item.date.includes(stateGenerales.fecha)
      }

      if(stateGenerales.search !== "" && stateGenerales.fecha == "" ){
        return item.folio.toLocaleUpperCase().includes(stateGenerales.search.toLocaleUpperCase());
      }

      if(stateGenerales.search == "" && stateGenerales.fecha !== "" ){
        return item.date.includes(stateGenerales.fecha)
      }

    






      return true;
    
    })
    setPage(0);
    setStateGenerales({...stateGenerales, generalesF:data});

  }, [stateGenerales.search, stateGenerales.fecha])


  useEffect(() => {
      
    var data = stateGenerales.generales.filter((item)=>{
      var active = item.deleted_at === null ? 1 : 0;

      if(stateGenerales.filter === 10){
        return true;
      }
      return active === stateGenerales.filter;
    })




    setPage(0);
    setStateGenerales({...stateGenerales, generalesF:data});

  }, [stateGenerales.filter])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleSwitch = async (data,id, val)=>{
  //   var res;
  //   if(!val){
  //     res = await services("DELETE","materials",undefined,id);
  //   }else{
  //     res = await services("GET","materials",undefined,id);
  //   }
  //   if(res.status){
  //     var generales = data
  //     var generales = selectFromObjbyId(data, id);
  //     generales.deleted_at = res.data.deleted_at;
  //     setStateGenerales({...stateGenerales, generales })
  //   }  
  // }

  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
                  
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                   }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stateGenerales.generalesF.filter((item)=>item.operation.id === props.operation && item.project_id == stateGenerales.project_id).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

            let active = row.deleted_at === null ? 1 : 0;
            let switchActive = row.deleted_at === null ? true : false;
         

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                          paddingRight:10,
                          paddingLeft:5,
                          paddingTop:5,
                          paddingBottom:0,

                         }}>
                          {

                            column.id === "ver" ? 
                            <Link to={`${url}/${row.id}`}>
                              <IconButton disabled={!switchActive}>
                                  <EyeFill size={20} className="cursor-pointer" color={switchActive ? "#0a5cbe" : "grey"}/>
                              </IconButton>
                            </Link> 

                            :  value
                          }
                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
              
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={stateGenerales.generalesF.filter((item)=>item.operation.id===props.operation).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default FormulariosGeneralesRecords;