import { ButtonGroup, IconButton } from '@material-ui/core'
import React, {useContext, useEffect, useState} from 'react'
import { ArrowLeftSquareFill,Journals, Back, Person } from 'react-bootstrap-icons'
import { UserContext } from '../context/user_context'

import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { ProyectosContext } from '../context/proyectos_context';
import { selectFromObjbyId } from '../utils/utils';
import ViewDataLoader from '../components/ui/view_data_loader';
import ListaDetalleRecords from '../components/records/listas_asistencia_detalle_records';
import { singleService } from '../services/api';
import { ListaContext } from '../context/lista_context';
import { ListasAsistenciaContext } from '../context/listas_asistencia_context';


function ListaDetalle() {

    const { user } = useContext(UserContext);        
    const [proyecto, setProyecto] = useState(undefined);
    const {state,setState} = useContext(ProyectosContext)
    const {stateLista,setStateLista} = useContext(ListaContext)
    const {stateListas, setStateListas} = useContext(ListasAsistenciaContext)
  
    let { id } = useParams();
    let history = useHistory();
    let location = useLocation();
    const { path, url } = useRouteMatch();

    useEffect( async ()=>{
        


        // let proyecto = selectFromObjbyId(state.projects,parseInt(id))
        // if(proyecto === undefined){
        //     console.log(location);
        //     history.replace(`/home/proyectos` );
        // }else{
        //     setProyecto(proyecto);
        // }

        

    },[id]);

    if(stateLista.loading === undefined){
        return (
            ViewDataLoader()
        )
    }else{
        return (
            <main role="main" className="">
                <div className="container-fluid position-relative py-1 ">
                <div className="d-flex align-items-end py-2">
                    <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                    history.goBack();
                    }}/>
                    <Journals className="ml-2" size="40" color="#0a5cbe"></Journals>
                    <div>
                        <h4 className="pl-1"> Catálogo: Listas de asistencia</h4>  
                        <h4 className="line-height-xs pl-1">PROYECTO: {stateListas.project_name} </h4>
                    </div>
                </div> 

                <div className="container-fluid p-0 py-2 mb-1 mt-3">
                    <div className="d-flex">
                        <div className="col-md-2 p-0">
                            <strong>Semana: </strong>{stateLista.lista.week}<br></br>
                            <strong>Turno: </strong>{stateLista.lista.turn}
                        </div>
                        
                        <div className="col-md-2 p-0">
                            <strong>Año: </strong>{stateLista.lista.year}<br></br>
                            <strong>Mes: </strong>{stateLista.lista.mounth}
                        </div>
                        <div className="col-md-2 p-0">
                            <strong>Inicio: </strong>{stateLista.lista.date_start} <br></br>
                            <strong>Fin: </strong>{stateLista.lista.date_end}
                        </div>
                        <div className="col-md-2 p-0">
                            <strong>Hora Inicio: </strong>{stateLista.lista.hour_start} <br></br>
                            <strong>Hora Fin: </strong>{stateLista.lista.hour_end}
                        </div>

                    </div>
                </div>
               

                <ListaDetalleRecords ></ListaDetalleRecords>

                </div>



            </main>
        )
    }
}

export default ListaDetalle
