import { useContext, useState } from "react"
import ViewDataLoader from "../components/ui/view_data_loader"
import { AlertContext } from "../context/alert_context"
import { TipoObrasContext } from "../context/tipo_obras_context"
import { UserContext } from "../context/user_context"
import { Journals, XCircleFill, FileSpreadsheetFill, ArrowLeftSquareFill } from "react-bootstrap-icons"
import { Form,Button, Col } from "react-bootstrap"
import ActionAlert from "../components/ui/alert"
import TipoObrasRecords from "../components/records/tipo_obras_records"
import { hideLoader, replaceObj, selectFromObjbyId, showLoader, today } from "../utils/utils"
import { services } from "../services/api"
import {useHistory} from 'react-router'


let editing = false
let editingId
export default function TipoObrasView(props) {
    let history = useHistory()
    const [validated, setValidated] = useState(false)
    const [title,setTitle] = useState(true)
    const {state,setState} = useContext(TipoObrasContext)
    const {user} = useContext(UserContext)
    const {alert,showAlert,closeAlert} = useContext(AlertContext)

    const showForm = (data=undefined)=>{
        let reason_edition = document.getElementById('reason_edition')
        reason_edition.classList.add('d-none')

        let form = document.getElementById('main_form')

        if(data !== undefined){
            reason_edition.classList.remove('d-none')
            setTitle(false)
            fillForm(data)
        }
        form.classList.add('d-block')
        form.classList.remove('d-none')
    }

    const hideForm = () =>{
        let form = document.getElementById("main_form");
        clearForm();
        form.classList.add("d-none");
        form.classList.remove("d-block");
    }

    const onChangeFilter = (evt) =>{
        setState({...state,filter:parseInt(evt.currentTarget.value)})
    }

    const onSearchChange = (val)=>{
        var search = val.currentTarget.value
        setState({...state,search})
    }

    const fillForm = (data) =>{
        let {elements} = document.getElementById('tipo_obras_form')
        elements.namedItem('reason_edition').disabled = false
        for(const [key,value] of Object.entries(data)){
            const field = elements.namedItem(key)
            if(key !== 'reason_edition'){
                field && (field.value = value)
            }
        }
        editing=true
        editingId=data.id
    }

    const clearForm = () =>{
        let form = document.getElementById('tipo_obras_form')
        editing = false
        editingId = undefined
        form.reset()
        setTitle(true)
        setValidated(false)
    }

    const handleSubmit = async (event) =>{
        event.preventDefault()
        event.stopPropagation()
        const form = event.currentTarget
        var formData = new FormData(form)

        var body = {}
        formData.forEach((value,key) => body[key] = value)
        body.project_id = state.project_id

        if(form.checkValidity()){
            var res;
            showLoader()
            if(!editing){
                res = await services('POST','work_types',body)
            }else{
                res = await services('PUT','work_types',body,editingId)
            }

            if(res.status){
                if(!editing){
                    var tipos = state.tipos
                    var tiposF = state.tiposF

                    var tipo = res.data
                    var project = selectFromObjbyId(state.projects, parseInt(tipo.project_id))
                    tipo.project = project
                    tipo.deleted_at = null

                    tipos.unshift(tipo)
                    tiposF.unshift(tipo)
                    setState({...state,tipos,tiposF})
                    showAlert('success', '¡Listo! Registro agregado exitosamente.')
                }else{
                    var tipo = res.data
                    var project = selectFromObjbyId(state.projects,parseInt(tipo.project_id))

                    tipo.project = project

                    var tipos = replaceObj(state.tipos,tipo)
                    var tiposF = replaceObj(state.tiposF,tipo)

                    setState({...state,tipos,tiposF})
                    showAlert('success', '¡Listo! Registro editado exitosamente.')
                }
                hideLoader()
                hideForm()
                clearForm()
            }
        }
    }

    if(state.loading){
        return(
            ViewDataLoader()
        )
    }else{
        return(
            <main role="main" className="">
                <div className="container-fluid position-relative min-h py-1">
                    <div className="d-flex align-items-end py-2">
                        <ArrowLeftSquareFill size={40} className="cursor-pointer" color="#0a5cbe" onClick={()=>{
                            history.goBack();
                        }}/>
                        <h2><Journals size={25} color='#0a4cbe'></Journals>Tipo de Obras</h2>
                    </div>
                    <div className="d-flex">
                        <div className="col-2 py-2 px-0">
                            <Form.Label>Buscar:</Form.Label>
                            <Form.Control type='text' name='buscar' onChange={onSearchChange}/>
                        </div>
                        <div className="col-3 py-2 px-0 mx-2">
                            <Form.Label>Estatus:</Form.Label>
                            <Form.Control as='select' name='estatus' onChange={onChangeFilter}>
                                <option value="10">Todos</option>
                                <option value="1">Activo</option>
                                <option value="0">Desactivado</option>
                            </Form.Control>
                        </div>
                        <div className="col-4 d-flex align-items-end justify-content-end py-2 px-0">
                            <Button className='primary px-4' onClick={()=>{showForm()}}>Nuevo</Button>
                        </div>
                        <div className="col-2 d-flex align-items-end justify-content-end py-2 px-0">
                            <Button className="btn success px-4" variant="success"><a className="text-light" href={`https://mineria.canteradigital.mx/api/work_types/excel/${state.project_id}?type=${state.search}&status=${state.filter===10?'Todos':state.filter===1?'Activos':'Inactivos'}`}><FileSpreadsheetFill/> Descargar</a></Button>
                        </div>
                    </div>
                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                    <TipoObrasRecords onEdit={(data)=>showForm(data)}/>
                    <div className="catalogo_form_modal h-100 d-none py-3" id="main_form">
                        <Form validated={validated} onSubmit={handleSubmit} id='tipo_obras_form'>
                            <div className="container w-50 position-relative bg-white rounded pt-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col">
                                            <h5>{title ? 'NUEVO' : "EDITAR"} TIPO DE OBRAS</h5>
                                        </div>
                                        <Form.Group as={Col}>
                                            <Form.Label>Fecha: </Form.Label>
                                            <div className="form-control disabled">{today()}</div>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Responsable: </Form.Label>
                                            <div className="form-control disabled">{user.name}</div>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Código</Form.Label>
                                            <Form.Control type="text" name='code' required/>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Tipo de Obra</Form.Label>
                                            <Form.Control type="text" name='type' required/>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Descripción</Form.Label>
                                            <Form.Control type="text" name='description' required/>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <div id="reason_edition">
                                            <Form.Group as={Col}>
                                                <Form.Label>Motivo de edición</Form.Label>
                                                <Form.Control as="textarea" rows={3} name='reason_edition' disabled required/>
                                                <Form.Control.Feedback type="invalid">
                                                    Este campo es obligatorio
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <Form.Group as={Col}>
                                            <Button type="submit">GUARDAR</Button>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="cta position-absolute top right p-2" onClick={hideForm}>
                                    <XCircleFill size="45"></XCircleFill>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </main>
        )
    }
}