import { useContext, useState } from "react"
import { ArrowLeftSquareFill, Journals, XCircleFill } from "react-bootstrap-icons"
import { useHistory } from "react-router"
import ViewDataLoader from "../components/ui/view_data_loader"
import { AlertContext } from "../context/alert_context"
import { PlaneacionContext } from "../context/planeacion_context"
import { UserContext } from "../context/user_context"
import { Form, Button, Col} from 'react-bootstrap'
import ActionAlert from "../components/ui/alert"
import PlaneacionMetrosRecords from "../components/records/planeacion_metros_records"
import PlaneacionProduccionRecords from "../components/records/planeacion_produccion_records"
import PlaneacionExtraccionRecords from "../components/records/planeacion_extraccion_records"
import axios from "axios"
import { filteredPlanning, planningFile, services } from "../services/api"
import {hideLoader, showLoader} from '../utils/utils'
import { StarTwoTone } from "@material-ui/icons"

let editing = false
let editingId

export default function PlaneacionView(props) {
    let history = useHistory()
    const [validated,setValidated] = useState(false)
    const [title,setTitle] = useState(true)
    const {state,setState} = useContext(PlaneacionContext)
    const {user} = useContext(UserContext)
    const {alert,showAlert,closeAlert} = useContext(AlertContext)
    const [metersFile, setMetersFile] = useState()
    const [tonsFile, setTonsFile] = useState()
    const [clear,setClear] = useState('')

    const getFilteredData = async () =>{
        if(state.fechaEnd!=='' && state.fechaStart!==''){
            filteredPlanning(state.project_id,state.fechaStart,state.fechaEnd,'ml_m3').then(res=>{
                setState({...state,metrosF:res.data,update:!state.update})
            })
            filteredPlanning(state.project_id,state.fechaStart,state.fechaEnd,'tons_extraction').then(resExtr=>{
                console.log(resExtr)
                setState({...state,extrF:resExtr.data,update:!state.update})
            })
            filteredPlanning(state.project_id,state.fechaStart,state.fechaEnd,'tons_production').then(resProd=>{
                setState({...state,prodF:resProd.data,update:!state.update})
            })
        }
    }


    const showForm = (data=undefined, modal) =>{
        let reason_edition = document.getElementById(modal)
        reason_edition.classList.add('d-none')

        let form = document.getElementById(modal)
        
        if(data !== undefined){
            reason_edition.classList.remove('d-none')
            setTitle(false)
            fillForm(data,modal)
        }
        form.classList.add('d-block')
        form.classList.remove('d-none')
    }

    const fillForm  = (data,modal) =>{
        let type = modal === 'second_form' ? 'production' : 'extraction'
        let {elements} = document.getElementById(type)
        for(const [key,value] of Object.entries(data)){
            const field = elements.namedItem(key)
            if(key !== 'reason_edition'){
                field && (field.value = value)
            }
        }
        editing = true
        editingId = data.id
    }

    const hideForm = (modal) =>{
        let form = document.getElementById(modal)
        clearForm(modal)
        form.classList.add('d-none')
        form.classList.remove('d-block')
    }

    const clearForm = (modal) =>{
        let form = document.getElementById('extraction')
        if(modal==='second_form') form = document.getElementById('production')
        editing = false
        editingId = undefined
        form.reset()
        setTitle(true)
        setValidated(false)
    }

    const onStartDateChange = (evt) =>{
        setState({...state,fechaStart:evt.currentTarget.value})
    }

    const onEndDateChange = (evt) =>{
        setState({...state,fechaEnd:evt.currentTarget.value})
    }
    
    const showExcelForm = () =>{
        let form = document.getElementById('excel_form')
        form.classList.remove('d-none')
        form.classList.add('d-block')
    }

    const hideExcelForm = () =>{
        let form = document.getElementById('excel_form')
        form.classList.remove('d-block')
        form.classList.add('d-none')
        setMetersFile()
        setTonsFile()
        setClear(new Date())
    }

    const saveExcel = async type =>{
        if(type==='meters'){
            let data = new FormData()
            data.set('excel_file',metersFile,'excel_file.xlsx')
            let res = await planningFile(state.project_id,data,'ml_m3')
            if(res.status){
                hideExcelForm()
            }
        }else{
            let data = new FormData()
            data.set('excel_file',tonsFile,'excel_file.xlsx')
            let res = await planningFile(state.project_id,data,'tons_extraction')
            if(res.status){
                hideExcelForm()
            }
        }
    }

    const saveMetersFile = e =>{
        setMetersFile(e.target.files[0])
    }
    
    const saveTonsFile = e =>{
        setTonsFile(e.target.files[0])
    }


    const handleSubmit = async (event) =>{
        event.preventDefault()
        event.stopPropagation()
        const form = event.currentTarget
        var formData = new FormData(form)

        var body = {}
        formData.forEach((value,key)=>body[key]=value)
        body.project_id = state.project_id
        console.log(form.id)
        console.log(body)
        if(form.checkValidity()){
            var res
            showLoader()
            if(form.id==='metros_form'){
                body.ml = parseFloat(body.ml)
                body.m3 = parseFloat(body.m3)
                res = await services('POST','planning_ml_m3',body)
                if(res.status){
                    showAlert('success','¡Listo! Registro agregado exitosamente.')
                }else{
                    var message = ''
                    Object.keys(res.errors).map(key=>{
                        message = message + ' ' + res.errors[key]
                    })
                    showAlert('error',message)
                }
                
            }else{
                body.plan_mes = parseFloat(body.plan_mes)
                if(!editing){
                    res = await services('POST','planning_tons_'+form.id,body)
                }else{
                    res = await services('POST','planning_tons_'+form.id,body,editingId)
                }
                if(res.status){
                    showAlert('success','¡Listo! Registro agregado exitosamente.')
                }else{
                    var message = ''
                    Object.keys(res.errors).map(key=>{
                        message = message + ' ' + res.errors[key]
                    })
                    showAlert('error',message)
                }
            }
            hideLoader()
            hideForm('main_form')
            hideForm('second_form')
            hideForm('third_form')
            clearForm()
        }
    }

    if(state.loading){
        return(
            ViewDataLoader()
        )
    }else{
        return(
            <main role="main">
                <div className="container-fluid position-relative min-h py-1">
                    <div className="d-flex align-items-end py-2">
                        <ArrowLeftSquareFill size={40} className='cursor-pointer' color="#0a5cbe" onClick={()=>history.goBack()} />
                        <h2><Journals size={25} color='#0a4cbe'/>Planeación</h2>
                    </div>
                    <div className="d-flex">
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Desde: </Form.Label>
                            <Form.Control type="date" name="date_start" value={state.fechaStart} onChange={onStartDateChange}/>
                        </div>
                        <div className="col-2 py-2 px-0 mx-2">
                            <Form.Label>Hasta: </Form.Label>
                            <Form.Control type="date" name="date_end" value={state.fechaEnd} onChange={onEndDateChange}/>
                        </div>
                        <div className="col-2 d-flex align-items-end justify-content-end py-2 px-0">
                            <Button className="primary px-4" onClick={()=>getFilteredData()}>Filtrar</Button>
                        </div>
                        <div className="col-2 d-flex align-items-end justify-content-end py-2 px-0">
                            <a href={`https://mineria.canteradigital.mx/api/planning_ml_m3/excel/${state.project_id}/?desde=${state.fechaStart}&hasta=${state.fechaEnd}`} className="btn btn-primary px-4">Exportar</a>
                        </div>
                        <div className="col-2 d-flex align-items-end justify-content-end py-2 px-0">
                            <Button onClick={()=>showExcelForm()}>Carga Masiva</Button>
                        </div>
                    </div>
                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert}/>
                    <div className="">
                        <div className="d-flex justify-content-between">
                            <h5>Planeación metros lineales y metros cúbicos del periodo: {state.fechaStart} - {state.fechaEnd}</h5>
                            <button className="btn btn-primary" onClick={()=>showForm(undefined,'main_form')}>Nuevo</button>
                        </div>
                        <PlaneacionMetrosRecords/>
                    </div>
                    <div className="d-flex py-2">
                        <div className="col-6">
                            <div className="d-flex justify-content-between">
                                <h5>Planeacion Toneladas producción del periodo: {state.fechaStart} - {state.fechaEnd}</h5>
                                <button className="btn btn-primary" onClick={()=>showForm(undefined,'second_form')}>Nuevo</button>
                            </div>
                            <PlaneacionProduccionRecords onEdit={(data)=>showForm(data,'second_form')}/>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-between">
                                <h5>Planeacion Toneladas Extracción del periodo: {state.fechaStart} - {state.fechaEnd}</h5>
                                <button className="btn btn-primary" onClick={()=>showForm(undefined,'third_form')}>Nuevo</button>
                            </div>
                            <PlaneacionExtraccionRecords onEdit={(data)=>showForm(data,'third_form')}/>
                        </div>
                    </div>
                    <div className="catalogo_form_modal h-100 d-none py-3" id="main_form">
                        <Form validated={validated} onSubmit={handleSubmit} id='metros_form'>
                        <div className="container w-50 position-relative bg-white rounded pt-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col">
                                            <h5>{title ? 'NUEVO' : 'EDITAR'} PLANEACION ML Y M3</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Form.Group as={Col}>
                                        <Form.Label>Obra</Form.Label>
                                        <Form.Control required as='select' type="select" name="obra">
                                            <option value="">Selecciona</option>
                                            {state.obras.map(obra=>{
                                                return <option key={obra.id} value={obra.name}>{obra.name}</option>
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    <Form.Group as={Col}>
                                        <Form.Label>Fecha</Form.Label>
                                        <Form.Control type="date" required name='date'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="row">
                                    <Form.Group as={Col}>
                                        <Form.Label>ML</Form.Label>
                                        <Form.Control type='number' required name='ml'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>M3</Form.Label>
                                        <Form.Control type='number' required name='m3'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="row">
                                    <div id="reason_edition">
                                        <Form.Group as={Col}>
                                            <Form.Label>Motivo de edición</Form.Label>
                                            <Form.Control as="textarea" rows={3} name='reason_edition' disabled required/>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <Form.Group as={Col}>
                                        <Button type="submit">GUARDAR</Button>
                                    </Form.Group>
                                    <div className="cta position-absolute top right p-2" onClick={()=>hideForm('main_form')}>
                                        <XCircleFill size='45'></XCircleFill>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="catalogo_form_modal h-100 d-none py-3" id="second_form">
                        <Form validated={validated} onSubmit={handleSubmit} id='production'>
                        <div className="container w-50 position-relative bg-white rounded pt-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col">
                                            <h5>{title ? 'NUEVO' : 'EDITAR'} PRODUCCIÓN DE TON</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Form.Group as={Col}>
                                        <Form.Label>Obra</Form.Label>
                                        <Form.Control required as='select' type="select" name="obra">
                                            <option value="">Selecciona</option>
                                            {state.obras.map(obra=>{
                                                return <option key={obra.id} value={obra.id}>{obra.name}</option>
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    <Form.Group as={Col}>
                                        <Form.Label>Fecha Inicio</Form.Label>
                                        <Form.Control type="date" required name='fecha_inicio'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Fecha Fin</Form.Label>
                                        <Form.Control type="date" required name='fecha_fin'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="row">
                                    <Form.Group as={Col}>
                                        <Form.Label>Toneladas</Form.Label>
                                        <Form.Control type='number' step={0.00000001} required name='plan_mes'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div id="reason_edition">
                                    </div>
                                    <Form.Group as={Col}>
                                        <Button type="submit">GUARDAR</Button>
                                    </Form.Group>
                                    <div className="cta position-absolute top right p-2" onClick={()=>hideForm('second_form')}>
                                        <XCircleFill size='45'></XCircleFill>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="catalogo_form_modal h-100 d-none py-3" id="third_form">
                        <Form validated={validated} onSubmit={handleSubmit} id='extraction'>
                        <div className="container w-50 position-relative bg-white rounded pt-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col">
                                            <h5>{title ? 'NUEVO' : 'EDITAR'} EXTRACCIÓN DE TON</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Form.Group as={Col}>
                                        <Form.Label>Obra</Form.Label>
                                        <Form.Control required as='select' type="select" name="obra">
                                            <option value="">Selecciona</option>
                                            {state.obras.map(obra=>{
                                                return <option key={obra.id} value={obra.id}>{obra.name}</option>
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    <Form.Group as={Col}>
                                        <Form.Label>Fecha Inicio</Form.Label>
                                        <Form.Control type="date" required name='fecha_inicio'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Fecha Fin</Form.Label>
                                        <Form.Control type="date" required name='fecha_fin'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="row">
                                    <Form.Group as={Col}>
                                        <Form.Label>Toneladas</Form.Label>
                                        <Form.Control type='number' step={0.00000001} required name='plan_mes'/>
                                        <Form.Control.Feedback>
                                            Este Campo es Obligatorio
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div id="reason_edition">
                                    </div>
                                    <Form.Group as={Col}>
                                        <Button type="submit">GUARDAR</Button>
                                    </Form.Group>
                                    <div className="cta position-absolute top right p-2" onClick={()=>hideForm('third_form')}>
                                        <XCircleFill size='45'></XCircleFill>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="catalogo_form_modal h-100 d-none py-3" id='excel_form'>
                        <div className="w-50 container position-relative bg-white rounded pt-4 py-2">
                            <div className="row d-flex justify-content-center">
                                <h6>Carga masiva de información</h6>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <p>En este apartado se podrá realizar la carga masiva de registros, para esto se debe de seguir el layout de muestra para evitar inconsistencias.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Metros</h6>
                                    <input key={clear} className="py-2" type="file" name="planning_ml_m3" id="planning_ml_m3" onChange={e=>saveMetersFile(e)}/>
                                    <Button onClick={()=>saveExcel('meters')}>Guardar Carga Masiva ML/M3</Button>
                                </div>
                                <div className="col-md-6">
                                    <h6>Toneladas</h6>
                                    <input key={clear} className="py-2" type="file" name="tons" id="tons" onChange={e=>saveTonsFile(e)}/>
                                    <Button onClick={()=>saveExcel('tons')}>Guardar Carga Masiva Toneladas Producción/Extracción</Button>
                                </div>
                            </div>
                            <div className="cta position-absolute top right p-2" onClick={()=>hideExcelForm()}>
                                <XCircleFill size='45'></XCircleFill>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}