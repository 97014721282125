import React, { createContext, useState,useEffect, useContext } from "react"
import {dependentService, historialFitro, recordsService, recordsServicePage} from '../services/api' 


import { useAuth } from "../services/auth";
import { AlertContext } from "./alert_context";
import { UserContext } from "./user_context";


export const HistorialEdicionesContext  = createContext();
export const HistorialEdicionesProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)
    const {alert, showAlert, closeAlert} = useContext(AlertContext)


    const [state, setState] = useState({
        historial:[],
        usuarios:[],
        filter:10,
        search: "",
        loading:false,
        userFilter:"",
        catalogFilter:"",
        startDate:"",
        endDate:"",
        historialFiltered:[],
        count:0,
        countF:0,
        pages:9
    });

  
    const getMore = async (limit, page) => {

        var more = await recordsServicePage("GET","historicals",limit, page);
       
        return more;

    }

    const getFilter = async () => {
        setState({...state, loading:true});

        var res = await historialFitro( 
        state.startDate,
        state.endDate,
        state.userFilter,
        state.catalogFilter

        );
        if(res.status){
           
            setState({...state, startDate:"", endDate:"", userFilter:"", catalogFilter:"", loading:false, historialFiltered:res.data, countF:res.data.length});
        }

    }

    useEffect(async () =>{
        if(state.historial.length === 0 || state.usuarios.length === 0 || state.historialFiltered === 0 ){
            setState({...state, loading:true});
         

            var res = await recordsService("GET","historicals", 100);
            var resUsers = await dependentService("GET","users","Todos");
      

            let historial = res.data;

            if(res.status){
                setState({...state, loading:false, historial,  historialFiltered:historial, usuarios:resUsers.data, count:res.count, countF:res.count});
            }
        }
    },[])
    
    return(
        <HistorialEdicionesContext.Provider value={{
            state,
            setState,
            getFilter,
            getMore
        }}>
            {children}
        </HistorialEdicionesContext.Provider>

    )
}






