import React,{useState, useEffect} from 'react'
import  {services,} from "../../services/api"
import {PlusCircleFill, PencilSquare } from 'react-bootstrap-icons';
import DataListInput from 'react-datalist-input';
import {message, handleObraRowsAutofill, handleInputChange} from "../capturaUtils"
import { StatusContent,  SectoresContent, RmrContent, BarsContent, ZonasContent, ObrasContent} from "../capturaUtils/CapturaInputs"

function SoporteObraRow({f, context, showAlert}) {
    const [editing, setEditing] = useState(false)
    const [ item, setItem ] = useState(f)
    const anclasArray = f.anclas.map((item, index)=>{
        return {'id':index + 1,'ancla':item.ancla,'cantidad':item.cantidad}
    })
    const [anclas, setAnclas] = useState(anclasArray)
    

    useEffect(()=>{
        setItem(f)
    },[f])

    useEffect(()=>{
        if(typeof item.id == 'string'){
            setEditing(true)
        }
        console.log(anclas)
    },[])

    const handleOperationItemChange = ((e)=>{
        const value = e.target.value
        setItem({
            ...item,
            [e.target.name]: value
          });
        console.log(anclas)
    })

    const handlePutReq =  (async(itemId)=>{
        const body = {
            "id": item.id,
            "folio": item.folio,
            "project_id": item.project_id,
            "obra": item.obra,
            "zona": item.zona,
            "seccion_largo": `${item.seccion_largo}`,
            "seccion_ancho": `${item.seccion_ancho}`,
            "seccion_real_largo": `${item.seccion_real_largo}`,
            "seccion_real_ancho": `${item.seccion_real_ancho}`,
            "rmr": item.rmr,
            "anclas":anclas,
            "barr_servi": item.barr_servi,
            "hora_inicial": item.hora_inicial,
            "hora_final": item.hora_final,
            "estatus": item.estatus,
            "observaciones": item.observaciones,
        }

        const res = await services("PUT" , "work_support_forms" ,  body, itemId)
        if(res.status == true){
            showAlert("success",`Listo, datos guardados correctamente`)
            setItem(res.data)
            setEditing(false)
            console.log(res)
        }else{
            showAlert("error",`Error al guardar sus datos.Compruebe sus datos e intente de nuevo.`)
            setEditing(true)
            console.log(res)
        }
    })
    
    const handleEditing = (()=>{
        setEditing(true)
    })

  return (
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
            <select id=""  className="form-control" name="obra" value={item.obra} required readOnly={!editing} onChange={(e)=>{
                    handleOperationItemChange(e,item.id)
                    handleObraRowsAutofill(e, item, setItem, context)
            }} >
                <ObrasContent obras={context.generalObras.data}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="sector" value={item.sector} required>
                <SectoresContent sectores={context.sectores}/>
            </select>
        </td>
        <td>
            <select type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="zona" value={item.zona} required>
                <ZonasContent zonas={context.zonasListado}/>
            </select>
        </td>
        <td >
            <input type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_largo" value={item.seccion_largo} required/>
        </td>
        <td >
            <input type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_ancho" value={item.seccion_ancho} required/>
        </td>
        <td >
            <input type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_real_largo" value={item.seccion_real_largo} required/>
        </td>
        <td >
            <input type="number" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="seccion_real_ancho" value={item.seccion_real_ancho} required/>
        </td>
        <td>
            <select 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="rmr" 
                value={item.rmr} 
                readOnly={!editing}
                type ="hidden"
                >
                    <RmrContent rmr={context.rmr}/>
            </select>
        </td>
        <td className="d-flex">
            {anclas.map((anclasItem, i)=>(
                <span key={i}>
                    <label>{anclasItem.ancla}</label>
                    <input type="number" name="cantidad" className='mr-2 form-control' value={anclasItem.cantidad} onChange={(e) => handleInputChange(e, anclasItem.id, anclas, setAnclas )} readOnly={!editing}/>
                </span>
            ))}
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="barr_servi" value={item.barr_servi} required/>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="hora_inicial" value={item.hora_inicial} required/>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="hora_final" value={item.hora_final} required/>
        </td>
        <td>
        <td>
            <select 
                type="text" 
                className="form-control" 
                onChange={(e)=>handleOperationItemChange(e,item.id)} 
                name="estatus" 
                value={item.estatus} 
                readOnly={!editing}
            >
                <StatusContent/>
            </select>
        </td>
        </td>
        <td>
            <input type="text" className="form-control" onChange={(e)=>handleOperationItemChange(e,item.id)} readOnly={!editing} name="observaciones" value={item.observaciones} required/>
        </td>
        <td>
        {editing ? 
            <button className='btn btn-primary mx-2 text-nowrap' onClick={() => {if(window.confirm(message)){handlePutReq(item.id)}}}>Guardar Cambio</button> :
            <PencilSquare size="25" color="#606060" className='cursor-pointer mx-2' onClick={handleEditing}/>
        }
        </td>
    </tr>
  )
}

export default SoporteObraRow