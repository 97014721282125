import React,{useState, useEffect, useContext} from 'react'
import  {dependentServiceId, getOperationRecords} from "../services/api"//
import { AlertContext } from '../context/alert_context';
import { useHistory, useParams,useRouteMatch, Link } from 'react-router-dom';
import ServiciosAdministrativosRecords from "./ServiciosAdministrativosRecords"
import { FormularioGeneralContext } from '../context/formulario_general_context';

function ServiciosAdministrativosView(){

    let { id } = useParams();
    const {stateGenerales, setStateGenerales} = useContext(FormularioGeneralContext)  
    const {alert, showAlert, closeAlert} = useContext(AlertContext)
    const [operationItems, setOperationItems] = useState([])

    useEffect(async()=>{
        var operationData = await dependentServiceId("GET", "administrative_services_form",1)  
        setOperationItems(operationData.data.filter(item=>item.project_id == id).reverse())
        console.log(operationData)
        console.log(stateGenerales)
    },[])

    return(
        <main role="main" className={!alert.open ? "d-flex flex-column menu_collapsed px-3": "d-flex flex-column px-3"}>
            <ServiciosAdministrativosRecords operationContent={operationItems} context={stateGenerales}/>
        </main>
    )
}

export default ServiciosAdministrativosView;