import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const EspeciBarrenacionContext  = createContext();
export const EspeciBarrenacionProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateBarrenacion, setStateBarrenacion] = useState({
        tipos_barrenacion:[],
        tipos_barrenacionF:[],


        plantillas_barrenacion:[],
        plantillas_barrenacionF:[],



        project_id:null,
        project_name:"",
        loading:false,
        filter_plantilla:null,
        filter_tipo:null,
    });

    useEffect(async () =>{
        
        
        
            setStateBarrenacion({...state, loading:true});
           

            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
                var tipos_barreRes = await recordsServiceId("GET","type_drillings", id);
                var platillas_barreRes = await recordsServiceId("GET","template_drillings", id);
            
                if(tipos_barreRes.status && platillas_barreRes.status){ 

                
                
                    setStateBarrenacion({
                 
                        tipos_barrenacion:tipos_barreRes.data,
                        tipos_barrenacionF:tipos_barreRes.data,


                        plantillas_barrenacion:platillas_barreRes.data,
                        plantillas_barrenacionF:platillas_barreRes.data,


                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter_plantilla:10,
                        filter_tipo:10,
                        
                    });
                }else{
                    if(tipos_barreRes.code === 401 || tipos_barreRes.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])

    useEffect(() => {
     

        if(stateBarrenacion.tipos_barrenacion !== undefined && stateBarrenacion.plantillas_barrenacion !== undefined){

        let tipos_barrenacionF = stateBarrenacion.tipos_barrenacion.filter((item)=>{
          var active = item.deleted_at === null ? 1 : 0;
    
          if(stateBarrenacion.filter_tipo === 10){
            return true;
          } 
          return active === stateBarrenacion.filter_tipo;
         
        })

        let plantillas_barrenacionF = stateBarrenacion.plantillas_barrenacion.filter((item)=>{
            var active = item.deleted_at === null ? 1 : 0;
      
            if(stateBarrenacion.filter_plantilla === 10){
              return true;
            } 
            return active === stateBarrenacion.filter_plantilla;
           
          })
        //setPage(0);
          setStateBarrenacion({...stateBarrenacion, tipos_barrenacionF, plantillas_barrenacionF});

        }


      }, [stateBarrenacion.filter_plantilla, stateBarrenacion.filter_tipo ])


    
    return(
        <EspeciBarrenacionContext.Provider value={{
            stateBarrenacion,
            setStateBarrenacion
        }}>
            {children}
        </EspeciBarrenacionContext.Provider>
    )
}






