import React,{useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { PencilSquare } from 'react-bootstrap-icons';

import Switchy from '../triggers/switchy';
import { CategoriasContext } from '../../context/categorias_context';
import { services } from '../../services/api';
import { selectFromObjbyId } from '../../utils/utils';
import { IconButton } from '@material-ui/core';

const columns = [
  { id:'id', label:'id', minWidth: 50 },
  { id:'name', label:'Categoria', minWidth: 150 },
  { id:'description', label:'Descripción', minWidth: 150 },
  { id:'department_id', label:'Departamento', minWidth: 150 },
  { id:'editar', label:'Editar', width:100 },
  { id:'activar', label:'Activar', width:100 },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});

function CategoriasRecords(props) {

    const {state, setState} = useContext(CategoriasContext)
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
      
      var data = state.categories.filter((item)=>{
        var active = item.deleted_at === null ? 1 : 0;

        if(state.search === "" && state.filter === 10){
          return true;
        }

        if(state.search !== "" && state.filter === 10){
       
          return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase())
        }
       
        return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase()) && active === state.filter;
       
      })
      setPage(0);
      setState({...state, categoriesF:data});

    }, [state.search, state.filter])

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const matchDepartment = (id) => {
      var department = state.departments.find((dep)=>{
        return dep.id === parseInt(id);
      })
      return department !== undefined ? department.name : ""
    }

    const handleSwitch = async (data,id, val)=>{
      var res;
      if(!val){
        res = await services("DELETE","categories",undefined,id);
        
      }else{
        res = await services("GET","categories",undefined,id);
      }
      if(res.status){
        var categories = data
        var category = selectFromObjbyId(data, id);
        category.deleted_at = res.data.deleted_at;
        setState({...state, categories })
      }  
    }


  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
          
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                   }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.categoriesF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

              let active = row.deleted_at === null ? 1 : 0;
              let switchActive = row.deleted_at === null ? true : false;
       

                             
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                          paddingRight:10,
                          paddingLeft:5,
                          paddingTop:5,
                          paddingBottom:0,

                         }}>
                          {
                            column.id === "department_id" ?
                              matchDepartment(value)
                            :
                            column.id === "editar" ? 
                            <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
                              <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                            </IconButton>
                            : column.id === "activar" ?  
                            <Switchy value={switchActive} onChange={(val)=>{
                              handleSwitch(state.categories, row.id, val)
                            }} /> 
                            : value
                          }

                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
             


            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.categoriesF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default CategoriasRecords;