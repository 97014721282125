import React, {useState, useEffect, useContext} from 'react'
import  {recordsServiceId, dependentService, dependentServiceId, recordsService, services,} from "../services/api"//
import { Form, Col, Button } from 'react-bootstrap';
import {PlusCircleFill, ArrowLeftSquareFill } from 'react-bootstrap-icons';
import { useHistory, useParams,useRouteMatch, Link } from 'react-router-dom';
import { ButtonGroup, IconButton } from '@material-ui/core'
import GeneralTableDirectory from "./GeneralTableDirectory"
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import {handleInputChange, handlePost, handleDeleteUnsaved } from "./capturaUtils"
import serviciosAdministrativosRow from "./capturaUtils/ServiciosAdministrativosRow";
import ServiciosAdministrativosRow from './capturaUtils/ServiciosAdministrativosRow';
import BackToProjectsButton from './capturaUtils/BackToProjectsButton';
import {ZonasContent, TurnContent} from "./capturaUtils/CapturaInputs"

function BarrenacionJumboRecords({operationContent, context}) {
    
    let { id } = useParams();
    const operationId = 1
    let history = useHistory();
    const [validated, setValidated] = useState(false);
    const [operationItems, setOperationItems] = useState(operationContent)
    const [dateRange, setDateRange] = useState(['0001-01-01','9999-01-01'])
    const {alert, showAlert, closeAlert} = useContext(AlertContext)
    const [postItems, setPostItems] = useState([])

    useEffect(()=>{
        setOperationItems(operationContent)
    },[operationContent])

    //los objectos de esta operacion no tienen valor date
     const handleDateFilter = (e,state, setState, dateRange) =>{
        e.preventDefault()
        const dateStart = document.querySelector('#date-start-input').value;
        const dateEnd = document.querySelector("#date-end-input").value
        const dateStartValue = new Date(dateStart).getTime()
        const dateEndValue = new Date(dateEnd).getTime()
        dateRange([dateStart, dateEnd])
    
        const filteredItems = state.filter((item)=>{
            return new Date(item.fecha).getTime() >= dateStartValue &&
            new Date(item.fecha).getTime() <= dateEndValue
        })
        setState(filteredItems)
    }

    const handleNewRow = () =>{
        //fecha del dia de hoy
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd ;
        
        const body={
            id:`newId${Math.floor(Math.random() * (100000 - 0 + 1))}`,
            fecha: today,
            project_id: id,
            actividad: "",
            colaborador: "colaborador 1",
            turno: "turno 1",
            zona: "zona 1",
            folio:1
        }

        setPostItems([...postItems,...[body]])
    }

    const postOperations = () =>{
        handlePost(postItems, "administrative_services_form").then((data) => {
            console.log(data)
            const errorData = data.filter((item)=>{
                return item.status == false
            })

            if(errorData.length > 0){
                showAlert("error",`Error al guardar algunas filas.Compruebe sus datos e intente de nuevo.`)
            }else{
                showAlert("success",`Listo! , Registros guardados exitosamente`)
            }

            const successData = data.filter((item)=>{
                return item.status == true
            }).map((i)=>{
                return i.data
            })

            const itemsToRemove = successData.map((item)=>{
                return item.folio
            })

            const notSavedItems = postItems.filter(e => !itemsToRemove.includes(e.folio))
            setPostItems(notSavedItems)
            setOperationItems([...successData, ...operationItems])
        })
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        if(postItems.length > 0){
            postOperations()
        }
    }
    
  return (
    <>
            <BackToProjectsButton project={context.project_name}/>
            <Form onSubmit={(e)=> handleDateFilter(e,operationContent , setOperationItems ,setDateRange)}>
                <div className="row align-items-end mb-2">
                    <div className="col-12 col-md-3">
                        <div className="form-group d-flex align-items-center">
                            <label className='mr-3 mb-0' htmlFor="">Desde</label>
                            <input type="date" className="form-control" id="date-start-input" required/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                    <div className="form-group d-flex align-items-center">
                            <label className="mr-3 mb-0" htmlFor="">Hasta</label>
                            <input type="date" className="form-control" id="date-end-input" required/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <Button className='primary mb-3 mr-2' type="submit">Aplicar  filtro</Button>
                        <Button variant="light" className='border mb-3' onClick={()=> setOperationItems(operationContent)}>Deshacer filtro</Button>
                    </div>
                </div>
            </Form>
            <div className="d-flex justify-content-between mx-3 my-5">
                <GeneralTableDirectory/>
                <div className='d-flex'>
                    <button className="text-nowrap btn btn-primary mr-2 ml-5" type="submit" form="general-form" value="Update"> Guardar Nuevos  Registros</button>
                    <button className="text-nowrap btn btn-light border" onClick={handleNewRow}>+ Añadir nuevo registro</button>
                </div>
            </div>
                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
            <div className='w-100 overflow-auto general-form-container flex-column flex-1'>
            <Form className="" noValidate validated={validated} id="general-form" onSubmit={(e)=>handleSubmit(e)}>
                <table className='proyect-table'>
                    <tr>
                        <th>Colaborador</th>
                        <th>Turno</th>
                        <th>Fecha</th>
                        <th>Zona</th>
                        <th>Actividad</th>
                        <th>Acciones</th>
                    </tr>
                        <>
                        {postItems.map((f)=>(
                            <tr>
                            <td>
                                <select type="text" className="form-control large-input" onChange={(e) => handleInputChange(e, f.id, postItems, setPostItems)} name="colaborador" value={f.colaborador} required>
                                    <option value="">Seleccionar</option>
                                    {context.users.map((item)=>(
                                        <option value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <select type="text" className="form-control large-input" onChange={(e) => handleInputChange(e, f.id, postItems, setPostItems)} name="turno" value={f.turno} required>
                                    <TurnContent/>
                                </select>
                            </td>
                            <td>
                                <input type="date" className="form-control large-input" onChange={(e) => handleInputChange(e, f.id, postItems, setPostItems)} name="fecha" value={f.fecha} required/>
                            </td>
                            <td>
                                <select type="text" className="form-control large-input" onChange={(e) => handleInputChange(e, f.id, postItems, setPostItems)} name="zona" value={f.zona} required>
                                    <ZonasContent zonas={context.zonasListado}/>
                                </select>
                            </td>
                            <td>
                                <input type="text" className="form-control large-input" onChange={(e) => handleInputChange(e, f.id, postItems, setPostItems)} name="actividad" value={f.actividad} required/>
                            </td>
                            <td>
                                 <div className="btn btn-danger" onClick={()=>handleDeleteUnsaved(f.id, postItems, setPostItems)}>Borrar</div>
                            </td>
                        </tr>
                        ))}
                        {operationItems.map(f => (
                            <ServiciosAdministrativosRow f={f} context={context} showAlert={showAlert}/>
                        ))} 
                    </>
                </table>
            </Form>
        </div>
        </>
  )
}

export default BarrenacionJumboRecords