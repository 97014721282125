
import React, { useState, useContext, createRef } from 'react';
import {XCircleFill,PlusCircleFill, TrashFill, Journals } from 'react-bootstrap-icons';

import UsuariosRecords from '../components/records/usuarios_records'

import {showLoader, hideLoader, removeFromObjbyId, today, replaceObj, selectFromObjbyId} from '../utils/utils';
import {Multiselect} from 'multiselect-react-dropdown'


import { Form, Col, Button } from 'react-bootstrap';
import { UserContext } from '../context/user_context';
import { UsuariosContext } from '../context/usuarios_context';//


import IconButton from '@material-ui/core/IconButton';
import ViewDataLoader from '../components/ui/view_data_loader';
import {services, getUserPermissions, editUserPermissions } from '../services/api'
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';

var projectSelected = null;
var departmentSelected = null;
var categorySelected = null;
var acceder = 0;
var editing = false;
var editingId;
function UsuariosView(){
   
      const [currentUser, setCurrentUser ] = useState("")
      const [currentUserId, setCurrentUserId] = useState(null)//
      const [permissionsData, setPermissionsData] = useState([])
      const defaultPermissionsValues ={
        create: null,
        delete: null,
        edit: null,
        export: null,
        load: null,
        name: null,
        view: null
      }
      const [singlePermissionData, setSinglePermissionData] = useState(defaultPermissionsValues)
      const [validated, setValidated] = useState(false);
      const [title, setTitle] = useState(true);
      const {user, setUser} = useContext(UserContext);
      const {state, setState} = useContext(UsuariosContext);
      const {alert, showAlert, closeAlert} = useContext(AlertContext)
      const [combo, setCombo] = useState({
        projectSelected_id:null,
        departmentSelected_id:null,
        categorySelected_id:null
      });

      const proyectosMultiSelect = createRef()
      const departamentosMultiSelect = createRef()
      const categoriasMultiSelect = createRef()

      const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
        body.access = acceder

        var projects = []
        state.projectsSelected.forEach((combo) => {
            projects.push({project_id:combo.project.id, department_id:combo.department.id, category_id:combo.category.id})
        })

        body.projects = JSON.stringify(projects);
        body.user_add = user.id;

        

        if (form.checkValidity() === true) {

            var res;
            showLoader();
            if(!editing){
                res = await services("POST","users", body);
            }else{
                res = await services("PUT","users", body, editingId);
            }

            if(res.status){

                if(!editing){
                   

                    var usuario = res.data
                    
                    usuario.access = acceder;
                    usuario.location = selectFromObjbyId(state.locations, parseInt(usuario.location_id));
                    usuario.deleted_at = null;
                    var user_projects = state.projectsSelected;
                    user_projects.forEach((combo)=>{
                        combo.categorie = combo.category;
                    })
                    usuario.user_projects = user_projects;
                    


                    var usuarios = state.usuarios;
                    var usuariosF = state.usuariosF;
                    usuarios.unshift(usuario);
                    usuariosF.unshift(usuario);
                    setState({...state,usuarios, usuariosF});
                    showAlert("success", "¡Listo! Registro agregado exitosamente.")
                }else{
                    
                    var usuario = res.data
                    usuario.access = acceder;
                    usuario.location = selectFromObjbyId(state.locations, parseInt(usuario.location_id));
                    var user_projects = state.projectsSelected;
                    user_projects.forEach((combo)=>{
                        combo.categorie = combo.category;
                    })
                    usuario.user_projects = user_projects;


                    var usuarios = replaceObj(state.usuarios,usuario);
                    var usuariosF = replaceObj(state.usuariosF,usuario);
                    setState({...state,categories:usuarios,usuariosF});
                }
                hideLoader();
                hideForm();
            }else{
                
                hideLoader();
                var message = "";
                Object.keys(res.errors).map(function(key, index) {
                    message = message + " " +res.errors[key][0]
                    
                });
                showAlert("error", message);
                  
            }
            
        }else{
        
            setValidated(true);
        }
      };


      const showForm = (data=undefined) =>{
        proyectosMultiSelect.current.resetSelectedValues();
        departamentosMultiSelect.current.resetSelectedValues();
        categoriasMultiSelect.current.resetSelectedValues();

        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');

        let usuarios_records = document.getElementById('usuarios_records');
        usuarios_records.classList.add('d-none');
        

        let f = document.getElementById("main_form");
        
        if(data !== undefined){
            reason_edition.classList.remove('d-none');
            setTitle(false);
            fillForm(data)
        }
        f.classList.add("d-block")
        f.classList.remove("d-none");
      }


      const hideForm = () =>{
        let form = document.getElementById("main_form");
        clearForm();
        form.classList.add("d-none");
        form.classList.remove("d-block");

        let usuarios_records = document.getElementById('usuarios_records');
        usuarios_records.classList.remove('d-none');
      }
      const onChangeFilter = (evt) =>{
       
        setState({...state, filter:parseInt(evt.currentTarget.value)})
      }
      const onSearchChange = (val) => {
        var search = val.currentTarget.value
        setState({...state, search});
      }
      const fillForm = (data) => {
         
        let { elements } = document.getElementById('usuarios_form');;
        elements.namedItem("reason_edition").disabled = false;

        for (const [ key, value ] of Object.entries(data) ) {
          const field = elements.namedItem(key)
          if(key !== "reason_edition"){
            field && (field.value = value)
            }
          
        }

        editing = true;
        editingId = data.id

        var accederbox = document.getElementById("acceder")
        accederbox.checked = data.access
        acceder = data.access;



        var projects = state.projectsSelected;
        data.user_projects.forEach((el,i) =>{
            var combo = {id:i,project:el.project,department:el.department,category:el.categorie}
            projects.unshift(combo);
        })

        setState({...state, projectsSelected:projects});
      }

      const clearForm = () => {
        let form = document.getElementById('usuarios_form');
        let {elements} = form
        elements.namedItem("reason_edition").disabled = true;
        projectSelected = null;
        departmentSelected = null;
        categorySelected = null;
        acceder = 0;
        editing = false;
        editingId = undefined;
        form.reset();
        setTitle(true);
        setValidated(false);
        setState({...state,projectsSelected:[]});
        setCombo({
            projectSelected_id:null,
            departmentSelected_id:null,
            categorySelected_id:null
        });
     
    }


      const handleCheckAcceder = (evt) => {
    
        let checked = evt.currentTarget.checked;
  
        if(checked){
            acceder = 1
        }else{
            acceder = 0
        }
        
      }

      //funciones para el modal de edición de permisos

      const showPermissionsModal  = async (userId, userName) =>{
        //Llama a los permisos de usuario al llamar a modal de edición
        getUserPermissions(userId)
        .then((res)=> {setPermissionsData(res)})
        //oculta contenido en el body
        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');

        let usuarios_records = document.getElementById('usuarios_records');
        usuarios_records.classList.add('d-none');
        //muestra modal
        const permissionsForm = document.getElementById("permissions-form")
        permissionsForm.classList.add("d-block")
        permissionsForm.classList.remove("d-none") 
        //guarda los valores para ser usados en la sección de permisos
        setCurrentUser(userName)
        setCurrentUserId(userId)
      }

      const hidePermissionsModal = () =>{
        //Regresa la tabla de permisos a su valor original
        setSinglePermissionData(defaultPermissionsValues)
        //oculta modal y muestra tabla principal
        const permissionsForm = document.getElementById("permissions-form")
        permissionsForm.classList.add("d-none")
        permissionsForm.classList.remove("d-block")

        let usuarios_records = document.getElementById('usuarios_records');
        usuarios_records.classList.remove('d-none');
      }

      const handleSinglePermission= (permName) =>{
           const singlePermission = permissionsData.data.data.permission.filter((item)=>{
                return item.name == permName
           })

           setSinglePermissionData(singlePermission[0])
      }

      const handlePermissionChange  = async () =>{
          showLoader()
          const body ={
                "id"     : singlePermissionData.id,
                "name"   : singlePermissionData.name,
                "view"   : singlePermissionData.view,
                "edit"   : singlePermissionData.edit,
                "create" : singlePermissionData.create,
                "delete" : singlePermissionData.delete,
                "load"   : singlePermissionData.load,
                "export" : singlePermissionData.export,
            }            

            editUserPermissions(currentUserId, body)
            .then(res=>{
                return getUserPermissions(currentUserId)
            })
            .then(res=>{
                setPermissionsData(res)
                hideLoader()
                showAlert("success", "Permisos cambiados exitosamente.")
            })
      }

    if(state.loading){
        return (
            ViewDataLoader()
        )
    }else{
        return(
            <main role="main">
                <div className="col">
                    <h2><Journals size="25" color="#0a5cbe"></Journals> Catalogo: Usuarios</h2>  
                </div>    
                <div className="container-fluid position-relative mh-100 py-1" id="usuarios_records">
                    <div className="d-flex">
                        <div className="col-2 py-2 px-0">
                            
                            <Form.Label>Estatus:</Form.Label>
                            <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                                <option value ="10">Todos</option>
                                <option value ="1">Activo</option>
                                <option value ="0">Desactivado</option>
                            </Form.Control>
                        </div>
                        <div className="col-3 py-2 px-0 mx-2">
                            
                            <Form.Label>BUSCAR:</Form.Label>
                            <Form.Control  type="text" name="buscar" onChange={onSearchChange} />
                        </div>
                        <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                            <Button className="primary px-4" onClick={()=>{showForm()}} >
                                Nuevo
                            </Button>
                        </div>
                    </div>

                    <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                    
                    <UsuariosRecords 
                        onEdit={(data)=>{showForm(data);}}
                        onEditPermissions={(UserId, userName)=>showPermissionsModal(UserId, userName)}
                    >
                    </UsuariosRecords>
                </div>

                <div className="catalogo_form_modal d-none" id="permissions-form">
                    <Form classNam="flex-column" id="permissions-form-content">
                        <div className="container-fluid container-md position-relative bg-white rounded pt-4">
                            <div className="col py-3">
                                <h5>Editar permisos de usuario</h5>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="border">
                                            <div className='border-bottom p-2 mb-2'>
                                                <h5 className="text-link m-0" data-name="Inicio" onClick={()=>handleSinglePermission("Inicio")}>1. Inicio</h5>
                                            </div>
                                            <div className='border-bottom'>
                                                <div className='border-bottom p-2 mb-2'>
                                                    <h5 className="text-link m-0" data-name="Catalogo" onClick={()=>handleSinglePermission("Catálogo")}>2. Catálogos</h5>
                                                </div>
                                                <h6 className='text-link pl-4' data-name="Maquinaria" onClick={()=>handleSinglePermission("Maquinaria")}>Maquinaria</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Locación")}>Locación</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Eficiencia de barrenación")}>Eficiencia de barrenación</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Centro de costo")}>Centro de costo</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Puesto")}>Puesto</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Departamentos")}>Departamentos</h6>
                                            </div>
                                            <div className='border-bottom'>
                                                <div className='border-bottom p-2 mb-2'>
                                                    <h5 className="text-link m-0" onClick={()=>handleSinglePermission("Usuarios")}>3. Usuarios</h5>
                                                </div>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Colaboradores")}>Colaboradores</h6>
                                            </div>
                                            <div className='border-bottom'>
                                                <div className='border-bottom p-2 mb-2'>
                                                    <h5 className="text-link m-0" onClick={()=>handleSinglePermission("Proyectos")}>4. Proyectos</h5>
                                                </div>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Panel de proyectos")}>Panel de proyectos</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Especificaciones")}>Especificaciones</h6>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Plantilla de barrenación")}>Plantilla de barrenación</div>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Área")}>Área</div>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Zonas")}>Zonas</div>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Materiales")}>Materiales</div>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("RMR")}>RMR</div>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Indicadores de seguridad")}>Indicadores de seguridad</div>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Incidencias de seguridad")}>Incidencias de seguridad</div>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Captura")}>Captura</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Base de datos")}>Base de datos</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Catálogo de Obras")}>Catálogo de Obras</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Lista de asistencia")}>Lista de asistencia</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Planeación Mensua")}>Planeación Mensual</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Control de obra")}>Control de obra</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Destajos")}>Destajos</h6>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Tabulador de destajos")}>Tabulador de destajos</div>
                                                <div className='text-link pl-5 mb-1' onClick={()=>handleSinglePermission("Cálculo de destajo")}>Cálculo de destajo</div>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Avance Resumen")}>Avance Resumen</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Avance Actual")}>Avance Actual</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Reporte gerencia diario")}>Reporte gerencia diario</h6>
                                            </div>
                                            <div className='border-bottom'>
                                                <div className='border-bottom p-2 mb-2'>
                                                    <h5 className="text-link m-0" onClick={()=>handleSinglePermission("Reportes")}>5. Reportes</h5>
                                                </div>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Consumo de combustibles")}>Consumo de combustibles</h6>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Rendimiento Horas máquina")}>Rendimiento Horas máquina</h6>
                                            </div>
                                            <div className='border-bottom'>
                                                <div className='border-bottom p-2 mb-2'>
                                                    <h5 className="text-link m-0" onClick={()=>handleSinglePermission("Historial")}>6. Historial</h5>
                                                </div>
                                                <h6 className='text-link pl-4' onClick={()=>handleSinglePermission("Historial de ediciones")}>Historial de ediciones</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="border">
                                            <h5 className="bg-blue txt-white py-3"></h5>
                                            <div className="d-flex justify-content-between p-3">
                                                <h6>sección: {singlePermissionData.name}
                                                </h6>
                                                <h6>Usuario: {currentUser}</h6>
                                            </div>
                                            <div className="d-flex p-3">
                                                {/* <span className='w-50'>Todos</span> */}
                                                <span className='w-50'>Crear</span>
                                                <input 
                                                type="checkbox" 
                                                checked={singlePermissionData.create}
                                                onClick={()=>setSinglePermissionData((prevState)=>({...prevState,"create":!singlePermissionData.create}))}
                                                />
                                            </div>
                                            <div className="d-flex p-3">
                                                <span className='w-50'>Ver</span>
                                                <input 
                                                 type="checkbox" 
                                                 checked={singlePermissionData.view} 
                                                 onClick={()=>{setSinglePermissionData((prevState)=>({...prevState,"view":!singlePermissionData.view}))}}
                                                 />
                                            </div>
                                            <div className="d-flex p-3">
                                                <span className='w-50'>Editar Registros</span>
                                                <input 
                                                 type="checkbox"
                                                 checked={singlePermissionData.edit}
                                                 onClick={()=>{setSinglePermissionData((prevState)=>({...prevState,"edit":!singlePermissionData.edit}))}}
                                                 />
                                            </div>
                                            <div className="d-flex p-3">
                                                <span className="w-50">Eliminar</span>
                                                <input 
                                                 type="checkbox" 
                                                 id="idsas"
                                                 checked={singlePermissionData.delete} 
                                                 onClick={()=>{setSinglePermissionData((prevState)=>({...prevState,"delete":!singlePermissionData.delete}))}}
                                                 />
                                            </div>
                                            <div className="d-flex p-3">
                                                <span className='w-50'>Cargar</span>
                                                {/* <span className='w-50'>Descarga y carga masiva</span> */}
                                                <input 
                                                type="checkbox" 
                                                checked={singlePermissionData.load} 
                                                onClick={()=>{setSinglePermissionData((prevState)=>({...prevState,"load":!singlePermissionData.load}))}}
                                                />
                                            </div>
                                            <div className="d-flex p-3">
                                                <span className='w-50'>Exportar</span>
                                                <input 
                                                type="checkbox" 
                                                checked={singlePermissionData.export} 
                                                onClick={()=>{setSinglePermissionData((prevState)=>({...prevState,"export":!singlePermissionData.export}))}}
                                                />
                                            </div>
                                            <div className='w-100 d-flex justify-content-end p-3'>
                                                <Button className="primary px-4"  onClick={()=>handlePermissionChange()} disabled={singlePermissionData.name === null ? true : false}>
                                                    Guardar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cta position-absolute top right pt-2 pr-4" onClick={hidePermissionsModal}>
                                    <XCircleFill size="45"></XCircleFill>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>

                <div className="catalogo_form_modal d-none " id="main_form">
                
                    <Form className="flex-column" noValidate validated={validated} onSubmit={handleSubmit} id="usuarios_form">
                   
                        <div className="container-fluid container-md position-relative bg-white rounded pt-4">
                            
                            <div className="col">
                                <h5>{title === true ?"NUEVO":"EDITAR"} USUARIO</h5>
                                <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />
                            </div>
                          
                            <div className="row">
                                
                                <div className="col-md-6">
                                    

                                    <Form.Group as={Col}>
                                        <Form.Label>FECHA</Form.Label>
                                        <div className="form-control disabled">{today()}</div>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>RESPONSABLE</Form.Label>
                                        <div className="form-control disabled">{user.name}</div>
                                        {console.log(user.name)}
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>NUMERO DE EMPLEADO</Form.Label>
                                        <Form.Control className="text-uppercase" type="text" name="number"  required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>NOMBRE</Form.Label>
                                        <Form.Control type="text" name="name"  required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>APELLIDO PATERNO</Form.Label>
                                        <Form.Control type="text" name="last_name"  required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>APELLIDO MATERNO</Form.Label>
                                        <Form.Control type="text" name="surname_name"  required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>CORREO</Form.Label>
                                        <Form.Control type="email" name="email"  required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    
                                </div>
                                <div className="col-md-6">
                                    

                                    <Form.Group as={Col}>
                                        <Form.Label>TELEFONO</Form.Label>
                                        <Form.Control type="number" name="phone"  required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                
                                    <Form.Group as={Col}>
                                        <Form.Label>RFC</Form.Label>
                                        <Form.Control className="text-uppercase" type="text" name="rfc" minLength="13" maxLength="13"  required />
                                        <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio, minimo 12 digitos
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>CURP</Form.Label>
                                        <Form.Control className="text-uppercase" type="text" name="curp" minLength="18" maxLength="18" required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio, 18 digitos
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>UBICACIÓN</Form.Label>
                                        <Form.Control as="select"  name="location_id" required>
                                            <option value ="">Seleccionar</option>
                                            {state.locations.map((loc)=>{
                                                return (<option key={loc.id} value ={loc.id}>{loc.name}</option>)
                                            })}
                                        </Form.Control>

                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* <Form.Group as={Col}>
                                        <Form.Label>PUESTO</Form.Label>
                                        <Form.Control as="select"  name="rol_id" required>
                                            <option value ="">Seleccionar</option>
                                            {state.roles.map((rol)=>{
                                                return (<option key={rol.id} value ={rol.id}>{rol.display_name}</option>)
                                            })}
                                        </Form.Control>

                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group> */}
                                    
                                    <Form.Group as={Col}>
                                        <Form.Check 
                                            type="checkbox"
                                            id="acceder"
                                            name="acceder"
                                            //value="BARRENACIÓN CON JUMBO"
                                            onClick={((evt)=>{handleCheckAcceder(evt)})}
                                            label={'ACCEDER O NO'}
                                        />
                                    </Form.Group>

                                    <div id="reason_edition">
                                        <Form.Group as={Col}>
                                            <Form.Label>Motivo de edicion</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="reason_edition" disabled required />
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                    <Form.Group as={Col}>
                                        <Button type="submit">GUARDAR</Button>
                                    </Form.Group>

                                </div>
                                
                            </div>
                            <div className="cta position-absolute top right pt-2 pr-4" onClick={hideForm}>
                                <XCircleFill size="45"></XCircleFill>
                            </div>

                        </div>
                        <div className="container-fluid container-md position-relative bg-white rounded pt-4 pb-5">

                            
                        
                            <div className="container-fluid container-md min-display">
                                <table className="table-striped w-100 ">
                                    <thead>
                                        <tr>
                                            <td width="33%">
                                                <Form.Group>
                                                    <Form.Label>PROYECTO</Form.Label>
                                                    <Multiselect
                                                        style={{chips: { background: "#0a5cbe",margin:0 }}}
                                                        ref={proyectosMultiSelect}
                                                        options={state.projects}
                                                        selectionLimit="1"
                                                        closeIcon="cancel"
                                                      
                                                        //selectedValues={state.selected}
                                                        displayValue="name"
                                                        placeholder=""
                                                        emptyRecordMsg="Sin opción"
                                                        onSelect={(p, el)=>{
                                                            projectSelected = el;
                                                            setCombo({...combo, projectSelected_id:el.id})
                                                        }}
                                                        onRemove={()=>{
                                                            setState({...state, projectSelected:[]})
                                                            setCombo({
                                                                projectSelected_id:null,
                                                                departmentSelected_id:null,
                                                                categorySelected_id:null
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td  width="33%" style={{paddingLeft:15}}>
                                                <Form.Group >
                                                    <Form.Label>DEPARTAMENTO</Form.Label>
                                                    <Multiselect
                                                        style={{chips: { background: "#0a5cbe",margin:0 }}}
                                                        ref={departamentosMultiSelect}
                                                        options={state.departmets.filter((a) => {
                                                            var find = a.depto_projects.find((b) => b.project_id == combo.projectSelected_id);
                                                            if(find){
                                                                return true;
                                                            }else{
                                                                return false;
                                                            }
                                                        })}

                                                        selectionLimit="1"
                                                        closeIcon="cancel"
                                                        
                                                        //selectedValues={state.selected}
                                                        displayValue="name"
                                                        placeholder=""
                                                        emptyRecordMsg="Sin opción"
                                                        onSelect={(p, el)=>{
                                                            departmentSelected = el;
                                                            setCombo({...combo, departmentSelected_id:el.id})
                                                        }}
                                                        onRemove={(p)=>{
                                                            setState({...state, departmentSelected:{}})
                                                            setCombo({
                                                                ...combo,
                                                                departmentSelected_id:null,
                                                                categorySelected_id:null
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td  width="33%" style={{paddingLeft:15}}>
                                                <Form.Group>
                                                    <Form.Label>CATEGORIA</Form.Label>
                                                    <Multiselect
                                                        style={{chips: { background: "#0a5cbe",margin:0 }}}
                                                        ref={categoriasMultiSelect}
                                                        options={state.categories.filter((a) => a.department_id == combo.departmentSelected_id )}
                                                        selectionLimit="1"
                                                        closeIcon="cancel"
                                                       
                                                        //selectedValues={state.selected}
                                                        emptyRecordMsg="Sin opción"
                                                        displayValue="name"
                                                        placeholder=""
                                                        onSelect={(p, el)=>{
                                                            categorySelected = el;
                                                            //setState({...state, categorySelected})
                                                        }}
                                                        onRemove={(p)=>{

                                                            setState({...state, categorySelected:{}})
                                                        }}
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td  width="" style={{paddingLeft:10}}>
                                                <Form.Group >
                                                    <Form.Label className="invisible">AÑADIR</Form.Label>
                                                    <IconButton size="medium" onClick={() => {
                                                        
                                                        if(projectSelected && departmentSelected && categorySelected){
                                                            var projects = state.projectsSelected;
                                                            var id = projects.length + 1;
                                                            
                                                            var combo = {id:id,project:projectSelected,department:departmentSelected,category:categorySelected}
                                                            projects.unshift(combo);
                                                            setState({...state,projectsSelected:projects});

                                                            proyectosMultiSelect.current.resetSelectedValues();
                                                            departamentosMultiSelect.current.resetSelectedValues();
                                                            categoriasMultiSelect.current.resetSelectedValues();
                                                            projectSelected = null;
                                                            departmentSelected = null;
                                                            categorySelected = null;
                                                        }else{
                                                           
                                                        }
                                                        

                        
                                                    }}>
                                                        <PlusCircleFill size="32" color="#0a5cbe" /> 
                                                    </IconButton>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {state.projectsSelected.map((item,i) =>{
                                            return (
                                            <tr key={i}>
                                                <td style={{paddingLeft:5}}>{item.project.name}</td>
                                                <td style={{paddingLeft:20}}>{item.department.name}</td>
                                                <td style={{paddingLeft:20}}>{item.category.name}</td>
                                                <td style={{paddingLeft:10}}>
                                                    <Form.Group className="m-0 d-flex justify-content-center">
                                                        <IconButton size="medium" onClick={()=>{
                                                            var projectsSelected = removeFromObjbyId(state.projectsSelected, item.id);
                                                            setState({...state,projectsSelected});
                                                        }}>
                                                            <TrashFill size="18" color="tomato" /> 
                                                        </IconButton>
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                            );
                                        })}
                                        

                                    
                                    </tbody>
                                </table>
                            </div>

                        
                            
                            
                        
                        </div>
                    </Form>
                </div>
            </main>
        )
}

}


export default UsuariosView;