import React, {useContext,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Tabs,Tab, Button, Form} from 'react-bootstrap'
import { OrigenesDestinosContext } from '../../context/origenes_destinos_context';
import {deleteOrigenesDestinos, services} from '../../services/api' 
import {showLoader, hideLoader, removeFromObjbyId, selectFromObjbyId} from '../../utils/utils';
import { EspeciBarrenacionContext } from '../../context/especi_barrenacion_context';
import { PencilSquare } from 'react-bootstrap-icons';
import { IconButton } from '@material-ui/core';
import Switchy from '../triggers/switchy';


function EspeciBarrenacionRecords(props) {


  const [key, setKey] = React.useState('plantillas')
  //const {stateBarrenacion,setStateBarrenacion} = useContext(EspeciBarrenacionContext)

  return (
    <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        variant="pills"
    >
      
        <Tab eventKey="plantillas"  title="Plantillas de barrenación">
            <div className="container-fluid px-0 py-3">
                <PlantillasBarrenacion 
                    //data={stateBarrenacion.plantillas_barrenacion} 
                    onEdit={(data, val)=>{
                        props.onEdit(data, val)
                    }} 
                    onAdd={(val)=>{
                        props.onAdd(val)
                    }}>
                </PlantillasBarrenacion>
            </div>
        </Tab>
        <Tab eventKey="tipos" title="Tipos de barrenación">
            <div className="container-fluid px-0 py-3">
                <TiposBarrenacion 
                    //data={stateBarrenacion.tipos_barrenacion} 
                    onEdit={(data, val)=>{
                        props.onEdit(data, val)
                    }}
                    onAdd={(val)=>{
                        props.onAdd(val)
                    }}>
                </TiposBarrenacion>
            </div>
        </Tab>
    </Tabs>
  
  );
}

function TiposBarrenacion(props){
   
   
    const columns = [
        { id:'id', label:'ID'},
        { id:'name', label:'Nombre', minWidth: 170 },
        { id:'description', label:'Descripción', minWidth: 170 },
        { id:'length_linear_meters', label: 'Metros Lineales', minWidth: 170},
        { id:'', label:'', minWidth: 300 },
        {id:'editar', label:"Editar", width: 100},
        {id:'activar', label:"Activar", width: 100}
      ];
      const useStyles = makeStyles({
        root: {
          width: '100%',
        },
      });
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {stateBarrenacion, setStateBarrenacion} = useContext(EspeciBarrenacionContext)

      useEffect(() => {
        setPage(0);
      }, [stateBarrenacion.filter_tipo])


    

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleSwitch = async (data,id, val)=>{
        var res;
        if(!val){
          res = await services("DELETE","type_drillings",undefined,id);
        }else{
          res = await services("GET","type_drillings",undefined,id);
        }
        if(res.status){
          var tipos_barrenacion = data
          var tipo = selectFromObjbyId(data, id);
          tipo.deleted_at = res.data.deleted_at;
          setStateBarrenacion({...stateBarrenacion, tipos_barrenacion })
        }  
    }

    const onChangeFilter = (evt) =>{  
        setStateBarrenacion({...stateBarrenacion, filter_tipo:parseInt(evt.currentTarget.value)})
    }

    return(
        <>
        <div className="d-flex align-items-center py-2">
            <div className="col-2 p-0">
                <Form.Label>Estatus:</Form.Label>
                <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                    <option value ="10">Todos</option>
                    <option value ="1">Activo</option>
                    <option value ="0">Desactivado</option>
                </Form.Control>
            </div>
            <div className="col"></div>
            <div className="btn btn-primary align-self-end" onClick={()=>{
                props.onAdd(false);
            }}>NUEVO</div>
        </div>
        <Paper className={classes.root}>
        <TableContainer  className={classes.container}>
            <Table  stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ 
                        minWidth: column.minWidth,
                        paddingLeft:0,
                        paddingBottom:10,
                        paddingTop:10,
                        paddingLeft:5,
                        paddingRight:10,
                        lineHeight:1.2
                    }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {stateBarrenacion.tipos_barrenacionF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

                    let switchActive = row.deleted_at === null ? true : false;
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        {columns.map((column) => {
                        var value = row[column.id];
                        return (
                            <TableCell key={column.id} align={column.align} style={{ 
                                paddingRight:10,
                                paddingLeft:5,
                                paddingTop:5,
                                paddingBottom:5,
                        
                                }}>
                            {
                               column.id === "editar" ? 
                               <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row, false)}}>
                                 <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                               </IconButton>
                               : column.id === "activar" ?  
   
                               <Switchy value={switchActive} onChange={(val)=>{
                                 handleSwitch(stateBarrenacion.tipos_barrenacion, row.id, val)
                               }} /> 
                               : value
                            }
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            animation="false"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={stateBarrenacion.tipos_barrenacionF.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            //labelRowsPerPage={"Ver"}
            //abelDisplayedRows={"a"}
            
        />
        </Paper>
        </>
    );
}

function PlantillasBarrenacion(props){
    const {stateBarrenacion, setStateBarrenacion} = useContext(EspeciBarrenacionContext)

    const columns = [
        { id:'id', label:'ID', minWidth: 100 },
        { id:'seccion', label:'Sección', minWidth: 170 },
        { id:'number_bars', label:'Numero de barrenos', minWidth: 170 },
        { id:'length_linear_meters', label:'Longitud (metros lineales)', minWidth:170},
        {id:'editar', label:"Editar", width: 100},
        {id:'activar', label:"Activar", width: 100}
      
      ];
      
    
    const useStyles = makeStyles({
    root: {
        width: '100%',
    },

    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {

      setPage(0);
  
    }, [stateBarrenacion.filter_plantilla])


  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const onChangeFilterP = (evt) =>{  
        setStateBarrenacion({...stateBarrenacion, filter_plantilla:parseInt(evt.currentTarget.value)})
    }

    const handleSwitch = async (data,id, val)=>{
        var res;
        if(!val){
          res = await services("DELETE","template_drillings",undefined,id);
        }else{
          res = await services("GET","template_drillings",undefined,id);
        }
        if(res.status){
          var plantillas_barrenacion = data
          var plantilla = selectFromObjbyId(data, id);
          plantilla.deleted_at = res.data.deleted_at;
          setStateBarrenacion({...stateBarrenacion, plantillas_barrenacion })
        }  
    }

    return(
        <>
        <div className="d-flex align-items-center py-2">
            <div className="col-2 p-0">
                <Form.Label>Estatus:</Form.Label>
                <Form.Control as="select"  name="proyecto" onChange={onChangeFilterP} required>
                    <option value ="10">Todos</option>
                    <option value ="1">Activo</option>
                    <option value ="0">Desactivado</option>
                </Form.Control>
            </div>
            <div className="col"></div>
            <div className="btn btn-primary align-self-end" onClick={()=>{
                props.onAdd(true);
            }}>NUEVO</div>
        </div>
        <Paper className={classes.root}>
        <TableContainer  className={classes.container}>
            <Table  stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ 
                        minWidth: column.minWidth,
                        paddingLeft:0,
                        paddingBottom:10,
                        paddingTop:10,
                        paddingLeft:5,
                        paddingRight:10,
                        lineHeight:1.2
                    }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {stateBarrenacion.plantillas_barrenacionF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
                       let switchActive = row.deleted_at === null ? true : false;
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        {columns.map((column) => {
                        var value = ''
                        if(column.id==='seccion'){
                            value = value.concat(row['section_length'],'X',row['section_width']);
                        }else{
                            value = row[column.id];
                        }
                        return (
                            <TableCell key={column.id} align={column.align} style={{ 
                                paddingRight:10,
                                paddingLeft:5,
                                paddingTop:5,
                                paddingBottom:5,
                        
                                }}>
                            {
                                 column.id === "editar" ? 
                                 <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row, true)}}>
                                   <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                                 </IconButton>
                                 : column.id === "activar" ?  
     
                                 <Switchy value={switchActive} onChange={(val)=>{
                                   handleSwitch(stateBarrenacion.plantillas_barrenacion, row.id, val)
                                 }} /> 
                                 : value
                            }
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            animation="false"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={stateBarrenacion.plantillas_barrenacionF.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            //labelRowsPerPage={"Ver"}
            //abelDisplayedRows={"a"}
            
        />
        </Paper>
        </>
    );
}

export default EspeciBarrenacionRecords;