import React, { createContext, useState,useEffect, useContext } from "react"
import {dependentService, recordsService, recordsServiceId} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const PresupuestoObrasContext  = createContext();
export const PresupuestoObrasProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [statePresupuesto, setStatePresupuesto] = useState({
        historial:[],
        historialF:[],

        presupuestos:[],
        presupuestosF:[],
        rocas:[],
        zonas:[],
        conceptos:[],
        project_id:null,
        project_name:"",
        loading:false,
        filter_platilla:10,
        desde:"",
        hasta:"",
        desdeP:"",
        hastaP:"",
        
    });

    useEffect(async () =>{
        
        
        setStatePresupuesto({...statePresupuesto, loading:true});
           

            let proyecto = selectFromObjbyId(state.projects,parseInt(id))
            if(proyecto !== undefined){
                var tipos_barreRes = await recordsServiceId("GET","type_drillings", id);
                var presupuestosRes = await recordsServiceId("GET","budgets", id);
                var rocasRes = await dependentService("GET","rock_indices", "Todos");
                var zonasRes = await dependentService("GET","zones");
                var conceptosRes = await dependentService("GET","type_drillings");

    
               
                if(tipos_barreRes.status && presupuestosRes.status){ 

                
                
                    setStatePresupuesto({
                       
                        // tipos_barrenacion:tipos_barreRes.data.reverse(),
                        // tipos_barrenacionF:tipos_barreRes.data,
                        presupuestos:presupuestosRes.data,
                        presupuestosF:presupuestosRes.data,
                        historial:presupuestosRes.data,
                        historialF:presupuestosRes.data,
                        rocas:rocasRes.data,
                        zonas:zonasRes.data,
                        conceptos:conceptosRes.data,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filter_plantilla:10,
                        desde:"",
                        hasta:"",
                        desdeP:"",
                        hastaP:"",
                     
                    });
                }else{
                    if(tipos_barreRes.code === 401 || tipos_barreRes.code === 500){
                        window.localStorage.clear();
                        setUser({});
                        auth.setMsg(true)
                        auth.signout();
                    }
                }
               
            }
    },[state.projects])
    
    return(
        <PresupuestoObrasContext.Provider value={{
            statePresupuesto,
            setStatePresupuesto
        }}>
            {children}
        </PresupuestoObrasContext.Provider>
    )
}






