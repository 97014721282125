import React, {useContext,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Tabs,Tab, Form} from 'react-bootstrap'


import {services} from '../../services/api' 
import {selectFromObjbyId} from '../../utils/utils';

import { ChevronCompactLeft, PencilSquare, Trash } from 'react-bootstrap-icons';
import { IconButton } from '@material-ui/core';
import Switchy from '../triggers/switchy';
import { PresupuestoObrasContext } from '../../context/presupuesto_obras_context';
import { Col,Button } from 'react-bootstrap';
import { AlertContext } from '../../context/alert_context';


function PresupuestoObrasRecords(props) {


  const [key, setKey] = React.useState('Presupuestos')
  const {statePresupuesto,setStatePresupuesto} = useContext(PresupuestoObrasContext)

  return (
    <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        variant="pills"
    >
        <Tab eventKey="Presupuestos"  title="Presupuestos">
            <div className="container-fluid px-0 py-3">
                <PresupuestoObras 
                    //data={statePresupuesto.presupuestos} 
                    onEdit={(data, val)=>{
                        props.onEdit(data, val)
                    }} 
                    onAdd={(val)=>{
                        props.onAdd(val)
                    }}>
                </PresupuestoObras>
            </div>
        </Tab>
        <Tab eventKey="Historial" title="Historial">
            <div className="container-fluid px-0 py-3">
                <PresupuestoHistorial 
                    //data={statePresupuesto.tipos_barrenacion} 
                    onEdit={(data, val)=>{
                        props.onEdit(data, val)
                    }}
                    onAdd={(val)=>{
                        props.onAdd(val)
                    }}>
                </PresupuestoHistorial>
            </div>
        </Tab>
    </Tabs>
  
  );
}

function PresupuestoHistorial(props){

    const {alert, showAlert, closeAlert} = useContext(AlertContext)
   
   
    const columns = [
        { id:'id', label:'No. Folio', minWidth: 100},
        { id:'month', label:'Mes', minWidth: 100 },
        { id:'year', label:'Año', minWidth: 100 },
        { id:'item', label:'Item', minWidth: 100 },
        { id:'obra', label:'Obra', minWidth: 130 },
        { id:'rmr', label:'No. RMR', minWidth: 100 },
        { id:'zone', label:'Zona', minWidth: 100 },
        { id:'develop', label:'Desarrollo (ml)', minWidth: 150 },
        { id:'tumbe', label:'Tumbe (m3)', minWidth: 120 },
        { id:'number_concept', label:'No. Concepto', minWidth: 150 },
        { id:'concepto', label:'Concepto', minWidth: 170 },

      
      ];
      const useStyles = makeStyles({
        root: {
          width: '100%',
        },
      });
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {statePresupuesto, setStatePresupuesto} = useContext(PresupuestoObrasContext)


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleFilter = (event) =>{  
     
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
      

   
        var desde = new Date(Date.parse(body.desde))
        var hasta = new Date(Date.parse(body.hasta))

        if(desde < hasta){
            closeAlert();
            var data = statePresupuesto.historial.filter((item)=>{
           
                return item.year >= desde.getFullYear() && item.year <= hasta.getFullYear() && item.month >= (desde.getMonth() + 1) && item.month <= (hasta.getMonth()+1)
                //return new Date(Date.parse(item.created_at)) >= desde &&  new Date(Date.parse(item.created_at)) <= hasta;
            })
            setStatePresupuesto({...statePresupuesto, historialF:data});
                        
        }else{
            showAlert("error", "¡Error! Fecha: Desde no puede ser mayor a Fecha: Hasta")
           
        }
        
        
      
    }
    const onChangeFilterMes = (evt) =>{  
        setStatePresupuesto({...statePresupuesto, filter_mes:evt.currentTarget.value})
    }

    return(
        <>
        {/* noValidate validated={validated} */}
        <Form  onSubmit={handleFilter} id="">
        <div className="d-flex align-items-center py-2">
            <div className="col-2 p-0">
            
                    <Form.Label>DESDE</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        name="desde"
                        value={statePresupuesto.desde}
                        onChange={(val)=>{
                            
                            setStatePresupuesto({...statePresupuesto, desde:val.currentTarget.value})
                            
                        }}
                    />
        
            </div>
            <div className="col-2 p-0 ml-2">
            
                    <Form.Label>HASTA</Form.Label>
                    <Form.Control
                        
                        required
                        type="date"
                        name="hasta"
                        value={statePresupuesto.hasta}
                        onChange={(val)=>{
                            
                            setStatePresupuesto({...statePresupuesto, hasta:val.currentTarget.value})
                            
                        }}
                        
                    />
                
            
            </div>
            <Button type="submit" className="align-self-end ml-2">BUSCAR</Button>
            <IconButton size="small" className="align-self-end" onClick={()=>{
                    setStatePresupuesto({...statePresupuesto, hasta:"", desde:"", historialF:statePresupuesto.historial})
                }}>
                <Trash size={26} className="cursor-pointer" color="tomato"/>
            </IconButton>
        </div>
        </Form>
        <Paper className={classes.root}>
        <TableContainer  className={classes.container}>
            <Table  stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ 
                        minWidth: column.minWidth,
                        paddingLeft:0,
                        paddingBottom:10,
                        paddingTop:10,
                        paddingLeft:5,
                        paddingRight:10,
                        lineHeight:1.2
                    }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {statePresupuesto.historialF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

                    let switchActive = row.deleted_at === null ? true : false;
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        {columns.map((column) => {
                        var value = row[column.id];
                        return (
                            <TableCell key={column.id} align={column.align} style={{ 
                                paddingRight:10,
                                paddingLeft:5,
                                paddingTop:15,
                                paddingBottom:15,
                        
                                }}>
                            {
                            //    column.id === "editar" ? 
                            //    <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row, false)}}>
                            //      <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                            //    </IconButton>
                            //    : 
                            //    column.id === "activar" ?  
   
                            //    <Switchy value={switchActive} onChange={(val)=>{
                            //      handleSwitch(statePresupuesto.historial, row.id, val)
                            //    }} /> 
                            //    : 
                            column.id == "item" ?     
                            row.work.item :

                            column.id == "obra" ?     
                            row.work.name :

                            column.id == "rmr" ?     
                            row.rock_indice.quality :

                            column.id == "zone" ?     
                            row.zone.name :

                            column.id == "concepto" ?     
                            row.type_drilling.name :
                               value
                            }
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            animation="false"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={statePresupuesto.historialF.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            //labelRowsPerPage={"Ver"}
            //abelDisplayedRows={"a"}
            
        />
        </Paper>
        </>
    );
}


function PresupuestoObras(props){
    const {statePresupuesto, setStatePresupuesto} = useContext(PresupuestoObrasContext)
    const {alert, showAlert, closeAlert} = useContext(AlertContext)

    const columns = [
        { id:'id', label:'No. Folio', minWidth: 100},
        { id:'month', label:'Mes', minWidth: 100 },
        { id:'year', label:'Año', minWidth: 100 },
        { id:'item', label:'Item', minWidth: 100 },
        { id:'obra', label:'Obra', minWidth: 130 },
        { id:'rmr', label:'No. RMR', minWidth: 100 },
        { id:'zone', label:'Zona', minWidth: 100 },
        { id:'develop', label:'Desarrollo (ml)', minWidth: 150 },
        { id:'tumbe', label:'Tumbe (m3)', minWidth: 120 },
        { id:'number_concept', label:'No. Concepto', minWidth: 150 },
        { id:'concepto', label:'Concepto', minWidth: 170 },
        {id:'editar', label:"Editar", width: 100},
        {id:'activar', label:"Activar", width: 100}
      
      ];
      
    
    const useStyles = makeStyles({
    root: {
        width: '100%',
    },

    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        
        var data = statePresupuesto.presupuestos.filter((item)=>{
          var active = item.deleted_at === null ? 1 : 0;
    
          if(statePresupuesto.filter_plantilla === 10){
            return true;
          } 
          return active === statePresupuesto.filter_plantilla;
         
        })
       
        setPage(0);
        setStatePresupuesto({...statePresupuesto, presupuestosF:data});
      }, [statePresupuesto.filter_plantilla])
  

  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const onChangeFilter = (evt) =>{  
        setStatePresupuesto({...statePresupuesto, filter_plantilla:parseInt(evt.currentTarget.value)})
    }

    const handleSwitch = async (data,id, val)=>{
        var res;
        if(!val){
          res = await services("DELETE","budgets",undefined,id);
        }else{
          res = await services("GET","budgets",undefined,id);
        }

     
        if(res.status){
          var presupuestos = data
          var presupuesto = selectFromObjbyId(data, id);
          presupuesto.deleted_at = res.data.deleted_at;
          setStatePresupuesto({...statePresupuesto, presupuestos })
        }  
    }

    const handleFilter2 = (event) =>{  
     
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
      

   
        var desde = new Date(Date.parse(body.desde))
        var hasta = new Date(Date.parse(body.hasta))
        //hasta.setDate(hasta.getDate() + 1);

        if(desde < hasta){
            closeAlert();
            var data = statePresupuesto.presupuestos.filter((item)=>{
                return item.year >= desde.getFullYear() && item.year <= hasta.getFullYear() && item.month >= (desde.getMonth() + 1) && item.month <= (hasta.getMonth()+1)
                //return new Date(Date.parse(item.created_at)) >= desde &&  new Date(Date.parse(item.created_at)) <= hasta;
            })
            setStatePresupuesto({...statePresupuesto, presupuestosF:data});
            
                        
        }else{
            showAlert("error", "¡Error! Fecha: Desde no puede ser mayor a Fecha: Hasta")
           
        }


      
        
        
      
    }

    return(
        <>
        <div className="d-flex align-items-center py-2">
            <div className="col-2 p-0">
                <Form.Label>ESTATUS:</Form.Label>
                <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                    <option value ="10">Todos</option>
                    <option value ="1">Activo</option>
                    <option value ="0">Desactivado</option>
                </Form.Control>
            </div>
            <div className="col-8 pl-2">
            <Form  onSubmit={handleFilter2} id="">
                <div className="d-flex align-items-center py-2">
                    <div className="col-3 p-0 m-0">
                    
                            <Form.Label>DESDE</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                name="desde"
                                value={statePresupuesto.desdeP}
                                onChange={(val)=>{
                                    
                                    setStatePresupuesto({...statePresupuesto, desdeP:val.currentTarget.value})
                                    
                                }}
                            />
                
                    </div>
                    <div className="col-3 p-0 ml-2">
                    
                            <Form.Label>HASTA</Form.Label>
                            <Form.Control
                                
                                required
                                type="date"
                                name="hasta"
                                value={statePresupuesto.hastaP}
                                onChange={(val)=>{
                                    
                                    setStatePresupuesto({...statePresupuesto, hastaP:val.currentTarget.value})
                                    
                                }}
                                
                            />
                        
                    
                    </div>
                    <Button type="submit" className="align-self-end ml-2">BUSCAR</Button>
                    <IconButton size="small" className="align-self-end" onClick={()=>{
                            setStatePresupuesto({...statePresupuesto, hastaP:"", desdeP:"", presupuestosF:statePresupuesto.presupuestos})
                        }}>
                        <Trash size={26} className="cursor-pointer" color="tomato"/>
                    </IconButton>
                </div>
            </Form>
            </div>  
            <div className="col"></div>
            
            {/* <div className="col-1 h-100"> */}
                <div className="btn btn-primary align-self-end my-2" onClick={()=>{
                    props.onAdd(true);
                }}>NUEVO</div>
            {/* </div> */}



          
            
            
           


        </div>
        <Paper className={classes.root}>
        <TableContainer  className={classes.container}>
            <Table  stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ 
                        minWidth: column.minWidth,
                        paddingLeft:0,
                        paddingBottom:10,
                        paddingTop:10,
                        paddingLeft:5,
                        paddingRight:10,
                        lineHeight:1.2
                    }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {statePresupuesto.presupuestosF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
                       let switchActive = row.deleted_at === null ? true : false;
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                        {columns.map((column) => {
                        var value = row[column.id];
                        return (
                            <TableCell key={column.id} align={column.align} style={{ 
                                paddingRight:10,
                                paddingLeft:5,
                                paddingTop:5,
                                paddingBottom:5,
                        
                                }}>
                            {
                                column.id == "item" ?     
                                row.work.item :

                                column.id == "obra" ?     
                                row.work.name :

                                column.id == "rmr" ?     
                                row.rock_indice.quality :

                                column.id == "zone" ?     
                                row.zone.name :

                                column.id == "concepto" ?     
                                row.type_drilling.name :

                                column.id === "editar" ? 
                                <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row, true)}}>
                                <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                                </IconButton>
                                : column.id === "activar" ?  

                                <Switchy value={switchActive} onChange={(val)=>{
                                handleSwitch(statePresupuesto.presupuestos, row.id, val)
                                }} /> 
                                : value
                            }
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            animation="false"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={statePresupuesto.presupuestosF.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            //labelRowsPerPage={"Ver"}
            //abelDisplayedRows={"a"}
            
        />
        </Paper>
        </>
    );
}

export default PresupuestoObrasRecords;