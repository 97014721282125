import React, { createContext, useState,useEffect, useContext } from "react"
import {recordsService, recordsServiceId, singleService} from '../services/api' 

import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";
import {
    useHistory,
    useParams,
  } from "react-router-dom";
import { selectFromObjbyId } from "../utils/utils";
import { ProyectosContext } from "./proyectos_context";


export const ListaContext = createContext();
export const ListaProvider = ({children}) => {

    let auth = useAuth();
    let { id } = useParams();
    let history = useHistory();
    
    const {setUser}= useContext(UserContext)
    const {state}= useContext(ProyectosContext);


    const [stateLista, setStateLista ] = useState({
        lista:[],
        users:[],
        usersF:[],
        project_id:null,
        project_name:"",
        loading:false,
        filtro:10
       
    });

    useEffect(async () =>{
       
        
        
        setStateLista({...stateLista, loading:true});
         

          
            var listaRes = await singleService("GET","assists", id);
            console.log(listaRes);
        

            let proyecto = selectFromObjbyId(state.projects,parseInt(listaRes.data.data.project_id))
           
            if(proyecto !== undefined){
               
               
                let lista = listaRes.data.data
                let users = listaRes.data.users
          
                // if(rocasRes.status){ 

                    setStateLista({
                        lista,
                        users,
                        usersF:users,
                        project_id:proyecto.id,    
                        project_name:proyecto.name,
                        loading:false,
                        filtro:10
                   
                    });
                // }else{
                //     if(rocasRes.code === 401 || rocasRes.code === 500){
                //         window.localStorage.clear();
                //         setUser({});
                //         auth.setMsg(true)
                //         auth.signout();
                //     }
                // }
               
            }
    },[state.projects])
    
    return(
        <ListaContext.Provider value={{
            stateLista, 
            setStateLista
        }}>
            {children}
        </ListaContext.Provider>
    )
}











