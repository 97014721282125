
import * as axios from "axios";



const config = () =>{
    let token = window.localStorage.getItem('token');
    //let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWluZXJpYS5jYW50ZXJhZGlnaXRhbC5teFwvYXV0aFwvbG9naW4iLCJpYXQiOjE2MjIwNDU0MzYsImV4cCI6MTYyMjA0OTAzNiwibmJmIjoxNjIyMDQ1NDM2LCJqdGkiOiJQWEliSDR2cnJJWVAxTEZqIiwic3ViIjoxLCJwcnYiOiJmNjRkNDhhNmNlYzdiZGZhN2ZiZjg5OTQ1NGI0ODhiM2U0NjI1MjBhIn0.vW_gXpmNftS79RnW8L2xPg7FaWeKxI_BBvBedX4PxyM"
    return { headers: { Authorization: `Bearer ${token}` }}
}
const uid = () =>{
    return window.localStorage.getItem('uid');
}

export const resetPassword = async (email) =>{
    var body = {email:email}
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/resetPassword`,body).then((r)=>{
        return {status:true, data:r.data};
    }).catch((err)=>{
    
        return {status:false, code:err.response.status}
    });
}

export const getUser = () =>{
    return axios.get(`${process.env.REACT_APP_API_URL}/api/users/${uid()}`,config()).then((r)=>{
        return {status:true, data:r.data};
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const getUserPermissions = (id) =>{
    return axios.get(`${process.env.REACT_APP_API_URL}/api/user_permissions/get_permission/${id}`,config()).then((r)=>{
        return {status:true, data:r.data};
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const getOperationRecords = (id) =>{
    return axios.get(`${process.env.REACT_APP_API_URL}/api/general_forms/operation/${id}/all`,config()).then((r)=>{
        return response(r)
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const editUserPermissions = (id, body) =>{
    return axios.put(`${process.env.REACT_APP_API_URL}/api/user_permissions/assign_permission/${id}`,body ,config()).then((res)=>{
        console.log(res);
    }).catch((err)=>{
        console.log(err);
    });
}

export const getObras = (id) =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/works/all/${id}`;
    return axios.get(url,config()).then((r)=>{
        return r;
    }).catch((err)=>{
        console.log(err)
    });
}

export const getUsersList = (id, date) =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/users/assists/${id}/${date}`;
    return axios.get(url,config()).then((r)=>{
        return r;
    }).catch((err)=>{
        console.log(err)
    });
}

// export const recordCount = (service) => {
//     let url  = `${process.env.REACT_APP_API_URL}/api/${service}/paginated/1/0?status=Todos`;
//     if(service == 'historicals'){
//         url = `${process.env.REACT_APP_API_URL}/api/${service}/paginated/1/0`
//     }
//     return axios.get(url,config()).then((r)=>{
//         return {status:false, count:r.data.count}
//     }).catch((err)=>{
//         return {status:false, code:err.response.status}
//     });
// }

export const singleService = (method, service, id) =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/${service}/${id}`;
    
    return axios.get(url,config()).then((r)=>{
       
        return r;
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}


export const recordsServiceId = (method, service, id, limit = 500, ) =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/${service}/paginated/${limit}/0?status=Todos&project_id=${id}`;
    if(service === 'work_types' || service === 'works' || service === 'planning_ml_m3' || service === 'planning_tons_production' || service === 'planning_tons_extraction') url = `${process.env.REACT_APP_API_URL}/api/${service}/all/${id}?status=Todos`
    return axios.get(url,config()).then((r)=>{
       
        return response(r);
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const recordsService = (method, service, limit = 500) =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/${service}/paginated/${limit}/0?status=Todos`;
    if(service == 'historicals'){
        url = `${process.env.REACT_APP_API_URL}/api/${service}/paginated/${limit}/0`
    }
    return axios.get(url,config()).then((r)=>{
      
        return response(r);
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const recordsServicePage = (method, service, limit, page) =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/${service}/paginated/${limit}/${page}?status=Todos`;
    if(service == 'historicals'){
        url = `${process.env.REACT_APP_API_URL}/api/${service}/paginated/${limit}/${page}`
    }
    return axios.get(url,config()).then((r)=>{
        return response(r);
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const dependentService = (method, service, f = "Activos") =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/${service}/all?status=${f}`;
    return axios.get(url,config()).then((r)=>{
        return response(r);
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const dependentServiceId = (method, service, id) =>{
    let url  = `${process.env.REACT_APP_API_URL}/api/${service}/all?project_id=${id}}`;
    return axios.get(url,config()).then((r)=>{
        return response(r);
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });
}

export const services = (method,service,body,id = undefined) => {

    switch (method) {
        case "GET":
      
            let url  = `${process.env.REACT_APP_API_URL}/api/${service}/${id}/restore`;
          
            return axios.get(url,config()).then((r)=>{
                return response(r);
            }).catch((err)=>{
                return {status:false, code:err.response.status}
            });

            //break;
        case "POST":

            return axios.post(`${process.env.REACT_APP_API_URL}/api/${service}`,body,config()).then((r)=>{
                return response(r);
            }).catch((err)=>{
                if(err.response){
                    return {status:false, code:err.response.status}
                }else{
                    return {status:false, code:1}
                }
                
            });

           // break;
        case "PUT":
            return axios.put(`${process.env.REACT_APP_API_URL}/api/${service}/${id}`,body,config()).then((r)=>{
            
                return response(r);
            }).catch((err)=>{
                return {status:false, code:err.response.status}
            });
            //break;
        case "DELETE":
            return axios.delete(`${process.env.REACT_APP_API_URL}/api/${service}/${id}`,config()).then((r)=>{
                return response(r);
            }).catch((err)=>{
                return {status:false, code:err.response.status}
            });

            //break;    
        default:
            break;
    }
}

export const graficosFiltro = (start, end, id) =>{
    
    let url  = `${process.env.REACT_APP_API_URL}/api/assists/graph/project/${id}?start_date=${start}&end_date=${end}`
    
 
    return axios.get(url,config()).then((r)=>{
        return r
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });

}


export const historialFitro = (start, end, user, catalog) =>{
    
    let url  = `${process.env.REACT_APP_API_URL}/api`;
    //1
    if(start !== "" && end === "" && user === "" && catalog === ""){
        url = url + `/historicals/all?start_date=${start}`
    }
    if(start === "" && end !== "" && user === "" && catalog === ""){
       
        url = url + `/historicals/all?end_date=${end}`
    }
    if(start === "" && end === "" && user !== "" && catalog === ""){
        url = url + `/historicals/all?user_id=${user}`
    }
    if(start === "" && end === "" && user === "" && catalog !== ""){
        url = url + `/historicals/all?catalog=${catalog}`
    }

    //2

    if(start !== "" && end !== "" && user === "" && catalog === ""){
        url = url + `/historicals/all?start_date=${start}&end_date=${end}`
    }
    if(start !== "" && end === "" && user !== "" && catalog === ""){
        url = url + `/historicals/all?start_date=${start}&user_id=${user}`
    }
    if(start !== "" && end === "" && user === "" && catalog !== ""){
        url = url + `/historicals/all?start_date=${start}&catalog=${catalog}`
    }

    if(start === "" && end !== "" && user !== "" && catalog === ""){
        url = url + `/historicals/all?end_date=${end}&user_id=${user}`
    }
    if(start === "" && end !== "" && user === "" && catalog !== ""){
        url = url + `/historicals/all?end_date=${end}&catalog=${catalog}`
    }
    if(start === "" && end === "" && user !== "" && catalog !== ""){
        url = url + `/historicals/all?user_id=${user}&catalog=${catalog}`
    }
    
    if(start !== "" && end !== "" && user !== "" && catalog === ""){
        url = url + `/historicals/all?start_date=${start}&end_date=${end}&user_id=${user}`
    }
    if(start !== "" && end !== "" && user === "" && catalog !== ""){
        url = url + `/historicals/all?start_date=${start}&end_date=${end}&catalog=${catalog}`
    }
    if(start !== "" && end === "" && user !== "" && catalog !== ""){
        url = url + `/historicals/all?start_date=${start}&user_id=${user}&catalog=${catalog}`
    }
    if(start === "" && end !== "" && user !== "" && catalog !== ""){
        url = url + `/historicals/all?end_date=${end}&user_id=${user}&catalog=${catalog}`
    }
    if(start !== "" && end !== "" && user !== "" && catalog !== ""){
        url = url + `/historicals/all?start_date=${start}&end_date=${end}&user_id=${user}&catalog=${catalog}`
    }
    
    
    return axios.get(url,config()).then((r)=>{
        return response(r);
    }).catch((err)=>{
        return {status:false, code:err.response.status}
    });

}

export const filterExcel = (service,id,status,tipo,desde,hasta,fase,estado) =>{
    let url = `${process.env.REACT_APP_API_URL}/api/${service}/excel/`
    let estatus

    if(status===10){
        estatus = 'Todos'
    }else if(status===1){
        estatus = 'Activos'
    }else{
        estatus = 'Inactivos'
    }
    if(service==='work_types'){
        url = url + `${id}?type=${tipo}&status=${estatus}`
    }

    return axios.get(url,config()).then(res=>{
        return res.blob()
    }).catch(err=>{
        return {status:false, code:err.response.status}
    })
}

export const filteredPlanning = (id,fechaStart,fechaEnd,type)=>{
    let url = `${process.env.REACT_APP_API_URL}/api/planning_${type}/all/${id}?desde=${fechaStart}&hasta=${fechaEnd}`
    return axios.get(url,config()).then(r=>{
        return response(r)
    }).catch((err)=>{
        console.log(err.response)
        return {status:false,code:err.response.status}
    })
}

export const planningFile = (id,file,type) =>{
    let url = `${process.env.REACT_APP_API_URL}/api/planning_${type}/upload/${id}`
    return axios.post(url,file,{headers:{'Content-Type':'multipart/form-data',Authorization:'Bearer '+localStorage.getItem('token')}}).then(r=>{
        return response(r)
    }).catch((err)=>{
        console.log(err.response)
        return {status:false,code:err.response.status}
    })
}

const response = (r) => {
    //console.log(r);
    if(r.status == 200 && r.data.status == "Success"){
        let data = r.data.data !== null ? r.data.data : []
        return {status:true, data, count:r.data.count !== null ? r.data.count : 0}
    }
    return {status:false, errors: r.data.errors}
}



