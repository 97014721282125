import React, {useContext} from 'react'
import { Person } from 'react-bootstrap-icons'
import { UserContext } from '../context/user_context'

function ProfilleView() {

    const { user } = useContext(UserContext)
    
  

    return (
        <main role="main" className="home_main h-100 px-2">

            <h2><Person size="30" color="#0a5cbe"></Person> {user !== undefined ? user.name:  null} </h2>
            <div className="pl-5">

                
                {
                    
                    user.user_projects !== undefined ? 
                    user.user_projects.map((element, i) => {
                     
                        return <pre key={i}>{element.categorie.name}</pre>
                    }) :null
                
                }
            </div>
           

        </main>
    )
}

export default ProfilleView
