import {createContext, useContext, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { dependentService, recordsServiceId } from '../services/api'
import { useAuth } from '../services/auth'
import { UserContext } from './user_context'


export const ObrasContext = createContext()
export const ObrasProvider = ({children}) => {

    let auth = useAuth()
    let {id} = useParams()
    const {setUser} = useContext(UserContext)

    const [state,setState] = useState({
        obras:[],
        obrasF:[],
        tipos:[],
        zonas:[],
        rocas:[],
        sectores:[],
        filter:10,
        projects:[],
        search:'',
        fechaStart:'',
        fechaEnd:'',
        tipoF:'',
        faseF:'',
        estadoF:'',
        project_id:id,
        loading:false
    })

    useEffect(()=>{
        const fetchData = async () =>{
            if(state.obras.length === 0){
                setState({...state,loading:true})
                var res = await recordsServiceId('GET','works',id)
                var resProj = await dependentService('GET','projects')
                var resTipos = await recordsServiceId('GET','work_types',id)
                var resZonas = await recordsServiceId('GET','zones',id)
                var resRocas = await recordsServiceId('GET','rock_indices',id)
                var resSectores = await recordsServiceId('GET','sectors',id)
                if(res.status){
                    setState({...state,obras:res.data,obrasF:res.data,tipos:resTipos.data,zonas:resZonas.data,rocas:resRocas.data,sectores:resSectores.data,loading:false})
                }else{
                    if(res.code===401){
                        window.localStorage.clear()
                        setUser({})
                        auth.setMsg(true)
                        auth.signout()
                    }
                }
            }
        }
        fetchData()
    },[])

    return(
        <ObrasContext.Provider value={{state,setState}}>
            {children}
        </ObrasContext.Provider>
    )
}