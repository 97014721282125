import React,{useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { PencilSquare } from 'react-bootstrap-icons';

import Switchy from '../triggers/switchy';

import {services} from '../../services/api';
import { TipoAnclasContext } from '../../context/tipo_anclas_context';
import { selectFromObjbyId } from '../../utils/utils';
import { IconButton } from '@material-ui/core';

const columns = [
  // { id:'id', label:'id', minWidth: 60 },
  { id:'name', label:'Ancla', minWidth: 170 },
  { id:'description', label:'Descripcion', minWidth: 300 },
  { id:'project', label:'Proyecto', minWidth: 300 },
  { id:'editar', label:'Editar', minWidth: 50 },
  { id:'activar', label:'Activar', minWidth: 50 },
  
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});

function TipoAnclasRecords(props) {


  const {state, setState} = useContext(TipoAnclasContext)
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
      
    var data = state.anclas.filter((item)=>{
      var active = item.deleted_at === null ? 1 : 0;

      if(state.search === "" && state.filter === 10){
        return true;
      }

      if(state.search !== "" && state.filter === 10){
     
        return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase())
      }
     
      return item.name.toLocaleUpperCase().includes(state.search.toLocaleUpperCase()) && active === state.filter;
     
    })
    setPage(0);
    setState({...state, anclasF:data});

  }, [state.search, state.filter])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSwitch = async (data,id, val)=>{
    if(!val){
      var res = await services("DELETE","anchorTypes",undefined,id);
    
    }else{
      var res = await services("GET","anchorTypes",undefined,id);
    }
    if(res.status){
      var anclas = data
      var ancla = selectFromObjbyId(data, id);
      ancla.deleted_at = res.data.deleted_at;
      setState({...state, anclas })
    }  
  }

  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
                    paddingLeft:0,
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                   }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.anclasF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {
            let active = row.deleted_at === null ? 1 : 0;
            let switchActive = row.deleted_at === null ? true : false;
  
                     
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                          paddingRight:10,
                          paddingLeft:5,
                          paddingTop:5,
                          paddingBottom:0,

                         }}>
                          {
                            column.id === "editar" ? 
                            <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
                              <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                            </IconButton>
                            : column.id === "project" ?  
                              row.project.name
                            : column.id === "activar" ?  
                            <Switchy value={switchActive} onChange={(val)=>{
                              handleSwitch(state.anclas, row.id, val)
                            }} />
                              : value
                          }
                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
              
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.anclasF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default TipoAnclasRecords;