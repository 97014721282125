import React, { createContext, useState,useEffect, useContext } from "react"
import { recordsService } from "../services/api";


import { useAuth } from "../services/auth";
import { UserContext } from "./user_context";

export const EquiposContext  = createContext();

export const EquiposProvider = ({children}) => {

    let auth = useAuth();
    const {setUser}= useContext(UserContext)


    const [state, setState] = useState({
          
        equipments:[],
        equipmentsF:[],
        filter: 10,
        loading:false
    
    
    });

    useEffect(async () =>{
        if(state.equipments.length === 0){
            setState({...state,loading:true})
            var res = await recordsService("GET","equipments");
            if(res.status){
                setState({...state,loading:false, equipments:res.data, equipmentsF:res.data});
            }else{
                if(res.code == 401 || res.code === 500){
                    window.localStorage.clear();
                    setUser({});
                    auth.setMsg(true)
                    auth.signout();
                }
            }
        }
    },[])
    
    return(
        <EquiposContext.Provider value={{
            state,
            setState
        }}>
            {children}
        </EquiposContext.Provider>

    )
}






