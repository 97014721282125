import React from 'react'

function ViewDataLoader() {
    return (
        <main role="main" className="position-relative">
            <div className="absolute-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </main>
    )
}

export default ViewDataLoader
