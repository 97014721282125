import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow';
import { PencilSquare } from 'react-bootstrap-icons';
import { IconButton } from '@material-ui/core';
import Switchy from '../triggers/switchy';
import { useContext, useEffect, useState } from 'react'
import { ObrasContext } from '../../context/obras_context'
import { services } from '../../services/api'
import { selectFromObjbyId } from '../../utils/utils'

const columns = [
    { id: 'name', label: 'Nombre de la Obra', minWidth: 170},
    { id: 'start_date', label: 'Fecha de Inicio', minWidth: 170},
    { id: 'work_type_id', label: 'Tipo de Obra', minWidth: 170},
    { id: 'zone_id', label: 'Zona', minWidth: 170},
    { id: 'rmr', label: 'RMR', minWidth: 170},
    { id: 'pep', label: 'PEP', minWidth: 170},
    { id: 'fase', label: 'Fase', minWidth: 170},
    { id: 'state', label: 'Estado', minWidth: 170},
    { id: 'editar', label: 'Editar', minWidth: 170},
    { id: 'activar', label: 'Activar', minWidth: 170}
]

const useStyles = makeStyles({
    root : {
        width: '100%'
    }
})

export default function ObrasRecords(props) {

    const {state,setState} = useContext(ObrasContext)
    const classes = useStyles()
    const [page,setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(()=>{
        let data = state.obras

        if(state.filter!==10) data = data.filter(item=>{
            let active = item.deleted_at === null ? 1 : 0
            return active === state.filter
        })

        if(state.fechaStart!=='') data = data.filter(item=>{
            let sDate = new Date(item.start_date)
            let fDate = new Date(state.fechaStart)
            return sDate.getTime() >= fDate.getTime()
        })

        if(state.fechaEnd!=='') data = data.filter(item=>{
            let sDate = new Date(item.start_date)
            let fDate = new Date(state.fechaEnd)
            return sDate.getTime() <= fDate.getTime()
        })

        if(state.tipoF!=='') data = data.filter(item=>{
            return item.work_type_id === state.tipoF
        })

        if(state.faseF!=='') data = data.filter(item=>{
            return item.fase === state.faseF
        })

        if(state.estadoF!=='') data = data.filter(item=>{
            return item.state === state.estadoF
        })

        setPage(0)
        setState({...state,obrasF:data})
    },[state.filter,state.fechaStart,state.fechaEnd,state.tipoF,state.faseF,state.estadoF])

    const handleChangePage = (event, newPage) =>{
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) =>{
        setRowsPerPage(event.target.value)
        setPage(0)
    }

    const handleSwitch = async (data,id,val) =>{
        if(!val){
            var res = await services('DELETE','works',undefined,id)
        }else{
            var res = await services('GET', 'works',undefined,id)
        }

        if(res.status){
            var obras = data
            var obra = selectFromObjbyId(data,id)
            obra.deleted_at = res.data.deleted_at
            setState({...state,obras})
        }
    }


    
    return(
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky label'>
                    <TableHead>
                        <TableRow>
                            {columns.map(column=>{
                                return(
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            paddingBottom:10,
                                            paddingTop:10,
                                            paddingLeft:5,
                                            paddingRight:10,
                                            lineHeight:1.2
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.obrasF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i)=>{
                            let switchActive = row.deleted_at === null ? 1 : 0
                            return(
                                <TableRow>
                                    {columns.map(column=>{
                                        var value = row[column.id]
                                        return(
                                            <TableCell>
                                                {column.id === 'editar' ?
                                                <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
                                                    <PencilSquare size={20} className='cursor-pointer' color={switchActive ? 'tomato' : 'grey'}></PencilSquare>
                                                </IconButton>
                                                : column.id === 'activar' ?
                                                <Switchy value={switchActive} onChange={(val)=>{
                                                    handleSwitch(state.obras,row.id,val)
                                                }}/>
                                                :
                                                value
                                                }
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                animation={false}
                rowsPerPageOptions={[10,25,100]}
                component='div'
                count={state.obrasF.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
}