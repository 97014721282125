
import React, { useState,useContext, useMemo,useEffect, createRef } from 'react';
import MaquinariaRecords from '../components/records/maquinaria_records'
import {Journals, XCircleFill} from 'react-bootstrap-icons';
import {showLoader, hideLoader,replaceObj, selectFromObjbyId, today, allowActions} from '../utils/utils';
import { services }  from '../services/api' 
import { Form, Row, Col, Button } from 'react-bootstrap';
import { MaquinariaContext } from '../context/maquinaria_context';
import { UserContext } from '../context/user_context';

import ViewDataLoader from '../components/ui/view_data_loader';
import ActionAlert from '../components/ui/alert';
import { AlertContext } from '../context/alert_context';
import {Multiselect} from 'multiselect-react-dropdown'


//SI traes fecha esta desactivado.
var projectsSelected = [];
var operationsSelected = [];
var editing = false;
var editingId;
function MaquinariaView(){
        
        const [validated, setValidated] = useState(false);
        const {state, setState} = useContext(MaquinariaContext)
        const {user, setUser} = useContext(UserContext)
        const {alert, showAlert, closeAlert} = useContext(AlertContext)
        const proyectosMultiSelect = createRef();


     

    
      const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        formData.forEach((value, key) => body[key] = value);
        body.operations = fetchOperations();
        body.capacity = body.capacity + ""+document.getElementById("unidad").value
        var projects = []
        projectsSelected.forEach((p) =>{
            projects.push({'project_id':p.id})
        });
        body.projects = JSON.stringify(projects);

        if(projects.length === 0){
            showAlert("Error","Campo de PROYECTOS no puede ir vacio");   
            var view = document.querySelector("main")
            view.scrollTo({top: 0, behavior: 'smooth'});
            return;
        }

        if(body.operations === ""){
             showAlert("Error","Debes seleccionar al menos una OPERACIÓN");
             var view = document.querySelector("main")
             view.scrollTo({top: 0, behavior: 'smooth'});
             return;
        }
        

        if (form.checkValidity() === true) {
    
            var resMac;
            showLoader();
            if(!editing){
                resMac = await services("POST","machinaries",body);
                console.log(resMac)
            }else{
                resMac = await services("PUT","machinaries",body,editingId);
            }
            if(resMac.status){

                if(!editing){
                  
                    var data = state.data;
                    var dataF = state.dataF;
                    
                    var machinary = resMac.data;
                    
                    machinary.bar = selectFromObjbyId(state.bars, parseInt(machinary.bar_id))
                    machinary.equipment = selectFromObjbyId(state.equipments, parseInt(machinary.equipment_id))
                    machinary.project = selectFromObjbyId(state.proyects, parseInt(machinary.project_id))
                    machinary.machinary_operations = unFetchOperations();
                    machinary.deleted_at = null;

                    var machinary_projects = []
                    projectsSelected.forEach((p) =>{
                        machinary_projects.push({projects:p})
                    })
                    machinary.machinary_projects = machinary_projects


                    data.unshift(machinary);
                    dataF.unshift(machinary)
              
                    setState({...state,data,dataF});
                    showAlert("success","¡Listo! Registro creado exitosamente.");
        
                }else{

                    var data = state.data;
                    var machinary = resMac.data;
                    machinary.bar = selectFromObjbyId(state.bars, parseInt(machinary.bar_id))
                    machinary.equipment = selectFromObjbyId(state.equipments, parseInt(machinary.equipment_id))
                    machinary.project = selectFromObjbyId(state.proyects, parseInt(machinary.project_id))
                    machinary.machinary_operations = unFetchOperations();

                    var machinary_projects = []
                    projectsSelected.forEach((p) =>{
                        machinary_projects.push({projects:p})
                    })
                    machinary.machinary_projects = machinary_projects


                    var newData = replaceObj(state.data,machinary);
                    var newDataF = replaceObj(state.dataF,machinary);
                    setState({...state,data:newData, dataF:newDataF});
                    showAlert("success","¡Listo! Registro editado exitosamente.");

                }
                hideLoader();
                hideForm();
            }else{

                
             
                
            }
            
        }else{
            
            setValidated(true);
        }
        
      };


      const showForm = async (data = undefined) =>{
        proyectosMultiSelect.current.resetSelectedValues()
        let reason_edition = document.getElementById('reason_edition');
        reason_edition.classList.add('d-none');
        


        let f = document.getElementById("main_form");
        if(data !== undefined){
            reason_edition.classList.remove('d-none');
            fillForm(data)
        }else{
            setState({...state, title:"NUEVO"});
        }
        f.classList.add("d-block");
        f.classList.remove("d-none");
        
       
        
      }

      const hideForm = () =>{
        let f = document.getElementById("main_form");
        clearForm();
        f.classList.add("d-none");
        f.classList.remove("d-block");
      }
      

      const clearForm = () => {
        let form = document.getElementById('maquinaria_form');
        let {elements} = form
        elements.namedItem("reason_edition").disabled = true;
        editing = false;
        editingId = undefined;
        operationsSelected = [];
        projectsSelected=[]
        form.reset();
        setState({...state, project_selected:"",project_selected_id:null, mtsE:null, projectsSelected:[]})
        setValidated(false);
      }
      const fillForm = (data) => {

        let { elements } = document.getElementById('maquinaria_form');;
        elements.namedItem("reason_edition").disabled = false;
        for (const [ key, value ] of Object.entries(data) ) {

                

                const field = elements.namedItem(key)

                if(key == "capacity"){
                    var capacity = parseInt(value.replace("ml", "").replace("m2", "").replace("lts", ""));
                    var unidad = value.replace(/[0-9]/g, '');
                 
                    elements.namedItem("capacity").value = capacity;
                    elements.namedItem("unidad").value = unidad;
                }else{
                    if(key !== "reason_edition"){
                        field && (field.value = value)
                    }
                }
                


               
                

        }
        editing = true;
        editingId = data.id
        data.machinary_operations.forEach((e)=>{
            operationsSelected.push(e.operation);
            var elem = document.getElementById(e.operation)
            if(elem !== null){
                elem.checked = true;
            }
          
        })
     
        var bar = state.bars.find((e)=>{
            return e.id == parseInt(data.bar.id);
        })
        var projects = [];
        data.machinary_projects.forEach((p) =>{
            projects.push(p.projects);
        });
        projectsSelected = projects;


        if(bar !== undefined){
            //setState({...state, mtsE:bar.efficient_meters, project_selected:data.project.name, project_selected_id:data.project_id});
            setState({...state, title:"EDITAR", mtsE:bar.efficient_meters, projectsSelected:projects})
        }else{
            setState({...state, title:"EDITAR", projectsSelected:projects});
        }
        
        
      }
      

      const onChangeFilter = (evt) =>{ 
        setState({...state, filter:parseInt(evt.currentTarget.value)})
      }

      const onChangeBar = (evt) => {
       
        var bar = state.bars.find((bar)=>{
            return bar.id == parseInt(evt.currentTarget.value);
        })

        console.log(bar);
        if(bar !== undefined){
            setState({...state, mtsE:bar.efficient_meters});
        }else{
            setState({...state, mtsE:""});
        }
        
      }
      

      const handleCheckOperations = (evt) => {
    
        let checked = evt.currentTarget.checked;
        let value = evt.currentTarget.value;
       
        if(checked){
            operationsSelected.push(value);
     
        }else{
           
            operationsSelected = operationsSelected.filter(e => e !== value);
        }
        
      }
      const fetchOperations = () => {
            let operation = ""
            
            operationsSelected.forEach((el,i) => {
        
                if(operationsSelected.length == 1){
                    operation = el;
                
                }else{
                    if(i == operationsSelected.length - 1){
                        operation = operation  + el  
                    }else{
                        operation = operation  + el + ",";
                    }
                    
                }
                
            });
            return operation;
        }
    
        const unFetchOperations = () =>{
            var operations = []
            operationsSelected.forEach((el)=>{
                operations.push({operation:el});
            });
            return operations
        }
    

    const items = useMemo(
        () =>
          state.proyects.map((oneItem) => ({
   
            label: oneItem.name,
     
            key: oneItem.id,
        
            ...oneItem,
          })),
        [state.proyects]
      );

    
    const chips = {chips: { background: "#0a5cbe" }}

    if(state.loading){
        return (
            ViewDataLoader()
        )
    }else{
    return(
        <main role="main">
            
            <div className="container-fluid position-relative py-1 ">
            <h2><Journals size="25" color="#0a5cbe"></Journals> Catalogo: Maquinaria</h2>  
            <div className="d-flex">
                <div className="col-2 py-2 px-0">
                    
                    <Form.Label>Estatus:</Form.Label>
                    <Form.Control as="select"  name="proyecto" onChange={onChangeFilter} required>
                        <option value ="10">Todos</option>
                        <option value ="1">Activo</option>
                        <option value ="0">Desactivado</option>
                    </Form.Control>
                </div>
                <div className="col d-flex align-items-end justify-content-end py-2 px-0">
                    <Button className="primary px-4" onClick={()=>{
                            if(allowActions(user,"add")){
                                showForm()
                            }else{
                                showAlert("error","No cuentas con permisos para editar registros.");
                            }
                        }} >
                        Nuevo
                    </Button>
                </div>
            </div>
            <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

            <MaquinariaRecords onEdit={(data)=>{
               
                // if(allowActions(user,"edit")){
                    showForm(data)
                // }else{
                //     showAlert("error","No cuentas con permisos para editar registros.");
                // }
                
            }}></MaquinariaRecords>

            </div>

            <div className="catalogo_form_modal d-none" id="main_form">

                
                
                <Form noValidate validated={validated} onSubmit={handleSubmit} id="maquinaria_form">
                    <div className="container position-relative bg-white rounded pb-5">

                     <ActionAlert type={alert.type} show={alert.show} msg={alert.message} onClose={closeAlert} />

                    <div className="p-3">
                        <h3>{state.title} MAQUINARIA</h3>
                    </div>

                   
                        
                        <div className="row">



                            <div className="col-md-6">

                            <div className="col">
                                <h5>Datos del equipo</h5>
                            </div>


                            <Form.Group as={Col}>
                                <Form.Label>Número Económico</Form.Label>
                                <Form.Control type="text" name="economic_number" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* <Form.Group as={Col}>
                                <Form.Label>Proyecto</Form.Label>
                                <input className="form-control" name="project_id" id="project_id" hidden required />
                                <DataListInput
                                    dropdownClassName="list-data"
                                    itemClassName="list-item"
                                    // dropDownLength={10}
                                    inputClassName="form-control-n"
                                    placeholder="Selecciona"
                                    value={state.project_selected}
                                    items={items}
                                    onSelect={(el)=>{
                                        document.getElementById("project_id").value=el.id;
                                        setState({...state, project_selected:el.name})
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group> */}

<                           Form.Group as={Col}>
                                <Form.Label>PROYECTOS</Form.Label>
                                {/* <Form.Control type="text" name="project_id"  required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback> */}
                                <Multiselect
                                    style={{chips: { background: "#0a5cbe",margin:0 }}}
                                    ref={proyectosMultiSelect}
                                    options={state.proyects}
                                    selectedValues={state.projectsSelected}
                                    displayValue="name"
                                    placeholder="Seleccionar"
                                    closeIcon="cancel"
                                    
                                    onSelect={(p)=>{
                                        projectsSelected = p;
                                        setState({...state, projectsSelected})
                                    }}
                                    onRemove={(p)=>{
                                        projectsSelected = p;
                                        setState({...state, projectsSelected})
                                    }}
                                    
                                    
                                    
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control as="textarea" rows={3} name="descriptions" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Equipo</Form.Label>
                                <Form.Control as="select" 
                                // onFocus={(evt)=>{
                                //     evt.currentTarget.size=10
                                // }}
                                // onBlur={(evt)=>{
                                //     evt.currentTarget.size=1
                                // }}  
                                // onChange={(evt)=>{
                                //    evt.currentTarget.size=1
                                //    evt.currentTarget.blur()
                                // }}
                                name="equipment_id" required>
                                    <option value ="">Seleccionar</option>
                                    {state.equipments.map((equipment)=>{
                                        return (<option key={equipment.id} value ={equipment.id}>{equipment.name}</option>)
                                    })}
                                </Form.Control>

                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Marca</Form.Label>
                                <Form.Control type="text" name="brand" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Modelo</Form.Label>
                                <Form.Control type="text" name="model" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Año</Form.Label>
                                <Form.Control as="select"  name="year" required>
                                    
                                    <option value ="">Seleccionar</option>
                                    <option value ="2020">2020</option>
                                    <option value ="2021">2021</option>
                                    <option value ="2022">2022</option>
                                    <option value ="2023">2023</option>
                                    <option value ="2024">2024</option>
                                    <option value ="2025">2025</option>
                                    <option value ="2026">2026</option>
                                    <option value ="2027">2027</option>
                                    <option value ="2028">2028</option>
                                    <option value ="2029">2029</option>
                                    <option value ="2030">2030</option>
                              
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Serie</Form.Label>
                                <Form.Control type="text" name="serie" required />
                                <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <div className="row m-0">
                                    <div className="col-md-6 ">
                                        <Form.Group as={Row}>
                                            <Form.Label>Capacidad</Form.Label>
                                            <Form.Control type="number" min="1" name="capacity" step="any"  required />
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio. Numeros positivos.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <Form.Group>
                                            <Form.Label>Unidad</Form.Label>
                                            <Form.Control as="select"  name="" id="unidad" required>
                                                <option value="">Seleccionar</option>
                                                <option value="m³">Metros cúbicos</option>
                                                <option value="ml">Metros lineales</option>
                                                <option value="lts">Litros</option>
                                          
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Form.Group>

                            <div className="col">
                                <h5>Operación</h5>
                            </div>


                            <div className="col d-flex flex-wrap">
                                <Form.Check 
                                    type="checkbox"
                                    id="BARRENACIÓN CON JUMBO"
                                    value="BARRENACIÓN CON JUMBO"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'BARRENACIÓN CON JUMBO'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="VOLADURAS"
                                    value="VOLADURAS"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'VOLADURAS'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="REZAGADO"
                                    value="REZAGADO"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'REZAGADO'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="ACARREO"
                                    value="ACARREO"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'ACARREO'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="AMACICE Y LIMPIEZA DE OBRA"
                                    value="AMACICE Y LIMPIEZA DE OBRA"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'AMACICE Y LIMPIEZA DE OBRA'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="ACARREO DE CONCRETO"
                                    value="ACARREO DE CONCRETO"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'ACARREO DE CONCRETO'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="LANZADO DE CONCRETO"
                                    value="LANZADO DE CONCRETO"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'LANZADO DE CONCRETO'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="SOPORTE DE OBRA"
                                    value="SOPORTE DE OBRA"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'SOPORTE DE OBRA'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="SERVICIOS GENERALES"
                                    value="SERVICIOS GENERALES"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'SERVICIOS GENERALES'}
                                />
                                <Form.Check 
                                    type="checkbox"
                                    id="TRANSPORTE DE PERSONAL Y MATERIALES"
                                    value="TRANSPORTE DE PERSONAL Y MATERIALES"
                                    onClick={((evt)=>{handleCheckOperations(evt)})}
                                    label={'TRANSPORTE DE PERSONAL Y MATERIALES'}
                                />
                               
                            </div>

                            </div>
                            <div className="col-md-6">

                                <div className="col">
                                    <h5>Fecha de llegada</h5>
                                </div>

                                <Form.Group as={Col} controlId="" className="fecha_llegada">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control
                                        required
                                        type="date"
                                        name="arrival_date"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <div className="col">
                                    <h5>Datos del motor</h5>
                                </div>
                                <Form.Group as={Col}>
                                    <Form.Label>Combustible</Form.Label>
                                    <Form.Control as="select"  name="engine_fuel" required>
                                        <option value ="">Seleccionar</option>
                                        <option value ="Diesel">Diésel</option>
                                        <option value ="Gasolina">Gasolina</option>
                                  
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group as={Col}>
                                    <Form.Label>Marca</Form.Label>
                                    <Form.Control type="text" name="engine_brand" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Modelo</Form.Label>
                                    <Form.Control type="text" name="engien_model" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Serie</Form.Label>
                                    <Form.Control type="text" name="engien_serie" required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Capacidad (Lts)</Form.Label>
                                    <Form.Control type="number" name="engien_capacity" min='1' step='any' required />
                                    <Form.Control.Feedback type="invalid">
                                        Este campo es obligatorio. Numeros positivos.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <div className="col">
                                    <h5>Proyección de combustible</h5>
                                </div>

                                <Form.Group as={Col}>
                                    <Form.Label>Bajo (lts)</Form.Label>
                                    <Form.Control type="number" min="1" name="projection_under" step="any" required />
                                    <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio. Numeros positivos.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Medio (lts)</Form.Label>
                                    <Form.Control type="number" min="1" name="projection_medium" step="any" required />
                                    <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio. Numeros positivos. 
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Alto (lts)</Form.Label>
                                    <Form.Control type="number" min="1" name="proyection_high" step="any" required />
                                    <Form.Control.Feedback type="invalid">
                                    Este campo es obligatorio. Numeros positivos.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <div className="col">
                                    <h5>Especificaciones de barrenación</h5>
                                </div>


                                <Form.Group as={Col}>
                                    <div className="row">
                                        <div className="col-md-6 p-0">
                                        <Form.Group as={Col}>
                                            <Form.Label>Longitud de barra</Form.Label>
                                            <Form.Control as="select"  name="bar_id" onChange={onChangeBar}>
                                                <option value ="">Seleccionar</option>
                                                {state.bars.map((bar, i)=>{
                                                    return (<option key={i} value ={bar.id}>{bar.length}</option>)
                                                })}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Este campo es obligatorio.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </div>
                                        <div className="col-md-6 p-0">
                                        <Form.Group as={Col}>
                                            <Form.Label>Metros eficientes</Form.Label>
                                         
                                         
                                            <div className="form-control disabled">{state.mtsE}</div>
                                 
                                        </Form.Group>
                                        </div>
                                    </div>
                                </Form.Group>

                                <div id="reason_edition">
                                    <Form.Group as={Col}>
                                        <Form.Label>FECHA DE EDICIÓN</Form.Label>
                                        <div className="form-control disabled">{today()}</div>
                                                    
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>ENCARGADO</Form.Label>
                                        <div className="form-control disabled">{user.name}</div>
                                    </Form.Group>
                                
                                
                                    <Form.Group as={Col}>
                                        <Form.Label>Motivo de edición</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="reason_edition" disabled required />
                                        <Form.Control.Feedback type="invalid">
                                            Este campo es obligatorio.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                


                                <Form.Group as={Col}>
                                    <Button type="submit">GUARDAR</Button>
                                </Form.Group>

                           
                            </div>
                        </div>
                        <div className="cta position-absolute top right p-2" onClick={hideForm}>
                            <XCircleFill size="45"></XCircleFill>
                        </div>
                    </div>
                    
                </Form>

                

            </div>
            
        </main>
    )}

}


export default MaquinariaView;