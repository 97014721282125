import React,{useContext,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { PencilSquare } from 'react-bootstrap-icons';
import Switchy from '../triggers/switchy';
import { BarrasContext } from '../../context/barras_context';
import {services} from '../../services/api';
import { selectFromObjbyId } from '../../utils/utils';
import { IconButton } from '@material-ui/core';
import { SectorContext } from '../../context/sector_context';
import { MaterialContext } from '../../context/material_context';

const columns = [

  { id:'name', label:'Material', minWidth: 170 },
  { id:'description', label:'Descripción', minWidth: 170 },
  { id:'material_weight', label:'Peso Específico (gr/cm3)', minWidth: 100 },
  { id:'', label:'', minWidth: 170 },
  { id:'editar', label:'Editar', minWidth: 100 },
  { id:'activar', label:'Activar', minWidth: 100 },
  
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
 
});




function MaterialRecords(props) {

  const {stateMaterial, setStateMaterial} = useContext(MaterialContext)

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
      
    var data = stateMaterial.materiales.filter((item)=>{
      var active = item.deleted_at === null ? 1 : 0;

      if(stateMaterial.filter === 10){
        return true;
      }
      return active === stateMaterial.filter;
    })
    setPage(0);
    setStateMaterial({...stateMaterial, materialesF:data});

  }, [stateMaterial.filter])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSwitch = async (data,id, val)=>{
    var res;
    if(!val){
      res = await services("DELETE","materials",undefined,id);
    }else{
      res = await services("GET","materials",undefined,id);
    }
    if(res.status){
      var materiales = data
      var material = selectFromObjbyId(data, id);
      material.deleted_at = res.data.deleted_at;
      setStateMaterial({...stateMaterial, materiales })
    }  
  }

  return (
    <Paper className={classes.root}>
      <TableContainer  className={classes.container}>
        <Table  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
                  
                    paddingBottom:10,
                    paddingTop:10,
                    paddingLeft:5,
                    paddingRight:10,
                    lineHeight:1.2
                   }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stateMaterial.materialesF.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,i) => {

            let active = row.deleted_at === null ? 1 : 0;
            let switchActive = row.deleted_at === null ? true : false;
         

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>

                    {columns.map((column) => {
                      var value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} style={{ 
                          paddingRight:10,
                          paddingLeft:5,
                          paddingTop:5,
                          paddingBottom:0,

                         }}>
                          {

                            column.id === "editar" ? 
                            <IconButton disabled={!switchActive} onClick={()=>{props.onEdit(row)}}>
                              <PencilSquare size={20} className="cursor-pointer" color={switchActive ? "tomato" : "grey"}/>
                            </IconButton>
                            : column.id === "activar" ?  

                            <Switchy value={switchActive} onChange={(val)=>{
                              handleSwitch(stateMaterial.materiales, row.id, val)
                            }} /> 
                            : value
                          }
                        </TableCell>
                  
                      );
                    })}
                  
                    
                  </TableRow>
                );
              
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        animation="false"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={stateMaterial.materialesF.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        //labelRowsPerPage={"Ver"}
        //abelDisplayedRows={"a"}
        
      />
    </Paper>
  );
}

export default MaterialRecords;