import { ContactsOutlined } from '@material-ui/icons';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Form, Col, Button } from 'react-bootstrap'
import DataListInput from 'react-datalist-input';
import { BarrenacionJumboContext } from '../context/barrenacion_jumbo_context';
import { FormularioGeneralContext } from '../context/formulario_general_context';
import { services, singleService } from '../services/api';
import { hideLoader, selectFromObjbyId, showLoader, today, todayC, getStringDay } from '../utils/utils';


const Context = createContext();
function FormularioGeneral(props){

 
    const [validated, setValidated] = useState(false);

    
    const [state, setState] = useState({ 
        data:props.data, 
        equipo:null, 
        capacidad:null, 
        ayudante:null, 
        operador:null,
        polvorero:null,
        oficial:null,
        lista:null, 
        jornada:null,
        maquinarias:[],
        //hour_dead_laboral:0,
        satellite_team_hours:null,
        hours_worked:null,
        hours_reparation:null,
        projected_effective_hours:null,
        electronic_hr_i:null,
        electronic_hr_f:null,
        diesel_hr_i:null,
        diesel_hr_f:null,
        operation:props.operation,
        listaRes:[],
        supervisores:[],
        oficiales:[],
        ayudantes:[],
        operadores:[],
        polvoreros:[]
     })
    const {stateGenerales,setStateGenerales, _onCreateForm, _onEditForm} = useContext(FormularioGeneralContext);
   

    useEffect(()=>{


        var jornada = null;
        if(props.data !== undefined){
            var lista = stateGenerales.listasAll.filter((lista)=>{

                if(lista.id == props.data.turn){
                    return true
                }
                return false
            })  

            if(lista.length > 0){

                var h_start = lista[0].hour_start.split(":")[0];
                var h_end = lista[0].hour_end.split(":")[0];
                jornada = parseInt(h_end) - parseInt(h_start);

            }
        }


        let hours_worked;
        if(props.data == undefined){
            hours_worked = 0;
        }

        setState({
            ...state, 
            jornada:jornada,
            //maquinarias:maquinarias,
            ayudante:props.data !== undefined ? props.data.assistant : null, 
            operador:props.data !== undefined ? props.data.operator : null, 
            oficial:props.data !== undefined ? props.data.oficial : null, 
            polvorero:props.data !== undefined ? props.data.polvorero : null, 
            equipo:props.data !== undefined ? props.data.equipment : null,
            satellite_team_hours:props.data !== undefined ? props.data.satellite_team_hours : "",
            hours_worked:hours_worked,
            hours_reparation:props.data !== undefined ? props.data.hours_reparation : "",
            projected_effective_hours: props.data !== undefined ? props.data.projected_effective_hours : "",
            electronic_hr_i:props.data !== undefined ? props.data.electronic_hr_i : 0,
            electronic_hr_f:props.data !== undefined ? props.data.electronic_hr_f : 0,
            diesel_hr_i:props.data !== undefined ? props.data.diesel_hr_i : 0,
            diesel_hr_f:props.data !== undefined ? props.data.diesel_hr_f : 0,
        })




    },[props.data])

    useEffect(()=>{
        if(state.listaRes.length>0){
            let inListSup = areInList(11)
            let inListOff = areInList(38)
            let inListAyu = areInList(47)
            let inListOpe = []
            let inListPol = areInList(36)
            if(getOperatorID(state.operation.id) == 40 || getOperatorID(state.operation.id == 41)){
                inListOpe = areInList(40)
                inListOpe.push(...areInList(41))
            }else{
                inListOpe = areInList(getOperatorID(state.operation.id))
            }
            setState({...state,supervisores:assisted(inListSup),oficiales:assisted(inListOff),ayudantes:assisted(inListAyu),operadores:assisted(inListOpe),polvoreros:assisted(inListPol)})
        }else{
            setState({...state,supervisores:[],oficiales:[],ayudantes:[],operadores:[],polvoreros:[]})
        }
    },[state.listaRes])
   
    const assisted = (inList) =>{
        let day = getStringDay(new Date().getDay())
        let conAssist
        inList.map(el=>{
            conAssist = state.listaRes.filter((item)=>{
                if(item.category == el.categorie.name || item.category === el.categorie.name){
                    if(item[day] == 'Asistencia' || item[day] === 'Asistencia') return true
                }
                return false
            })
        })
        return conAssist
    }

    const areInList = (id) =>{
        let inList = []
        stateGenerales.users.map((user)=>{
            user.user_projects.map((item)=>{
                if(item.category_id == id || item.category_id === id){
                    inList.push(item)
                }
            })
        })
        return inList
    }

    const getOperatorID = (opId) =>{
        switch(opId){
            case 1:
            case 2:
                return 40;
            case 3:
            case 4:
                return 42;
            case 5:
                return 43
            case 6:
                return 44
            case 8:
                return 45
            case 9:
                return 46
            default:
                break;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        var formData = new FormData(form)

        var body = {};
        console.log(formData)
        formData.forEach((value, key) => {
            console.log(key,'key')
            console.log(value,'value')
            body[key] = value
        });
   
        // body.oficial = 1;
        //body.operator = 1;
        body.project_id = stateGenerales.project_id;
        body.user_id = body.supervisor;
        body.operation_id = body.operation;
        body.turn = state.lista.turn;
        // body.turn = body.turn

        console.log(body);


        if (form.checkValidity() === true) {
            showLoader();
            var response
            console.log(state.data)
            if(state.data === undefined){
                
                let formulario = await _onCreateForm(body, props.operation);
               
                if(formulario){
                    setState({...state, data:formulario});
                    props.onCreate(formulario.id)
                }
            
            }else{
             
                let formulario = await _onEditForm(body, state.data.id, props.operation);
               
              

            }
            hideLoader();
            var view = document.querySelector("main")
            view.scrollTo({top: 0, behavior: 'smooth'});


        }

        setValidated(true);
    };

    const onChangeEquipo = (maquinariaId) => {
       

        let maquinaria = selectFromObjbyId(stateGenerales.maquinarias,parseInt(maquinariaId));
        
        setState({...state, equipo:maquinaria.equipment, capacidad: maquinaria.capacity})
    }


    return (
        <Context.Provider value={{
            state,
            setState,
            onChangeEquipo
        }}>
           
        <div className="form_general pb-5 mr-2 sticky-left bg-white border-radius-0">
            <div className="bg-blue text-white d-flex justify-content-center align-items-center p-2 mb-2 font-weight-bold">Formulario General</div>
            {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <General />
                <Equipo />
                <Unidad />

                {state.operation.id == 10 ?
                <Voladuras />
                : null }

                <Tproceso />
                <Form.Group as={Col}>
                <Button type="submit">GUARDAR</Button>
                </Form.Group>
            </Form> 
        </div>
        </Context.Provider>
    )

}


function General(props) {


    const {state,setState} = useContext(Context);
    const {stateGenerales,setStateGenerales} = useContext(FormularioGeneralContext);

    let listas = stateGenerales.listasAll.filter((el) =>{
        var date;
        if(state.data !== undefined){
            date = state.data.date
        }else{
            date = todayC()
        }
                            
       
        var startDate = Date.parse(el.date_start);
        var endDate = Date.parse(el.date_end);
        var today = Date.parse(date);
        
        if(today >= startDate && today <= endDate && el.project_id === stateGenerales.project_id){
            return true
        }
        return false;
        
    })

   /*      console.log(stateGenerales.listasAll);
        console.log(state.data.date)
        console.log(listas) */

    

    return (
        <>
        <div className="text-center"><strong>GENERAL</strong></div>
        <Etiqueta label="OPERACIÓN" value={state.data !== undefined ? state.data.operation.name : state.operation.name} />
        <HiddenField name="operation" value={state.data !== undefined ? state.data.operation.id : state.operation.id} />

        <TextField name="folio" label="FOLIO" value={state.data !== undefined ? state.data.folio : null} />

        <Etiqueta label="FECHA" value={state.data !== undefined ? state.data.date : todayC()} />
        <HiddenField name="date" value={state.data !== undefined ? state.data.date : todayC()} />


        <SelectField name="turn" label="TURNO" value={state.data !== undefined ? state.data.turn:null} onChange={async (evt)=>{

            if(evt.currentTarget.value !== ""){
                let lista = selectFromObjbyId(stateGenerales.listasAll,parseInt(evt.currentTarget.value));
                console.log(evt.target.label,'curr')
                var h_start = lista.hour_start.split(":")[0];
                var h_end = lista.hour_end.split(":")[0];
                var jornada = parseInt(h_end) - parseInt(h_start);
                let listaActual = await singleService("GET", "assists",evt.currentTarget.value)
                setState({...state, lista, jornada:jornada, listaRes:listaActual.data.users})
                
            }else{
                setState({...state, lista:null, jornada:null,listaRes:[]})
            }

        }}>
           

            <option value="" label="">Seleccionar</option>

            {
                listas.map((item,i) => {
                    console.log(i)
                    return (<option key={i} value={item.id}>{item.turn}</option>)
                })
            }
            

        </SelectField>

        {
            listas.length === 0 ? 
            <div className="form-message form-message--red">
                No hay listas de asistencias en esta semana para este  proyecto
            </div> : null
        }
        {
            state.listaRes.length === 0 && state.lista ? 
            <div className="form-message form-message--red">
                Esta lista de asistencia no tiene usuarios asignados.
            </div> : null
        }
        
     


        <Etiqueta label="SEMANA" value={state.data !== undefined ? state.data.week : state.lista !== null ? state.lista.week :null}/>


        <HiddenField name="week" label="SEMANA"  value={state.data !== undefined ? state.data.week : state.lista !== null ? state.lista.week :null} />

       
        <SelectField name="supervisor" label="SUPERVISOR" value={state.data !== undefined ? state.data.supervisor.id : null}>

            <option value="">Seleccionar</option>
            {state.supervisores.map((user,i)=>{
                console.log(user)
                return (<option key={i} value={user.user_id}>{user.name}</option>)
            })}



        </SelectField>

        {state.operation.id  == 7 || state.operation.id == 10 ?  

            <>
            <Etiqueta label="OFICIAL"  value={state.oficial !== null ? state.oficial.name : null}/>
            <HiddenField name="oficial" value={state.oficial !== null ? state.oficial.user_id : null}></HiddenField>
            <Form.Group as={Col} >
                <Form.Label>ITEM</Form.Label><br></br>
                <DataListInput
                    inputClassName="form-control-n"
                    placeholder=""      
                    value={state.oficial !== null ? state.oficial.number : ""}
                    items={state.oficiales.map((item)=>({label:item.number,key:item.user_id,...item}))}
                    onInput = {(a) => {
                        setState({...state, oficial:null});
                    }}
                    onSelect={(el)=>{
                        console.log(el,"el")
                        setState({...state, oficial:el});
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    Este campo es obligatorio.
                </Form.Control.Feedback>
            </Form.Group>


     
            </>

        : null }


        {state.operation.id !== 7 && 
        state.operation.id !== 10 &&
        state.operation.id !== 1 ? 
            <>
            
            <Etiqueta label="OPERADOR"  value={state.operador !== null ? state.operador.name : null}/>
            <HiddenField name="operator" value={state.operador !== null ? state.operador.user_id : null}></HiddenField>
            <Form.Group as={Col} >
                <Form.Label>ITEM</Form.Label><br></br>
                <DataListInput
                    inputClassName="form-control-n"
                    placeholder=""      
                    value={state.operador !== null ? state.operador.number : ""}
                    items={state.operadores.map((item)=>({label:item.number,key:item.user_id,...item}))}
                    onInput = {(a) => {
                        setState({...state, operador:null});
                    }}
                    onSelect={(el)=>{
                      
                        setState({...state, operador:el});
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    Este campo es obligatorio.
                </Form.Control.Feedback>
            </Form.Group>
            </>
            :null
        }

        {
        state.operation.id == 4 ||
        state.operation.id == 5 ||
        state.operation.id == 7 ||
        state.operation.id == 8 ||
        state.operation.id == 10 ? 
        <>   
        <Etiqueta label="AYUDANTE"  value={state.ayudante !== null ? state.ayudante.name : null}/>
        <HiddenField name="assistant" value={state.ayudante !== null ? state.ayudante.user_id : null}></HiddenField>
    

        <Form.Group as={Col} >
            <Form.Label>ITEM</Form.Label><br></br>
            <DataListInput
                inputClassName="form-control-n"
                placeholder=""      
                value={state.ayudante !== null ? state.ayudante.number : ""}
                items={state.ayudantes.map((item)=>({label:item.number,key:item.user_id,...item}))}
                onInput = {(a) => {
                    setState({...state, ayudante:null});
                }}
                onSelect={(el)=>{
                  
                    setState({...state, ayudante:el});
                }}
            />
            <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
            </Form.Control.Feedback>
        </Form.Group>
        </>

        :null}

        </>
    )
}
function Equipo(){
    const {state,setState, onChangeEquipo} = useContext(Context);
    const {stateGenerales,setStateGenerales} = useContext(FormularioGeneralContext);


    let maquinarias = stateGenerales.maquinarias.filter((item) => {
       

        let has_operation;
        let has_project;

        if(state.data){

            if(state.operation.id === 9){
                has_operation = item.machinary_operations.filter(item => item.operation == "TRANSPORTE DE PERSONAL Y MATERIALES" )
            }else{
                has_operation = item.machinary_operations.filter(item => item.operation == state.data.operation.name )
            }
            has_project = item.machinary_projects.filter(item => item.project_id == stateGenerales.project_id )
        }else{


            if(state.operation.id === 9){
                has_operation = item.machinary_operations.filter(item => item.operation == "TRANSPORTE DE PERSONAL Y MATERIALES" )
            }else{
                has_operation = item.machinary_operations.filter(item => item.operation == state.operation.name )
            }


            has_project = item.machinary_projects.filter(item => item.project_id == stateGenerales.project_id )
        }

        if(has_operation.length !== 0 && has_project.length !== 0){
            return true
        }
        return false;

    })


     


    return(
        <>
        <div className="text-center"><strong>EQUIPO</strong></div>
        <SelectField name="machinarie_id" label="NO. ECON" value={state.data !== undefined ? state.data.machinarie_id: null}  onChange={(evt)=>{
            onChangeEquipo(evt.currentTarget.value)
        }}>
        <option value ="">Seleccionar</option>
        
            {maquinarias.map((maqui,i)=>{

                return (<option key={i} value ={maqui.id}>{maqui.economic_number}</option>)
                
            })}

        </SelectField>

        {
            maquinarias.length === 0 ? 
            <div className="form-message form-message--red">
                No hay maquinarias asignadas a este proyecto o operación
            </div> : null
        }



        <HiddenField name="equipment_id" label="equipment_id" value={state.equipo !== null ? state.equipo.id: null} />

        

        {
        state.operation.id == 1 ||
        state.operation.id == 2 ||
        state.operation.id == 3 ||
        state.operation.id == 9  ? 
        <Etiqueta label="EQUIPO" value={state.equipo !== null ? state.equipo.name : null}></Etiqueta>
        : null}

        {
        state.operation.id == 1 ||
        state.operation.id == 2 ||
        state.operation.id == 6  ?
        <Etiqueta label="CAPACIDAD" value={state.capacidad}></Etiqueta>
        : null}
        </>
    )
       
}
function Unidad(){

    const {state,setState, onChangeEquipo} = useContext(Context);
    const {stateGenerales,setStateGenerales} = useContext(FormularioGeneralContext);

    return(
        <>
 
            <NumberField name="diesel_hr_i" step="any" label="DIESEL HR I." 
                value={state.diesel_hr_i}
                onChange={(evt)=>{
                    let val = evt.currentTarget.value !== "" ? parseFloat(evt.currentTarget.value) : 0;
                    setState({...state,diesel_hr_i:val })
                }}
                
            />
            <NumberField name="diesel_hr_f" step="any" label="DIESEL HR F" 
                value={state.diesel_hr_f}
                onChange={(evt)=>{
                    let val = evt.currentTarget.value !== "" ? parseFloat(evt.currentTarget.value) : 0;
                    setState({...state,diesel_hr_f:val })
                }}
            />

            {
            state.operation.id == 4 ||
            state.operation.id == 8  ? 
            <>
      
                <NumberField name="electronic_hr_i" step="any" label="ELÉCTRICO HR I." 
                    value={state.electronic_hr_i}
                    onChange={(evt)=>{
                        let val = evt.currentTarget.value !== "" ? parseFloat(evt.currentTarget.value) : 0;
                        console.log(evt.currentTarget.value)
                        setState({...state,electronic_hr_i:val })
                    



                    /*    if(evt.currentTarget.value !== ""){
                            if(state.electronic_hr_f !== null && state.electronic_hr_f !== "" ){
                                console.log("MAKE")
                                let hours_worked = Math.abs(parseFloat(evt.currentTarget.value) - state.electronic_hr_i);
                                setState({...state,hours_worked,electronic_hr_i:  parseFloat(evt.currentTarget.value) })
                            }else{
                                setState({...state,hours_worked:"",electronic_hr_i:parseFloat(evt.currentTarget.value) })
                            }
                        }else{
                            setState({...state,hours_worked:"",electronic_hr_i:evt.currentTarget.value})
                        } */
                    }}
                />
                <NumberField name="electronic_hr_f" step="any" label="ELÉCTRICO HR F." 
                    value={state.electronic_hr_f}  
                    onChange={(evt)=>{
                        let val = evt.currentTarget.value !== "" ? parseFloat(evt.currentTarget.value) : 0;
                        setState({...state,electronic_hr_f:val })
                    /*   if(evt.currentTarget.value !== ""){
                            if(state.electronic_hr_i !== null && state.electronic_hr_i !== "" ){
                                let hours_worked = Math.abs(parseFloat(evt.currentTarget.value) - state.electronic_hr_i);
                                setState({...state,hours_worked, electronic_hr_f:parseFloat(evt.currentTarget.value) })
                            }else{
                                setState({...state,hours_worked:"", electronic_hr_f:parseFloat(evt.currentTarget.value) })
                            }
                        }else{
                            setState({...state,hours_worked:"",electronic_hr_f:evt.currentTarget.value})
                        } */
                    }}
                />
                <NumberField name="percussion_hr_i" step="any" label="PERCUSIÓN HR I." value={state.data !== undefined ? state.data.percussion_hr_i : null}  />
                <NumberField name="percussion_hr_f" step="any" label="PERCUSIÓN HR F." value={state.data !== undefined ? state.data.percussion_hr_f : null}  />
            </>
            :null}
            <NumberField name="charge_fuel" step="any" label="CARGA DE COMBUSTIBLE (LTS.)" value={state.data !== undefined ? state.data.charge_fuel : null}  />
            <NumberField name="load_meter" step="any" label="HORÓMETRO DE CARGA" value={state.data !== undefined ? state.data.load_meter : null}  />        
        </>

    )
}
function Voladuras(){

    const {state,setState, onChangeEquipo} = useContext(Context);
    const {stateGenerales,setStateGenerales} = useContext(FormularioGeneralContext);

    let maquinarias = stateGenerales.maquinarias.filter((item) => {

        let has_operation;
        let has_project;

        if(state.data){
            has_operation = item.machinary_operations.filter(item => item.operation == state.data.operation.name )
            has_project = item.machinary_projects.filter(item => item.project_id == stateGenerales.project_id )
        }else{
            has_operation = item.machinary_operations.filter(item => item.operation == state.operation.name )
            has_project = item.machinary_projects.filter(item => item.project_id == stateGenerales.project_id )
        }

        if(has_operation.length !== 0 && has_project.length !== 0){
            return true
        }
        return false;

    })


    let users = stateGenerales.users.filter((user)=>{

        let categoria = user.user_projects.filter(item => item.category_id == 36)
        if(categoria.length > 0 ){
            return true;
        }
        return false
    })


    const items = useMemo(
        () =>
        users.map((item) => ({ label: item.number, key: item.id, ...item})),
        [stateGenerales.users]
      );



    return(
        <>
        <div className="text-center"><strong>VOLADURAS</strong></div>


        <Etiqueta label="polvorero"  value={state.polvorero !== null ? state.polvorero.name : null}/>
        <HiddenField name="polvorero" value={state.polvorero !== null ? state.polvorero.user_id : null}></HiddenField>
        
    

        <Form.Group as={Col} >
            <Form.Label>ITEM</Form.Label><br></br>
            <DataListInput
                inputClassName="form-control-n"
                placeholder=""      
                value={state.polvorero !== null ? state.polvorero.number : ""}
                items={state.polvoreros.map((item)=>({label:item.number,key:item.user_id,...item}))}
                onInput = {(a) => {
                    setState({...state, polvorero:null});
                }}
                onSelect={(el)=>{
                   
                    setState({...state, polvorero:el});
                }}
            />
            <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
            </Form.Control.Feedback>
        </Form.Group>

        {
            users.length === 0 ? 
            <div className="form-message form-message--red">
                No hay polveros asigandos a este proyecto
            </div> : null
        }

        

        <SelectField name="polvorero_equipment_id" label="NO. ECON." value={state.data !== undefined ? state.data.polvorero_equipment_id : null}  onChange={(evt)=>{

            onChangeEquipo(evt.currentTarget.value)
            }}>
            <option value ="">Seleccionar</option>
            { maquinarias.map((item,i)=>{
                
                return (<option key={i} value ={item.id}>{item.equipment.name}</option>)
            })}
        </SelectField>


        <TextField name="polvorero_km_i"  label="KM I." value={state.data !== undefined ? state.data.polvorero_km_i : null}/>
        <TextField name="polvorero_km_f" label="KM F." value={state.data !== undefined ? state.data.polvorero_km_f : null} />
        <NumberField name="polvorero_charge_fuel" label="CARGA DE COMBUSTIBLE (LTS.)" value={state.data !== undefined ? state.data.polvorero_charge_fuel : null} /> 
        <NumberField name="polvorero_load_meter" label="HORÓMETRO DE CARGA" value={state.data !== undefined ? state.data.polvorero_load_meter : null}/>    
        </>


    )

}
function Tproceso(){
    const {state,setState, onChangeEquipo} = useContext(Context);
    const {stateGenerales,setStateGenerales} = useContext(FormularioGeneralContext);

    const getHoursWorked = () => {

        if(state.operation.id == 4 || state.operation.id == 8 ){
            return ((parseInt(state.diesel_hr_f) - parseInt(state.diesel_hr_i)) + (parseInt(state.electronic_hr_i) - parseInt(state.electronic_hr_f))).toFixed(1); 
        }else{
            return (parseInt(state.diesel_hr_f) - parseInt(state.diesel_hr_i)).toFixed(1);
        }

        
    }

    return(
        <>
        <div className="text-center"><strong>T. PROCESO</strong></div><br></br>


        <NumberField name="satellite_team_hours" step="any" label="HORAS EQUIPO SATELITAL" 
            required={false} 
            value={state.satellite_team_hours} 
            onChange={(evt)=>{
            setState({...state, satellite_team_hours:evt.currentTarget.value})
        }}/>  

        <NumberField name="hours_reparation" step="any" label="HORAS EN REPARACIÓN" 
            required={false} 
            value={state.hours_reparation} 
            onChange={(evt)=>{
            setState({...state, hours_reparation:evt.currentTarget.value})
        }}/>  
        
        
        <Etiqueta label="HORAS TRABAJADAS" value={getHoursWorked()}/>
        <HiddenField name="hours_worked" value={getHoursWorked()} />

        
        {/* <NumberField name="hours_worked" step="any" label="HORAS TRABAJADAS" 
            required={false} 
            value={state.hours_worked} 
            onChange={(evt)=>{
            setState({...state, hours_worked:evt.currentTarget.value})
        }}/>  */}

        <NumberField name="projected_effective_hours" step="any" label="HORAS EFECTIVAS PROYECTADAS" 
            required={false} 
            value={state.projected_effective_hours} 
            onChange={(evt)=>{
            setState({...state, projected_effective_hours:evt.currentTarget.value})
        }}/>  
        
        
        <NumberField name="dead_hours" step="any" label="HORAS MUERTAS" 
        required={false} 
        value={state.data !== undefined ? state.data.dead_hours : null}/> 


        {/* <NumberField name="hour_dead_laboral" label="HORAS MUERTAS/JORNADA" value={state.data !== undefined ? state.data.hour_dead_laboral : null}/>   */}

        <Etiqueta label="HORAS MUERTAS/JORNADA" value={(state.jornada - getHoursWorked() -  state.satellite_team_hours - state.hours_reparation).toFixed(1)}/>
        <HiddenField name="hour_dead_laboral" value={state.jornada - getHoursWorked() -  state.satellite_team_hours - state.hours_reparation} />

        {/*<NumberField name="hour_dead_effective" label="HORAS MUERTAS/HORAS EFECTIVAS PROYECTADAS" value={state.data !== undefined ? state.data.hour_dead_effective : null} />   */}
        <Etiqueta label="HORAS MUERTAS/HORAS EFECTIVAS PROYECTADAS" value={(state.projected_effective_hours - getHoursWorked() -  state.satellite_team_hours - state.hours_reparation).toFixed(1)}/>
        <HiddenField name="hour_dead_effective" value={(state.projected_effective_hours - getHoursWorked() -  state.satellite_team_hours - state.hours_reparation).toFixed(1)} />


        <TextAreaField name="comments" label="COMENTARIOS T. PROCESOS" value={state.data !== undefined ? state.data.comments : null}/>


        </>
    )
}
function Etiqueta(props){
    return(
        <>
        <Form.Group as={Col}>
            <Form.Label>{props.label}</Form.Label>
            <div className="form-control disabled">{props.value}</div>
        </Form.Group>
        </>
    )
}
function TextField(props){
    return(
        <>
        <Form.Group as={Col}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control type="text" name={props.name} defaultValue={props.value} required={props.required} disabled={props.disabled}/>
            <Form.Control.Feedback type="invalid">
                {props.feedback}
            </Form.Control.Feedback>
        </Form.Group>
        </>
    )
}
function HiddenField(props){
    return(
        <>
        <Form.Group as={Col}>
        
        
            <Form.Control type="hidden" name={props.name} defaultValue={props.value} required={props.required} disabled={props.disabled}/>
            <Form.Control.Feedback type="invalid">
                {props.feedback}
            </Form.Control.Feedback>
        </Form.Group>
        </>
    )
}

function NumberField(props){
    
    return(
        <>
            <Form.Group as={Col}>
                <Form.Label>{props.label}</Form.Label>
                <Form.Control type="number" min={props.min} step={props.step} name={props.name} defaultValue={props.value} required={props.required} onChange={(evt)=>{
                if(props.onChange){
                    props.onChange(evt)
                }
            }} />
                <Form.Control.Feedback type="invalid">
                    {props.feedback}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    )
}
function SelectField(props){
   
    return (
        <>
        <Form.Group as={Col}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control as="select" name={props.name} defaultValue={props.value} required={props.required} onChange={(evt)=>{props.onChange(evt)}}>
                {props.children === undefined ? 
                    <>
                    <option value ="">Seleccionar</option>
                    <option value ="">Seleccion 1</option>
                    <option value ="">Seleccion 2</option>
                    </>
                
                : props.children}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                {props.feedback}
            </Form.Control.Feedback>
        </Form.Group>

        </>
    )
}

function DateField(props){
    return(
        <>
        <Form.Group as={Col}  className="">
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                required={props.required}
                type="date"
                name={props.name}
                defaultValue={props.value}
                disabled={props.disabled}
            />
            <Form.Control.Feedback type="invalid">
            {props.feedback}
            </Form.Control.Feedback>
        </Form.Group>
        </>
    )    
}

function TimeField(props){
    return(
        <>
        <Form.Group as={Col}  className="">
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                required={props.required}
                type="time"
                name={props.name}
                defaultValue={props.value}
            />
            <Form.Control.Feedback type="invalid">
            {props.feedback}
            </Form.Control.Feedback>
        </Form.Group>
        </>
    )    
}

function TextAreaField(props){
    return (
        <Form.Group as={Col}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control as="textarea" rows={3} name={props.name} defaultValue={props.value} required={props.required}/>
            <Form.Control.Feedback type="invalid">
               {props.feedback}
            </Form.Control.Feedback>
        </Form.Group>
    )
}


Etiqueta.defaultProps = {
    value:"",
    label:"LABEL"
}
TextField.defaultProps = {
    value:"",
    required:true,
    feedback: "Este campo es obligatorio",    
    label:"LABEL",
    disabled:false
}
HiddenField.defaultProps = {
    value:"",
    required:true,
    feedback: "Este campo es obligatorio",    
    label:"LABEL",
    disabled:false
}
NumberField.defaultProps = {
    min:"0",
    step:"1",
    value:"",
    required:true,
    feedback: "Este campo es obligatorio. Numeros positivos.",    
    label:"LABEL"
}
SelectField.defaultProps = {
    value:"",
    required:true,
    feedback: "Este campo es obligatorio",    
    label:"LABEL",
    onChange:()=>{}
    
}
DateField.defaultProps = {
    value:"",
    required:true,
    feedback: "Este campo es obligatorio",    
    label:"LABEL",
    disabled:false
}
TimeField.defaultProps = {
    value:"",
    required:true,
    feedback: "Este campo es obligatorio",    
    label:"LABEL"
}
TextAreaField.defaultProps = {
    value:"",
    required:true,
    feedback: "Este campo es obligatorio",    
    label:"LABEL" 
}


export default FormularioGeneral 
